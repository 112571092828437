import React, { ReactNode } from 'react';
import styled from 'styled-components';

import { DeviceQueries } from 'Common/src/components/styled';

interface Props {
  icon: ReactNode;
  text: ReactNode;
  subtext?: ReactNode;
  margin?: boolean;
  color?: string;
  onClick?: () => void;
}

const CallToAction = ({ icon, text, subtext, margin = true, color = 'currentColor', onClick }: Props) => {
  const hasSub = subtext !== undefined;

  return <Container onClick={onClick} $margin={margin}>
    <IconContainer>
      {icon}
    </IconContainer>
    <TextContainer $color={color}>
      <TextMain $hasSub={hasSub}>{text}</TextMain>
      {hasSub ? <TextSub>{subtext}</TextSub> : null}
    </TextContainer>
  </Container>;
};

const Container = styled.div<{ $margin: boolean }>`
  display: flex;
  ${({ $margin }) => $margin ? 'margin: 25px 0;' : ''}
  cursor: pointer;
  align-items: center;
`;

const IconContainer = styled.span`
  @media (${DeviceQueries.mobile}) {
    min-width: 35px;
  }
`;

const TextContainer = styled.span<{ $color: string }>`
  color: ${({ $color }) => $color};
`;

const TextMain = styled.div<{ $hasSub: boolean }>`
  margin: ${({ $hasSub }) => $hasSub ? '0 12px' : '0 0 7px 12px'};
  font-size: 24px;
  line-height: 1;
`;

const TextSub = styled.div`
  font-size: 18px;
  margin: 0 12px;
`;

export default CallToAction;
