import React from 'react';

const LinkIcon = ({ size = 28, color = 'currentColor', title }: { size?: number, color?: string, title?: string }) => {
  return <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <title>{title}</title>
    <path
      d="M12.918 16.5902L9.24585 20.2624C9.24585 20.2624 9.24585 20.2624 9.24576 20.2624C9.24576 20.2624 9.24576 20.2625 9.24568 20.2625C7.72715 21.7811 5.25617 21.7812 3.73746 20.2625C3.00174 19.5268 2.59666 18.5487 2.59666 17.5083C2.59666 16.4681 3.00174 15.4901 3.7372 14.7544C3.73729 14.7543 3.73738 14.7542 3.73746 14.7541L7.40958 11.0819C7.91653 10.5749 7.91653 9.7528 7.40949 9.24585C6.90254 8.73889 6.08043 8.73889 5.57339 9.24585L1.90128 12.918C1.90102 12.9183 1.90076 12.9187 1.9005 12.9189C0.674959 14.1449 0 15.7748 0 17.5083C0 19.2423 0.675219 20.8725 1.90136 22.0986C3.16698 23.3642 4.82927 23.997 6.49166 23.997C8.15404 23.997 9.81642 23.3642 11.0819 22.0986C11.082 22.0986 11.082 22.0985 11.082 22.0985L14.7541 18.4263C15.261 17.9193 15.261 17.0972 14.754 16.5902C14.2471 16.0832 13.4251 16.0832 12.918 16.5902Z"
      fill={color}/>
    <path
      d="M24 6.49165C24 4.75768 23.3247 3.1275 22.0985 1.90136C19.5674 -0.629698 15.449 -0.629612 12.918 1.90136C12.918 1.90153 12.9178 1.90162 12.9177 1.90179L9.24567 5.57373C8.73863 6.08068 8.73863 6.90287 9.24567 7.40983C9.49928 7.66344 9.83148 7.79015 10.1638 7.79015C10.496 7.79015 10.8283 7.66335 11.0818 7.40983L14.7538 3.73789C14.7539 3.73772 14.7541 3.73763 14.7542 3.73746C16.2728 2.21893 18.7437 2.21884 20.2624 3.73746C20.9981 4.47318 21.4033 5.45134 21.4033 6.49165C21.4033 7.53187 20.9982 8.50986 20.2627 9.24558L20.2624 9.24584L16.5903 12.918C16.0834 13.425 16.0834 14.2471 16.5904 14.7541C16.8439 15.0077 17.1762 15.1345 17.5084 15.1345C17.8407 15.1345 18.173 15.0077 18.4265 14.7541L22.0986 11.0819C22.0989 11.0817 22.0991 11.0813 22.0994 11.0811C23.3249 9.85511 24 8.22518 24 6.49165Z"
      fill={color}/>
    <path
      d="M7.40976 16.5902C7.66328 16.8437 7.99557 16.9705 8.32777 16.9705C8.66005 16.9705 8.99234 16.8437 9.24586 16.5902L16.5903 9.24575C17.0973 8.7388 17.0973 7.91669 16.5903 7.40965C16.0833 6.9027 15.2612 6.9027 14.7542 7.40965L7.40976 14.754C6.90272 15.2611 6.90272 16.0832 7.40976 16.5902Z"
      fill={color}/>
  </svg>;
};

export default LinkIcon;
