import React, { MouseEvent, ReactNode, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import { useUserContext } from '../../contexts/UserContext';
import { UEventFormField, useEditEventContext } from '../../contexts/EditEventContext';
// import { useCoinModalContext } from '../../contexts/CoinModalContext';
// import { useCoinContext } from '../../contexts/CoinContext';
import ExpandingTextInput from '../../components/ExpandingTextInput';
import { IUEvent } from '../../lib/event';
import { StyleLabels } from '../../lib/styles';
import { ErrorContainer } from '../../components/forms/EventForm';
import { ShadedBox } from '../BasePage';
import EventImageHistoryModal from '../../components/modals/EventImageHistoryModal';
import { useModalContext } from '../../contexts/ModalContext';
import ImageStyleDropdownButton from '../../components/buttons/ImageStyleDropdownButton';
import { getEventTintColor } from '../../lib/colorPicker';
import { useBackgroundContext } from '../../contexts/BackgroundContext';
import { ActionMenuLabel } from '../../components/EventForm/ActionMenuCommon';
import Sparkles from '../../components/icons/Sparkles';
import { DesktopShow } from '../../components/styled/Common';
import { getNormalizedPhotoUrl } from '../../lib/images';
import ColorButton from '../../components/buttons/ColorButton';
import { PillInputActionButton } from '../../components/EventForm/Common';
import PlusCircle from '../../components/icons/PlusCircle';

import { DeviceQueries } from 'Common/src/components/styled';
import { TimeoutError } from 'Common/src/utils/PromiseTimeout';
import { logSumoEvent, ULogApplication, ULogSeverity, ULogTag } from 'Common/src/api/SumoLogicApi';

import { TAppElkImage, TAppElkImageStyle } from 'TProtocol/prototypes/events/messages';

declare const ELK_COINS_ENABLED: boolean;

const GeneratedImageSelection = ({ event, excludeImage, onImageGenerate, onAddImage }: {
  event: IUEvent,
  excludeImage: boolean,
  onImageGenerate?: () => void;
  onAddImage?: () => void;
}) => {
  const userContext = useUserContext();
  const backgroundContext = useBackgroundContext();
  const editEventContext = useEditEventContext();
  // const coinContext = useCoinContext();
  // const coinModalContext = useCoinModalContext();
  const modalContext = useModalContext();

  const imagePlaceholderRef = useRef<HTMLDivElement>(null);
  const loading = event.loading;
  //const [loading, setLoading] = useState(false);
  const [timedOut, setTimedOut] = useState(false);
  const [showNoPromptMessage, setShowNoPromptMessage] = useState(false);
  const [style, setStyle] = useState<TAppElkImageStyle | undefined>();
  const [eventGeneratedImages, setEventGeneratedImages] = useState<TAppElkImage[]>([]);

  const mounted = useRef(true);

  const systemBackgroundColor = backgroundContext.systemColors.background;

  useEffect(() => {
    void fetchEventImages();

    return () => {
      mounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (event.aiGeneratedPhotoUrls && (event.photoUrl === undefined || event.photoUrl === '')) {
      editEventContext.setPhotoUrl(event.aiGeneratedPhotoUrls[0]);
      editEventContext.setIsLoading(false);
      //setLoading(false);

    }
  }, [event.aiGeneratedPhotoUrls]);

  const fetchEventImages = async () => {
    if (userContext.isLoggedIn()) {
      const imageHistory = await userContext.fetchEventImageHistory();
      if (mounted.current) {
        setEventGeneratedImages(imageHistory.images.filter(image => image.eventUuid === event.id));
      }
    }
  };

  const onAddImageClick = (e: MouseEvent) => {
    e.preventDefault();
    if (onAddImage !== undefined) {
      onAddImage();
    }
  };

  const onImageClick = (imageUrl: string) => {
    close();
    editEventContext.setPhotoUrl(imageUrl);
  };

  const showImageHistoryModal = () => {
    modalContext.show({
      contents: <EventImageHistoryModal close={modalContext.hide} event={event}/>
    });
  };

  const onRegenerate = async (e?: MouseEvent) => {
    e?.preventDefault();

    void logSumoEvent({
      app: ULogApplication.ELK,
      severity: ULogSeverity.INFO,
      userId: userContext.id,
      tag: ULogTag.UserAction,
      message: '[EditableEventPage] Clicked on generate'
    });

    if (loading) {
      return;
    }

    // if (ELK_COINS_ENABLED && coinContext.getBalance() < 3) {
    //   if (!userContext.isLoggedIn()) {
    //     userContext.login();
    //     return;
    //   }
    //
    //   coinModalContext.showMenuModal({ needed: 3 });
    //   return;
    // }

    if (!event.title && !event.prompt) {
      setShowNoPromptMessage(true);
      return;
    }

    if (onImageGenerate) {
      onImageGenerate();
    }

    editEventContext.clearPhotoUrls();
    editEventContext.setIsLoading(true);
    //setLoading(true);
    setTimedOut(false);
    setShowNoPromptMessage(false);

    try {
      // imagePlaceholderRef.current?.scrollIntoView({ behavior: 'smooth' });

      const actualStyle = (style !== undefined && +style === TAppElkImageStyle.DEFAULT) ? undefined : style;
      await editEventContext.fetchEventSuggestion(event, actualStyle);
    } catch (e) {
      if (e instanceof TimeoutError) {
        setTimedOut(true);
      }
    }
  };

  let dropdownText: ReactNode;
  const isPhotoEmpty = event.photoUrl === undefined || event.photoUrl === '';
  if (style !== undefined && +style !== TAppElkImageStyle.DEFAULT) {
    dropdownText = StyleLabels[style];
  } else {
    dropdownText = 'None';
  }

  let topContent: ReactNode;
  if (loading) {
    topContent = <>
      <BigImagePlaceholderContainer>
        <BigImagePlaceholder $backgroundRgba={systemBackgroundColor}>

          <Loader>
            <div className={'loader'}></div>
          </Loader>
          <LoaderText>{'Working on it! '}</LoaderText>
          <LoaderText>{'(typically takes 10 seconds)'}</LoaderText>
        </BigImagePlaceholder>
      </BigImagePlaceholderContainer>
    </>;
  } else if (isPhotoEmpty) {
    topContent = <BigImagePlaceholderContainer>
      <BigImagePlaceholder $backgroundRgba={systemBackgroundColor}>
        {loading ? <LoaderWrapper>
          <Loader>
            <div className={'loader'}></div>
          </Loader>
          <LoaderText>{'Working on it! '}</LoaderText>
          <LoaderText>{'(typically takes 10 seconds)'}</LoaderText>
        </LoaderWrapper> : <PillInputActionButton $fontSize={18} onClick={onAddImageClick}>
          <PlusCircle/> Add image
        </PillInputActionButton>}
      </BigImagePlaceholder>
    </BigImagePlaceholderContainer>;
  } else {
    topContent = <ImageContainer ref={imagePlaceholderRef}>
      <BigImage src={event.photoUrl}></BigImage>
    </ImageContainer>;
  }

  let imageSelectContent: ReactNode;
  if (userContext.isLoggedIn()) {
    imageSelectContent = <ExtraImageActionContainer
      onClick={showImageHistoryModal}
      ref={(div) => editEventContext.setFormErrorDiv(UEventFormField.Image, div)}>
      <ImageActionLabel>
        Or select from your image gallery
      </ImageActionLabel>
    </ExtraImageActionContainer>;
  }

  let errorMessage: ReactNode = null;
  if (showNoPromptMessage) {
    errorMessage = <WarningMessage>
      Please give your event a title or supply a manual prompt before generating images.
    </WarningMessage>;
  } else if (timedOut) {
    errorMessage = <WarningMessage>
      Sorry, the image generation request timed out. Please try again.
    </WarningMessage>;
  } else if (event?.aiGeneratedPhotoUrls?.length === 0) {
    errorMessage = <WarningMessage>
      Sorry, something went wrong generating the images. Please try again. You can also try changing the prompt.
    </WarningMessage>;
  }

  return <Wrapper>
    {event.errors.missingImage ?
      <ShadedBox><ImageErrorContainer>Image is required</ImageErrorContainer></ShadedBox> : undefined}
    {excludeImage ? <></> : topContent}
    {excludeImage ? <ActionMenuLabel>
      Generate cover with AI
    </ActionMenuLabel> : <></>}
    {errorMessage}
    {excludeImage ? <>
      <ButtonWrapper>
        <ExpandingTextInput
          placeholder='A beautiful array for a birthday celebration with balloons, confetti, gifts, and a birthday cake that says "Happy birthday Stella"'
          value={event.prompt ?? ''}
          onChange={(value) => editEventContext.setPrompt(value)}
          onSubmit={() => onRegenerate()}
          minHeight={160.5}
          isForSideBar={true}
          noVertMargin={true}
        />

        <ImageStyleDropdownButton buttonText={dropdownText} disabled={loading}
                                  style={style ?? TAppElkImageStyle.DEFAULT} onStyleChange={setStyle}/>
        {loading ? <LoaderWrapper>
          <Loader>
            <div className={'loader'}></div>
          </Loader>
          <LoaderText>{'Working on it! '}</LoaderText>
          <LoaderText>{'(typically takes 10 seconds)'}</LoaderText>
        </LoaderWrapper> : null}

        {
          excludeImage ? <ColorButton onClick={onRegenerate}>
            <Sparkles color="#FFF" size={20}/>
            Make it shine
          </ColorButton> : null
        }
        {
          excludeImage ? <ExtraImageActionContainer
            onClick={showImageHistoryModal}
            ref={(div) => editEventContext.setFormErrorDiv(UEventFormField.Image, div)}>
            {
              excludeImage ? <ImageActionLabel $color={getEventTintColor(backgroundContext.colors.primary)}>
                Or upload/choose from gallery
              </ImageActionLabel> : null
            }
          </ExtraImageActionContainer> : <LoaderText/>
        }
      </ButtonWrapper>
      <DesktopShow>
        {eventGeneratedImages.length > 0 &&
          <ImageHistoryContainer>
            <ActionMenuLabel>Previous covers</ActionMenuLabel>
            <EventImagesContainer>
              {eventGeneratedImages.slice(0, 8).map((eventImage) => <EventImageContainer
                key={eventImage.imageUrl} onClick={() => onImageClick(eventImage.imageUrl)}>
                <EventImage src={getNormalizedPhotoUrl(eventImage.imageUrl)} width="80" height="80"/>
              </EventImageContainer>)}
            </EventImagesContainer>
            {eventGeneratedImages.length > 8 &&
              <WideLabelWrapper>
                <SeeMoreImagesLabel onClick={showImageHistoryModal}
                                    $color={getEventTintColor(backgroundContext.colors.primary)}>See more
                  covers</SeeMoreImagesLabel>
              </WideLabelWrapper>
            }
          </ImageHistoryContainer>
        }
      </DesktopShow>
    </> : <></>}
  </Wrapper>;
};

export const ImageContainer = styled.div`
`;

export const BigImage = styled.img`
  width: 400px;
  height: 400px;
  border: #5e6575 1pt;
  border-radius: 20px;

  @media (${DeviceQueries.mobile}) {
    width: 100%;
    height: auto;
    aspect-ratio: 1;
  }
`;

export const WarningMessage = styled.div`
  font-size: 16px;
  font-weight: bold;
  margin: auto;
  width: 400px;
  max-width: 100%;
  color: var(--shine-system-error-color);
  text-align: center;
`;

export const BigImagePlaceholderContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const BigImagePlaceholder = styled.div<{
  $backgroundRgba: string
}>`
  width: 400px;
  height: 400px;
  border-radius: 30px;
  background: rgba(255, 255, 255, 0.20);
  backdrop-filter: blur(40px);

  justify-content: center;
  align-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;

  @media (${DeviceQueries.mobile}) {
    width: 100%;
    height: auto;
    aspect-ratio: 1;
  }
`;

export const LoaderText = styled.div`
  z-index: 100;
  margin: 10px 10px 0;
  height: 10px;
`;

export const LoaderWrapper = styled.div`
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const Loader = styled.div`
  z-index: 100;


  .loader {
    width: 48px;
    height: 48px;
    border: 5px solid gray;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
  }

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  width: 100%;
  text-align: left;
  gap: 12px;
`;

export const ExtraImageActionContainer = styled.div`
`;

export const ImageActionLabel = styled.div<{
  $color?: string
}>`
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  cursor: pointer;
  ${({ $color }) => $color && `color: ${$color}`};
`;

export const Label = styled.label`
  font-size: 14px;
  font-weight: 300;
`;

export const ImageErrorContainer = styled(ErrorContainer)`
  margin: 5px 0;
  text-align: center;
`;

const EventImagesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 8px;
`;

const EventImageContainer = styled.div`
  border-radius: 10px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.10);
  height: 80px;
  cursor: pointer;
`;

const EventImage = styled.img`
  border-radius: 10px;
`;

const SeeMoreImagesLabel = styled(ImageActionLabel)`
  margin-top: 12px;
`;

const WideLabelWrapper = styled.div`
  width: 100%;
  text-align: center;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const ImageHistoryContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 12px;
`;

export default GeneratedImageSelection;
