export interface InspirationIdea {
  id: string;
  category: InspirationCategory;
  photoUrl: string;
  backgroundAnimationUrl?: string;
  title: string;
  description?: string;
  weekday?: number;
}

export enum InspirationCategory {
  BD = 'Birthdays',
  BK = 'Kids\' Birthdays',
  BF = 'Bonfire',
  BP = 'Book Party',
  BS = 'Baby Shower',
  CK = 'Cocktail Party',
  CP = 'Costume Party',
  DP = 'Dessert Party',
  GD = 'Garden Party',
  GN = 'Games Night',
  GP = 'Graduation Party',
  HH = 'Happy Hour',
  HO = 'Holidays',
  HP = 'Holiday Party',
  HW = 'Housewarming',
  MC = 'Miscellaneous',
  MN = 'Movie Night',
  MS = 'Miscellaneous',
  PP = 'Pizza Party',
  SE = 'Sporting Event',
  SP = 'Super Bowl Party',
  TP = 'Tea Party',
  WD = 'Wedding Shower or Brunch',
  WT = 'Wine Tasting'
}

const IC = InspirationCategory;
const Inspirations: InspirationIdea[] = [
    {
      id: '1',
      category: IC.BD,
      photoUrl: 'https://firebasestorage.googleapis.com/v0/b/elk-ga/o/events%2Finspirations%2FBirthday_Bash.jpg?alt=media',
      title: 'Birthday Bash',
      backgroundAnimationUrl: 'https://videos.pexels.com/video-files/5457412/5457412-sd_426_240_30fps.mp4'
    },
    {
      id: '2',
      category: IC.BD,
      photoUrl: 'https://firebasestorage.googleapis.com/v0/b/elk-ga/o/events%2Finspirations%2FBirthday_Party_Time.jpg?alt=media',
      title: 'Birthday Party Time',
      backgroundAnimationUrl: 'https://media1.giphy.com/media/LGBKlgMCKQbkDKcG4t/giphy.mp4?cid=27dcf2b2gaujfr9r71gnh92b06rmwf9e825pah0ew34mh7j9&ep=v1_gifs_search&rid=giphy.mp4&ct=g'
    },
    {
      id: '124',
      category: IC.BD,
      photoUrl: 'https://firebasestorage.googleapis.com/v0/b/elk-ga/o/events%2Finspirations%2FPainterly_Birthday.jpg?alt=media',
      title: 'Painterly Birthday',
      backgroundAnimationUrl: 'https://videos.pexels.com/video-files/3831835/3831835-sd_426_226_25fps.mp4'
    },
    {
      id: '3',
      category: IC.BD,
      photoUrl: 'https://firebasestorage.googleapis.com/v0/b/elk-ga/o/events%2Finspirations%2FRainbow_Birthday.jpg?alt=media',
      title: 'Rainbow Birthday',
      backgroundAnimationUrl: 'https://media1.giphy.com/media/3VFmCh30aiRlgwFu4I/giphy360p.mp4?cid=27dcf2b20caj5apqibgts6y6np6dfs22ohzjxus9ge2aq12c&ep=v1_videos_search&rid=giphy360p.mp4&ct=v'
    },
    {
      id: '4',
      category: IC.BD,
      photoUrl: 'https://firebasestorage.googleapis.com/v0/b/elk-ga/o/events%2Finspirations%2FRainbows_Galore_Birthday.jpg?alt=media',
      title: 'Rainbows Galore Birthday',
      backgroundAnimationUrl: 'https://media0.giphy.com/media/l0Iy4ppWvwQ4SXPxK/giphy.mp4?cid=27dcf2b2vovjnq2vcz6qsis15muhi3fitgalt6lh5ynu1hhh&ep=v1_gifs_search&rid=giphy.mp4&ct=g'
    },
    {
      id: '125',
      category: IC.BD,
      photoUrl: 'https://firebasestorage.googleapis.com/v0/b/elk-ga/o/events%2Finspirations%2FShiny_Presents.jpg?alt=media',
      title: 'Shiny Presents',
      backgroundAnimationUrl: 'https://videos.pexels.com/video-files/3927356/3927356-sd_426_226_25fps.mp4',
    },
    {
      id: '5',
      category: IC.BF,
      photoUrl: 'https://firebasestorage.googleapis.com/v0/b/elk-ga/o/events%2Finspirations%2FBeach_Bonfire.jpg?alt=media',
      title: 'Beach Bonfire',
      backgroundAnimationUrl: 'https://videos.pexels.com/video-files/7150235/7150235-sd_240_426_25fps.mp4'
    },
    {
      id: '126',
      category: IC.BF,
      photoUrl: 'https://firebasestorage.googleapis.com/v0/b/elk-ga/o/events%2Finspirations%2FCampfire_in_the_Forest.jpg?alt=media',
      title: 'Campfire in the Forest',
      backgroundAnimationUrl: 'https://videos.pexels.com/video-files/6190918/6190918-sd_240_426_30fps.mp4'
    },
    {
      id: '6',
      category: IC.BF,
      photoUrl: 'https://firebasestorage.googleapis.com/v0/b/elk-ga/o/events%2Finspirations%2FCampout.jpg?alt=media',
      title: 'Campout',
      backgroundAnimationUrl: 'https://media1.giphy.com/media/ISgNsVOWzCgIZatZdx/giphy360p.mp4?cid=27dcf2b26rm8rxmi0c1igrqptlj1a4du0zwgrdr32kisrqb2&ep=v1_videos_search&rid=giphy360p.mp4&ct=v'
    },
    {
      id: '7',
      category: IC.BF,
      photoUrl: 'https://firebasestorage.googleapis.com/v0/b/elk-ga/o/events%2Finspirations%2FPainterly_Bonfire.jpg?alt=media',
      title: 'Painterly Bonfire',
      backgroundAnimationUrl: 'https://videos.pexels.com/video-files/1535674/1535674-sd_640_360_24fps.mp4'
    },
    {
      id: '8',
      category: IC.BF,
      photoUrl: 'https://firebasestorage.googleapis.com/v0/b/elk-ga/o/events%2Finspirations%2FS_mores_Party.jpg?alt=media',
      title: 'S\'mores Party',
      backgroundAnimationUrl: 'https://videos.pexels.com/video-files/7148093/7148093-sd_426_240_30fps.mp4'
    },
    {
      id: '9',
      category: IC.BF,
      photoUrl: 'https://firebasestorage.googleapis.com/v0/b/elk-ga/o/events%2Finspirations%2FSurfer_Beach_Party.jpg?alt=media',
      title: 'Surfer Beach Party',
      backgroundAnimationUrl: 'https://videos.pexels.com/video-files/7660618/7660618-sd_426_240_25fps.mp4',
    },
    {
      id: '10',
      category: IC.BK,
      photoUrl: 'https://firebasestorage.googleapis.com/v0/b/elk-ga/o/events%2Finspirations%2FBounce_Birthday.jpg?alt=media',
      title: 'Bounce Birthday',
      backgroundAnimationUrl: 'https://media3.giphy.com/media/4KFvmHQnOMxWaYl1A8/giphy.mp4?cid=27dcf2b2hhnzomvobjza8zyjfsddv7sfl8c5cfp4l9ddjw1s&ep=v1_gifs_search&rid=giphy.mp4&ct=g'
    },
    {
      id: '11',
      category: IC.BK,
      photoUrl: 'https://firebasestorage.googleapis.com/v0/b/elk-ga/o/events%2Finspirations%2FDinosaur_Birthday.jpg?alt=media',
      title: 'Dinosaur Birthday',
      backgroundAnimationUrl: 'https://media1.giphy.com/media/X9QMZgqWlurpwLENmh/giphy360p.mp4?cid=27dcf2b218kcfp4nxc2mzahws6n6rcxcz49gg3bdnjed1cn8&ep=v1_videos_search&rid=giphy360p.mp4&ct=v'
    },
    {
      id: '12',
      category: IC.BK,
      photoUrl: 'https://firebasestorage.googleapis.com/v0/b/elk-ga/o/events%2Finspirations%2FFairytale_Birthday.jpg?alt=media',
      title: 'Fairytale Birthday',
      backgroundAnimationUrl: 'https://media3.giphy.com/media/KxzImHp0mWgHAtL76R/giphy.mp4?cid=27dcf2b274ozowvpxr6fevpv867om9vwqtddi6u9ufsrfzhm&ep=v1_gifs_search&rid=giphy.mp4&ct=g'
    },
    {
      id: '13',
      category: IC.BK,
      photoUrl: 'https://firebasestorage.googleapis.com/v0/b/elk-ga/o/events%2Finspirations%2FLego_Birthday.jpg?alt=media',
      title: 'Lego Birthday',
      backgroundAnimationUrl: 'https://media4.giphy.com/media/PG2R4Ue8PcBJrbP3DY/giphy360p.mp4?cid=27dcf2b2ocynosa113u4ypflokgpcc6rstubwil88cn39hl9&ep=v1_videos_search&rid=giphy360p.mp4&ct=v'
    },
    {
      id: '14',
      category: IC.BK,
      photoUrl: 'https://firebasestorage.googleapis.com/v0/b/elk-ga/o/events%2Finspirations%2FMermaid_Birthday.jpg?alt=media',
      title: 'Mermaid Birthday',
      backgroundAnimationUrl: 'https://videos.pexels.com/video-files/5639397/5639397-sd_226_426_25fps.mp4',
    },
    {
      id: '15',
      category: IC.BK,
      photoUrl: 'https://firebasestorage.googleapis.com/v0/b/elk-ga/o/events%2Finspirations%2FMythical_Dragon_Birthday.jpg?alt=media',
      title: 'Mythical Dragon Birthday',
      backgroundAnimationUrl: 'https://media1.giphy.com/media/8jFRQb37wDQG1WYRMV/giphy360p.mp4?cid=27dcf2b2f1n00u5cc70nuftrm0hfeepourc6dr53mm2emv9a&ep=v1_videos_search&rid=giphy360p.mp4&ct=v&cc=en'
    },
    {
      id: '16',
      category: IC.BK,
      photoUrl: 'https://firebasestorage.googleapis.com/v0/b/elk-ga/o/events%2Finspirations%2FNerf_Party.jpg?alt=media',
      title: 'Nerf Party',
      backgroundAnimationUrl: 'https://media4.giphy.com/media/hUy19NChXFw0U/giphy.mp4?cid=27dcf2b2dbiq56eiqaln5assfywpv5f803lwtoo2mfxepm3b&ep=v1_gifs_search&rid=giphy.mp4&ct=g'
    },
    {
      id: '17',
      category: IC.BK,
      photoUrl: 'https://firebasestorage.googleapis.com/v0/b/elk-ga/o/events%2Finspirations%2FUnicorn_Birthday.jpg?alt=media',
      title: 'Unicorn Birthday',
      backgroundAnimationUrl: 'https://videos.pexels.com/video-files/856949/856949-sd_640_360_30fps.mp4'
    },
    {
      id: '18',
      category: IC.BK,
      photoUrl: 'https://firebasestorage.googleapis.com/v0/b/elk-ga/o/events%2Finspirations%2FWet_and_Wild_Birthday.jpg?alt=media',
      title: 'Wet and Wild Birthday',
      backgroundAnimationUrl: 'https://videos.pexels.com/video-files/7293591/7293591-sd_640_360_30fps.mp4'
    },
    {
      id: '127',
      category: IC.BP,
      photoUrl: 'https://firebasestorage.googleapis.com/v0/b/elk-ga/o/events%2Finspirations%2FBooks__Books__and_More_Books.jpg?alt=media',
      title: 'Books, Books and More Books',
      backgroundAnimationUrl: 'https://videos.pexels.com/video-files/2268807/2268807-sd_426_240_24fps.mp4'
    },
    {
      id: '19',
      category: IC.BP,
      photoUrl: 'https://firebasestorage.googleapis.com/v0/b/elk-ga/o/events%2Finspirations%2FDazzle_of_Books.jpg?alt=media',
      title: 'Dazzle of Books',
      backgroundAnimationUrl: 'https://videos.pexels.com/video-files/854417/854417-sd_640_360_25fps.mp4'
    },
    {
      id: '20',
      category: IC.BP,
      photoUrl: 'https://firebasestorage.googleapis.com/v0/b/elk-ga/o/events%2Finspirations%2FFlying_Books.jpg?alt=media',
      title: 'Flying Books',
      backgroundAnimationUrl: 'https://media3.giphy.com/media/12qxR8tbj3qvq23V7z/giphy360p.mp4?cid=27dcf2b28qx1s4xyil0qrfvlhl4finzgmaeongefrwq1rtcf&ep=v1_videos_search&rid=giphy360p.mp4&ct=v'
    },
    {
      id: '21',
      category: IC.BP,
      photoUrl: 'https://firebasestorage.googleapis.com/v0/b/elk-ga/o/events%2Finspirations%2FFountain_Pen.jpg?alt=media',
      title: 'Fountain Pen',
      backgroundAnimationUrl: 'https://videos.pexels.com/video-files/6918503/6918503-sd_226_426_25fps.mp4'
    },
    {
      id: '22',
      category: IC.BP,
      photoUrl: 'https://firebasestorage.googleapis.com/v0/b/elk-ga/o/events%2Finspirations%2FGilded_Library.jpg?alt=media',
      title: 'Gilded Library',
      backgroundAnimationUrl: 'https://media3.giphy.com/media/iMT4zMGK0njAJbu53d/giphy360p.mp4?cid=27dcf2b2khlo5cresji4ebhbrm7t3ykpwiwf3i4te20capo5&ep=v1_videos_search&rid=giphy360p.mp4&ct=v'
    },
    {
      id: '23',
      category: IC.BP,
      photoUrl: 'https://firebasestorage.googleapis.com/v0/b/elk-ga/o/events%2Finspirations%2FLibrary_of_Academia.jpg?alt=media',
      title: 'Library of Academia',
      backgroundAnimationUrl: 'https://videos.pexels.com/video-files/854533/854533-sd_640_360_25fps.mp4'
    },
    {
      id: '128',
      category: IC.BS,
      photoUrl: 'https://firebasestorage.googleapis.com/v0/b/elk-ga/o/events%2Finspirations%2FGender_Reveal.jpg?alt=media',
      title: 'Gender Reveal',
      backgroundAnimationUrl: 'https://videos.pexels.com/video-files/9669111/9669111-sd_240_426_25fps.mp4'
    },
    {
      id: '24',
      category: IC.BS,
      photoUrl: 'https://firebasestorage.googleapis.com/v0/b/elk-ga/o/events%2Finspirations%2FGroovy_Baby_Shower.jpg?alt=media',
      title: 'Groovy Baby Shower',
      backgroundAnimationUrl: 'https://videos.pexels.com/video-files/6982825/6982825-sd_320_240_25fps.mp4'
    },
    {
      id: '25',
      category: IC.BS,
      photoUrl: 'https://firebasestorage.googleapis.com/v0/b/elk-ga/o/events%2Finspirations%2FMod_Baby_Shower.jpg?alt=media',
      title: 'Mod Baby Shower',
      backgroundAnimationUrl: 'https://media0.giphy.com/media/MV1Pczbok1wtOdrLBk/giphy360p.mp4?cid=27dcf2b2jlr2rmh2jogue1dqmpvoepz6fbbmfg4bywqaoiqc&ep=v1_videos_search&rid=giphy360p.mp4&ct=v'
    },
    {
      id: '26',
      category: IC.BS,
      photoUrl: 'https://firebasestorage.googleapis.com/v0/b/elk-ga/o/events%2Finspirations%2FPastel_Baby_Shower.jpg?alt=media',
      title: 'Pastel Baby Shower',
      backgroundAnimationUrl: 'https://videos.pexels.com/video-files/7800154/7800154-sd_240_426_25fps.mp4'
    },
    {
      id: '27',
      category: IC.BS,
      photoUrl: 'https://firebasestorage.googleapis.com/v0/b/elk-ga/o/events%2Finspirations%2FSurreal_Baby_Shower.jpg?alt=media',
      title: 'Surreal Baby Shower',
      backgroundAnimationUrl: 'https://videos.pexels.com/video-files/7800088/7800088-sd_426_240_25fps.mp4'
    },
    {
      id: '28',
      category: IC.BS,
      photoUrl: 'https://firebasestorage.googleapis.com/v0/b/elk-ga/o/events%2Finspirations%2FZoo_licious_Baby_Shower.jpg?alt=media',
      title: 'Zoo-licious Baby Shower',
      backgroundAnimationUrl: 'https://videos.pexels.com/video-files/1528489/1528489-sd_640_360_25fps.mp4'
    },
    {
      id: '29',
      category: IC.CK,
      photoUrl: 'https://firebasestorage.googleapis.com/v0/b/elk-ga/o/events%2Finspirations%2FCocktail_Swirls.jpg?alt=media',
      title: 'Cocktail Swirls',
      backgroundAnimationUrl: 'https://videos.pexels.com/video-files/3264304/3264304-sd_426_240_30fps.mp4'
    },
    {
      id: '30',
      category: IC.CK,
      photoUrl: 'https://firebasestorage.googleapis.com/v0/b/elk-ga/o/events%2Finspirations%2FCocktails_in_NYC.jpg?alt=media',
      title: 'Cocktails in NYC',
      backgroundAnimationUrl: 'https://videos.pexels.com/video-files/3402770/3402770-sd_426_226_25fps.mp4'
    },
    {
      id: '129',
      category: IC.CK,
      photoUrl: 'https://firebasestorage.googleapis.com/v0/b/elk-ga/o/events%2Finspirations%2FFruity_Cocktail.jpg?alt=media',
      title: 'Fruity Cocktail',
      backgroundAnimationUrl: 'https://media4.giphy.com/media/Nbnkbsxhi5JT0rXhKQ/giphy.mp4?cid=27dcf2b27qlgafcrfaso60ztduldof1beuub7ebwapwjd1xn&ep=v1_gifs_search&rid=giphy.mp4&ct=g'
    },
    {
      id: '31',
      category: IC.CK,
      photoUrl: 'https://firebasestorage.googleapis.com/v0/b/elk-ga/o/events%2Finspirations%2FGuys_Night.jpg?alt=media',
      title: 'Guys Night',
      backgroundAnimationUrl: 'https://media2.giphy.com/media/4slFUazVLbDK5wsI5q/giphy360p.mp4?cid=27dcf2b2gkn6e81hvzebsj7wp4ir6h9h15q8er3txvdtmehr&ep=v1_videos_search&rid=giphy360p.mp4&ct=v'
    },
    {
      id: '32',
      category: IC.CK,
      photoUrl: 'https://firebasestorage.googleapis.com/v0/b/elk-ga/o/events%2Finspirations%2FRooftop_Cocktail.jpg?alt=media',
      title: 'Rooftop Cocktail',
      backgroundAnimationUrl: 'https://videos.pexels.com/video-files/1826896/1826896-sd_640_360_24fps.mp4'
    },
    {
      id: '33',
      category: IC.CK,
      photoUrl: 'https://firebasestorage.googleapis.com/v0/b/elk-ga/o/events%2Finspirations%2FTropical_Cocktails.jpg?alt=media',
      title: 'Tropical Cocktails',
      backgroundAnimationUrl: 'https://videos.pexels.com/video-files/854128/854128-sd_640_360_25fps.mp4'
    },
    {
      id: '34',
      category: IC.CP,
      photoUrl: 'https://firebasestorage.googleapis.com/v0/b/elk-ga/o/events%2Finspirations%2F80s.jpg?alt=media',
      title: '80s',
      backgroundAnimationUrl: 'https://videos.pexels.com/video-files/4842593/4842593-sd_426_226_25fps.mp4'
    },
    {
      id: '35',
      category: IC.CP,
      photoUrl: 'https://firebasestorage.googleapis.com/v0/b/elk-ga/o/events%2Finspirations%2FCostume_Closet.jpg?alt=media',
      title: 'Costume Closet',
      backgroundAnimationUrl: 'https://videos.pexels.com/video-files/853800/853800-sd_640_360_25fps.mp4'
    },
    {
      id: '36',
      category: IC.CP,
      photoUrl: 'https://firebasestorage.googleapis.com/v0/b/elk-ga/o/events%2Finspirations%2FDisco.jpg?alt=media',
      title: 'Disco',
      backgroundAnimationUrl: 'https://videos.pexels.com/video-files/855645/855645-sd_640_360_24fps.mp4'
    },
    {
      id: '37',
      category: IC.CP,
      photoUrl: 'https://firebasestorage.googleapis.com/v0/b/elk-ga/o/events%2Finspirations%2FDress_As_Your_Pet_Party.jpg?alt=media',
      title: 'Dress As Your Pet Party',
      backgroundAnimationUrl: 'https://videos.pexels.com/video-files/3191251/3191251-sd_426_226_25fps.mp4'
    },
    {
      id: '130',
      category: IC.CP,
      photoUrl: 'https://firebasestorage.googleapis.com/v0/b/elk-ga/o/events%2Finspirations%2FDress_Like_a_Pirate_Party.jpg?alt=media',
      title: 'Dress Like a Pirate Party',
      backgroundAnimationUrl: 'https://media2.giphy.com/media/IBUSwmV7ls6Fg0Crj8/giphy360p.mp4?cid=27dcf2b2gjoy6uo773ddm6e144aa1mwkxe08hpeddi0pczhw&ep=v1_videos_search&rid=giphy360p.mp4&ct=v&cc=en'
    },
    {
      id: '38',
      category: IC.CP,
      photoUrl: 'https://firebasestorage.googleapis.com/v0/b/elk-ga/o/events%2Finspirations%2FGreat_Gatsby.jpg?alt=media',
      title: 'Great Gatsby',
      backgroundAnimationUrl: 'https://videos.pexels.com/video-files/4922982/4922982-sd_426_240_24fps.mp4'
    },
    {
      id: '39',
      category: IC.CP,
      photoUrl: 'https://firebasestorage.googleapis.com/v0/b/elk-ga/o/events%2Finspirations%2FMasks_and_Jewels.jpg?alt=media',
      title: 'Masks and Jewels',
      backgroundAnimationUrl: 'https://videos.pexels.com/video-files/6208744/6208744-sd_426_240_24fps.mp4'
    },
    {
      id: '40',
      category: IC.CP,
      photoUrl: 'https://firebasestorage.googleapis.com/v0/b/elk-ga/o/events%2Finspirations%2FMasquerade.jpg?alt=media',
      title: 'Masquerade',
      backgroundAnimationUrl: 'https://media0.giphy.com/media/QVIWtHNYtRvG2meBQ6/giphy360p.mp4?cid=27dcf2b2aq26ayte1bg580b0f3ickc3zoenk3wbk9ybdwpus&ep=v1_videos_search&rid=giphy360p.mp4&ct=v&cc=en'
    },
    {
      id: '41',
      category: IC.CP,
      photoUrl: 'https://firebasestorage.googleapis.com/v0/b/elk-ga/o/events%2Finspirations%2FRegal_Renaissance.jpg?alt=media',
      title: 'Regal Renaissance',
      backgroundAnimationUrl: 'https://videos.pexels.com/video-files/8177804/8177804-sd_226_426_25fps.mp4'
    },
    {
      id: '42',
      category: IC.DP,
      photoUrl: 'https://firebasestorage.googleapis.com/v0/b/elk-ga/o/events%2Finspirations%2FBanana_Splits.jpg?alt=media',
      title: 'Banana Splits',
      backgroundAnimationUrl: 'https://videos.pexels.com/video-files/2235947/2235947-sd_426_240_24fps.mp4'
    },
    {
      id: '43',
      category: IC.DP,
      photoUrl: 'https://firebasestorage.googleapis.com/v0/b/elk-ga/o/events%2Finspirations%2FDonuts_.jpg?alt=media',
      title: 'Donuts!',
      backgroundAnimationUrl: 'https://videos.pexels.com/video-files/6187065/6187065-sd_226_426_30fps.mp4'
    },
    {
      id: '44',
      category: IC.DP,
      photoUrl: 'https://firebasestorage.googleapis.com/v0/b/elk-ga/o/events%2Finspirations%2FHow_Sweet_It_Is.jpg?alt=media',
      title: 'How Sweet It Is',
      backgroundAnimationUrl: 'https://videos.pexels.com/video-files/7662218/7662218-sd_240_426_30fps.mp4'
    },
    {
      id: '46',
      category: IC.DP,
      photoUrl: 'https://firebasestorage.googleapis.com/v0/b/elk-ga/o/events%2Finspirations%2FSugar_Tooth.jpg?alt=media',
      title: 'Sugar Tooth',
      backgroundAnimationUrl: 'https://videos.pexels.com/video-files/3326577/3326577-sd_426_240_24fps.mp4'
    },
    {
      id: '47',
      category: IC.DP,
      photoUrl: 'https://firebasestorage.googleapis.com/v0/b/elk-ga/o/events%2Finspirations%2FSundae_Party.jpg?alt=media',
      title: 'Sundae Party',
      backgroundAnimationUrl: 'https://videos.pexels.com/video-files/5059604/5059604-sd_226_426_30fps.mp4'
    },
    {
      id: '48',
      category: IC.DP,
      photoUrl: 'https://firebasestorage.googleapis.com/v0/b/elk-ga/o/events%2Finspirations%2FSundae_Special.jpg?alt=media',
      title: 'Sundae Special',
      backgroundAnimationUrl: 'https://media4.giphy.com/media/tDnEba6yitPJf0KsTJ/giphy360p.mp4?cid=27dcf2b23dxp1km5w9e8sv6uv58dzslvamx9fiz7vmmo4a3y&ep=v1_videos_search&rid=giphy360p.mp4&ct=v&cc=en'
    },
    {
      id: '131',
      category: IC.GD,
      photoUrl: 'https://firebasestorage.googleapis.com/v0/b/elk-ga/o/events%2Finspirations%2FColorful_Flowers.jpg?alt=media',
      title: 'Colorful Flowers',
      backgroundAnimationUrl: 'https://media3.giphy.com/media/sws94dq5je0N3wpZdq/giphy360p.mp4?cid=27dcf2b2dlcrsa1hmmgp87jcbz1x6g5k3tpja3qwdm8dqhhs&ep=v1_videos_search&rid=giphy360p.mp4&ct=v&cc=en'
    },
    {
      id: '49',
      category: IC.GD,
      photoUrl: 'https://firebasestorage.googleapis.com/v0/b/elk-ga/o/events%2Finspirations%2FFlower_Path.jpg?alt=media',
      title: 'Flower Path',
      backgroundAnimationUrl: 'https://videos.pexels.com/video-files/2616637/2616637-sd_426_240_30fps.mp4'
    },
    {
      id: '132',
      category: IC.GD,
      photoUrl: 'https://firebasestorage.googleapis.com/v0/b/elk-ga/o/events%2Finspirations%2FGarden_Vegetables.jpg?alt=media',
      title: 'Garden Vegetables',
      backgroundAnimationUrl: 'https://videos.pexels.com/video-files/3195650/3195650-sd_426_240_25fps.mp4'
    },
    {
      id: '50',
      category: IC.GD,
      photoUrl: 'https://firebasestorage.googleapis.com/v0/b/elk-ga/o/events%2Finspirations%2FPicnic_Art.jpg?alt=media',
      title: 'Picnic Art',
      backgroundAnimationUrl: 'https://videos.pexels.com/video-files/8620902/8620902-sd_426_240_25fps.mp4'
    },
    {
      id: '51',
      category: IC.GD,
      photoUrl: 'https://firebasestorage.googleapis.com/v0/b/elk-ga/o/events%2Finspirations%2FPop_Art_Tulips.jpg?alt=media',
      title: 'Pop Art Tulips',
      backgroundAnimationUrl: 'https://videos.pexels.com/video-files/854174/854174-sd_640_360_25fps.mp4'
    },
    {
      id: '52',
      category: IC.GD,
      photoUrl: 'https://firebasestorage.googleapis.com/v0/b/elk-ga/o/events%2Finspirations%2FSecret_Garden.jpg?alt=media',
      title: 'Secret Garden',
      backgroundAnimationUrl: 'https://videos.pexels.com/video-files/4504160/4504160-sd_426_240_24fps.mp4'
    },
    {
      id: '53',
      category: IC.GN,
      photoUrl: 'https://firebasestorage.googleapis.com/v0/b/elk-ga/o/events%2Finspirations%2FChess_Cartoon.jpg?alt=media',
      title: 'Chess Cartoon',
      backgroundAnimationUrl: 'https://videos.pexels.com/video-files/855386/855386-sd_640_360_25fps.mp4'
    },
    {
      id: '133',
      category: IC.GN,
      photoUrl: 'https://firebasestorage.googleapis.com/v0/b/elk-ga/o/events%2Finspirations%2FClassic_Games.jpg?alt=media',
      title: 'Classic Games',
      backgroundAnimationUrl: 'https://videos.pexels.com/video-files/855635/855635-sd_640_360_25fps.mp4'
    },
    {
      id: '54',
      category: IC.GN,
      photoUrl: 'https://firebasestorage.googleapis.com/v0/b/elk-ga/o/events%2Finspirations%2FCubist_Cards.jpg?alt=media',
      title: 'Cubist Cards',
      backgroundAnimationUrl: 'https://videos.pexels.com/video-files/3693458/3693458-sd_240_360_24fps.mp4'
    },
    {
      id: '55',
      category: IC.GN,
      photoUrl: 'https://firebasestorage.googleapis.com/v0/b/elk-ga/o/events%2Finspirations%2FGames_Galore.jpg?alt=media',
      title: 'Games Galore',
      backgroundAnimationUrl: 'https://videos.pexels.com/video-files/5529393/5529393-sd_426_240_30fps.mp4'
    },
    {
      id: '56',
      category: IC.GN,
      photoUrl: 'https://firebasestorage.googleapis.com/v0/b/elk-ga/o/events%2Finspirations%2FPoker_Chips.jpg?alt=media',
      title: 'Poker Chips',
      backgroundAnimationUrl: 'https://videos.pexels.com/video-files/6665529/6665529-sd_426_240_30fps.mp4'
    },
    {
      id: '57',
      category: IC.GN,
      photoUrl: 'https://firebasestorage.googleapis.com/v0/b/elk-ga/o/events%2Finspirations%2FStellar_Chess.jpg?alt=media',
      title: 'Stellar Chess',
      backgroundAnimationUrl: 'https://videos.pexels.com/video-files/3175515/3175515-sd_426_240_25fps.mp4'
    },
    {
      id: '134',
      category: IC.GP,
      photoUrl: 'https://firebasestorage.googleapis.com/v0/b/elk-ga/o/events%2Finspirations%2FThe_Graduate.jpg?alt=media',
      title: 'The Graduate',
      backgroundAnimationUrl: 'https://videos.pexels.com/video-files/7945759/7945759-sd_426_240_25fps.mp4'
    },
    {
      id: '58',
      category: IC.GP,
      photoUrl: 'https://firebasestorage.googleapis.com/v0/b/elk-ga/o/events%2Finspirations%2FGraduation_Party.jpg?alt=media',
      title: 'Graduation Party',
      backgroundAnimationUrl: 'https://videos.pexels.com/video-files/7945680/7945680-sd_426_240_25fps.mp4'
    },
    {
      id: '59',
      category: IC.GP,
      photoUrl: 'https://firebasestorage.googleapis.com/v0/b/elk-ga/o/events%2Finspirations%2FHats_Off.jpg?alt=media',
      title: 'Hats Off',
      backgroundAnimationUrl: 'https://videos.pexels.com/video-files/7945774/7945774-sd_426_240_25fps.mp4'
    },
    {
      id: '60',
      category: IC.HH,
      photoUrl: 'https://firebasestorage.googleapis.com/v0/b/elk-ga/o/events%2Finspirations%2FBeers__Cheers.jpg?alt=media',
      title: 'Beers, Cheers',
      backgroundAnimationUrl: 'https://videos.pexels.com/video-files/1899147/1899147-sd_640_360_30fps.mp4'
    },
    {
      id: '61',
      category: IC.HH,
      photoUrl: 'https://firebasestorage.googleapis.com/v0/b/elk-ga/o/events%2Finspirations%2FChampagne_Toast.jpg?alt=media',
      title: 'Champagne Toast',
      backgroundAnimationUrl: 'https://videos.pexels.com/video-files/3402879/3402879-sd_226_426_25fps.mp4'
    },
    {
      id: '135',
      category: IC.HH,
      photoUrl: 'https://firebasestorage.googleapis.com/v0/b/elk-ga/o/events%2Finspirations%2FPub_Happy_Hour.jpg?alt=media',
      title: 'Pub Happy Hour',
      backgroundAnimationUrl: 'https://videos.pexels.com/video-files/2836284/2836284-sd_426_240_24fps.mp4'
    },
    {
      id: '62',
      category: IC.HO,
      photoUrl: 'https://firebasestorage.googleapis.com/v0/b/elk-ga/o/events%2Finspirations%2FBBQ_Pool_Party.jpg?alt=media',
      title: 'BBQ Pool Party',
      backgroundAnimationUrl: 'https://videos.pexels.com/video-files/2776522/2776522-sd_426_240_30fps.mp4'
    },
    {
      id: '63',
      category: IC.HO,
      photoUrl: 'https://firebasestorage.googleapis.com/v0/b/elk-ga/o/events%2Finspirations%2FBlack_and_White_Haunted_House.jpg?alt=media',
      title: 'Black and White Haunted House',
      backgroundAnimationUrl: 'https://videos.pexels.com/video-files/16451841/16451841-sd_426_240_24fps.mp4'
    },
    {
      id: '64',
      category: IC.HO,
      photoUrl: 'https://firebasestorage.googleapis.com/v0/b/elk-ga/o/events%2Finspirations%2FEaster_Basket.jpg?alt=media',
      title: 'Easter Basket',
      backgroundAnimationUrl: 'https://videos.pexels.com/video-files/5528414/5528414-sd_240_426_24fps.mp4'
    },
    {
      id: '65',
      category: IC.HO,
      photoUrl: 'https://firebasestorage.googleapis.com/v0/b/elk-ga/o/events%2Finspirations%2FEaster_Eggs.jpg?alt=media',
      title: 'Easter Eggs',
      backgroundAnimationUrl: 'https://videos.pexels.com/video-files/3973143/3973143-sd_426_226_25fps.mp4'
    },
    {
      id: '66',
      category: IC.HO,
      photoUrl: 'https://firebasestorage.googleapis.com/v0/b/elk-ga/o/events%2Finspirations%2FFlag_and_Fireworks.jpg?alt=media',
      title: 'Flag and Fireworks',
      backgroundAnimationUrl: 'https://videos.pexels.com/video-files/4806404/4806404-sd_240_426_30fps.mp4'
    },
    {
      id: '67',
      category: IC.HO,
      photoUrl: 'https://firebasestorage.googleapis.com/v0/b/elk-ga/o/events%2Finspirations%2FFull_Moon_and_Jack_o_Lanterns.jpg?alt=media',
      title: 'Full Moon and Jack-o-Lanterns',
      backgroundAnimationUrl: 'https://videos.pexels.com/video-files/5422503/5422503-sd_426_240_25fps.mp4'
    },
    {
      id: '68',
      category: IC.HO,
      photoUrl: 'https://firebasestorage.googleapis.com/v0/b/elk-ga/o/events%2Finspirations%2FGraphic_Fourth_of_July.jpg?alt=media',
      title: 'Graphic Fourth of July',
      backgroundAnimationUrl: 'https://videos.pexels.com/video-files/855121/855121-sd_640_360_30fps.mp4'
    },
    {
      id: '69',
      category: IC.HO,
      photoUrl: 'https://firebasestorage.googleapis.com/v0/b/elk-ga/o/events%2Finspirations%2FHalloween_Confetti.jpg?alt=media',
      title: 'Halloween Confetti',
      backgroundAnimationUrl: 'https://media3.giphy.com/media/JmGfGaRfo5zmy9uc2G/giphy360p.mp4?cid=27dcf2b2drxeetz4nod2etwixhx6bypjfb2g6gm6rhgzfq0p&ep=v1_videos_search&rid=giphy360p.mp4&ct=v'
    },
    {
      id: '70',
      category: IC.HO,
      photoUrl: 'https://firebasestorage.googleapis.com/v0/b/elk-ga/o/events%2Finspirations%2FHalloween_Silhouette_.jpg?alt=media',
      title: 'Halloween Silhouette',
      backgroundAnimationUrl: 'https://videos.pexels.com/video-files/5435216/5435216-sd_426_226_25fps.mp4'
    },
    {
      id: '71',
      category: IC.HO,
      photoUrl: 'https://firebasestorage.googleapis.com/v0/b/elk-ga/o/events%2Finspirations%2FHalloween_Swirls.jpg?alt=media',
      title: 'Halloween Swirls',
      backgroundAnimationUrl: 'https://media1.giphy.com/media/GErFkkmqwNcv4iAFoA/giphy360p.mp4?cid=27dcf2b20rojb47kn93kh9hh7xkube73yjeis3rchjifprzv&ep=v1_videos_search&rid=giphy360p.mp4&ct=v'
    },
    {
      id: '72',
      category: IC.HO,
      photoUrl: 'https://firebasestorage.googleapis.com/v0/b/elk-ga/o/events%2Finspirations%2FHaunted_House.jpg?alt=media',
      title: 'Haunted House',
      backgroundAnimationUrl: 'https://media0.giphy.com/media/Zx3kBiI2UouTPYpPy2/giphy360p.mp4?cid=27dcf2b2b815ffoj9dx3dlicms67sgua47xk9b49j62uj035&ep=v1_videos_search&rid=giphy360p.mp4&ct=v'
    },
    {
      id: '73',
      category: IC.HO,
      photoUrl: 'https://firebasestorage.googleapis.com/v0/b/elk-ga/o/events%2Finspirations%2FLunch_by_the_Pool.jpg?alt=media',
      title: 'Lunch by the Pool',
      backgroundAnimationUrl: 'https://videos.pexels.com/video-files/6242776/6242776-sd_240_426_30fps.mp4'
    },
    {
      id: '74',
      category: IC.HO,
      photoUrl: 'https://firebasestorage.googleapis.com/v0/b/elk-ga/o/events%2Finspirations%2FMagic_Easter_Eggs.jpg?alt=media',
      title: 'Magic Easter Eggs',
      backgroundAnimationUrl: 'https://videos.pexels.com/video-files/6897892/6897892-sd_426_226_30fps.mp4'
    },
    {
      id: '75',
      category: IC.HO,
      photoUrl: 'https://firebasestorage.googleapis.com/v0/b/elk-ga/o/events%2Finspirations%2FRed__White_and_Blue_Fireworks.jpg?alt=media',
      title: 'Red, White and Blue Fireworks',
      backgroundAnimationUrl: 'https://videos.pexels.com/video-files/1741279/1741279-sd_640_360_30fps.mp4'
    },
    {
      id: '76',
      category: IC.HO,
      photoUrl: 'https://firebasestorage.googleapis.com/v0/b/elk-ga/o/events%2Finspirations%2FVery_Spooky.jpg?alt=media',
      title: 'Very Spooky',
      backgroundAnimationUrl: 'https://videos.pexels.com/video-files/854739/854739-sd_640_360_30fps.mp4'
    },
    {
      id: '77',
      category: IC.HP,
      photoUrl: 'https://firebasestorage.googleapis.com/v0/b/elk-ga/o/events%2Finspirations%2FIce_Skating_with_Friends.jpg?alt=media',
      title: 'Ice Skating with Friends',
      backgroundAnimationUrl: 'https://videos.pexels.com/video-files/3125200/3125200-sd_426_240_25fps.mp4'
    },
    {
      id: '78',
      category: IC.HP,
      photoUrl: 'https://firebasestorage.googleapis.com/v0/b/elk-ga/o/events%2Finspirations%2FPainterly_Skating.jpg?alt=media',
      title: 'Painterly Skating',
      backgroundAnimationUrl: 'https://videos.pexels.com/video-files/855379/855379-sd_640_360_30fps.mp4'
    },
    {
      id: '79',
      category: IC.HP,
      photoUrl: 'https://firebasestorage.googleapis.com/v0/b/elk-ga/o/events%2Finspirations%2FPolar_Express.jpg?alt=media',
      title: 'Polar Express',
      backgroundAnimationUrl: 'https://media4.giphy.com/media/KwLaAY5RXrEFYgnwUr/giphy360p.mp4?cid=27dcf2b2dkzfi85hmd4nmifv1ca63sk8zlujoiv7obr3fxct&ep=v1_videos_search&rid=giphy360p.mp4&ct=v'
    },
    {
      id: '80',
      category: IC.HP,
      photoUrl: 'https://firebasestorage.googleapis.com/v0/b/elk-ga/o/events%2Finspirations%2FSantas__Santas__and_More_Santas.jpg?alt=media',
      title: 'Santas, Santas, and More Santas',
      backgroundAnimationUrl: 'https://media1.giphy.com/media/tSiJfyrMvVHccQkhQE/giphy360p.mp4?cid=27dcf2b28tx1tmmhbz93md7od7wnbk6gt4s48tuef3w89fsh&ep=v1_videos_search&rid=giphy360p.mp4&ct=v'
    },
    {
      id: '81',
      category: IC.HP,
      photoUrl: 'https://firebasestorage.googleapis.com/v0/b/elk-ga/o/events%2Finspirations%2FUgly_Christmas_Sweaters.jpg?alt=media',
      title: 'Ugly Christmas Sweaters',
      backgroundAnimationUrl: 'https://media4.giphy.com/media/CMUoRkCvVrgMhM5shI/giphy360p.mp4?cid=27dcf2b2dp4fm8toccvh78ruzzp4bmjx32solokvnczdzadj&ep=v1_videos_search&rid=giphy360p.mp4&ct=v&cc=en'
    },
    {
      id: '82',
      category: IC.HP,
      photoUrl: 'https://firebasestorage.googleapis.com/v0/b/elk-ga/o/events%2Finspirations%2FVelvet_Christmas.jpg?alt=media',
      title: 'Velvet Christmas',
      backgroundAnimationUrl: 'https://videos.pexels.com/video-files/10449329/10449329-sd_640_360_24fps.mp4'
    },
    {
      id: '83',
      category: IC.HW,
      photoUrl: 'https://firebasestorage.googleapis.com/v0/b/elk-ga/o/events%2Finspirations%2FModern_Housewarming.jpg?alt=media',
      title: 'Modern Housewarming',
      backgroundAnimationUrl: 'https://media4.giphy.com/media/ns6sttAUMf91ezCmP3/giphy360p.mp4?cid=27dcf2b2cfpdqfusosoucd6mb51lk0hzlcokrgnmyjfjfajy&ep=v1_videos_search&rid=giphy360p.mp4&ct=v'
    },
    {
      id: '84',
      category: IC.HW,
      photoUrl: 'https://firebasestorage.googleapis.com/v0/b/elk-ga/o/events%2Finspirations%2FMoving_Day.jpg?alt=media',
      title: 'Moving Day',
      backgroundAnimationUrl: 'https://videos.pexels.com/video-files/4277482/4277482-sd_426_240_25fps.mp4'
    },
    {
      id: '85',
      category: IC.HW,
      photoUrl: 'https://firebasestorage.googleapis.com/v0/b/elk-ga/o/events%2Finspirations%2FMoving_Truck_Overflowing.jpg?alt=media',
      title: 'Moving Truck Overflowing',
      backgroundAnimationUrl: 'https://videos.pexels.com/video-files/855823/855823-sd_640_360_15fps.mp4'
    },
    {
      id: '86',
      category: IC.MC,
      photoUrl: 'https://firebasestorage.googleapis.com/v0/b/elk-ga/o/events%2Finspirations%2FBowling_Party.jpg?alt=media',
      title: 'Bowling Party',
      backgroundAnimationUrl: 'https://videos.pexels.com/video-files/5781320/5781320-sd_426_240_25fps.mp4'
    },
    {
      id: '87',
      category: IC.MC,
      photoUrl: 'https://firebasestorage.googleapis.com/v0/b/elk-ga/o/events%2Finspirations%2FGet_Your_Glow_On.jpg?alt=media',
      title: 'Get Your Glow On',
      backgroundAnimationUrl: 'https://videos.pexels.com/video-files/2759484/2759484-sd_426_240_30fps.mp4'
    },
    {
      id: '88',
      category: IC.MC,
      photoUrl: 'https://firebasestorage.googleapis.com/v0/b/elk-ga/o/events%2Finspirations%2FGive__em_Something_to_TACO__Bout.jpg?alt=media',
      title: 'Give \'em Something to TACO \'Bout',
      backgroundAnimationUrl: 'https://videos.pexels.com/video-files/7613581/7613581-sd_240_426_24fps.mp4'
    },
    {
      id: '89',
      category: IC.MC,
      photoUrl: 'https://firebasestorage.googleapis.com/v0/b/elk-ga/o/events%2Finspirations%2FIce_Skating_Party.jpg?alt=media',
      title: 'Ice Skating Party',
      backgroundAnimationUrl: 'https://media2.giphy.com/media/9O7TJY3mM0l6Aullru/giphy360p.mp4?cid=27dcf2b2mbear5h2kkmkuao0phu0gk9dj0pj98184yd8xai7&ep=v1_videos_search&rid=giphy360p.mp4&ct=v'
    },
    {
      id: '90',
      category: IC.MC,
      photoUrl: 'https://firebasestorage.googleapis.com/v0/b/elk-ga/o/events%2Finspirations%2FKite_Flying.jpg?alt=media',
      title: 'Kite Flying',
      backgroundAnimationUrl: 'https://videos.pexels.com/video-files/856975/856975-sd_640_360_25fps.mp4'
    },
    {
      id: '91',
      category: IC.MC,
      photoUrl: 'https://firebasestorage.googleapis.com/v0/b/elk-ga/o/events%2Finspirations%2FLadies_Night.jpg?alt=media',
      title: 'Ladies Night',
      backgroundAnimationUrl: 'https://videos.pexels.com/video-files/6395999/6395999-sd_240_426_25fps.mp4'
    },
    {
      id: '92',
      category: IC.MC,
      photoUrl: 'https://firebasestorage.googleapis.com/v0/b/elk-ga/o/events%2Finspirations%2FLet_s_Glow.jpg?alt=media',
      title: 'Let\'s Glow',
      backgroundAnimationUrl: 'https://media0.giphy.com/media/dxgnIpsltlJ9RR93I0/giphy360p.mp4?cid=27dcf2b2f8entq4daf1gh9p9wzg675swk55bf7e7tdvp1bh8&ep=v1_videos_search&rid=giphy360p.mp4&ct=v'
    },
    {
      id: '93',
      category: IC.MC,
      photoUrl: 'https://firebasestorage.googleapis.com/v0/b/elk-ga/o/events%2Finspirations%2FLet_s_Go_Fly_a_Kite.jpg?alt=media',
      title: 'Let\'s Go Fly a Kite',
      backgroundAnimationUrl: 'https://videos.pexels.com/video-files/1721289/1721289-sd_640_360_25fps.mp4'
    },
    {
      id: '94',
      category: IC.MC,
      photoUrl: 'https://firebasestorage.googleapis.com/v0/b/elk-ga/o/events%2Finspirations%2FRomance_and_Mystique.jpg?alt=media',
      title: 'Romance and Mystique',
      backgroundAnimationUrl: 'https://videos.pexels.com/video-files/1494279/1494279-sd_640_360_24fps.mp4'
    },
    {
      id: '95',
      category: IC.MN,
      photoUrl: 'https://firebasestorage.googleapis.com/v0/b/elk-ga/o/events%2Finspirations%2FDrive_In.jpg?alt=media',
      title: 'Drive-In',
      backgroundAnimationUrl: 'https://videos.pexels.com/video-files/3848792/3848792-sd_426_240_30fps.mp4'
    },
    {
      id: '96',
      category: IC.MN,
      photoUrl: 'https://firebasestorage.googleapis.com/v0/b/elk-ga/o/events%2Finspirations%2FHollywood_Golden.jpg?alt=media',
      title: 'Hollywood Golden',
      backgroundAnimationUrl: 'https://videos.pexels.com/video-files/855909/855909-sd_640_360_25fps.mp4'
    },
    {
      id: '97',
      category: IC.MN,
      photoUrl: 'https://firebasestorage.googleapis.com/v0/b/elk-ga/o/events%2Finspirations%2FLights._Camera._Action_.jpg?alt=media',
      title: 'Lights. Camera. Action!',
      backgroundAnimationUrl: 'https://videos.pexels.com/video-files/7985879/7985879-sd_426_226_25fps.mp4'
    },
    {
      id: '98',
      category: IC.MN,
      photoUrl: 'https://firebasestorage.googleapis.com/v0/b/elk-ga/o/events%2Finspirations%2FMovie_Night.jpg?alt=media',
      title: 'Movie Night',
      backgroundAnimationUrl: 'https://videos.pexels.com/video-files/854959/854959-sd_640_360_30fps.mp4'
    },
    {
      id: '99',
      category: IC.MN,
      photoUrl: 'https://firebasestorage.googleapis.com/v0/b/elk-ga/o/events%2Finspirations%2FOld_Fashioned_Film.jpg?alt=media',
      title: 'Old-Fashioned Film',
      backgroundAnimationUrl: 'https://videos.pexels.com/video-files/3945486/3945486-sd_426_226_25fps.mp4'
    },
    {
      id: '100',
      category: IC.MN,
      photoUrl: 'https://firebasestorage.googleapis.com/v0/b/elk-ga/o/events%2Finspirations%2FPopcorn_and_Candy.jpg?alt=media',
      title: 'Popcorn and Candy',
      backgroundAnimationUrl: 'https://media0.giphy.com/media/rwt7GZ2yaSOuecCYuD/giphy360p.mp4?cid=27dcf2b2qp9imqjfma16xzbh8i56j0wby6xxd71i2y8kgpt0&ep=v1_videos_search&rid=giphy360p.mp4&ct=v&cc=en'
    },
    {
      id: '137',
      category: IC.PP,
      photoUrl: 'https://firebasestorage.googleapis.com/v0/b/elk-ga/o/events%2Finspirations%2FThe_Last_Supper.jpg?alt=media',
      title: 'The Last Supper',
      backgroundAnimationUrl: 'https://media1.giphy.com/media/52kmsk1UBi6bnKURWm/giphy360p.mp4?cid=27dcf2b23hcoeb8494wnrtgo19q3annywcopq0y9yr4bsaoq&ep=v1_videos_search&rid=giphy360p.mp4&ct=v'
    },
    {
      id: '45',
      category: IC.PP,
      photoUrl: 'https://firebasestorage.googleapis.com/v0/b/elk-ga/o/events%2Finspirations%2FPizza_Party.jpg?alt=media',
      title: 'Pizza Party',
      backgroundAnimationUrl: 'https://videos.pexels.com/video-files/3252972/3252972-sd_426_240_25fps.mp4'
    },
    {
      id: '136',
      category: IC.PP,
      photoUrl: 'https://firebasestorage.googleapis.com/v0/b/elk-ga/o/events%2Finspirations%2FStreets_Lined_with_Pizza.jpg?alt=media',
      title: 'Streets Lined with Pizza',
      backgroundAnimationUrl: 'https://videos.pexels.com/video-files/4008533/4008533-sd_426_226_25fps.mp4'
    },
    {
      id: '101',
      category: IC.SE,
      photoUrl: 'https://firebasestorage.googleapis.com/v0/b/elk-ga/o/events%2Finspirations%2FBaseball_Stadium_of_Baseballs.jpg?alt=media',
      title: 'Baseball Stadium of Baseballs',
      backgroundAnimationUrl: 'https://videos.pexels.com/video-files/2430839/2430839-sd_426_240_24fps.mp4'
    },
    {
      id: '102',
      category: IC.SE,
      photoUrl: 'https://firebasestorage.googleapis.com/v0/b/elk-ga/o/events%2Finspirations%2FBaseball_Universe.jpg?alt=media',
      title: 'Baseball Universe',
      backgroundAnimationUrl: 'https://videos.pexels.com/video-files/5182768/5182768-sd_426_240_25fps.mp4'
    },
    {
      id: '103',
      category: IC.SE,
      photoUrl: 'https://firebasestorage.googleapis.com/v0/b/elk-ga/o/events%2Finspirations%2FBasketball_Stars.jpg?alt=media',
      title: 'Basketball Stars',
      backgroundAnimationUrl: 'https://videos.pexels.com/video-files/5192151/5192151-sd_426_240_30fps.mp4'
    },
    {
      id: '104',
      category: IC.SE,
      photoUrl: 'https://firebasestorage.googleapis.com/v0/b/elk-ga/o/events%2Finspirations%2FFriday_Night_Lights.jpg?alt=media',
      title: 'Friday Night Lights',
      backgroundAnimationUrl: 'https://videos.pexels.com/video-files/2249402/2249402-sd_426_240_24fps.mp4'
    },
    {
      id: '105',
      category: IC.SE,
      photoUrl: 'https://firebasestorage.googleapis.com/v0/b/elk-ga/o/events%2Finspirations%2FSoccer_World.jpg?alt=media',
      title: 'Soccer World',
      backgroundAnimationUrl: 'https://videos.pexels.com/video-files/3192198/3192198-sd_426_240_25fps.mp4'
    },
    {
      id: '106',
      category: IC.SE,
      photoUrl: 'https://firebasestorage.googleapis.com/v0/b/elk-ga/o/events%2Finspirations%2FSwirling_Soccer_Balls.jpg?alt=media',
      title: 'Swirling Soccer Balls',
      backgroundAnimationUrl: 'https://videos.pexels.com/video-files/5563400/5563400-sd_426_240_30fps.mp4'
    },
    {
      id: '107',
      category: IC.SP,
      photoUrl: 'https://firebasestorage.googleapis.com/v0/b/elk-ga/o/events%2Finspirations%2FFootball_Player_Art_.jpg?alt=media',
      title: 'Football Player Art',
      backgroundAnimationUrl: 'https://videos.pexels.com/video-files/7006036/7006036-sd_426_240_30fps.mp4'
    },
    {
      id: '108',
      category: IC.SP,
      photoUrl: 'https://firebasestorage.googleapis.com/v0/b/elk-ga/o/events%2Finspirations%2FFootball_Rocket.jpg?alt=media',
      title: 'Football Rocket',
      backgroundAnimationUrl: 'https://videos.pexels.com/video-files/7005865/7005865-sd_426_240_24fps.mp4'
    },
    {
      id: '109',
      category: IC.SP,
      photoUrl: 'https://firebasestorage.googleapis.com/v0/b/elk-ga/o/events%2Finspirations%2FStadium_Game.jpg?alt=media',
      title: 'Stadium Game',
      backgroundAnimationUrl: 'https://videos.pexels.com/video-files/20072764/20072764-sd_426_240_30fps.mp4'
    },
    {
      id: '110',
      category: IC.TP,
      photoUrl: 'https://firebasestorage.googleapis.com/v0/b/elk-ga/o/events%2Finspirations%2FCitrus_Tea.jpg?alt=media',
      title: 'Citrus Tea',
      backgroundAnimationUrl: 'https://videos.pexels.com/video-files/5742201/5742201-sd_240_426_25fps.mp4'
    },
    {
      id: '111',
      category: IC.TP,
      photoUrl: 'https://firebasestorage.googleapis.com/v0/b/elk-ga/o/events%2Finspirations%2FFairy_Creature_Tea.jpg?alt=media',
      title: 'Fairy Creature Tea',
      backgroundAnimationUrl: 'https://videos.pexels.com/video-files/3816531/3816531-sd_426_240_30fps.mp4'
    },
    {
      id: '112',
      category: IC.TP,
      photoUrl: 'https://firebasestorage.googleapis.com/v0/b/elk-ga/o/events%2Finspirations%2FPainted_Garden.jpg?alt=media',
      title: 'Painted Garden',
      backgroundAnimationUrl: 'https://videos.pexels.com/video-files/2048452/2048452-sd_640_360_30fps.mp4'
    },
    {
      id: '113',
      category: IC.TP,
      photoUrl: 'https://firebasestorage.googleapis.com/v0/b/elk-ga/o/events%2Finspirations%2FTea_and_food_and_flowers.jpg?alt=media',
      title: 'Tea and food and flowers',
      backgroundAnimationUrl: 'https://videos.pexels.com/video-files/8298059/8298059-sd_240_426_30fps.mp4'
    },
    {
      id: '114',
      category: IC.TP,
      photoUrl: 'https://firebasestorage.googleapis.com/v0/b/elk-ga/o/events%2Finspirations%2FTea_in_the_Garden.jpg?alt=media',
      title: 'Tea in the Garden',
      backgroundAnimationUrl: 'https://videos.pexels.com/video-files/3115459/3115459-sd_426_240_24fps.mp4'
    },
    {
      id: '138',
      category: IC.TP,
      photoUrl: 'https://firebasestorage.googleapis.com/v0/b/elk-ga/o/events%2Finspirations%2FTeddy_Bear_Tea.jpg?alt=media',
      title: 'Teddy Bear Tea',
      backgroundAnimationUrl: 'https://videos.pexels.com/video-files/7361134/7361134-sd_240_426_25fps.mp4'
    },
    {
      id: '139',
      category: IC.WD,
      photoUrl: 'https://firebasestorage.googleapis.com/v0/b/elk-ga/o/events%2Finspirations%2FBeautiful_Buffet_Brunch.jpg?alt=media',
      title: 'Beautiful Buffet Brunch',
      backgroundAnimationUrl: 'https://videos.pexels.com/video-files/8442722/8442722-sd_426_240_30fps.mp4'
    },
    {
      id: '115',
      category: IC.WD,
      photoUrl: 'https://firebasestorage.googleapis.com/v0/b/elk-ga/o/events%2Finspirations%2FFlowers_and_Rings.jpg?alt=media',
      title: 'Flowers and Rings',
      backgroundAnimationUrl: 'https://videos.pexels.com/video-files/4747643/4747643-sd_426_240_25fps.mp4'
    },
    {
      id: '116',
      category: IC.WD,
      photoUrl: 'https://firebasestorage.googleapis.com/v0/b/elk-ga/o/events%2Finspirations%2FPath_of_Roses.jpg?alt=media',
      title: 'Path of Roses',
      backgroundAnimationUrl: 'https://videos.pexels.com/video-files/8195677/8195677-sd_226_426_30fps.mp4'
    },
    {
      id: '117',
      category: IC.WD,
      photoUrl: 'https://firebasestorage.googleapis.com/v0/b/elk-ga/o/events%2Finspirations%2FSay_Yes_to_the_Dress.jpg?alt=media',
      title: 'Say Yes to the Dress',
      backgroundAnimationUrl: 'https://videos.pexels.com/video-files/5379128/5379128-sd_426_226_25fps.mp4'
    },
    {
      id: '118',
      category: IC.WD,
      photoUrl: 'https://firebasestorage.googleapis.com/v0/b/elk-ga/o/events%2Finspirations%2FSunny_Champagne.jpg?alt=media',
      title: 'Sunny Champagne',
      backgroundAnimationUrl: 'https://videos.pexels.com/video-files/6185445/6185445-sd_226_426_25fps.mp4'
    },
    {
      id: '119',
      category: IC.WD,
      photoUrl: 'https://firebasestorage.googleapis.com/v0/b/elk-ga/o/events%2Finspirations%2FWedding_Rings.jpg?alt=media',
      title: 'Wedding Rings',
      backgroundAnimationUrl: 'https://videos.pexels.com/video-files/4004214/4004214-sd_426_240_25fps.mp4'
    },
    {
      id: '120',
      category: IC.WT,
      photoUrl: 'https://firebasestorage.googleapis.com/v0/b/elk-ga/o/events%2Finspirations%2FArt___Wine.jpg?alt=media',
      title: 'Art & Wine',
      backgroundAnimationUrl: 'https://videos.pexels.com/video-files/1003928/1003928-sd_640_360_25fps.mp4'
    },
    {
      id: '140',
      category: IC.WT,
      photoUrl: 'https://firebasestorage.googleapis.com/v0/b/elk-ga/o/events%2Finspirations%2FCrush_Party.jpg?alt=media',
      title: 'Crush Party',
      backgroundAnimationUrl: 'https://videos.pexels.com/video-files/3601124/3601124-sd_426_240_30fps.mp4'
    },
    {
      id: '141',
      category: IC.WT,
      photoUrl: 'https://firebasestorage.googleapis.com/v0/b/elk-ga/o/events%2Finspirations%2FEverybody_Loves_Lucy.jpg?alt=media',
      title: 'Everybody Loves Lucy',
      backgroundAnimationUrl: 'https://videos.pexels.com/video-files/6895108/6895108-sd_426_240_30fps.mp4'
    },
    {
      id: '121',
      category: IC.WT,
      photoUrl: 'https://firebasestorage.googleapis.com/v0/b/elk-ga/o/events%2Finspirations%2FRainbow_of_Tastes.jpg?alt=media',
      title: 'Rainbow of Tastes',
      backgroundAnimationUrl: 'https://videos.pexels.com/video-files/8516544/8516544-sd_240_426_30fps.mp4'
    },
    {
      id: '122',
      category: IC.WT,
      photoUrl: 'https://firebasestorage.googleapis.com/v0/b/elk-ga/o/events%2Finspirations%2FRenaissance_Wine_Tasting.jpg?alt=media',
      title: 'Renaissance Wine Tasting',
      backgroundAnimationUrl: 'https://videos.pexels.com/video-files/6356806/6356806-sd_426_240_30fps.mp4'
    },
    {
      id: '123',
      category: IC.WT,
      photoUrl: 'https://firebasestorage.googleapis.com/v0/b/elk-ga/o/events%2Finspirations%2FWine_Tasting.jpg?alt=media',
      title: 'Wine Tasting',
      backgroundAnimationUrl: 'https://videos.pexels.com/video-files/8764786/8764786-sd_426_240_25fps.mp4'
    }
  ]
;

export const InspirationsByType: Map<InspirationCategory, InspirationIdea[]> = new Map();

for (const inspiration of Inspirations) {
  if (!InspirationsByType.has(inspiration.category)) {
    InspirationsByType.set(inspiration.category, []);
  }
  InspirationsByType.get(inspiration.category)?.push(inspiration);
}

export default Inspirations;
