import React from 'react';
import * as ReactDOM from 'react-dom';

import App from './core/App';

setTimeout(() => {
  document.body.className = '';
}, 5000);

const reactRoot = document.createElement('div');
reactRoot.id = 'react-root';
document.getElementsByTagName('body')[0].appendChild(reactRoot);

ReactDOM.render(
  <App/>,
  document.getElementById('react-root')
);

// Opt-in to Webpack hot module replacement
if (module.hot) module.hot.accept();

