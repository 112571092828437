import {Thrift, TProtocol} from 'thrift';

interface GenericThriftServiceClient {
  _reqs: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [name: number]: ((err: Error | object | undefined, val?: any) => void) | undefined;
  };
}

export type AugmentedThriftServiceClient<T extends GenericThriftServiceClient> = T & {
  recv_rateLimitError: (input: TProtocol, mtype: Thrift.MessageType, requestId: number) => void;
  recv_networkError: (input: TProtocol, mtype: Thrift.MessageType, requestId: number) => void;
  recv_timeoutError: (input: TProtocol, mtype: Thrift.MessageType, requestId: number) => void;
}

export class RateLimitException extends Error {

  constructor() {
    super('Rate limit exceeded');
  }

  getErrorMessage() {
    return this.message
  }
}

export class NetworkErrorException extends Error {
  private readonly errorMessage: string;

  constructor(error_message: string) {
    super(error_message)
    this.errorMessage = error_message
  }

  getErrorMessage() {
    return this.errorMessage;
  }
}


export const augmentService = <T extends GenericThriftServiceClient>(service: T): AugmentedThriftServiceClient<T> => {
  const augmentedService = service as AugmentedThriftServiceClient<T>;

  /*
   * In order to handle rate limiting, we need to extend our Thrift services to handle a fake "rateLimitError" method.
   * The rate limiting function on the load balancer will return a payload of: [1,"rateLimitError",3,1,{"2":{"i32":6}}],
   * which will trigger this method.
   */
  augmentedService.recv_rateLimitError = function (input: TProtocol, mtype: Thrift.MessageType, requestId: number) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const noop = (): any => null;
    const callback = this._reqs[requestId] || noop;
    callback(new RateLimitException());
  }

  /*
   * In SunshineXhrConnection we return these responses in case of network error or timeout
   */
  augmentedService.recv_networkError = function (input: TProtocol, mtype: Thrift.MessageType, requestId: number) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const noop = (): any => null;
    const callback = this._reqs[requestId] || noop;
    input.readString()
    input.readString()
    callback(new NetworkErrorException(input.readString()));
  }

  augmentedService.recv_timeoutError = function (input: TProtocol, mtype: Thrift.MessageType, requestId: number) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const noop = (): any => null;
    const callback = this._reqs[requestId] || noop;
    input.readString()
    input.readString()
    callback(new NetworkErrorException(input.readString()));
  }

  return augmentedService;
};
