import React from 'react';
import styled from 'styled-components';

import Plus from '../icons/Plus';

import { Dropdown } from 'Common/src/components/AutocompleteDropdown/AutocompleteDropdown';

const InviteCreateContact = ({ search, onClick }: { search: string, onClick: () => void }) => {
  return <Container onClick={onClick}>
    <PlusWrapper>
      <Plus size={21}/>
    </PlusWrapper>
    Create Contact &quot;{search}&quot;
  </Container>;
};

const Container = styled(Dropdown)`
  display: flex;
  padding: 8px 10px;
  align-items: center;
  cursor: pointer;
`;

const PlusWrapper = styled.div`
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default InviteCreateContact;
