import { IUUserContext } from '../contexts/UserContext';
import { blobToBase64 } from '../util/stringutils';

declare const WEBSERVER_URL: string;
declare const WEBSERVER_PORT: number;
declare const WEBSERVER_HTTPS: boolean;

const timeFormat = new Intl.DateTimeFormat(
  'en-US',
  { year: 'numeric', month: 'short', day: '2-digit', hour: 'numeric', minute: 'numeric' }
);

export type CSVField = 'name' | 'rsvp_status' | 'additional_guest_count' | 'message' | 'contact';

export interface DownloadCSVParams {
  fields: CSVField[];
  questions: string[];
}

export const downloadEventAttendeeCSV = async (userContext: IUUserContext, id: string, title: string,
                                               params?: DownloadCSVParams) => {
  let paramsStr = '';
  if (params !== undefined) {
    paramsStr = `/${btoa(JSON.stringify(params))}`;
  }

  const token = btoa(`${userContext.getAuthHeader().userId}:${userContext.getAuthHeader().accessToken}`);
  const response = await fetch(
    `http${WEBSERVER_HTTPS ? 's' : ''}://${WEBSERVER_URL}:${WEBSERVER_PORT}/ElkAnnotated/GetEventAttendeeCSV/${id}/export.csv${paramsStr}`,
    {
      mode: 'cors',
      cache: 'no-cache',
      headers: {
        'Authorization': token
      }
    }
  );

  const blob = await response.blob();
  const content = await blobToBase64(blob);

  if (content) {
    const link = document.createElement('a');
    link.download = `Guestlist for ${title} - ${timeFormat.format(Date.now())}.csv`;
    link.href = content;
    link.click();
  }
};
