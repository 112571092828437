import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { InspirationIdea, InspirationsByType } from '../lib/inspiration';
import BasePageHeader from './BasePageHeader';
import { BasePageContent } from './BasePage';
import { ColorScheme, useBackgroundContext } from '../contexts/BackgroundContext';
import FooterLinks from '../components/FooterLinks';

import { DeviceQueries } from 'Common/src/components/styled';

const InspirationGalleryPage = () => {
  const navigate = useNavigate();
  const backgroundContext = useBackgroundContext();

  useEffect(() => {
    document.title = 'Shine - Inspiration Gallery';

    backgroundContext.setScheme({ scheme: ColorScheme.Welcome });
  }, []);

  const inspire = (inspiration: InspirationIdea) => {
    navigate(`/event/create/fromGallery/${inspiration.id}`);
  };

  return <>
    <BasePageHeader/>
    <BasePageContent $wide={true} $full={true}>
      <GalleryHeading>
        Inspiration Gallery
      </GalleryHeading>
      <ContentContainer>
        {
          Array.from(InspirationsByType.keys()).map((category) => {
            return <CategoryContainer key={category}>
              <CategoryTitle>{category}</CategoryTitle>
              <CarouselWrapper>
                <GalleryContainer>
                  {
                    InspirationsByType.get(category)?.map((inspiration) => <Inspiration
                      key={inspiration.id} onClick={() => inspire(inspiration)}>
                      <InspirationImage src={inspiration.photoUrl}/>
                      <InspirationText>
                        {inspiration.title}
                      </InspirationText>
                    </Inspiration>)
                  }
                </GalleryContainer>
              </CarouselWrapper>
            </CategoryContainer>;
          })
        }
      </ContentContainer>
      <FooterLinks/>
    </BasePageContent>
  </>;
};

const GalleryContainer = styled.div`
  //display: grid;
  //grid-template-columns: repeat(auto-fit, minmax(400px, max-content));
  //grid-gap: 20px;
  display: flex;
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  margin-bottom: -17px; // Hide bottom scroll bar
  padding-bottom: 17px; // Prevent bottom content cut off
  gap: 41px;

  @media (${DeviceQueries.mobile}) {
    gap: 16px;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

const CategoryContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media (${DeviceQueries.mobile}) {
    gap: 12px;
  }
`;

const Inspiration = styled.div`
  width: 311px;
  margin: 0;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 8px;

  @media (${DeviceQueries.mobile}) {
    width: 242px;
  }
`;

const InspirationImage = styled.img`
  width: 311px;
  max-height: 311px;
  object-fit: contain;
  vertical-align: top;
  border-radius: 10px;
  opacity: 1;

  @media (${DeviceQueries.mobile}) {
    width: 242px;
    max-height: 242px;
  }
`;

const InspirationText = styled.div`
  display: flex;
  flex-direction: column;
  transition: background-color 1s;
  flex: 1;
  font-size: 16px;
  font-weight: 500;
`;

const GalleryHeading = styled.div`
  font-size: 30px;
  font-weight: 600;
  margin-bottom: 36px;
  font-family: Poppins, sans-serif;
`;

const CategoryTitle = styled.div`
  font-size: 24px;
  font-weight: 500;
`;

const CarouselWrapper = styled.div`
  overflow: hidden;
`;

export default InspirationGalleryPage;
