import styled from 'styled-components';

import { DeviceQueries } from 'Common/src/components/styled';

export const FilterContainer = styled.div`
  display: flex;
  padding: 6px;
  align-items: flex-end;
  gap: 8px;
  overflow-x: auto;
  flex-shrink: 0;

  @media (${DeviceQueries.mobile}) {
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
    margin-bottom: -17px; // Hide bottom scroll bar
    padding-bottom: 23px; // Prevent bottom content cut off
  }
`;

export const FilterPill = styled.div`
  padding: 6px 10px;
  border-radius: 20px;
  border: 1px solid rgba(0, 0, 0, 0.20);
  font-size: 12px;
  cursor: pointer;
  background: #FFF;
`;

export const InactiveFilterPill = styled(FilterPill)`
  background: none;
  color: rgba(0, 0, 0, 0.40);
`;

export const CarouselWrapper = styled.div`
  //overflow: hidden;
`;
