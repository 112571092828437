import React from 'react';

const TextBlast = ({ size = 21, color = 'currentColor' }: { size?: number, color?: string }) => {
  return <svg width={size} height={size} viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="SpeakerphoneOutline">
      <path id="Vector"
            d="M9.66623 5.40167V16.5333C9.66599 16.8787 9.54385 17.2129 9.32135 17.4771C9.09885 17.7413 8.79026 17.9185 8.44994 17.9774C8.10962 18.0364 7.75941 17.9734 7.46101 17.7994C7.1626 17.6255 6.93515 17.3518 6.81873 17.0267L5.02957 11.9017M5.02957 11.9017C4.32225 11.6009 3.74055 11.0659 3.38292 10.3856C3.0253 9.70523 2.9137 8.92223 3.06701 8.16908C3.22033 7.41593 3.62914 6.73888 4.22428 6.2525C4.81941 5.76612 5.5643 5.50029 6.3329 5.5H7.85957C11.2762 5.5 14.2137 4.47167 15.4996 3V14.6667C14.2137 13.195 11.2771 12.1667 7.85957 12.1667H6.3329C5.88509 12.1673 5.44178 12.0766 5.02957 11.9017ZM15.4996 11.3333C16.1626 11.3333 16.7985 11.0699 17.2673 10.6011C17.7362 10.1323 17.9996 9.49637 17.9996 8.83333C17.9996 8.17029 17.7362 7.53441 17.2673 7.06557C16.7985 6.59673 16.1626 6.33333 15.4996 6.33333"
            stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </g>
  </svg>;
};

export default TextBlast;
