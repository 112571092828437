import React, { createContext, PropsWithChildren, useContext, useEffect, useReducer, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { EventReducer } from '../state/reducers/event';
import { useUserContext } from './UserContext';
import { useEventCacheContext } from './EventCacheContext';
import { useCoinContext } from './CoinContext';
import { createEvent } from '../api/ElkEventService';
import { generateEventImage, sampleEventImage } from '../api/TElkThemeService';
import {
  areEqualEvents,
  IUEvent,
  IUEventColors,
  IUEventErrors,
  IUEventQuestion,
  IUInvitee,
  IULocation
} from '../lib/event';
import { useAutoSaveContext } from './AutoSaveContext';

import { promiseTimeoutWithDefault } from 'Common/src/utils/PromiseTimeout';
import { logSumoEvent, stringifyError, ULogApplication, ULogSeverity, ULogTag } from 'Common/src/api/SumoLogicApi';

import {
  TAppElkAttendeeRole,
  TAppElkAttendeeStatus,
  TAppElkBackgroundAnimation,
  TAppElkEvent,
  TAppElkImageStyle,
  TAppElkPhotoUploadMode,
  TElkCreateEventResponse,
  TElkGenerateImageResponse,
} from 'TProtocol/prototypes/events/messages';

declare const ELK_COINS_ENABLED: boolean;

const ONE_DAY = 24 * 60 * 60 * 1000;

export enum UEventFormField {
  Title = 1,
  Image,
  Day,
  Time,
  Location
}

export interface IUEventContext {
  event: IUEvent | undefined;
  previousEvent: IUEvent | undefined;
  createNewEvent: (defaults?: Partial<IUEvent>, prompt?: string | undefined) => void;
  eventDefaults: () => Partial<IUEvent>;
  storeEvent: (force?: boolean) => Promise<void>;
  unstoreEvent: (eventId?: string) => boolean;
  unstoreOrLoadEvent: (eventId?: string) => Promise<boolean>;
  fetchEvent: (eventId: string, modId?: string) => Promise<void>;
  generateEvent: () => Promise<TAppElkEvent | undefined>;
  createEvent: (event: TAppElkEvent, photoUploadMode: TAppElkPhotoUploadMode | undefined,
                albumId?: string) => Promise<void>;
  handleCreationResponse: (response: TElkCreateEventResponse) => void;
  getEventId: () => string | undefined;
  setEventId: (id: string) => void;
  setIsHost: (isHost: boolean) => void;
  updateFormErrors: (value: IUEventErrors) => void;
  setTitle: (title: string) => void;
  setSubtitle: (title: string) => void;
  setDate: (date: Date) => void;
  setDateQuery: (dateQuery?: string) => void;
  setTimes: (startTime?: number, endTime?: number) => void;
  setLocation: (location: IULocation) => void;
  setHostedBy: (hostedBy: string) => void;
  setHostEmail: (hostEmail: string) => void;
  setIsLoading: (isLoading: boolean) => void;
  setColors: (colors: IUEventColors) => void;
  setAnimation: (animation: TAppElkBackgroundAnimation) => void;
  setBackgroundAnimationSearchQuery: (query: string) => void;
  setBackgroundAnimationUrl: (animationUrl: string) => void;
  setVideoBackgroundDisabled: (disabled?: boolean) => void;
  setPhotoUrl: (photoUrl: string) => void;
  clearPhotoUrls: () => void;
  setTimeZone: (tz: string) => void;
  setEvent: (event: IUEvent) => void;
  setNewEvent: (event: IUEvent) => void;
  saveCheckpoint: () => void;
  restoreCheckpoint: () => void;
  fetchEventSuggestion: (event: IUEvent, style?: TAppElkImageStyle) => Promise<void>;
  clearEvent: () => void;
  updateUrl: (url: string) => void;
  setIsOpenInvite: (isOpenInvite: boolean) => void;
  setDescription: (description: string) => void;
  setShowGuestList: (showGuestList: boolean) => void;
  setHostUploadMode: (hostUploadMode: TAppElkPhotoUploadMode) => void;
  setModificationId: (modificationId: string) => void;
  cancelEvent: () => void;
  setMessage: (message: string) => void;
  setRemindersEnabled: (remindersEnabled: boolean) => void;
  addAQuestion: () => void;
  addSongRequestQuestion: () => void;
  modifyQuestion: (question: IUEventQuestion) => void;
  deleteQuestion: (question: IUEventQuestion) => void;
  setPrompt: (prompt: string) => void;
  setFieldInvalid: (field: UEventFormField) => void;
  isFieldInvalid: (field: UEventFormField) => boolean;
  clearFieldValidity: () => void;
  setFormErrorDiv: (field: UEventFormField, div: HTMLDivElement | null) => void;
  scrollToError: () => void;
  error?: string;
  setError: (error: string | undefined) => void;
  setPlaylistId: (playlistId: string | undefined) => void;
}

const EditEventContext = createContext<IUEventContext | null>(null);

export const EditEventContextProvider = (props: PropsWithChildren<object>) => {
  const userContext = useUserContext();
  const eventCacheContext = useEventCacheContext();
  const autoSaveContext = useAutoSaveContext();
  const coinContext = useCoinContext();

  const [error, setError] = useState<string | undefined>();
  const formErrorDivs = useRef<Map<UEventFormField, HTMLDivElement | null>>(new Map());
  const formValidity = useRef<Set<UEventFormField>>(new Set());

  const eventCreating = useRef(false);

  const [state, dispatch] = useReducer(EventReducer, {
    event: undefined,
    previousEvent: undefined,
    lastSavedEvent: undefined,
  });

  useEffect(() => {
    const heartbeat = async () => {
      await storeEvent();
    };

    const timer = setInterval(heartbeat, 5000);

    return () => {
      clearInterval(timer);
    };
  }, [state.event, state.lastSavedEvent]);

  useEffect(() => {
    if (state.event !== undefined && !areEqualEvents(state.event, state.lastSavedEvent)) {
      autoSaveContext.save(state.event, true);
    }
  }, [state.event]);

  const storeEvent = async (force?: boolean) => {
    if (state.event !== undefined &&
      (state.event.isCreate || state.event.isDraft) &&
      (force || !areEqualEvents(state.event, state.lastSavedEvent))) {
      await autoSaveContext.save(state.event, false);
      dispatch({ type: 'updateLastSaved' });
    }
  };

  const unstoreEvent = (eventId?: string) => {
    const thisEventId = eventId ?? state.event?.id;
    if (thisEventId === undefined) {
      return false;
    }
    const savedEvent = autoSaveContext.load(thisEventId);
    if (savedEvent !== undefined) {
      setEvent(savedEvent);
      return true;
    }
    return false;
  };

  const unstoreOrLoadEvent = async (eventId?: string) => {
    if (eventId && !unstoreEvent(eventId)) {
      const event = await autoSaveContext.fetchDraft(eventId);

      if (event) {
        setEvent(event);
        return true;
      }
    }
    return false;
  };

  const getEventId = () => {
    return state.event?.id;
  };

  const setTitle = (title: string) => {
    dispatch({ type: 'setTitle', title });
  };

  const setSubtitle = (subtitle: string) => {
    dispatch({ type: 'setSubtitle', subtitle });
  };

  const setDate = (date: Date) => {
    dispatch({ type: 'setDate', date });
  };

  const setDateQuery = (dateQuery?: string) => {
    dispatch({ type: 'setDateQuery', dateQuery });
  };

  const setTimes = (startTime?: number, endTime?: number) => {
    dispatch({ type: 'setTimes', startTime, endTime });
  };

  const setLocation = (location: IULocation) => {
    dispatch({ type: 'setLocation', location });
  };

  const setColors = (colors: IUEventColors) => {
    dispatch({ type: 'setColors', colors });
  };

  const setAnimation = (animation: TAppElkBackgroundAnimation) => {
    dispatch({ type: 'setAnimation', animation });
  };

  const setBackgroundAnimationSearchQuery = (query: string) => {
    dispatch({ type: 'setBackgroundAnimationSearchQuery', query });
  };

  const setBackgroundAnimationUrl = (animationUrl: string) => {
    dispatch({ type: 'setBackgroundAnimationUrl', animationUrl });
  };

  const setVideoBackgroundDisabled = (disabled?: boolean) => {
    dispatch({ type: 'setVideoBackgroundDisabled', disabled });
  };

  const setPhotoUrl = (photoUrl: string) => {
    dispatch({ type: 'setPhotoUrl', photoUrl });
  };

  const clearPhotoUrls = () => {
    dispatch({ type: 'clearPhotoUrls' });
  };

  const setAIGeneratedPhotoUrls = (photoUrls?: string[]) => {
    dispatch({ type: 'setAIGeneratedPhotoUrls', photoUrls });
  };

  const setHostUploadMode = (hostUploadMode: TAppElkPhotoUploadMode) => {
    dispatch({ type: 'setHostUploadMode', hostUploadMode });
  };

  const addAIGeneratedPhotoUrls = (photoUrls: string[]) => {
    dispatch({ type: 'addAIGeneratedPhotoUrls', photoUrls });
  };

  const setTimeZone = (tz: string) => {
    dispatch({ type: 'setTimeZone', tz });
  };

  const setEventId = (id: string) => {
    dispatch({ type: 'setEventId', id });
  };

  const setIsHost = (isHost: boolean) => {
    dispatch({ type: 'setIsHost', isHost });
  };

  const setEvent = (event: IUEvent) => {
    dispatch({ type: 'setEvent', event });
  };

  const saveCheckpoint = () => {
    dispatch({ type: 'saveCheckpoint' });
  };

  const restoreCheckpoint = () => {
    dispatch({ type: 'restoreCheckpoint' });
  };

  const setNewEvent = (event: IUEvent) => {
    dispatch({ type: 'setNewEvent', event });
  };

  const clearEvent = () => {
    dispatch({ type: 'clearEvent' });
  };

  const setIsOpenInvite = (isOpenInvite: boolean) => {
    dispatch({ type: 'setIsOpenInvite', isOpenInvite });
  };

  const setMessage = (message: string) => {
    dispatch({ type: 'setMessage', message });
  };

  const setRemindersEnabled = (remindersEnabled: boolean) => {
    dispatch({ type: 'setRemindersEnabled', remindersEnabled });
  };

  const setShowGuestList = (showGuestList: boolean) => {
    dispatch({ type: 'setShowGuestList', showGuestList: showGuestList });
  };

  const setDescription = (description: string) => {
    dispatch({ type: 'setDescription', description });
  };

  const setHostedBy = (hostedBy: string) => {
    dispatch({ type: 'setHostedBy', hostedBy });
  };

  const setIsLoading = (isLoading: boolean) => {
    dispatch({ type: 'setIsLoading', isLoading });
  };

  const setHostEmail = (hostEmail: string) => {
    dispatch({ type: 'setHostEmail', hostEmail });
  };

  const createNewEvent = (defaults?: Partial<IUEvent>) => {
    dispatch({ type: 'createNewEvent', defaults });
  };

  const updateFormErrors = (value: IUEventErrors) => {
    dispatch({ type: 'updateFormErrors', value });
  };

  const updateUrl = (url: string) => {
    dispatch({ type: 'updateUrl', url });
  };

  const fetchEvent = async (eventId: string) => {
    const event = await eventCacheContext.fetchEvent({ eventId, isPreview: !userContext.isLoggedIn() });

    if (event !== undefined) {
      dispatch({ type: 'setEvent', event });
    }
  };

  const eventDefaults = (): Partial<IUEvent> => {
    let userDefaults: Partial<IUEvent> = {};
    if (userContext.isLoggedIn()) {
      userDefaults = {
        hostedBy: userContext.name,
        attendees: [
          new IUInvitee({
            inviteeId: uuidv4(),
            userId: userContext.id,
            name: userContext.name,
            email: state.event?.hostEmail,
            rsvpMessage: '',
            additionalGuestCount: 0,
            role: TAppElkAttendeeRole.ORGANIZER,
            rsvpStatus: TAppElkAttendeeStatus.YES
          })
        ],
        hostEmail: userContext.getEmail(),
        title: state.event?.title,
        subtitle: state.event?.subtitle,
        startTime: state.event?.startTime ?? Date.now() + ONE_DAY,
        endTime: state.event?.endTime,
        location: state.event?.location,
        animation: state.event?.animation,
        photoUrl: state.event?.photoUrl,
        isCreate: state.event?.isCreate ?? true,
        isDraft: state.event?.isDraft ?? true,
        IsFromQuickCreate: state.event?.IsFromQuickCreate,
        dateQuery: state.event?.dateQuery,
        description: state.event?.description,
        backgroundAnimationSearchQuery: state.event?.backgroundAnimationSearchQuery,
        prompt: state.event?.prompt
      };
    } else {
      userDefaults = {
        title: state.event?.title,
        subtitle: state.event?.subtitle,
        startTime: state.event?.startTime,
        endTime: state.event?.endTime,
        location: state.event?.location,
        animation: state.event?.animation,
        photoUrl: state.event?.photoUrl,
        isCreate: true,
        isDraft: true,
        description: state.event?.description,
        backgroundAnimationSearchQuery: state.event?.backgroundAnimationSearchQuery,
        prompt: state.event?.prompt
      };
    }
    return userDefaults;
  };

  const generateEvent = async () => {
    if (state.event === undefined) {
      return undefined;
    }

    if (state.event.attendees.length === 0 && state.event.isCreate && userContext.id !== undefined && userContext.name !== '') {
      state.event.attendees.push(
        new IUInvitee({
          inviteeId: uuidv4(),
          userId: userContext.id,
          name: userContext.name,
          email: state.event.hostEmail,
          rsvpMessage: '',
          additionalGuestCount: 0,
          role: TAppElkAttendeeRole.ORGANIZER,
          rsvpStatus: TAppElkAttendeeStatus.YES
        })
      );
    }

    return await state.event.convertToTAppElkEvent(userContext.name);
  };

  const createEventInternal = async (event: TAppElkEvent, photoUploadMode: TAppElkPhotoUploadMode | undefined,
                                     albumId?: string) => {
    if (!eventCreating.current) {
      eventCreating.current = true;
      await createEvent(userContext, event, photoUploadMode, true, albumId);
      dispatch({ type: 'createComplete' });
      eventCreating.current = false;
    }
  };

  const handleCreationResponse = (response: TElkCreateEventResponse) => {
    dispatch({ type: 'handleCreationResponse', response });
  };

  const addAQuestion = () => {
    dispatch({ type: 'addAQuestion' });
  };

  const addSongRequestQuestion = () => {
    dispatch({ type: 'addSongRequestQuestion' });
  };

  const modifyQuestion = (question: IUEventQuestion) => {
    dispatch({ type: 'modifyQuestion', question });
  };

  const deleteQuestion = (question: IUEventQuestion) => {
    dispatch({ type: 'deleteQuestion', question });
  };

  const cancelEvent = () => {
    dispatch({ type: 'cancelEvent' });
  };

  const fetchEventSuggestion = async (event: IUEvent, style?: TAppElkImageStyle) => {
    const originalUrls = event.aiGeneratedPhotoUrls;
    setAIGeneratedPhotoUrls();

    const result = await fetchEventImage(event, style === undefined, event.prompt, style);

    // Normally, this does nothing, but in the case that all three fetches fail, this will set the photo urls to [],
    // which is the error case.
    addAIGeneratedPhotoUrls([]);

    if (!result && originalUrls !== undefined && event.photoUrl === undefined) {
      // Retain first of original AI generated photo URLs if result is invalid and event photoURL has not been set
      setPhotoUrl(originalUrls[0]);
    }

    if (ELK_COINS_ENABLED && !userContext.isLoggedIn()) {
      coinContext.spendCoins(result);
    }
  };

  const fetchEventImage = async (event: IUEvent, useVerbatim: boolean, prompt?: string,
                                 style?: TAppElkImageStyle): Promise<number> => {
    try {
      let generatePromise: Promise<TElkGenerateImageResponse>;
      if (userContext.isLoggedIn()) {
        generatePromise = generateEventImage(userContext, event, useVerbatim, prompt, style);
      } else {
        generatePromise = sampleEventImage(userContext, event, coinContext.getBalance(), useVerbatim, prompt, style);
      }
      const response = await promiseTimeoutWithDefault(
        generatePromise,
        60000,
        new TElkGenerateImageResponse({
          imageUrls: []
        })
      );
      addAIGeneratedPhotoUrls(response.imageUrls);
      return 1;
    } catch (e) {
      void logSumoEvent({
        app: ULogApplication.ELK,
        severity: ULogSeverity.WARN,
        userId: userContext.id,
        tag: ULogTag.ImageGen,
        message: `[EditEventContext] Error generating image: ${stringifyError(e)}`
      });

      return 0;
    }
  };

  const setModificationId = (modificationId: string) => {
    dispatch({ type: 'setModificationId', modificationId });
  };

  const setPrompt = (prompt: string) => {
    dispatch({ type: 'setPrompt', prompt });
  };

  const setFormErrorDiv = (field: UEventFormField, div: HTMLDivElement) => {
    formErrorDivs.current.set(field, div);
  };

  const setFieldInvalid = (field: UEventFormField) => {
    formValidity.current.add(field);
  };

  const isFieldInvalid = (field: UEventFormField) => {
    return formValidity.current.has(field);
  };

  const clearFieldValidity = () => {
    formValidity.current.clear();
  };

  const scrollToError = () => {
    for (const field of [UEventFormField.Title, UEventFormField.Image, UEventFormField.Day, UEventFormField.Time, UEventFormField.Location]) {
      if (formValidity.current.has(field)) {
        formErrorDivs.current.get(field)?.scrollIntoView();
        break;
      }
    }
  };

  const setPlaylistId = (playlistId: string) => {
    dispatch({ type: 'setPlaylistId', playlistId });
  };

  const context: IUEventContext = {
    event: state.event,
    previousEvent: state.previousEvent,
    createNewEvent,
    eventDefaults,
    storeEvent,
    unstoreEvent,
    unstoreOrLoadEvent,
    fetchEvent,
    generateEvent,
    createEvent: createEventInternal,
    getEventId,
    setEventId,
    setIsHost,
    setColors,
    setAnimation,
    setBackgroundAnimationSearchQuery,
    setBackgroundAnimationUrl,
    setVideoBackgroundDisabled,
    setPhotoUrl,
    clearPhotoUrls,
    setTimeZone,
    handleCreationResponse,
    updateFormErrors,
    setTitle,
    setSubtitle,
    setDate,
    setDateQuery,
    setTimes,
    setLocation,
    fetchEventSuggestion,
    clearEvent,
    setEvent,
    setNewEvent,
    saveCheckpoint,
    restoreCheckpoint,
    updateUrl,
    setIsOpenInvite,
    setDescription,
    setShowGuestList: setShowGuestList,
    setModificationId,
    cancelEvent,
    setHostedBy,
    setHostEmail,
    setMessage,
    setRemindersEnabled,
    addAQuestion,
    addSongRequestQuestion,
    modifyQuestion,
    deleteQuestion,
    setPrompt,
    setFieldInvalid,
    isFieldInvalid,
    clearFieldValidity,
    setFormErrorDiv,
    scrollToError,
    error,
    setError,
    setIsLoading,
    setHostUploadMode,
    setPlaylistId
  };

  return (
    <EditEventContext.Provider value={context}>
      {props.children}
    </EditEventContext.Provider>
  );
};

export const useEditEventContext = () => {
  const context = useContext(EditEventContext);
  if (context === null) {
    throw new Error('useEventContext must be used within a EditEventContextProvider');
  }
  return context;
};
