import React from 'react';

const Settings = ({ color }: {
  color?: string
}) => {
  return <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="CogOutline">
      <path id="Vector"
            d="M10.325 4.817C10.751 3.061 13.249 3.061 13.675 4.817C13.7389 5.0808 13.8642 5.32578 14.0407 5.532C14.2172 5.73822 14.4399 5.89985 14.6907 6.00375C14.9414 6.10764 15.2132 6.15085 15.4838 6.12987C15.7544 6.10889 16.0162 6.0243 16.248 5.883C17.791 4.943 19.558 6.709 18.618 8.253C18.4769 8.48466 18.3924 8.74634 18.3715 9.01677C18.3506 9.28721 18.3938 9.55877 18.4975 9.80938C18.6013 10.06 18.7627 10.2826 18.9687 10.4591C19.1747 10.6355 19.4194 10.7609 19.683 10.825C21.439 11.251 21.439 13.749 19.683 14.175C19.4192 14.2389 19.1742 14.3642 18.968 14.5407C18.7618 14.7172 18.6001 14.9399 18.4963 15.1907C18.3924 15.4414 18.3491 15.7132 18.3701 15.9838C18.3911 16.2544 18.4757 16.5162 18.617 16.748C19.557 18.291 17.791 20.058 16.247 19.118C16.0153 18.9769 15.7537 18.8924 15.4832 18.8715C15.2128 18.8506 14.9412 18.8938 14.6906 18.9975C14.44 19.1013 14.2174 19.2627 14.0409 19.4687C13.8645 19.6747 13.7391 19.9194 13.675 20.183C13.249 21.939 10.751 21.939 10.325 20.183C10.2611 19.9192 10.1358 19.6742 9.95929 19.468C9.7828 19.2618 9.56011 19.1001 9.30935 18.9963C9.05859 18.8924 8.78683 18.8491 8.51621 18.8701C8.24559 18.8911 7.98375 18.9757 7.752 19.117C6.209 20.057 4.442 18.291 5.382 16.747C5.5231 16.5153 5.60755 16.2537 5.62848 15.9832C5.64942 15.7128 5.60624 15.4412 5.50247 15.1906C5.3987 14.94 5.23726 14.7174 5.03127 14.5409C4.82529 14.3645 4.58056 14.2391 4.317 14.175C2.561 13.749 2.561 11.251 4.317 10.825C4.5808 10.7611 4.82578 10.6358 5.032 10.4593C5.23822 10.2828 5.39985 10.0601 5.50375 9.80935C5.60764 9.55859 5.65085 9.28683 5.62987 9.01621C5.60889 8.74559 5.5243 8.48375 5.383 8.252C4.443 6.709 6.209 4.942 7.753 5.882C8.749 6.49 10.049 5.952 10.325 4.817Z"
            stroke={color} strokeOpacity="0.7" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path id="Vector_2"
            d="M15 12.5C15 13.2956 14.6839 14.0587 14.1213 14.6213C13.5587 15.1839 12.7956 15.5 12 15.5C11.2044 15.5 10.4413 15.1839 9.87868 14.6213C9.31607 14.0587 9 13.2956 9 12.5C9 11.7044 9.31607 10.9413 9.87868 10.3787C10.4413 9.81607 11.2044 9.5 12 9.5C12.7956 9.5 13.5587 9.81607 14.1213 10.3787C14.6839 10.9413 15 11.7044 15 12.5Z"
            stroke={color} strokeOpacity="0.7" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </g>
  </svg>;
};

export default Settings;
