import React from 'react';

const Upload = ({ color = 'currentColor', size = 24 }: { color?: string, size?: number }) => {
  return <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd"
          d="M22.7692 8.096C22.7692 7.19877 22.4123 6.33846 21.7785 5.70338C20.5932 4.51938 18.2498 2.176 17.0658 0.990769C16.4308 0.356923 15.5705 0 14.6732 0C13.0671 0 9.84861 0 7.07692 0C5.52615 0 4.03938 0.615385 2.94277 1.712C1.84615 2.80862 1.23077 4.29538 1.23077 5.84615V18.1538C1.23077 21.3822 3.84861 24 7.07692 24H16.9231C20.1514 24 22.7692 21.3822 22.7692 18.1538C22.7692 14.5982 22.7692 10.0849 22.7692 8.096ZM14.7692 1.84862C14.7372 1.84738 14.7052 1.84615 14.6732 1.84615C13.0671 1.84615 9.84861 1.84615 7.07692 1.84615C6.016 1.84615 4.99815 2.26708 4.24861 3.01785C3.49784 3.76739 3.07692 4.78523 3.07692 5.84615V18.1538C3.07692 20.3631 4.86769 22.1538 7.07692 22.1538H16.9231C19.1323 22.1538 20.9231 20.3631 20.9231 18.1538C20.9231 14.5982 20.9231 10.0849 20.9231 8.096C20.9231 8.064 20.9218 8.032 20.9206 8H18.1538C17.2566 8 16.3951 7.64308 15.76 7.00923C15.1262 6.37415 14.7692 5.51262 14.7692 4.61538V1.84862ZM19.6172 6.15385L16.6154 3.152V4.61538C16.6154 5.024 16.7778 5.41415 17.0658 5.70338C17.3551 5.99138 17.7452 6.15385 18.1538 6.15385H19.6172Z"
          fill={color}/>
    <path fillRule="evenodd" clipRule="evenodd"
          d="M12.0001 8.61539H12.0222C12.1071 8.61785 12.1896 8.63139 12.2671 8.65477L12.2696 8.656C12.4099 8.69785 12.5416 8.77539 12.6524 8.88616L15.1139 11.3477C15.4745 11.7071 15.4745 12.2929 15.1139 12.6523C14.7545 13.0129 14.1687 13.0129 13.8093 12.6523L12.9231 11.7674V15.6923C12.9231 16.2018 12.5096 16.6154 12.0001 16.6154C11.4905 16.6154 11.077 16.2018 11.077 15.6923V11.7674L10.1908 12.6523C9.83144 13.0129 9.24559 13.0129 8.88621 12.6523C8.52559 12.2929 8.52559 11.7071 8.88621 11.3477L11.3477 8.88616C11.4585 8.77539 11.5902 8.69785 11.7305 8.656L11.733 8.65477C11.8105 8.63139 11.893 8.61785 11.9779 8.61539H12.0001Z"
          fill={color}/>
  </svg>;
};

export default Upload;
