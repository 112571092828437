import logRpc from '../util/logRpc';

import Net from 'Common/src/lib/Net';
import { augmentService } from 'Common/src/lib/ThriftServiceAugmentor';

import { TAuthHeader } from 'TProtocol/common/models';
import { TElkInitializePaymentRequest, TElkInitializePaymentResponse } from 'TProtocol/prototypes/events/messages';
import { TElkPaymentService } from 'PProtocol/prototypes/events';

const createPaymentService = (): TElkPaymentService.Client => {
  return augmentService(Net.createService(TElkPaymentService, '/json/ElkPaymentService'));
};

export const initializePayment = async (header: TAuthHeader, priceId: string): Promise<TElkInitializePaymentResponse> => {
  logRpc(null, 'initializePayment');
  const request: TElkInitializePaymentRequest = new TElkInitializePaymentRequest({
    priceId
  });
  const client: TElkPaymentService.Client = createPaymentService();
  return client.initializePayment(header, request);
};
