import React from 'react';

const MusicNote = () => {
  return <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M21 3.04395V15.5439C21 16.4722 20.6313 17.3624 19.9749 18.0188C19.3185 18.6752 18.4283 19.0439 17.5 19.0439C16.5717 19.0439 15.6815 18.6752 15.0251 18.0188C14.3687 17.3624 14 16.4722 14 15.5439C14 14.6157 14.3687 13.7254 15.0251 13.0691C15.6815 12.4127 16.5717 12.0439 17.5 12.0439C18.04 12.0439 18.55 12.1639 19 12.3839V6.51395L9 8.64395V17.5439C9 18.4722 8.63125 19.3624 7.97487 20.0188C7.3185 20.6752 6.42826 21.0439 5.5 21.0439C4.57174 21.0439 3.6815 20.6752 3.02513 20.0188C2.36875 19.3624 2 18.4722 2 17.5439C2 16.6157 2.36875 15.7254 3.02513 15.0691C3.6815 14.4127 4.57174 14.0439 5.5 14.0439C6.04 14.0439 6.55 14.1639 7 14.3839V6.04395L21 3.04395Z"
      fill="black"/>
  </svg>;
};

export default MusicNote;
