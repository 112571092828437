import React from 'react';

const DashedCircle = ({ color }: { color: string }) => {
  return <svg width="45" height="45" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
    <ellipse
      style={{
        fill: 'none',
        fillOpacity: 1,
        stroke: color,
        strokeWidth: 1,
        strokeDasharray: '4, 4',
        strokeDashoffset: 0,
        strokeOpacity: 1
      }}
      cx="30"
      cy="30"
      rx="30"
      ry="30"/>
  </svg>;
};

export default DashedCircle;
