import React from 'react';

const UserCircle = ({ color = 'currentColor', size = 21 }: { color?: string, size?: number }) => {
  return <svg width={size} height={size} viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd"
          d="M18 10.5C18 12.6217 17.1571 14.6566 15.6569 16.1569C14.1566 17.6571 12.1217 18.5 10 18.5C7.87827 18.5 5.84344 17.6571 4.34315 16.1569C2.84285 14.6566 2 12.6217 2 10.5C2 8.37827 2.84285 6.34344 4.34315 4.84315C5.84344 3.34285 7.87827 2.5 10 2.5C12.1217 2.5 14.1566 3.34285 15.6569 4.84315C17.1571 6.34344 18 8.37827 18 10.5ZM12 7.5C12 8.03043 11.7893 8.53914 11.4142 8.91421C11.0391 9.28929 10.5304 9.5 10 9.5C9.46957 9.5 8.96086 9.28929 8.58579 8.91421C8.21071 8.53914 8 8.03043 8 7.5C8 6.96957 8.21071 6.46086 8.58579 6.08579C8.96086 5.71071 9.46957 5.5 10 5.5C10.5304 5.5 11.0391 5.71071 11.4142 6.08579C11.7893 6.46086 12 6.96957 12 7.5ZM10 11.5C9.0426 11.4998 8.10528 11.7745 7.29942 12.2914C6.49356 12.8083 5.85304 13.5457 5.454 14.416C6.01668 15.0706 6.71427 15.5958 7.49894 15.9555C8.28362 16.3152 9.13681 16.5009 10 16.5C10.8632 16.5009 11.7164 16.3152 12.5011 15.9555C13.2857 15.5958 13.9833 15.0706 14.546 14.416C14.147 13.5457 13.5064 12.8083 12.7006 12.2914C11.8947 11.7745 10.9574 11.4998 10 11.5Z"
          fill={color}/>
  </svg>;
};

export default UserCircle;
