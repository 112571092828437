import React from 'react';
import styled from 'styled-components';

import MessageRow from './MessageRow';

import { TAppElkMessage } from 'TProtocol/prototypes/events/messages';

interface Props {
  messages?: (TAppElkMessage | undefined)[];
}

const MessageGroup = ({ messages }: Props) => {
  if (messages === undefined) {
    return null;
  }

  return <Group>
    {messages.map((message, index) => <MessageRow key={index} message={message}/>)}
  </Group>;

};

const Group = styled.div`
  display: flex;
  flex-direction: column;
  gap: 22px;
  overflow-y: scroll;
`;

export default MessageGroup;
