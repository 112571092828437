declare const TZ_API_KEY: string;

export interface GoogleTimezoneResponse {
  'dstOffset': number,
  'rawOffset': number,
  'status': string,
  'timeZoneId': string
  'timeZoneName': string
}

export const calculateTimeZone = async (lat: number, lon: number, ts: number): Promise<string> => {
  try {
    const input = `https://maps.googleapis.com/maps/api/timezone/json?location=${lat}%2C${lon}&timestamp=${Math.floor(
      ts / 1000)}&key=${TZ_API_KEY}`;
    const response = await (
      await fetch(input)
    ).json() as GoogleTimezoneResponse;

    return response.timeZoneId;
  } catch (e) {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  }
};
