import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Modal from './Modal';
import { EventsFilterType } from '../../lib/event';
import { useAutoSaveContext } from '../../contexts/AutoSaveContext';

const DeleteDraftModal = ({ eventId, close }: { eventId: string, close: () => void }) => {
  const navigate = useNavigate();
  const autoSaveContext = useAutoSaveContext();
  const [submitting, setSubmitting] = useState(false);

  const handleDelete = async () => {
    setSubmitting(true);
    await autoSaveContext.remove(eventId);
    close();
    navigate('/events', { state: { activeTab: EventsFilterType.Drafts } });
  };

  return <Modal
    title="Delete Draft"
    subtitle="Are you sure you want to delete this draft?"
    secondaryButtonLabel="Not now"
    onSecondaryClick={close}
    primaryButtonLabel="Yes, delete"
    onPrimaryClick={handleDelete}
    onClickOutside={close}
    primaryButtonDisabled={submitting}
    secondaryButtonDisabled={submitting}
  />;
};

export default DeleteDraftModal;

