import React from 'react';
import { useNavigate } from 'react-router-dom';

import { useEditEventContext } from '../../contexts/EditEventContext';
import { useUserContext } from '../../contexts/UserContext';
import Modal from './Modal';
import { cancelEvent } from '../../api/ElkEventService';

const CancelEventModal = ({ eventId, close }: { eventId: string, close: () => void }) => {
  const navigate = useNavigate();
  const eventContext = useEditEventContext();
  const userContext = useUserContext();

  const handleCancel = async () => {
    eventContext.cancelEvent();
    void cancelEvent(userContext, eventId);
    close();
    navigate(`/event/${eventId}`);
  };

  return <Modal
    title="Cancel Event"
    subtitle="Are you sure you want to cancel this event? A notification will be sent to all guests."
    secondaryButtonLabel="Not now"
    onSecondaryClick={close}
    primaryButtonLabel="Yes, cancel event"
    onPrimaryClick={handleCancel}
    onClickOutside={close}
  />;
};


export default CancelEventModal;

