import React from 'react';

const AppleIcon = ({ color = 'currentColor', size = 26 }: { color?: string, size?: number }) => {
  return <svg width={size} height={size} viewBox="0 0 25 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#n1wi6kxeza)" fill={color}>
      <path
        d="M17.311.5h.178c.143 1.763-.53 3.08-1.348 4.034-.803.948-1.901 1.867-3.679 1.727-.118-1.738.556-2.957 1.372-3.909.758-.887 2.147-1.676 3.477-1.852zM22.691 18.852v.049c-.5 1.513-1.212 2.81-2.081 4.012-.794 1.093-1.766 2.563-3.503 2.563-1.5 0-2.498-.965-4.036-.992-1.627-.026-2.521.807-4.009 1.017h-.507c-1.092-.158-1.974-1.023-2.616-1.803-1.894-2.303-3.357-5.278-3.63-9.085v-1.119c.116-2.724 1.44-4.94 3.2-6.013.928-.571 2.205-1.058 3.627-.84.61.094 1.232.303 1.777.51.517.198 1.164.55 1.776.532.415-.013.828-.229 1.246-.381 1.226-.443 2.426-.95 4.01-.712 1.902.288 3.252 1.133 4.087 2.437-1.61 1.025-2.882 2.568-2.665 5.204.193 2.394 1.585 3.795 3.324 4.62z"/>
    </g>
    <defs>
      <clipPath id="n1wi6kxeza">
        <path fill="#fff" transform="translate(0 .5)" d="M0 0h25v25H0z"/>
      </clipPath>
    </defs>
  </svg>
    ;
};

export default AppleIcon;
