import Net from 'Common/src/lib/Net';
import { augmentService } from 'Common/src/lib/ThriftServiceAugmentor';
import { TCoinService } from 'PProtocol/coin';
import {
  TAppVerifyCoinPurchaseData,
  TAppWebCoinTransactionData,
  TFetchCoinBalanceRequest,
  TFetchCoinBalanceResponse,
  TVerifyCoinPurchaseRequest,
  TVerifyCoinPurchaseResponse
} from 'TProtocol/coin/messages';

import { TAuthHeader } from 'TProtocol/common/models';
import logRpc from '../util/logRpc';

const createCoinService = (): TCoinService.Client => {
  return augmentService(Net.createService(TCoinService, '/json/CoinService'));
};

export const fetchCoinBalance = async (header: TAuthHeader): Promise<TFetchCoinBalanceResponse> => {
  logRpc(null, 'fetchCoinBalance');
  const request: TFetchCoinBalanceRequest = new TFetchCoinBalanceRequest();
  const client: TCoinService.Client = createCoinService();
  return client.fetchCoinBalance(header, request);
};

export const verifyCoinPurchase = async (header: TAuthHeader, taskId: string, paymentIntentId: string,
                                         productId: string): Promise<TVerifyCoinPurchaseResponse> => {
  logRpc(null, 'verifyCoinPurchase');
  const data = new TAppVerifyCoinPurchaseData({
    webData: new TAppWebCoinTransactionData({
      paymentIntentId,
      productId
    })
  });

  const request: TVerifyCoinPurchaseRequest = new TVerifyCoinPurchaseRequest({
    taskId,
    data
  });
  const client: TCoinService.Client = createCoinService();
  return client.verifyCoinPurchase(header, request);
};
