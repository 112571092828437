import React from 'react';

const DeleteUserIcon = ({ color = 'currentColor', size = 30 }: { color?: string, size?: number }) => {
  return <svg width={size} height={size} viewBox="0 0 30 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.4432 26.2618C23.0739 26.2618 28.5482 20.7706 28.5482 14.1568C28.5482 7.52488 23.0538 2.05176 16.4336 2.05176C10.267 2.05176 5.12504 6.76996 4.42871 12.7578C5.14425 12.5933 6.09229 12.5485 6.85682 12.6652C7.54354 7.98542 11.542 4.43627 16.4336 4.43627C21.8182 4.43627 26.1637 8.76261 26.1637 14.1568C26.1637 16.7223 25.1859 19.0491 23.5615 20.7657C22.3603 19.422 19.7808 18.1981 16.4336 18.1981C15.374 18.1981 14.3976 18.3261 13.5203 18.534C13.6546 19.0902 13.7228 19.689 13.7228 20.2782C13.7228 22.1844 13.0043 23.9532 11.8075 25.3133C13.2405 25.925 14.8078 26.2618 16.4432 26.2618ZM16.4336 16.2671C18.6963 16.2884 20.4679 14.3518 20.4679 11.8385C20.4679 9.47347 18.6825 7.50167 16.4336 7.50167C14.1752 7.50167 12.3759 9.47347 12.3972 11.8385C12.4111 14.3422 14.1689 16.2479 16.4336 16.2671Z"
      fill={color}/>
    <path fillRule="evenodd"
          d="M6.03117 26.3211C9.30234 26.3211 12.0623 23.5857 12.0623 20.2878C12.0623 16.9782 9.34078 14.2587 6.03117 14.2587C2.72906 14.2587 0 16.9878 0 20.2878C0 23.6049 2.72906 26.3211 6.03117 26.3211Z M4.4126 23.1019C4.08471 23.4319 3.56112 23.3872 3.25034 23.071C2.92995 22.7644 2.8894 22.2462 3.2194 21.9184L4.88089 20.259L3.33893 18.7074C3.03987 18.3987 3.03026 17.889 3.33893 17.5857C3.6476 17.2941 4.15197 17.2866 4.46065 17.5857L6.01221 19.1277L7.66409 17.4662C7.99409 17.1383 8.50174 17.1884 8.82002 17.5013C9.12869 17.8217 9.18307 18.3273 8.85518 18.6573L7.19369 20.3187L8.73143 21.8586C9.03471 22.179 9.03471 22.6749 8.73143 22.9782C8.42065 23.2794 7.9226 23.2911 7.60971 22.9803L6.07198 21.4501L4.4126 23.1019Z"
          fill={color}/>
  </svg>
    ;
};

export default DeleteUserIcon;
