import React from 'react';

const LinkIcon = ({ color = 'currentColor', size=20 }: { color?: string, size?: number }) => {
  return <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.5233 8.47477C10.8982 7.84987 10.0505 7.49881 9.16661 7.49881C8.28273 7.49881 7.43504 7.84987 6.80994 8.47477L3.47661 11.8081C3.15824 12.1156 2.9043 12.4834 2.72961 12.8901C2.55491 13.2968 2.46296 13.7342 2.45911 14.1768C2.45526 14.6194 2.5396 15.0583 2.70721 15.4679C2.87481 15.8776 3.12232 16.2498 3.43529 16.5628C3.74827 16.8757 4.12044 17.1232 4.5301 17.2908C4.93975 17.4584 5.37868 17.5428 5.82128 17.5389C6.26388 17.5351 6.70128 17.4431 7.10796 17.2684C7.51464 17.0937 7.88246 16.8398 8.18995 16.5214L9.10828 15.6039M8.47661 11.5214C9.1017 12.1463 9.9494 12.4974 10.8333 12.4974C11.7172 12.4974 12.5649 12.1463 13.1899 11.5214L16.5233 8.1881C17.1305 7.55943 17.4665 6.71742 17.4589 5.84343C17.4513 4.96944 17.1007 4.1334 16.4827 3.51537C15.8646 2.89735 15.0286 2.54678 14.1546 2.53919C13.2806 2.53159 12.4386 2.86758 11.8099 3.47477L10.8933 4.39144"
      stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>;
};

export default LinkIcon;
