import React, { MouseEvent, useState } from 'react';
import styled from 'styled-components';
import { FileUploader } from 'react-drag-drop-files';

import { useUserContext } from '../../contexts/UserContext';
import { upload } from '../../api/UploadInviteApi';
import { ModalContent, ModalHeader, ModalTitle, Overlay, SystemModalContainer } from './CommonModal';
import { BackArrowContainer } from '../../pages/InvitePage';
import BackArrow from '../icons/BackArrow';
import SystemButton, { ButtonInternal } from '../buttons/SystemButton';
import File from '../icons/File';
import { ErrorContainer } from '../login/SinglePhoneEmailInput';
import ErrorIcon from '../icons/ErrorIcon';

import { logSumoEvent, stringifyError, ULogApplication, ULogSeverity, ULogTag } from 'Common/src/api/SumoLogicApi';

interface Props {
  eventId: string,
  close: () => void,
  onComplete: () => void
}

const InviteUploadModal = ({ eventId, close, onComplete }: Props) => {
  const userContext = useUserContext();

  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const onModalClick = (e: MouseEvent) => {
    e.stopPropagation();
  };

  const onFileChange = (file: File) => {
    setSelectedFile(file);
    setError(null);
  };

  const onSubmit = async () => {
    if (selectedFile) {
      try {
        setSubmitting(true);
        setError(null);
        const response = await upload(userContext.getAuthHeader(), selectedFile, eventId);
        if (response.isValid) {
          onComplete();
        } else {
          setError('Sorry, that CSV is not in a format we recognize.');
        }
      } catch (e) {
        void logSumoEvent({
          app: ULogApplication.ELK,
          severity: ULogSeverity.WARN,
          userId: userContext.id,
          tag: ULogTag.UserError,
          message: `[InviteUploadModal] Error during CSV upload: ${stringifyError(e)}`
        });

        setError(
          'Sorry, something went wrong. Please try again, and if the problem persists, contact Customer Support.');
      }
      setSubmitting(false);
    }
  };

  const onClose = () => {
    void logSumoEvent({
      app: ULogApplication.ELK,
      severity: ULogSeverity.INFO,
      userId: userContext.id,
      tag: ULogTag.UserAction,
      message: '[InviteUploadModal] Closed modal'
    });

    close();
  };

  const uploadFile = <FileUploader handleChange={onFileChange} name="file" types={['csv']}>
    <FileUploadArea>
      <File/>
      <ImportLabel>Import CSV file</ImportLabel>
      <ImportLabelDescription>Drag file or choose from computer.</ImportLabelDescription>
    </FileUploadArea>
  </FileUploader>;

  const editFile = <SelectedFileDisplay>
    <FileName>{selectedFile?.name}</FileName>
    <ReplaceFileButton onClick={() => setSelectedFile(null)}>Replace</ReplaceFileButton>
  </SelectedFileDisplay>;

  return <Overlay onClick={onClose}>
    <SystemModalContainer onClick={onModalClick}>
      <ModalContent>
        <ModalHeader>
          <BackArrowContainer onClick={onClose}>
            <BackArrow/>
          </BackArrowContainer>
          <ModalTitle>Upload invites</ModalTitle>
        </ModalHeader>
        <FileDescription>Import a .csv file with column names: Name, Email</FileDescription>
        {selectedFile === null ? uploadFile : editFile}
        {error && <ErrorContainer><ErrorIcon/><span>{error}</span></ErrorContainer>}
      </ModalContent>
      <Footer>
        <SystemButton disabled={selectedFile === null || submitting} onClick={onSubmit}>Upload</SystemButton>
      </Footer>
    </SystemModalContainer>
  </Overlay>;
};

const SelectedFileDisplay = styled.div`
  display: flex;
  padding: 8px 10px;
  justify-content: space-between;
  align-items: center;
  border-radius: 12px;
  background: rgba(255, 255, 255, 0.30);
  box-shadow: 0 2px 5px 0 rgba(103, 110, 118, 0.08), 0 0 0 1px rgba(103, 110, 118, 0.16), 0 1px 1px 0 rgba(0, 0, 0, 0.12);
`;

const FileName = styled.div`
  color: #000;
  font-size: 16px;
  font-weight: 400;
`;

const ReplaceFileButton = styled.button`
  color: var(--shine-highlight-color);
  font-size: 16px;
  font-weight: 500;
  border: none;
  background: none;
  padding: 0;
  cursor: pointer;
  font-family: Poppins, sans-serif;
`;

const FileUploadArea = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px 10px;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  background: rgba(0, 0, 0, 0.04);
  cursor: pointer;

  &:hover {
    background: rgba(0, 0, 0, 0.09);
  }
`;

const ImportLabel = styled.div`
  color: rgba(0, 0, 0, 0.80);
  font-size: 18px;
  font-weight: 500;
`;

const ImportLabelDescription = styled.div`
  color: rgba(0, 0, 0, 0.40);
  font-size: 13px;
  font-weight: 400;
`;

const Footer = styled.div`
  ${ButtonInternal} {
    width: 100%;
  }
`;

const FileDescription = styled.div`
  color: rgba(0, 0, 0, 0.80);
`;

export default InviteUploadModal;

