import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

import { ColorScheme, useBackgroundContext } from '../contexts/BackgroundContext';
import BasePageHeader, { UPageType } from './BasePageHeader';
import { BasePageContent } from './BasePage';
import ShineLogo from '../components/icons/ShineLogo';
import SparkleButton from '../components/buttons/SparkleButton';
import { useEditEventContext } from '../contexts/EditEventContext';
import FooterLinks from '../components/FooterLinks';

import heroLeft from '../assets/HeroLeft.png';
import heroRight from '../assets/HeroRight.png';
import mobileWelcomePhone from '../assets/MobileWelcomePhone2.png';
import Rect1 from '../assets/promos/Rectangle 4059 (1).png';
import Photo2 from '../assets/promos/photo2.jpeg.png';
import Photo4 from '../assets/promos/photo4.png';
import Photo6 from '../assets/promos/photo6.png';

import { DeviceQueries } from 'Common/src/components/styled';

const WelcomePage = () => {
  const navigate = useNavigate();
  const backgroundContext = useBackgroundContext();
  const editEventContext = useEditEventContext();

  useEffect(() => {
    document.title = 'Shine';
    backgroundContext.setScheme({ scheme: ColorScheme.Welcome });
  }, []);

  const onCreateClick = () => {
    editEventContext.clearEvent();
    navigate('/event/create/ai');
  };

  return <>
    <BasePageHeader page={UPageType.Welcome}/>
    <BasePageContent $wide={true} $flush={true}>
      <ContentContainer>
        <LeftColumn>&nbsp;</LeftColumn>
        <MiddleColumn>
          <Header>
            Enlightened&nbsp;Events
          </Header>
          <Subheader>
            with <ShineLogo color="#FF6422" size={144} opacity={1}/>
          </Subheader>
          <DesktopShow>
            <ContentLine>
              Flawless, compelling events
            </ContentLine>
            <ContentLine>
              Unique invites with <strong>generative AI</strong>
            </ContentLine>
            <ContentLine>
              <strong>Integrated photo sharing</strong>
            </ContentLine>
            <ContentLine>
              Invite and communicate <strong>with ease</strong>
            </ContentLine>
          </DesktopShow>
          <ContentLine>
            <SparkleButtonContainer>
              <SparkleButton buttonText="Create your invite" disabled={false} onClick={onCreateClick}/>
            </SparkleButtonContainer>
          </ContentLine>
          <MobileShow>
            <PhoneImage src={mobileWelcomePhone} width={200} height={300}/>
            <ContentLine>
              Flawless, compelling events
            </ContentLine>
            <ContentLine>
              <strong>Generative AI</strong>
            </ContentLine>
            <ContentLine>
              <strong>Plus, integrated photo sharing</strong>
            </ContentLine>
            <PhotosRow>
              <SmallImage src={Photo2}/>
              <SmallImage src={Rect1}/>
              <SmallImage src={Photo6}/>
              <SmallImage src={Photo4}/>
            </PhotosRow>
          </MobileShow>
          <FooterLinks/>
        </MiddleColumn>
        <RightColumn>&nbsp;</RightColumn>
      </ContentContainer>
    </BasePageContent>
  </>;
};

const ContentContainer = styled.div`
  justify-content: center;
  text-align: center;
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 20px;
  flex-wrap: wrap;
  width: 100svw;
  height: calc(100svh - 70px);
`;

const OuterColumn = styled.div`
  flex-grow: 1;
  background-size: cover;
  height: 100%;

  @media (${DeviceQueries.mobile}) {
    display: none;
  }
`;

const LeftColumn = styled(OuterColumn)`
  background-image: url(${heroLeft});
  background-position: right;
`;

const MiddleColumn = styled.div`
  min-width: max(525px, 40svw);
  color: #19191B;
  display: flex;
  flex-direction: column;
  gap: 32px;
  align-items: center;

  @media (${DeviceQueries.mobile}) {
    min-width: 100svw;
    gap: 10px;
  }
`;

const RightColumn = styled(OuterColumn)`
  background-image: url(${heroRight});
  background-position: left;
`;

const Header = styled.div`
  font-size: 55px;

  @media (${DeviceQueries.mobile}) {
    font-size: 30px;
  }
`;

const Subheader = styled.div`
  font-size: 40px;
  display: flex;
  justify-content: center;
  gap: 10px;

  @media (${DeviceQueries.mobile}) {
    font-size: 23px;

    svg {
      height: 29px;
      width: 84px;
      margin-top: -1px;
    }
  }
`;

const ContentLine = styled.div`
  font-size: 24px;
  font-weight: 400;

  strong {
    font-weight: 500;
  }

  @media (${DeviceQueries.mobile}) {
    font-size: 20px;
  }
`;

const SparkleButtonContainer = styled.span`
  --button-text-color: #FF6422;
  display: inline-block;
`;

const PhoneImage = styled.img`
  object-fit: cover;
  object-position: top;
  -webkit-mask-image: linear-gradient(to top, transparent 0%, black 25%, black 100%);
  mask-image: linear-gradient(to top, transparent 0%, black 25%, black 100%);
`;

const PhotosRow = styled.div`
  display: flex;
  gap: 14px;
`;

const SmallImage = styled.img`
  @media (${DeviceQueries.mobile}) {
    width: 20vw;
  }
`;

const MobileShow = styled.div`
  display: contents;

  @media not (${DeviceQueries.mobile}) {
    display: none;
  }
`;
const DesktopShow = styled.div`
  display: contents;

  @media (${DeviceQueries.mobile}) {
    display: none;
  }
`;

export default WelcomePage;
