import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';

import { IUEvent } from '../../lib/event';
import { useEditEventContext } from '../../contexts/EditEventContext';
import GeneratedImageSelection from '../../pages/Create/GeneratedImageSelection';
import BackgroundAnimationPicker from '../../pages/Create/BackgroundAnimationPicker';
import MultiColorPicker from '../ColorPicker/MultiColorPicker';
import Questions from '../questions/Questions';
import useVideoBackgroundPicker from '../../hooks/useVideoBackgroundPicker';
import GiphyLogo from '../../assets/GiphyLogo.png';
import PexelsLogo from '../../assets/PexelsLogo.png';
import ExpandingTextInput from '../ExpandingTextInput';
import { ActionMenuLabel, ContentTitle, ContentTitleWrapper } from './ActionMenuCommon';
import { DesktopShow, MobileShow } from '../styled/Common';
import QuestionMarkCircle from '../icons/QuestionMarkCircle';
import Modal from '../modals/Modal';
import { useModalContext } from '../../contexts/ModalContext';
import { useUserContext } from '../../contexts/UserContext';
import Edit from '../icons/Edit';
import ShareWithFriendsModal from '../modals/ShareWithFriendsModal';
import Mail from '../icons/Mail';
import TextBlast from '../icons/TextBlast';
import SpotifyQuestionsWidget from '../SpotifyQuestionsWidget';
import ShineLogo from '../icons/ShineLogo';
import ColorButton from '../buttons/ColorButton';
import PhotographOutline from '../icons/PhotographOutline';
import ComputerOutline from '../icons/ComputerOutline';
import Settings from '../icons/Settings';
import XCircle from '../icons/XCircle';
import { Container } from '../forms/Input';

import { logSumoEvent, stringifyError, ULogApplication, ULogSeverity, ULogTag } from 'Common/src/api/SumoLogicApi';
import LinkIcon from 'Common/src/components/icons/LinkIcon';
import { BLACK } from 'Common/src/lib/Colors';
import { DeviceQueries, isMobile } from 'Common/src/components/styled';
import Checkbox from 'Common/src/components/Checkbox';

import { TAppElkEventQuestionType, TAppElkPhotoUploadMode } from 'TProtocol/prototypes/events/messages';

const ONE_DAY = 24 * 60 * 60 * 1000;

export enum ActionMenuTab {
  GUESTS = 'Guest options',
  IMAGE_GEN = 'Image',
  THEME = 'Background',
  QUESTIONS = 'Questions',
  MORE = 'More',
  HOME = 'Home',
  PROFILE = 'Profile'
}

declare const ELK_WEBAPP_URL: string;

const ActionMenu = ({ event, scrollToTop, openTab, setOpenTab }: {
  event: IUEvent;
  isCreate?: boolean,
  scrollToTop: () => void;
  openTab?: ActionMenuTab;
  setOpenTab: (tab?: ActionMenuTab) => void;
}) => {
  const videoPicker = useVideoBackgroundPicker();
  const editEventContext = useEditEventContext();
  const userContext = useUserContext();
  const modalContext = useModalContext();
  const location = useLocation();
  const navigate = useNavigate();
  const { eventId } = useParams();
  const initialBackgroundLoad = useRef<boolean>(true);

  const inEditMode = location.pathname.includes('/edit') || location.pathname.includes('/create');

  const [videoPromptChanged, setVideoPromptChanged] = useState<boolean>(false);

  //TODO: remove feature flag after Spotify approval
  const SPOTIFY_FEATURE_FLAG_ENABLED = userContext.id === 'cf936152-2823-4c21-8713-d80c4ffe8e4c';
  // const SPOTIFY_FEATURE_FLAG_ENABLED = true;

  useEffect(() => {
    if (inEditMode) {
      if (event.backgroundAnimationUrl) {
        void videoPicker.prepopulateVideoList();
      } else if (!event.backgroundAnimationSearchQuery) {
        void videoPicker.setDefaultBackgroundVideo();
      } else {
        void videoPicker.setNewBackgroundVideo(event.backgroundAnimationSearchQuery);
      }
    }
  }, []);

  useEffect(() => {
    if (location.state?.spotifySuccess) {
      setOpenTab(ActionMenuTab.QUESTIONS);
    }
  }, [location.state]);

  const handleInputChange = (name: string, value: string) => {
    if (name === 'description') {
      editEventContext.setDescription(value);
    } else if (name === 'hosted') {
      editEventContext.setHostedBy(value);
    } else if (name === 'hostEmail') {
      editEventContext.setHostEmail(value);
    } else if (name === 'title') {
      editEventContext.setTitle(value);
    } else if (name === 'subtitle') {
      editEventContext.setSubtitle(value);
      if (initialBackgroundLoad.current) {
        initialBackgroundLoad.current = false;
      } else {
        setVideoPromptChanged(true);
      }
    }
  };

  const handleTabClick = (tab: ActionMenuTab) => {
    if (tab === ActionMenuTab.HOME) {
      navigate('/');
    } else if (tab === ActionMenuTab.PROFILE) {
      navigate('/profile/edit');
    } else {
      editEventContext.saveCheckpoint();
      setOpenTab(tab);
    }
  };

  const handleSaveClick = () => {
    setOpenTab(undefined);
    modalContext.hide();
  };

  const handleCancelClick = () => {
    editEventContext.restoreCheckpoint();
    setOpenTab(undefined);
    modalContext.hide();
  };

  const handleEditClick = () => {
    modalContext.hide();
    if (event === undefined || !event.isHost) {
      return;
    }
    editEventContext.setEvent(event);
    navigate('/event/edit/' + event.id);
  };

  const handleTextBlast = () => {
    modalContext.hide();
    navigate('/event/text/' + event?.id);
  };

  const onShare = async () => {
    modalContext.hide();
    if (event?.isHost) {
      if (eventId !== undefined) {
        navigate(`/event/invite/${eventId}`);
      }
    } else {
      modalContext.show({
        contents: <ShareWithFriendsModal close={modalContext.hide} event={event}/>
      });
    }
  };

  const onShareLink = async () => {
    if (navigator.share) {
      try {
        void logSumoEvent({
          app: ULogApplication.ELK,
          severity: ULogSeverity.INFO,
          userId: userContext.id,
          tag: ULogTag.UserAction,
          message: '[EventPage] Clicked on share link with share API'
        });

        await navigator.share({
          title: event?.title ?? 'Shine',
          url: event?.url ?? (event ? `https://${ELK_WEBAPP_URL}/event/${event.id}` : `https://${ELK_WEBAPP_URL}/`),
        });
      } catch (e) {
        if (e.name !== 'AbortError') {
          void logSumoEvent({
            app: ULogApplication.ELK,
            severity: ULogSeverity.WARN,
            userId: userContext.id,
            tag: ULogTag.UserError,
            message: `[EventPage] Error during user share: ${stringifyError(e)}`
          });
        }
      }
    } else {
      // Fallback for browsers that do not support the Web Share API
      void logSumoEvent({
        app: ULogApplication.ELK,
        severity: ULogSeverity.INFO,
        userId: userContext.id,
        tag: ULogTag.UserAction,
        message: '[EventPage] Clicked on share link without share API'
      });

      modalContext.show({
        contents: <ShareWithFriendsModal close={modalContext.hide} event={event}/>
      });
    }
  };

  const onShuffleVideo = (query: string) => {
    if (videoPromptChanged || !videoPicker.hasMore()) {
      void videoPicker.setNewBackgroundVideo(query);
      setVideoPromptChanged(false);
    } else {
      videoPicker.incrementIndex();
    }
  };

  const onImageGenerate = () => {
    if (isMobile()) {
      setOpenTab(undefined);
      modalContext.hide();
      scrollToTop();
    }
  };

  const formatLastSavedTimestamp = (timestamp: number) => {
    const date = new Date(timestamp);
    if (Date.now() - timestamp > ONE_DAY) {
      return new Intl.DateTimeFormat('us',
        { hour: 'numeric', hour12: true, minute: '2-digit', month: 'short', day: 'numeric' }).format(date);
    } else {
      return new Intl.DateTimeFormat('us', { hour: 'numeric', hour12: true, minute: '2-digit' }).format(date);
    }
  };

  const editEventTabs = <TabsContainer $isCollapsed={openTab === undefined} $includeLabels={false}>
    <ActionTabs>
      <DesktopShow>
        <MenuTab
          onClick={() => navigate('/')}
          $active={false} $noHover={true}>
          <ShineLogo/>
        </MenuTab>
      </DesktopShow>
      <MenuTab
        onClick={() => handleTabClick(ActionMenuTab.IMAGE_GEN)}
        $active={openTab === ActionMenuTab.IMAGE_GEN}
      >
        <IconContainer><PhotographOutline color={BLACK}/></IconContainer>
        Cover image
      </MenuTab>
      <MenuTab
        onClick={() => handleTabClick(ActionMenuTab.THEME)}
        $active={openTab === ActionMenuTab.THEME}
      >
        <IconContainer><ComputerOutline color={BLACK}/></IconContainer>
        Video background
      </MenuTab>
      <MenuTab
        onClick={() => handleTabClick(ActionMenuTab.GUESTS)}
        $active={openTab === ActionMenuTab.GUESTS}
      >
        <IconContainer><Settings color={BLACK}/></IconContainer>
        Guest options
      </MenuTab>
      <MenuTab
        onClick={() => handleTabClick(ActionMenuTab.QUESTIONS)}
        $active={openTab === ActionMenuTab.QUESTIONS}
      >
        <IconContainer><QuestionMarkCircle color={BLACK}/></IconContainer>
        Guest questions
      </MenuTab>
    </ActionTabs>
    {event.lastSavedTimestamp && <SavedAt>
      <div>Saved at</div>
      {formatLastSavedTimestamp(event.lastSavedTimestamp)}
    </SavedAt>}
  </TabsContainer>;

  const hostActionTabs = <>
    <DesktopShow>
      {editEventTabs}
    </DesktopShow>
    <MobileShow>
      <TabsContainer $isCollapsed={true} $includeLabels={true}>
        <ActionTabs>
          <MenuTab
            onClick={handleEditClick}
            $active={false}
          >
            <Edit size={20}/>
            <>Edit</>
          </MenuTab>
          <MenuTab
            onClick={onShare}
            $active={false}
          >
            <Mail size={20}/>
            <>Invite</>
          </MenuTab>
          <MenuTab
            onClick={onShareLink}
            $active={false}
          >
            <LinkIcon size={20}/>
            <>Share</>
          </MenuTab>
          {event.attendees.length > 1 &&
            <MenuTab
              onClick={handleTextBlast}
              $active={false}
            >
              <TextBlast size={20}/>
              <>Message</>
            </MenuTab>
          }
        </ActionTabs>
      </TabsContainer>
    </MobileShow>
  </>;

  const showHostActionTabs = event.isHost && (event.cancelledTimestamp === undefined || event.cancelledTimestamp === 0);

  return (
    <ActionMenuArea>
      {
        openTab !== undefined && <Overlay onClick={handleSaveClick}/>
      }
      <ActionMenuContainer $isCollapsed={openTab === undefined}>
        {inEditMode ? editEventTabs : showHostActionTabs ? hostActionTabs : null}

        <ContentWrapper $isCollapsed={openTab === undefined}>
          <ContentAnimationMask $isCollapsed={openTab === undefined}>
            <ContentArea $isCollapsed={openTab === undefined}>
              <ContentTitleWrapper $withMarginBottom={openTab === ActionMenuTab.QUESTIONS}>
                <ContentTitle>
                  {openTab}
                </ContentTitle>
                <CloseIconWrapper onClick={handleSaveClick}>
                  <XCircle/>
                </CloseIconWrapper>
              </ContentTitleWrapper>
              {openTab === ActionMenuTab.IMAGE_GEN &&
                <ImageTabContent>
                  <GeneratedImageSelection event={event} excludeImage={true} onImageGenerate={onImageGenerate}/>
                </ImageTabContent>
              }
              {openTab === ActionMenuTab.THEME &&
                <ThemeTabContent>
                  <PickersContainer>
                    <BackgroundAnimationPicker
                      searchQuery={event.subtitle}
                      onSearchQueryChange={(value) => handleInputChange('subtitle', value)}
                      onSubmit={onShuffleVideo}
                    />
                    <ColorButton
                      disabled={event.subtitle?.length === 0 || (!videoPromptChanged && !videoPicker.hasMore())}
                      onClick={() => onShuffleVideo(event.subtitle)}
                    >
                      {videoPromptChanged ? 'Search' : 'Try another'}
                    </ColorButton>
                    <Checkbox id="turn-off-video"
                              checked={editEventContext.event?.videoBackgroundDisabled}
                              onInputChange={(checked) => editEventContext.setVideoBackgroundDisabled(checked)}
                              $labelFontSize="16px">
                      Turn off video background
                    </Checkbox>
                    <MultiColorPicker/>
                  </PickersContainer>
                  <VideoSourceLogos>
                    <img src={GiphyLogo}/>
                    <Link to="https://www.pexels.com" target="_blank" rel="noreferrer noopener">
                      <PexelsLogoImg src={PexelsLogo}/>
                    </Link>
                  </VideoSourceLogos>
                </ThemeTabContent>
              }
              {openTab === ActionMenuTab.GUESTS && (
                <GuestTabContent>
                  <CheckboxesContainer>
                    <Checkbox id={'field-add'}
                              checked={editEventContext.event?.openInvite ?? false}
                              onInputChange={(checked) => editEventContext.setIsOpenInvite(checked)}
                              $labelFontSize={'16px'}>
                      Allow guests to invite more people
                    </Checkbox>
                    <Checkbox id={'GuestList'}
                              checked={editEventContext.event?.publicGuestList ?? false}
                              onInputChange={(checked) => editEventContext.setShowGuestList(checked)}
                              $labelFontSize={'16px'}>
                      Show guest list
                    </Checkbox>
                    <Checkbox id={'Reminders'}
                              checked={editEventContext.event?.remindersEnabled ?? false}
                              onInputChange={(checked) => editEventContext.setRemindersEnabled(checked)}
                              $labelFontSize={'16px'}>
                      Send guests automatic reminders
                    </Checkbox>
                  </CheckboxesContainer>
                  <HostNameWrapper>
                    <ActionMenuLabel>Guests will see the following host name:</ActionMenuLabel>
                    <ExpandingTextInput
                      placeholder={''}
                      value={event.hostedBy}
                      textColor="currentColor"
                      onChange={(value) => editEventContext.setHostedBy(value)}
                      noVertMargin={true}
                      isForSideBar={true}
                    />
                  </HostNameWrapper>
                </GuestTabContent>
              )}
              {openTab === ActionMenuTab.QUESTIONS && <>
                <Questions
                  event={event}
                  questions={event.questions.filter(
                    question => question.questionType === TAppElkEventQuestionType.TEXT)}
                  questionType={TAppElkEventQuestionType.TEXT}
                  handleAddQuestion={() => editEventContext.addAQuestion()}
                  onCancel={handleCancelClick}
                  onSave={handleSaveClick}
                />
                {/* ------ SPOTIFY FEATURE FLAG ----- */}
                {SPOTIFY_FEATURE_FLAG_ENABLED &&
                  <SpotifyQuestionsWidget event={event} onCancel={handleCancelClick} onSave={handleSaveClick}/>}
              </>}
            </ContentArea>
          </ContentAnimationMask>
        </ContentWrapper>
      </ActionMenuContainer>
    </ActionMenuArea>
  );
};

const ActionMenuContainer = styled.div<{
  $isCollapsed: boolean
}>`
  display: flex;
  z-index: 100;
  position: relative;

  //overflow-x: hidden; // Prevent horizontal scrolling

  @media (${DeviceQueries.mobile}) {
    flex-direction: column-reverse; // Stack vertically with tabs at the bottom
    width: 100%;
    height: auto;
    padding: 0;
  }

  @media not (${DeviceQueries.mobile}) {
    height: 100%;
    //max-width: 45vw;
  }
`;

const TabsContainer = styled.div<{
  $isCollapsed: boolean,
  $includeLabels: boolean
}>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0; // Prevent the tab container from shrinking
  backdrop-filter: blur(40px);
  width: 140px;
  background: rgba(255, 255, 255, 0.80);
  z-index: 101;

  @media (${DeviceQueries.mobile}) {
    padding: 10px;
    box-sizing: border-box;
    width: 100%; // Take full width of the screen
    flex-direction: row; // Align tabs horizontally
    justify-content: space-around; // Distribute tabs evenly
    border-top: 1px solid rgba(15, 8, 22, 0.00);
    margin-top: 1px; // To show border
    z-index: 101;
  }
`;

const ContentWrapper = styled.div<{
  $isCollapsed: boolean
}>`
  display: flex;
  position: relative;
  flex-direction: column;

  @media (${DeviceQueries.mobile}) {
    width: 100%; // Use the full width on mobile
    max-height: 60vh;
      //max-height: ${({ $isCollapsed }) => ($isCollapsed ? '0' : 'calc(60vh)')}; // Adjust height dynamically
    //border-top-left-radius: 12px;
    //border-top-right-radius: 12px;
  }

`;

const ContentAnimationMask = styled.div<{ $isCollapsed: boolean }>`
  width: ${({ $isCollapsed }) => $isCollapsed ? 0 : '400px'};
  height: 100%;
  position: absolute;
  overflow: hidden;
  transition: width 0.3s cubic-bezier(.36, -0.01, 0, .77);

  @media (${DeviceQueries.mobile}) {
    width: 100%;
    position: relative;
    height: auto;
  }
`;

const ContentArea = styled.div<{ $isCollapsed: boolean }>`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 20px;
  overflow-x: hidden; // Prevent horizontal scrolling within content area
  transition: background-color 0.3s ease, margin-left 0.3s cubic-bezier(.36, -0.01, 0, .77), margin-bottom 0.3s cubic-bezier(.36, -0.01, 0, .77);
  color: black;
  width: 400px;
  height: 100%;
  backdrop-filter: blur(40px);
  background: rgba(255, 255, 255, 0.80);
  border-radius: 0 16px 16px 0;
  //position: absolute;
  box-sizing: border-box;
  z-index: 100;

  @media not (${DeviceQueries.mobile}) {
    margin-left: ${({ $isCollapsed }) => $isCollapsed ? '-400px' : 0};
  }

  @media (${DeviceQueries.mobile}) {
    height: auto;
    max-height: 60vh;
    width: 100%;
    background: rgba(255, 255, 255, 0.78);
    border-radius: 16px 16px 0 0;
    bottom: 0;
    margin-bottom: ${({ $isCollapsed }) => $isCollapsed ? '-100vh' : 0};
  }
`;

const ArrowButton = styled.button`
  //padding: 5px;
  //margin-top: -6px;
  //margin-right: 20px;
  //transform: translateY(-50%);
  cursor: pointer;
  border: none;
  background: none;
  font-size: 1.5em; /* Adjust size as needed */

  @media (${DeviceQueries.mobile}) {
    position: absolute;
    padding: 5px;
    top: 0%;
    right: 0%;
    margin-right: 30px;
    margin-top: 60px;
    z-index: 200;
    transform: translateX(50%) translateY(-100%) rotate(-90deg);
  }

`;

const MenuTab = styled.div<{
  $active: boolean,
  $noHover?: boolean,
}>`
  padding: 25px;
  cursor: pointer;
  ${({ $active }) => $active ? 'background: rgba(0, 0, 0, 0.06); box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.04);' : 'background: transparent'};
  transition: background-color 0.3s ease;
  color: rgba(0, 0, 0, 0.70);
  flex-direction: column;
  //height: 50px;
  display: flex;
  align-items: center;
  gap: 5px;
  max-width: 110px;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;

  @media (${DeviceQueries.mobile}) {
    border-radius: 10px;
    background-color: ${({ $active }) => $active ? 'rgba(0, 0, 0, 0.06)' : 'transparent'};
    padding: 10px;
    height: 72px;
    flex-direction: column;
    justify-content: center;
    font-size: 12px;
    word-spacing: 100vw;
  }

  &:hover {
    ${({ $noHover, $active }) => !$noHover && !$active && 'background: rgba(0, 0, 0, 0.03)'};
  }
`;

const CheckboxesContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 20px 0;
  gap: 20px;
`;

const MoreTabContent = styled.div`
`;

const ThemeTabContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

const VideoSourceLogos = styled.div`
  display: flex;
  margin-top: 20px;
`;

const PexelsLogoImg = styled.img`
  height: 30px;
  margin-left: 15px;
  margin-bottom: -15px;
`;

const ActionMenuArea = styled.div`
  z-index: 100;
`;

const ActionTabs = styled.div`
  @media (${DeviceQueries.mobile}) {
    width: 100%;
    display: flex;
    justify-content: space-around;
  }
`;

const ButtonContainer = styled.div`
  margin: 15px 0;
`;

const PickersContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  ${Container} {
    min-height: auto;
  }
`;

const IconContainer = styled.div`
  height: 24px;
`;

const ImageTabContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const GuestTabContent = styled.div`
`;

const HostNameWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;

export const ActionButtons = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media (${DeviceQueries.mobile}) {
    flex-direction: row-reverse;
    gap: 10px;
  }
`;

export const Overlay = styled.div`
  top: 0;
  left: 0;
  position: absolute;
  z-index: 50;
  width: 100%;
  height: 100%;
`;

const CloseIconWrapper = styled.div`
  cursor: pointer;
`;

const SavedAt = styled.div`
  color: rgba(0, 0, 0, 0.40);
  font-size: 14px;
  padding-bottom: 30px;
  text-align: center;

  @media (${DeviceQueries.mobile}) {
    display: none;
  }
`;

export default ActionMenu;
