import styled from 'styled-components';

export const Container = styled.div<{
  $zIndex?: number,
  $growing?: boolean,
  $noVertMargin?: boolean,
  $backgroundColor?: string,
  $excludeBackground?: boolean
}>`
  margin: ${({ $noVertMargin }) => $noVertMargin ? '0;' : '20px 0;'}
  gap: 4px;
  padding: 0;
  border-radius: 12px;
  ${({ $excludeBackground }) => !$excludeBackground && 'border: solid 2px rgba(255, 255, 255, 0.21);'};
  ${({
       $excludeBackground,
       $backgroundColor
     }) => !$excludeBackground && `background-color: ${$backgroundColor ?? 'var(--shine-system-background-color)'}`};
  ${({ $growing }) => $growing ? 'min-height: 56px;' : 'height: 56px;'};
  ${({ $growing }) => $growing ? 'min-width: 100%;' : 'height: 56px;'};

  display: flex;
  flex-direction: column;
  position: relative;
  ${({ $zIndex }) => $zIndex !== undefined ? `z-index: ${$zIndex};` : ''}
`;

export const Input = styled.input<{
  $color?: string,
  $noVerticalPadding?: boolean,
  $noBackgroundContainer?: boolean,
  $inModal?: boolean
}>`
  border: 0;
  outline: 0;
  box-sizing: border-box;
  background: transparent;
  font-size: ${({ $inModal = false }) => $inModal ? '16px' : '22px'};
  color: ${({ $color = 'currentColor' }) => $color};
  width: 100%;
  font-family: Poppins, sans-serif;
  align-self: stretch;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 4px;
  border-radius: 10px;
  height: 56px;
  padding: ${({
                $noVerticalPadding,
                $inModal = false
              }) => $noVerticalPadding ? $inModal ? '0 10px' : '0 16px' : $inModal ? '10px' : '20px 16px 10px 16px'};

  ${({ $inModal = false }) => $inModal && 'height: auto; border-radius: 12px; background: rgba(255, 255, 255, 0.30); box-shadow: 0 2px 5px 0 rgba(103, 110, 118, 0.08), 0 0 0 1px rgba(103, 110, 118, 0.16), 0 1px 1px 0 rgba(0, 0, 0, 0.12);'};

  &:autofill ~ div[data-lastpass-icon-root], &:-webkit-autofill ~ div[data-lastpass-icon-root] {
    display: none;
  }

  &::placeholder {
      // ${({ $noBackgroundContainer }) => $noBackgroundContainer && 'opacity: 1;'};
  }
`;

export const Label = styled.label<{
  $noLeftPadding?: boolean
}>`
  font-weight: 300;
  font-size: 18px;
  padding: ${({ $noLeftPadding }) => $noLeftPadding ? '5px 0 0 0' : '5px 0 0 16px'};

  font-family: Poppins, sans-serif;

  input:autofill + &, input:-webkit-autofill + & {
    color: #000;
  }
`;

export const ExternalLabel = styled.label`
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
`;

export const InputContainer = styled.div<{
  $borderColor?: string,
  $filled?: boolean,
  $smaller?: boolean,
  $fixedHeight?: boolean,
  $minHeight?: number,
  $color?: string
}>`
  /* Existing styles */
  position: relative;
  display: flex;
  font-family: Poppins, sans-serif;
  flex-direction: column;
  font-size: 24px;
  align-self: stretch;
  flex-grow: 0;
  justify-content: flex-start;
  gap: 4px;
  width: ${({ $smaller }) => ($smaller ? '70%' : '100%')};
  ${({ $fixedHeight }) => $fixedHeight ? 'height: 56px;' : ''}
  ${({ $minHeight }) => $minHeight !== undefined && `min-height: ${$minHeight}px`};
  color: ${({ $color }) => $color ?? 'currentColor'};

  &:focus-within label,
  & label,
  &[data-ph] {
    position: absolute;
    pointer-events: none;
    transform: ${({ $filled }) => ($filled ? 'translate(5px, 2.5px) scale(0.7)' : 'translate(0, 15px) scale(1)')};
    transform-origin: top left;
    transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    font-size: 16px;
    line-height: 1;
  }

  /* This ensures the label stays in the transformed state when the input has content */
  ${({ $filled }) =>
    $filled &&
    `
    label {
      opacity: 1;
      transform: translate(5px, 2.5px) scale(0.7);
    }
  `}
`;

export const ExternalContainer = styled.div<{
  $borderColor?: string,
  $filled?: boolean,
  $smaller?: boolean,
  $fixedHeight?: boolean,
  $minHeight?: number,
  $inModal?: boolean
}>`
  ${({ $inModal }) => !$inModal && 'font-size: 24px; background-color: var(--shine-system-background-color); border: 1px solid rgba(15, 8, 22, 0.06);'}

  border-radius: ${({ $inModal }) => ($inModal ? '12px' : '6px')};
  ${({ $inModal }) => $inModal && '0 2px 5px 0 rgba(103, 110, 118, 0.08), 0 0 0 1px rgba(103, 110, 118, 0.16), 0 1px 1px 0 rgba(0, 0, 0, 0.12);'};

`;

export const ErrorContainer = styled.div`
  font-size: 16px;
  color: var(--shine-system-error-color);
  font-weight: bold;
  margin: 1px 0 5px 16px;
`;

export const HiddenInput = styled.input`
  opacity: 0;
  height: 1px;
  display: block;
  position: absolute;
`;

export const Textarea = styled.textarea<{ $invalid: boolean }>`
  ${({ $invalid }) => $invalid ? 'border: 1px solid #cc3333;' : ''}
  width: calc(100% - 8px);
  resize: vertical;
  padding: 8px 10px;
  border-radius: 12px;
  background: rgba(255, 255, 255, 0.30);
  box-shadow: 0 2px 5px 0 rgba(103, 110, 118, 0.08), 0 0 0 1px rgba(103, 110, 118, 0.16), 0 1px 1px 0 rgba(0, 0, 0, 0.12);
  border: none;
  font-family: Poppins, sans-serif;

  &::placeholder {
    color: rgba(0, 0, 0, 0.20);
    font-size: 13px;
    font-weight: 400;
  }
`;
