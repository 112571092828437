import React from 'react';

const BackArrow = ({ color = 'currentColor', size = 20 }: { color?: string, size?: number }) => {
  return <svg xmlns="http://www.w3.org/2000/svg" width="20" height={size} viewBox="0 0 20 21" fill="none">
    <path d="M8.33333 16.3346L2.5 10.5013M2.5 10.5013L8.33333 4.66797M2.5 10.5013H17.5" stroke={color} strokeWidth="2"
          strokeLinecap="round" strokeLinejoin="round"/>
  </svg>;
};

export default BackArrow;
