import { useEffect } from 'react';

declare const ELK_WEBAPP_URL: string;

const HostRedirect = (): null => {

  useEffect(() => {
    if (ELK_WEBAPP_URL && window.location.host !== ELK_WEBAPP_URL) {
      window.location.host = ELK_WEBAPP_URL;
    }
  }, []);

  return null;
};

export default HostRedirect;
