import React from 'react';

const LocationMarker = ({ color = 'currentColor', size = 18 }: { color?: string, size?: number }) => {
  return <svg width={size} height={size} viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd"
          d="M2.05051 2.59723C3.36333 1.28441 5.14389 0.546875 7.0005 0.546875C8.85711 0.546875 10.6377 1.28441 11.9505 2.59723C13.2633 3.91005 14.0009 5.69062 14.0009 7.54723C14.0009 9.40384 13.2633 11.1844 11.9505 12.4972L7.0005 17.4472L2.05051 12.4972C1.40042 11.8472 0.884739 11.0755 0.532912 10.2262C0.181084 9.37686 0 8.46655 0 7.54723C0 6.62792 0.181084 5.71761 0.532912 4.86828C0.884739 4.01896 1.40042 3.24725 2.05051 2.59723ZM7.0005 9.54723C7.53094 9.54723 8.03965 9.33652 8.41472 8.96144C8.78979 8.58637 9.00051 8.07767 9.00051 7.54723C9.00051 7.0168 8.78979 6.50809 8.41472 6.13302C8.03965 5.75795 7.53094 5.54723 7.0005 5.54723C6.47007 5.54723 5.96136 5.75795 5.58629 6.13302C5.21122 6.50809 5.0005 7.0168 5.0005 7.54723C5.0005 8.07767 5.21122 8.58637 5.58629 8.96144C5.96136 9.33652 6.47007 9.54723 7.0005 9.54723Z"
          fill={color}/>
  </svg>;
};

export default LocationMarker;
