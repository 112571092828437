import React from 'react';
import styled from 'styled-components';

const SingleFieldError = ({ error }: { error: string }) => {
  return <ErrorBox $opacity={error === '' ? 0 : 1}>{error}</ErrorBox>;
};

const ErrorBox = styled.div<{ $opacity: number }>`
  margin: 15px 0 0;
  padding: 14px 7px 17px 8px;
  border-radius: 12px;
  background-color: var(--shine-system-background-color);
  font-size: 18px;
  line-height: 1.5;
  font-weight: bold;
  color: var(--shine-system-error-color);
  text-align: center;
  opacity: ${({ $opacity }) => $opacity};
`;

export default SingleFieldError;
