import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  body {
    font-family: Poppins, sans-serif;
    touch-action: none;
    margin: 0;
  }

  [data-lastpass-icon-root] {
    display: none !important;
  }
`;

export default GlobalStyle;
