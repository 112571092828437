import React, { ReactNode, useContext, useState } from 'react';

export interface IUModalConfig {
  contents: ReactNode;
}

export interface IUModalContext {
  show: (config: IUModalConfig) => void;
  hide: () => void;
  shown: boolean;
}

const ModalContext = React.createContext<IUModalContext | null>(null);

export function ModalContextProvider(props: { children: React.ReactNode }) {
  const [config, setConfig] = useState<IUModalConfig | null>(null);
  const [shown, setShown] = useState(false);

  const show = (config: IUModalConfig) => {
    setConfig(config);
    setShown(true);
  };

  const hide = () => {
    setConfig(null);
    setShown(false);
  };

  const context: IUModalContext = {
    show,
    hide,
    shown
  };

  return <ModalContext.Provider value={context}>
    {config ? config.contents : null}
    {props.children}
  </ModalContext.Provider>;
}

export const useModalContext = () => {
  const context = useContext(ModalContext);
  if (context === null) {
    throw new Error('useModalContext must be used within a ModalContextProvider');
  }
  return context;
};
