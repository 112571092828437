import React, { ReactNode } from 'react';
import styled from 'styled-components';

interface Props {
  icon: ReactNode;
  text: ReactNode;
  color: string;
  iconOffsetX?: number,
  iconOffsetY?: number;
  isButtonCentered?: boolean;
  onClick?: () => void
}

const IconCircle = ({
                      icon,
                      text,
                      color,
                      iconOffsetX = 0,
                      iconOffsetY = 0,
                      isButtonCentered = false,
                      onClick
                    }: Props) => {

  return <Container onClick={onClick} $isButtonCentered={isButtonCentered}>
    <Wrapper>
      {
        <Circle $color={color} $offsetX={iconOffsetX} $offsetY={iconOffsetY}>
          {icon}
        </Circle>
      }

      <TextContainer $color={color} $shouldGrow={!isButtonCentered}>
        {text}
      </TextContainer>
    </Wrapper>
  </Container>;
};

const Container = styled.div<{ $isButtonCentered: boolean }>`
  display: flex;
  margin: 25px 0;
  cursor: pointer;
  justify-content: ${({ $isButtonCentered }) => $isButtonCentered ? 'center' : 'flex-start'};
`;

const Circle = styled.span<{ $color: string, $offsetX: number, $offsetY: number }>`
  width: 30px;
  height: 30px;
  padding: ${({ $offsetX, $offsetY }) => `${15 + $offsetY}px ${15 - $offsetX}px ${15 - $offsetY}px ${15 + $offsetX}px`};
  background-color: ${({ $color }) => $color};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 5px;
`;

const TextContainer = styled.span<{ $color: string, $shouldGrow: boolean }>`
  flex-grow: ${({ $shouldGrow }) => $shouldGrow ? 1 : 0};
  font-size: 12pt;
`;


const Wrapper = styled.div`
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export default IconCircle;
