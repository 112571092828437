import {Dispatch, SetStateAction, useEffect, useState} from 'react';

const rot13 = (str: string) => {
  return str.replace(/[a-z]/gi, l => String.fromCharCode(l.charCodeAt(0) + (l.toLowerCase() <= 'm' ? 13 : -13)));
}

const useLocalStorage = <T>(storageKey: string, fallbackState: T, obfuscate?: boolean): [T, Dispatch<SetStateAction<T>>, () => void] => {
  let defaultValue: T;
  try {
    let storageValue = window.localStorage.getItem(storageKey);
    if (storageValue && obfuscate) {
      storageValue = atob(rot13(storageValue));
    }
    defaultValue = JSON.parse(storageValue ?? '') ?? fallbackState
  } catch (e) {
    defaultValue = fallbackState;
  }

  const [value, setValue] = useState<T>(defaultValue);

  useEffect(() => {
    if (value === null) {
      localStorage.removeItem(storageKey);
      return;
    }
    let storageValue = JSON.stringify(value);
    if (obfuscate) {
      storageValue = rot13(btoa(storageValue));
    }
    localStorage.setItem(storageKey, storageValue);
  }, [value, storageKey]);

  const removeItem = () => {
    localStorage.removeItem(storageKey)
  }

  return [value, setValue, removeItem];
};

export default useLocalStorage;
