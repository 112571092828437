import { getNormalizedPhotoUrl } from '../../lib/images';

import { TAppElkPhotoUploadMode, TAppElkTextPermissionStatus, TAppElkUser } from 'TProtocol/prototypes/events/messages';
import { TSendEmailSignInCodeResponse, TSendPhoneSignInCodeResponse } from 'TProtocol/authentication/messages';

export interface ULoginState {
  phone?: string;
  email?: string;
  status: ULoginStatus;
  signInResponse?: TSendPhoneSignInCodeResponse;
  codeResendTimestamp?: number;
  error?: string;
}

export interface UUser {
  id?: string;
  authToken?: string;
  name?: string;
  phoneNumber?: string;
  photoUrl?: string;
  grantedTextPermission?: boolean;
  email?: string;
  emails?: string[];
  loginState: ULoginState;
}

export enum ULoginStatus {
  NotLoggedIn = 'NOT_LOGGED_IN',
  PhoneSubmitting = 'PHONE_SUBMITTING',
  EmailSubmitting = 'EMAIL_SUBMITTING',
  CodeSent = 'CODE_SENT',
  CodeSubmitting = 'CODE_SUBMITTING',
  WaitingForRedirectResult = 'WAITING_FOR_REDIRECT_RESULT',
  SigningInToFirebase = 'SIGNING_IN_TO_FIREBASE',
  SignedInToFirebase = 'SIGNED_IN_TO_FIREBASE',
  WaitingForAccountCheck = 'WAITING_FOR_ACCOUNT_CHECK',
  Authenticating = 'AUTHENTICATING',
  AfterLogin = 'AFTER_LOGIN',
  AskingForName = 'ASKING_FOR_NAME',
  NameSubmitting = 'NAME_SUBMITTING',
  CreatingUser = 'CREATING_USER',
  UserCreated = 'USER_CREATED',
  LoggedIn = 'LOGGED_IN',
  ErrorDuringLogin = 'ERROR_DURING_LOGIN',
  UserCancelled = 'USER_CANCELLED'
}

type IReducerAction = {
  type: 'setName',
  name: string
} | {
  type: 'setEmail',
  email?: string
} | {
  type: 'setGrantedTextPermissions',
  grantedTextPermissions: boolean
} | {
  type: 'setPhoneNumber',
  phoneNumber?: string
} | {
  type: 'setPhotoUrl',
  photoUrl?: string
} | {
  type: 'nameSubmitting',
  name: string
} | {
  type: 'setLoginStatus',
  status: ULoginStatus,
  error?: string
} | {
  type: 'setLoginError',
  error: string
} | {
  type: 'codeSent',
  phone: string,
  signInResponse: TSendPhoneSignInCodeResponse
} | {
  type: 'emailCodeSent',
  email: string,
  signInResponse: TSendEmailSignInCodeResponse
} | {
  type: 'handleAuthenticate',
  id: string,
  authToken: string,
  phoneNumber?: string,
  email?: string
} | {
  type: 'storeElkUser',
  elkUser: TAppElkUser,
  uploadMode: TAppElkPhotoUploadMode | undefined,
  authToken: string,
  status: ULoginStatus
} | {
  type: 'updateProfile',
  name?: string,
  photoUrl?: string,
  grantedTextPermissions?: boolean,
  textPermissionStatus?: TAppElkTextPermissionStatus
};

const updateLoginState = (state: UUser, eventChange: Partial<ULoginState>) => {
  return { ...state, loginState: { ...state.loginState, ...eventChange } };
};

export const UserReducer = (state: UUser, action: IReducerAction) => {
  switch (action.type) {
    case 'setName':
      return {
        ...state,
        name: action.name
      };
    case 'setEmail':
      if (action.email) {
        return {
          ...state,
          emails: [action.email, ...(state.emails ?? [])]
        };
      } else {
        return {
          ...state,
          emails: []
        };
      }
    case 'setGrantedTextPermissions':
      return {
        ...state,
        grantedTextPermission: action.grantedTextPermissions
      };
    case 'setPhoneNumber':
      if (action.phoneNumber) {
        return {
          ...state,
          phoneNumber: action.phoneNumber
        };
      } else {
        return {
          ...state,
          phoneNumber: undefined
        };
      }
    case 'setPhotoUrl':
      return {
        ...state,
        photoUrl: action.photoUrl
      };
    case 'nameSubmitting':
      return {
        ...state,
        name: action.name,
        loginState: {
          ...state.loginState,
          status: ULoginStatus.NameSubmitting
        }
      };
    case 'setLoginStatus':
      return updateLoginState(state, { status: action.status, error: action.error });
    case 'setLoginError':
      return updateLoginState(state, { error: action.error });
    case 'codeSent':
      return updateLoginState(state, {
        phone: action.phone,
        status: ULoginStatus.CodeSent,
        signInResponse: action.signInResponse,
        codeResendTimestamp: Date.now(),
        error: undefined
      });
    case 'emailCodeSent':
      return updateLoginState(state, {
        email: action.email,
        status: ULoginStatus.CodeSent,
        signInResponse: action.signInResponse,
        codeResendTimestamp: Date.now(),
        error: undefined
      });
    case 'handleAuthenticate':
      return {
        ...state,
        id: action.id,
        authToken: action.authToken,
        phoneNumber: action.phoneNumber,
        email: action.email,
        loginState: {
          ...state.loginState,
          status: ULoginStatus.WaitingForAccountCheck
        }
      };
    case 'storeElkUser':
      const photoUrl = action.elkUser.photoUrl !== undefined ? getNormalizedPhotoUrl(
        action.elkUser.photoUrl) : undefined;

      return {
        id: action.elkUser.userId,
        authToken: action.authToken,
        defaultUploadMode: action.uploadMode,
        name: action.elkUser.name ?? '',
        phoneNumber: action.elkUser.phoneNumber ?? '',
        photoUrl,
        grantedTextPermission: action.elkUser.grantedTextPermission,
        emails: action.elkUser.emails,
        loginState: {
          status: action.status
        }
      };
    case 'updateProfile':
      const newState = {
        ...state,
        grantedTextPermission: action.grantedTextPermissions,
        textPermissionStatus: action.textPermissionStatus,
        loginState: {
          status: ULoginStatus.LoggedIn
        }
      };
      if (action.name !== undefined) {
        newState.name = action.name;
      }
      if (action.photoUrl !== undefined) {
        newState.photoUrl = action.photoUrl;
      }

      return newState;
  }
};
