import { v4 as uuidv4 } from 'uuid';

import { ULogApplication, ULogSeverity, ULogTag, logSumoEvent, stringifyError } from '../api/SumoLogicApi';

let deviceId: string | undefined;

export const getDeviceId = (app: ULogApplication) => {
  if (deviceId) {
    return deviceId;
  }

  try {
    return JSON.parse(window.localStorage.getItem('deviceId') ?? '');
  } catch (e) {
    deviceId = `WEB-${uuidv4()}`;

    void logSumoEvent({
      app,
      severity: ULogSeverity.INFO,
      tag: ULogTag.Browser,
      message: `[DeviceId] New device id created: ${deviceId} - error was ${stringifyError(e)}`
    });

    try {
      window.localStorage.setItem('deviceId', JSON.stringify(deviceId));
    } catch (ee) {
      void logSumoEvent({
        app,
        severity: ULogSeverity.ERROR,
        tag: ULogTag.Browser,
        message: `[DeviceId] Error setting deviceId: ${stringifyError(ee)}`
      });
    }

    return deviceId;
  }
};
