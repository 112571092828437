/// <reference types="user-agent-data-types" />

export const getIsIOS = (): boolean => {
  return /iPad|iPhone|iPod/.test(navigator.userAgent);
};

export const getIsAndroid = (): boolean => {
  return /android/i.test(navigator.userAgent);
}

export const getIsMacOS = (): boolean => {
  return /^Mac/.test(navigator.platform) || navigator.userAgentData?.platform === 'MacOS';
}

export const getIsWindows = (): boolean => {
  return /^Win/.test(navigator.platform) || navigator.userAgentData?.platform === 'Windows';
}
