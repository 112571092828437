import React, { createContext, PropsWithChildren, ReactNode, useContext, useEffect, useState } from 'react';
import styled, { createGlobalStyle } from 'styled-components';

import {
  getBlurOverlayColor,
  getDarkenedColor,
  getDefaultErrorColor,
  getDefaultTextColor,
  getLogoColor,
  getSystemBackgroundColor
} from '../lib/colorPicker';
import { IUEventColors } from '../lib/event';
import { IUUserContext, useUserContext } from './UserContext';
import VideoAnimation from '../components/animations/VideoAnimation';
import WelcomeBackground from '../layout/WelcomeBackground';

import { logSumoEvent, stringifyError, ULogApplication, ULogSeverity, ULogTag } from 'Common/src/api/SumoLogicApi';
import { BLACK, WHITE } from 'Common/src/lib/Colors';

import { TAppElkBackgroundAnimation } from 'TProtocol/prototypes/events/messages';

interface IUSystemColors {
  buttonText: string;
  error: string;
  background: string;
  backgroundText: string;
}

interface IUBackgroundContext {
  colors: IUEventColors;
  systemColors: IUSystemColors;
  animation?: TAppElkBackgroundAnimation;
  backgroundAnimationUrl?: string;
  setScheme: ({ scheme, animation }: { scheme: ColorScheme, animation?: TAppElkBackgroundAnimation }) => void;
  setColors: ({ colors, animation, animationUrl }: {
    colors?: IUEventColors,
    animation?: TAppElkBackgroundAnimation,
    animationUrl?: string,
    subdued?: boolean
  }) => void;
  setBackgroundAnimationUrl: (url: string) => void;
  isWelcome: boolean;
}

export enum ColorScheme {
  None,
  Home,
  Orange,
  Welcome,
}

interface SchemeColors {
  primaryBackground: string;
  secondaryBackground: string;
  textColor: string;
  highlightText?: string;
}

export const COLOR_SCHEMES: {
  [key in ColorScheme]: SchemeColors;
} = {
  [ColorScheme.None]: {
    primaryBackground: '',
    secondaryBackground: '',
    textColor: ''
  },
  [ColorScheme.Home]: {
    primaryBackground: '#FFDF64',
    secondaryBackground: '#EF6623',
    textColor: ''
  },
  [ColorScheme.Orange]: {
    primaryBackground: '#FFFAEE',
    secondaryBackground: '#FECEB0',
    textColor: '',
    highlightText: '#FF6422'
  },
  [ColorScheme.Welcome]: {
    primaryBackground: '#ffffff',
    secondaryBackground: '#ffffff',
    textColor: '#FF6422',
  },

};

if (window.CSS.registerProperty) {
  window.CSS.registerProperty({
    name: '--x',
    syntax: '<percentage>',
    inherits: false,
    initialValue: '0%',
  });
  window.CSS.registerProperty({
    name: '--y',
    syntax: '<percentage>',
    inherits: false,
    initialValue: '100%',
  });
  window.CSS.registerProperty({
    name: '--shine-bg-color',
    syntax: '<color>',
    inherits: true,
    initialValue: '#FFF',
  });
  window.CSS.registerProperty({
    name: '--shine-bg-blur-overlay-color',
    syntax: '<color>',
    inherits: true,
    initialValue: 'rgba(255, 255, 255, 0.5)',
  });
  window.CSS.registerProperty({
    name: '--shine-secondary-bg-color',
    syntax: '<color>',
    inherits: true,
    initialValue: '#FFF',
  });
  window.CSS.registerProperty({
    name: '--shine-highlight-color',
    syntax: '<color>',
    inherits: true,
    initialValue: '#FFF',
  });
  window.CSS.registerProperty({
    name: '--shine-system-button-text-color',
    syntax: '<color>',
    inherits: true,
    initialValue: '#000',
  });
  window.CSS.registerProperty({
    name: '--shine-system-error-color',
    syntax: '<color>',
    inherits: true,
    initialValue: '#000',
  });
  window.CSS.registerProperty({
    name: '--shine-system-background-color',
    syntax: '<color>',
    inherits: true,
    initialValue: 'rgba(0, 0, 0, 0.5)',
  });
  window.CSS.registerProperty({
    name: '--shine-default-background-color',
    syntax: '<color>',
    inherits: true,
    initialValue: '#fff',
  });
  window.CSS.registerProperty({
    name: '--shine-logo-color',
    syntax: '<color>',
    inherits: true,
    initialValue: '#FF6422',
  });
}

export default COLOR_SCHEMES;

export const DEFAULT_COLORS: IUEventColors = {
  primary: '#ffffff',
  secondary: '#FFCB02',
  highlightText: '#FF6422',
  text: '#000000',
  buttonTextColor: '#ffffff'
};

export const DEFAULT_SYSTEM_COLORS: IUSystemColors = {
  buttonText: getDefaultTextColor(DEFAULT_COLORS.highlightText),
  error: getDefaultErrorColor(DEFAULT_COLORS),
  background: getSystemBackgroundColor(DEFAULT_COLORS),
  backgroundText: getDefaultTextColor(DEFAULT_COLORS.primary)
};

const getInitialColors = (userContext: IUUserContext) => {
  const colors = { ...DEFAULT_COLORS };

  try {
    const params = new URLSearchParams(window.location.search);
    const c = params.get('ec');
    if (c) {
      colors.primary = atob(c);
      colors.secondary = atob(c);
    }
    const tc = params.get('tc');
    if (tc) {
      colors.text = atob(tc);
    }
  } catch (e) {
    void logSumoEvent({
      app: ULogApplication.ELK,
      severity: ULogSeverity.WARN,
      userId: userContext.id,
      tag: ULogTag.General,
      message: `[BackgroundContext] Error decoding default colors: ${stringifyError(e)}`
    });
  }

  return colors;
};

const BackgroundContext = createContext<IUBackgroundContext | null>(null);

export const BackgroundContextProvider = (props: PropsWithChildren<object>) => {
  const userContext = useUserContext();

  const [colors, setColors] = useState<IUEventColors>(getInitialColors(userContext));
  const [systemColors, setSystemColors] = useState<IUSystemColors>(DEFAULT_SYSTEM_COLORS);
  const [animation, setAnimation] = useState<TAppElkBackgroundAnimation | undefined>();
  const [backgroundAnimationUrl, setBackgroundAnimationUrl] = useState<string>('');
  const [isWelcome, setIsWelcome] = useState(false);
  const [isInitial, setInitial] = useState(true);

  const setScheme = ({ scheme, animation }: { scheme: ColorScheme, animation?: TAppElkBackgroundAnimation }) => {
    setAnimation(animation);
    setBackgroundAnimationUrl('');

    const colorScheme = COLOR_SCHEMES[scheme];
    setColors({
      primary: colorScheme.primaryBackground,
      secondary: colorScheme.secondaryBackground,
      highlightText: colorScheme.highlightText ?? '#FF6422',
      text: '#000000',
      buttonTextColor: colorScheme.primaryBackground
    });

    setIsWelcome(scheme === ColorScheme.Welcome);
    setInitial(false);
  };

  const setColorScheme = ({ colors, animation, animationUrl, subdued }: {
    colors?: IUEventColors,
    animation?: TAppElkBackgroundAnimation,
    animationUrl?: string,
    subdued?: boolean
  }) => {
    setAnimation(animation);
    if (colors !== undefined && subdued) {
      setColors({
        primary: colors.primary,
        secondary: colors.secondary,
        highlightText: getDarkenedColor(colors.highlightText),
        text: '#000000',
        buttonTextColor: '#FFFFFF',
      });
    } else {
      setColors(colors ?? DEFAULT_COLORS);
    }
    setBackgroundAnimationUrl(animationUrl ?? '');

    setIsWelcome(false);
    setInitial(false);
  };

  useEffect(() => {
    const systemButtonText = getDefaultTextColor(colors.highlightText);
    const systemBackgroundText = backgroundAnimationUrl ? 'rgba(255, 255, 255, 0.90)' : getDefaultTextColor(
      colors.primary);
    const systemError = getDefaultErrorColor(colors);
    const systemBackground = getSystemBackgroundColor(colors);
    const systemBackgroundBlurOverlay = getBlurOverlayColor(colors);
    const logoColor = getLogoColor(colors);

    document.querySelector('meta[name="theme-color"]')?.setAttribute('content', colors.primary);

    document.body.style.setProperty('--x', '0%');
    document.body.style.setProperty('--y', '100%');
    document.body.style.setProperty('--shine-bg-color', colors.primary);
    document.body.style.setProperty('--shine-bg-blur-overlay-color', systemBackgroundBlurOverlay);
    document.body.style.setProperty('--shine-secondary-bg-color', colors.secondary);
    document.body.style.setProperty('--shine-highlight-color', colors.highlightText);
    document.body.style.setProperty('--shine-system-button-text-color', systemButtonText);
    document.body.style.setProperty('--shine-system-error-color', systemError);
    document.body.style.setProperty('--shine-system-background-color', systemBackground);
    document.body.style.setProperty('--shine-default-background-color', DEFAULT_COLORS.primary);
    document.body.style.setProperty('--shine-logo-color', logoColor);

    setSystemColors({
      buttonText: systemButtonText,
      error: systemError,
      background: systemBackground,
      backgroundText: systemBackgroundText
    });

  }, [colors, backgroundAnimationUrl]);

  const context: IUBackgroundContext = {
    animation,
    colors,
    systemColors,
    backgroundAnimationUrl,
    setScheme,
    setColors: setColorScheme,
    setBackgroundAnimationUrl,
    isWelcome
  };

  let backgroundContent: ReactNode;
  if (isWelcome) {
    backgroundContent = <WelcomeBackground/>;
  } else {
    backgroundContent = <>
      {!isInitial && <DarkMask/>}
      {backgroundAnimationUrl ? <VideoAnimation url={backgroundAnimationUrl}/> :
        <AnimationContainer $color={colors.primary}/>}
    </>;
  }

  return <BackgroundContext.Provider value={context}>
    <GlobalStyle $color={systemColors.backgroundText}/>
    <AnimationContainer $color={isWelcome ? BLACK : WHITE}>
      {backgroundContent}
    </AnimationContainer>
    {props.children}
  </BackgroundContext.Provider>;
};

const GlobalStyle = createGlobalStyle<{ $color: string }>`
  body {
    //transition: --shine-bg-color 1s, --shine-secondary-bg-color 1s, --shine-bg-blur-overlay-color 1s, --shine-logo-color 1s;
    color: ${({ $color }) => $color};
  }
`;

const AnimationContainer = styled.div<{ $color: string }>`
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: ${({ $color }) => $color};
  z-index: -1;
`;

const DarkMask = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
`;

export const useBackgroundContext = () => {
  const context = useContext(BackgroundContext);
  if (context === null) {
    throw new Error('useBackgroundContext must be used within a BackgroundContextProvider');
  }
  return context;
};
