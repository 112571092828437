import React from 'react';

const Plus = ({ color = 'currentColor', size = 35 }: { color?: string, size?: number }) => {
  return <svg width={size} height={size} viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd"
          d="M10.5 3.5C10.7652 3.5 11.0196 3.60536 11.2071 3.79289C11.3946 3.98043 11.5 4.23478 11.5 4.5V9.5H16.5C16.7652 9.5 17.0196 9.60536 17.2071 9.79289C17.3946 9.98043 17.5 10.2348 17.5 10.5C17.5 10.7652 17.3946 11.0196 17.2071 11.2071C17.0196 11.3946 16.7652 11.5 16.5 11.5H11.5V16.5C11.5 16.7652 11.3946 17.0196 11.2071 17.2071C11.0196 17.3946 10.7652 17.5 10.5 17.5C10.2348 17.5 9.98043 17.3946 9.79289 17.2071C9.60536 17.0196 9.5 16.7652 9.5 16.5V11.5H4.5C4.23478 11.5 3.98043 11.3946 3.79289 11.2071C3.60536 11.0196 3.5 10.7652 3.5 10.5C3.5 10.2348 3.60536 9.98043 3.79289 9.79289C3.98043 9.60536 4.23478 9.5 4.5 9.5H9.5V4.5C9.5 4.23478 9.60536 3.98043 9.79289 3.79289C9.98043 3.60536 10.2348 3.5 10.5 3.5Z"
          fill={color} fillOpacity="0.6"/>
  </svg>;
};

export default Plus;
