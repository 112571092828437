import React, { MouseEvent, PropsWithChildren, ReactNode } from 'react';
import styled from 'styled-components';

import ActionButton from './ActionButton';
import Sparkles from '../icons/Sparkles';
import { DeviceQueries } from 'Common/src/components/styled';

interface Props {
  buttonText: ReactNode;
  disabled: boolean;
  textFontSize?: number;
  shorter?: boolean;
  onClick?: (e: MouseEvent) => void;
  noMarginTop?: boolean;
  overrideButtonColor?: boolean;
}

const SparkleButton = ({
                         buttonText,
                         textFontSize,
                         disabled,
                         onClick,
                         noMarginTop,
                         shorter,
                         overrideButtonColor
                       }: PropsWithChildren<Props>) => {
  return <ButtonContainer>
    <ActionButton onClick={onClick} disabled={disabled} lessPadding={false} noMarginTop={noMarginTop}
                  overrideColor={overrideButtonColor}>
      <SparklesContainer>
        <Sparkles size={24} color="var(--button-text-color)" includePadding={!shorter}></Sparkles>
      </SparklesContainer>
      <TextWrapper $fontSize={textFontSize}>{buttonText}</TextWrapper>
    </ActionButton>
  </ButtonContainer>;
};


export default SparkleButton;

const TextWrapper = styled.div<{ $fontSize?: number }>`
  align-content: center;
  text-align: left;
  font-size: ${({ $fontSize }) => $fontSize !== undefined ? `${$fontSize}px` : '20px'};;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;

  @media (${DeviceQueries.mobile}) {
    font-size: 16px;
  }
`;

const ButtonContainer = styled.div`
  width: 100%;
  display: inline-flex;
  flex-direction: row;
  z-index: 100;
  justify-content: center;
`;

export const SparklesContainer = styled.div`
`;

