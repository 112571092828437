import React from 'react';
import styled from 'styled-components';

const EventCancelledIndicator = () => {
  return <Container>
    Event canceled.
  </Container>;
};

const Container = styled.div`
  display: flex;
  width: 100%;
  padding: 8px 20px;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;

  border-radius: 2px;
  border: 1px solid #FFF;
  background: #FF1B1B;
  box-sizing: border-box;
`;

export default EventCancelledIndicator;
