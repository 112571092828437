// Docs: https://developer.spotify.com/documentation/web-api

import useLocalStorage from 'Common/src/hooks/useLocalStorage';
import { TAppElkSpotifySong } from 'TProtocol/prototypes/events/messages';
import { b64tos, stob64 } from '../util/stringutils';
import { logSumoEvent, ULogApplication, ULogSeverity, ULogTag } from 'Common/src/api/SumoLogicApi';
import { useUserContext } from '../contexts/UserContext';
import { UButterBarLevel, useButterBarContext } from '../contexts/ButterBarContext';

declare const SPOTIFY_CLIENT_ID: string;
declare const SPOTIFY_CLIENT_SECRET: string;
declare const SPOTIFY_REDIRECT_URI: string;

interface SpotifyTrackArtist {
  name: string;
}

interface SpotifyImage {
  url: string;
  height: number;
  width: number;
}

interface SpotifyAlbum {
  name: string;
  images: SpotifyImage[];
}

export interface SpotifyTrack {
  album: SpotifyAlbum;
  artists: SpotifyTrackArtist[];
  id: string;
  name: string;
  explicit: boolean;
}

const useSpotify = () => {
  const userContext = useUserContext();
  const butterBarContext = useButterBarContext();

  const authorizationEndpoint = 'https://accounts.spotify.com/authorize';
  const tokenEndpoint = 'https://accounts.spotify.com/api/token';
  const scope = 'user-read-private user-read-email playlist-modify-public playlist-modify-private';

  const [accessToken, setAccessToken] = useLocalStorage<string>('spotifyAccessToken', '');
  const [refreshToken, setRefreshToken] = useLocalStorage<string>('spotifyRefreshToken', '');
  const [tokenExpiration, setTokenExpiration] = useLocalStorage<number>('spotifyTokenExpiration', 0);
  const [clientAccessToken, setClientAccessToken] = useLocalStorage<string>('spotifyClientToken', '');
  const [clientTokenExpiration, setClientTokenExpiration] = useLocalStorage<number>('spotifyClientTokenExpiration', 0);
  const [codeVerifier, setCodeVerifier] = useLocalStorage<string>('spotifyCodeVerifier', '');

  const getCodeChallenge = async () => {
    const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const randomValues = crypto.getRandomValues(new Uint8Array(64));
    const randomString = randomValues.reduce((acc, x) => acc + possible[x % possible.length], '');

    const code_verifier = randomString;
    const data = new TextEncoder().encode(code_verifier);
    const hashed = await crypto.subtle.digest('SHA-256', data);

    setCodeVerifier(code_verifier);

    const code_challenge_base64 = btoa(String.fromCharCode(...new Uint8Array(hashed)))
      .replace(/=/g, '')
      .replace(/\+/g, '-')
      .replace(/\//g, '_');

    return code_challenge_base64;
  };

  const spotifyOAuthSignIn = async () => {
    // Using Authentication Code with PKCE for user auth
    const codeChallenge = await getCodeChallenge();

    // Create <form> element to submit parameters to OAuth 2.0 endpoint.
    const form = document.createElement('form');
    form.setAttribute('method', 'GET'); // Send as a GET request.
    form.setAttribute('action', authorizationEndpoint);

    // Parameters to pass to OAuth 2.0 endpoint.
    const paramsMap: Map<string, string> = new Map();
    paramsMap.set('client_id', SPOTIFY_CLIENT_ID);
    paramsMap.set('response_type', 'code');
    paramsMap.set('redirect_uri', SPOTIFY_REDIRECT_URI);
    paramsMap.set('scope', scope);
    paramsMap.set('code_challenge_method', 'S256');
    paramsMap.set('code_challenge', codeChallenge);

    // Add form parameters as hidden input values.
    paramsMap.forEach((value: string, key: string) => {
      const input = document.createElement('input');
      input.setAttribute('type', 'hidden');
      input.setAttribute('name', key);
      input.setAttribute('value', value);
      form.appendChild(input);
    });

    // Add form to page and submit it to open the OAuth 2.0 endpoint.
    document.body.appendChild(form);
    form.submit();
  };

  const setSpotifyAccessToken = async (code: string) => {
    if (!code) {
      logSpotifyError('Error connecting Spotify account: missing auth code');
      return false;
    }

    const paramsObj = {
      client_id: SPOTIFY_CLIENT_ID,
      grant_type: 'authorization_code',
      redirect_uri: SPOTIFY_REDIRECT_URI,
    };
    const requestParams = new URLSearchParams(paramsObj);
    requestParams.append('code', code);
    requestParams.append('code_verifier', codeVerifier);

    const response = await fetch(tokenEndpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: requestParams,
    });

    const tokenResponse = await response.json();
    if (tokenResponse.error) {
      logSpotifyError(`Error requesting access token with auth code: ${tokenResponse.error.message}`);
      return false;
    }

    if (tokenResponse['access_token']) {
      setAccessToken(tokenResponse['access_token']);
    } else {
      return false;
    }
    if (tokenResponse['refresh_token']) {
      setRefreshToken(tokenResponse['refresh_token']);
    }
    const expiry = (new Date()).getTime() + (tokenResponse['expires_in'] * 1000);
    setTokenExpiration(expiry);

    localStorage.removeItem('spotifyCodeVerifier');

    return true;
  };

  const refreshAccessToken = async () => {
    if (!refreshToken) {
      await spotifyOAuthSignIn();
    } else {
      const response = await fetch(tokenEndpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: new URLSearchParams({
          client_id: SPOTIFY_CLIENT_ID,
          grant_type: 'refresh_token',
          refresh_token: refreshToken
        }),
      });

      const token = await response.json();
      if (token.error) {
        logSpotifyError(`Error refreshing access token: ${token.error.message}`);
        return;
      }
      setAccessToken(token['access_token']);
      setRefreshToken(token['refresh_token']);

      const expiry = (new Date()).getTime() + (token['expires_in'] * 1000);
      setTokenExpiration(expiry);
    }
  };

  const setClientCredentialAccessToken = async () => {
    const response = await fetch('https://accounts.spotify.com/api/token', {
      method: 'POST',
      body: new URLSearchParams({
        'grant_type': 'client_credentials',
      }),
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': 'Basic ' + (Buffer.from(SPOTIFY_CLIENT_ID + ':' + SPOTIFY_CLIENT_SECRET).toString('base64')),
      },
    });

    const token = await response.json();
    if (token.error) {
      logSpotifyError(`Error setting client credential access token: ${token.error.message}`);
      return;
    }
    setClientAccessToken(token['access_token']);

    const expiry = (new Date()).getTime() + (token['expires_in'] * 1000);
    setClientTokenExpiration(expiry);
  };

  const isAccessTokenExpired = () => {
    const currentTs = (new Date()).getTime();
    return !accessToken || accessToken.trim() === '' || !refreshToken || currentTs >= tokenExpiration;
  };

  const isClientAccessTokenExpired = () => {
    const currentTs = (new Date()).getTime();
    return !clientAccessToken || clientAccessToken.trim() === '' || currentTs >= clientTokenExpiration;
  };

  const isConnected = () => {
    return refreshToken && refreshToken.trim() !== '';
  };

  const fetchUserId = async () => {
    const result = await fetch('https://api.spotify.com/v1/me', {
      method: 'GET', headers: { Authorization: `Bearer ${accessToken}` }
    });

    const res = await result.json();
    if (res.error) {
      logSpotifyError(`Error fetching user id: ${res.error.message}`);
      return '';
    }
    return res.id;
  };

  const createPlaylist = async (playlistName: string): Promise<string> => {
    if (isAccessTokenExpired()) {
      await refreshAccessToken();
    }

    const userId = await fetchUserId();

    const requestParams = { name: playlistName, collaborative: true };

    const response = await fetch(`https://api.spotify.com/v1/users/${userId}/playlists`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestParams),
    });

    const result = await response.json();
    if (result.error) {
      logSpotifyError(`Error creating playlist: ${result.error.message}`);
      return '';
    }
    return result.id;
  };

  const searchSpotifyTrack = async (searchQuery: string): Promise<SpotifyTrack[]> => {
    const resultLimit = 5;

    const encodedQuery = encodeURIComponent(searchQuery);

    // Use client credentials
    if (isClientAccessTokenExpired()) {
      await setClientCredentialAccessToken();
    }

    // Search with query
    const response = await fetch(`https://api.spotify.com/v1/search?q=${encodedQuery}&type=track&limit=${resultLimit}`,
      {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${clientAccessToken}`,
        }
      });

    const searchResult = await response.json();
    if (searchResult.error) {
      logSpotifyError(`Error searching for tracks: ${searchResult.error.message}`);
      return [];
    }
    return searchResult['tracks']['items'];
  };

  const constructPlaylistLinkWithId = (playlistId: string) => {
    return `https://open.spotify.com/playlist/${playlistId}`;
  };

  const getSmallestAlbumImage = (album: SpotifyAlbum) => {
    return album.images.length === 0 ? null : album.images[album.images.length - 1];
  };

  const getTrackUri = (trackId: string) => {
    return `spotify:track:${trackId}`;
  };

  const encodeTAppElkSpotifySong = (tAppSong: TAppElkSpotifySong): string => {
    const encodedObj = {
      songUri: stob64(tAppSong.songUri),
      songName: stob64(tAppSong.songName),
      artistName: stob64(tAppSong.artistName ?? ''),
      albumName: stob64(tAppSong.albumName ?? ''),
      imageUrl: stob64(tAppSong.imageUrl ?? ''),
    };
    return stob64(JSON.stringify(encodedObj));
  };

  const decodeTAppElkSpotifySong = (encodedStr: string): TAppElkSpotifySong => {
    const songJson = JSON.parse(b64tos(encodedStr));
    return new TAppElkSpotifySong({
      songUri: b64tos(songJson.songUri),
      songName: b64tos(songJson.songName),
      artistName: b64tos(songJson.artistName),
      albumName: b64tos(songJson.albumName),
      imageUrl: b64tos(songJson.imageUrl)
    });
  };

  const convertToTAppElkSpotifySong = (song: SpotifyTrack) => {
    return new TAppElkSpotifySong({
      songUri: getTrackUri(song.id),
      songName: song.name,
      artistName: song.artists[0].name,
      albumName: song.album.name,
      imageUrl: getSmallestAlbumImage(song.album)?.url ?? undefined
    });
  };

  const logSpotifyError = (errorMsg: string) => {
    void logSumoEvent({
      app: ULogApplication.ELK,
      severity: ULogSeverity.WARN,
      userId: userContext.id,
      tag: ULogTag.Browser,
      message: `[useSpotify] ${errorMsg}`
    });
    butterBarContext.show({
      level: UButterBarLevel.ERROR,
      contents: 'Sorry, something went wrong connecting with Spotify. Please try again.',
      duration: 20000
    });
  };

  return {
    spotifyOAuthSignIn,
    setSpotifyAccessToken,
    tokenEndpoint,
    isConnected,
    createPlaylist,
    searchSpotifyTrack,
    constructPlaylistLinkWithId,
    setClientCredentialAccessToken,
    getSmallestAlbumImage,
    encodeTAppElkSpotifySong,
    decodeTAppElkSpotifySong,
    convertToTAppElkSpotifySong
  };
};

export default useSpotify;
