import React, { MouseEvent, useState } from 'react';
import styled from 'styled-components';

import { useUserContext } from '../../contexts/UserContext';
import { Overlay, SystemModalContainer } from '../modals/CommonModal';
import { CSVField, downloadEventAttendeeCSV } from '../../api/ElkAnnotatedService';
import { IUEvent } from '../../lib/event';
import SystemButton, { ButtonInternal } from '../buttons/SystemButton';
import LeftArrow from '../icons/LeftArrow';

import Checkbox from 'Common/src/components/Checkbox';

const DownloadCSVModal = ({ event, onCancel }: {
  event: IUEvent;
  onCancel: () => void
}) => {
  const userContext = useUserContext();

  const [selectedFields, setSelectedFields] = useState<Set<CSVField>>(new Set());
  const [selectedQuestions, setSelectedQuestions] = useState<Set<string>>(new Set());

  const onModalClick = (e: MouseEvent) => {
    e.stopPropagation();
  };

  const onFieldChange = (field: CSVField, checked: boolean) => {
    if (checked) {
      selectedFields.add(field);
    } else {
      selectedFields.delete(field);
    }
    setSelectedFields(new Set(selectedFields));
  };

  const onQuestionChange = (questionUuid: string, checked: boolean) => {
    if (checked) {
      selectedQuestions.add(questionUuid);
    } else {
      selectedQuestions.delete(questionUuid);
    }
    setSelectedQuestions(new Set(selectedQuestions));
  };

  const handleSubmit = async () => {
    await downloadEventAttendeeCSV(userContext, event.id, event.title, {
      fields: Array.from(selectedFields),
      questions: Array.from(selectedQuestions)
    });

    onCancel();
  };

  if (event == undefined) {
    return null;
  }

  return <Overlay onClick={onCancel}>
    <SystemModalContainer onClick={onModalClick}>
      <Container>
        <Header>
        <span onClick={onCancel}>
          <LeftArrow/>
        </span>
          <span>
          Download CSV
        </span>
        </Header>
        <Checkboxes>
          <Checkbox id="rsvp-status" onInputChange={(checked) => onFieldChange('rsvp_status', checked)}>
            RSVP Status (Yes/No)
          </Checkbox>
          <Checkbox id="name-field" onInputChange={(checked) => onFieldChange('name', checked)}>
            Name
          </Checkbox>
          <Checkbox id="contact" onInputChange={(checked) => onFieldChange('contact', checked)}>
            Contact
          </Checkbox>
          <Checkbox id="plusOne" onInputChange={(checked) => onFieldChange('additional_guest_count', checked)}>
            Additional Guest Count
          </Checkbox>
          <Checkbox id="message" onInputChange={(checked) => onFieldChange('message', checked)}>
            RSVP Message
          </Checkbox>
          {
            event.questions.map((question) => <Checkbox
              id={question.questionUuid} key={question.questionUuid}
              onInputChange={(checked) => onQuestionChange(question.questionUuid, checked)}
            >
              {question.question}
            </Checkbox>)
          }
        </Checkboxes>
        <Footer>
          <SystemButton onClick={handleSubmit}>Download</SystemButton>
        </Footer>
      </Container>
    </SystemModalContainer>
  </Overlay>;
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

const Header = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
  font-size: 24px;
  font-weight: 500;
`;

const Checkboxes = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  font-size: 16px;;`;

const Footer = styled.div`
  ${ButtonInternal} {
    width: 100%;
  }
`;

export default DownloadCSVModal;
