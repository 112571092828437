import React from 'react';

const Home = ({ color = 'currentColor', size = 24 }: { color?: string, size?: number }) => {
  return <svg width={size} height={size} viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.5 10.5L4.16667 8.83333M4.16667 8.83333L10 3L15.8333 8.83333M4.16667 8.83333V17.1667C4.16667 17.3877 4.25446 17.5996 4.41074 17.7559C4.56702 17.9122 4.77899 18 5 18H7.5M15.8333 8.83333L17.5 10.5M15.8333 8.83333V17.1667C15.8333 17.3877 15.7455 17.5996 15.5893 17.7559C15.433 17.9122 15.221 18 15 18H12.5M7.5 18C7.72101 18 7.93298 17.9122 8.08926 17.7559C8.24554 17.5996 8.33333 17.3877 8.33333 17.1667V13.8333C8.33333 13.6123 8.42113 13.4004 8.57741 13.2441C8.73369 13.0878 8.94565 13 9.16667 13H10.8333C11.0543 13 11.2663 13.0878 11.4226 13.2441C11.5789 13.4004 11.6667 13.6123 11.6667 13.8333V17.1667C11.6667 17.3877 11.7545 17.5996 11.9107 17.7559C12.067 17.9122 12.279 18 12.5 18M7.5 18H12.5"
      stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>;
};

export default Home;
