import React from 'react';

const DropdownSelector = () => {
  return <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
    <path fillRule="evenodd" clipRule="evenodd"
          d="M9.99979 3.5C10.265 3.50006 10.5193 3.60545 10.7068 3.793L13.7068 6.793C13.8889 6.9816 13.9897 7.2342 13.9875 7.4964C13.9852 7.7586 13.88 8.00941 13.6946 8.19482C13.5092 8.38023 13.2584 8.4854 12.9962 8.48767C12.734 8.48995 12.4814 8.38916 12.2928 8.207L9.99979 5.914L7.70679 8.207C7.51818 8.38916 7.26558 8.48995 7.00339 8.48767C6.74119 8.4854 6.49038 8.38023 6.30497 8.19482C6.11956 8.00941 6.01439 7.7586 6.01211 7.4964C6.00983 7.2342 6.11063 6.9816 6.29279 6.793L9.29279 3.793C9.48028 3.60545 9.73459 3.50006 9.99979 3.5ZM6.29279 12.793C6.48031 12.6055 6.73462 12.5002 6.99979 12.5002C7.26495 12.5002 7.51926 12.6055 7.70679 12.793L9.99979 15.086L12.2928 12.793C12.4814 12.6108 12.734 12.51 12.9962 12.5123C13.2584 12.5146 13.5092 12.6198 13.6946 12.8052C13.88 12.9906 13.9852 13.2414 13.9875 13.5036C13.9897 13.7658 13.8889 14.0184 13.7068 14.207L10.7068 17.207C10.5193 17.3945 10.265 17.4998 9.99979 17.4998C9.73462 17.4998 9.48031 17.3945 9.29279 17.207L6.29279 14.207C6.10532 14.0195 6 13.7652 6 13.5C6 13.2348 6.10532 12.9805 6.29279 12.793Z"
          fill="black" fillOpacity="0.6"/>
  </svg>;
};

export default DropdownSelector;
