import React, { useContext, useState } from 'react';

import ButterBar from '../components/ButterBar';

export enum UButterBarLevel {
  INFO = 1,
  WARN,
  ERROR
}

export interface IUButterBarConfig {
  contents: string;
  duration?: number;
  level?: UButterBarLevel;
  hidden?: boolean;
}

export interface IUButterBarContext {
  show: (config: IUButterBarConfig) => void;
  hide: () => void;
  shown: boolean;
}

const ButterBarContext = React.createContext<IUButterBarContext | null>(null);

export function ButterBarContextProvider(props: { children: React.ReactNode }) {
  const [config, setConfig] = useState<IUButterBarConfig | null>(null);
  const [shown, setShown] = useState(false);

  const show = (config: IUButterBarConfig) => {
    setConfig(config);
    setShown(true);

    setTimeout(() => {
      onClose(config);
    }, config.duration ?? 5000);
  };

  const hide = () => {
    setConfig(null);
    setShown(false);
  };

  const onClose = (config: IUButterBarConfig) => {
    config.hidden = true;
    setConfig({ ...config });

    setTimeout(() => {
      hide();
    }, 500);
  };

  const context: IUButterBarContext = {
    show,
    hide,
    shown
  };

  return <ButterBarContext.Provider value={context}>
    {config !== null ? <ButterBar config={config} onClose={onClose}/> : null}
    {props.children}
  </ButterBarContext.Provider>;
}

export const useButterBarContext = () => {
  const context = useContext(ButterBarContext);
  if (context === null) {
    throw new Error('useButterBarContext must be used within a ButterBarContextProvider');
  }
  return context;
};
