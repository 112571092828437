import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { Location, useBlocker, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';

import { useUserContext } from '../../contexts/UserContext';
import { useBackgroundContext } from '../../contexts/BackgroundContext';
import { BasePageContent } from '../BasePage';
import { getAttendeeIndex, getRSVPedInvitee, hasUserRSVPed } from '../../lib/attendance';
import { endUserEvent } from '../../lib/performance';
import { useEventCacheContext } from '../../contexts/EventCacheContext';
import { useModalContext } from '../../contexts/ModalContext';
import ShareWithFriendsModal from '../../components/modals/ShareWithFriendsModal';
import ChangeRSVPModal from '../../components/modals/ChangeRSVPModal';
import { updateAttendance, updateAttendanceThroughEmail } from '../../api/ElkEventService';
import useInviteeUuidStorage from '../../hooks/useInviteeUuidStorage';
import BasePageHeader, { UPageType } from '../BasePageHeader';
import { b64tos, stob64 } from '../../util/stringutils';
import AttendeesList from './AttendeesList';
import AlbumDetails from './AlbumDetails';
import EventCreatedModal from '../../components/modals/EventCreatedModal';
import TextBlast from '../../components/icons/TextBlast';
import FooterLinks from '../../components/FooterLinks';
import MessageGroup from '../../components/messages/MessageGroup';
import PhotoViewer from '../../components/PhotoViewer';
import RSVPButton from '../../components/buttons/RSVPButton';
import GuestManagementModal from '../../components/modals/GuestManagementModal';
import PostRsvpModal from '../Login/PostRsvpModal';
import WhiteButton from '../../components/buttons/WhiteButton';
import MailOutline from '../../components/icons/MailOutline';
import PencilAlt from '../../components/icons/PencilAlt';
import { ActionRow, EventModules } from '../../components/EventForm/Common';
import TopModule from './TopModule';
import EventDetailsModule from './EventDetailsModule';
import Duplicate from '../../components/icons/Duplicate';
import TrashOutline from '../../components/icons/TrashOutline';
import DeleteEventModal from '../../components/modals/DeleteEventModal';
import CancelEventModal from '../../components/modals/CancelEventModal';
import Share from '../../components/icons/Share';
import RSVPYesModal from '../../components/modals/RSVPYesModal';
import RSVPNoModal from '../../components/modals/RSVPNoModal';
import { findCurrentInvitee } from '../../util/attendee';
import SendMessageModal from '../SendMessageModal';
import { IUInvitee } from '../../lib/event';
import { parseHyperlinks } from '../../util/links';

import { logSumoEvent, stringifyError, ULogApplication, ULogSeverity, ULogTag } from 'Common/src/api/SumoLogicApi';
import KebabMenu, { KebabMenuOption } from 'Common/src/components/KebabMenu';
import { DeviceQueries } from 'Common/src/components/styled';

import { TAppElkAttendeeRole, TAppElkAttendeeStatus, TAppElkMessage } from 'TProtocol/prototypes/events/messages';

export interface EventPageState {
  showPhotosPromo: boolean;
  showEventCreated: boolean;
  showRSVP: boolean;
  showGuestOptions: boolean;
  finishingRSVP: boolean; // For usage by UpdateAttendance actions
  fromLogin?: boolean;
  inviteeName?: string;
}

interface IUSeparatedMessages {
  blasts: TAppElkMessage[];
  byInviteeId: Map<string, TAppElkMessage[]>;
}

declare const ELK_WEBAPP_URL: string;

const EventPage = ({ eventIdProp }: {
  eventIdProp?: string
}) => {
  const userContext = useUserContext();
  const eventCacheContext = useEventCacheContext();
  const backgroundContext = useBackgroundContext();
  const modalContext = useModalContext();
  const { getInviteeUuid, getToken, setToken, getPhone, getEmail } = useInviteeUuidStorage();

  const navigate = useNavigate();
  const [queryParams] = useSearchParams();
  const { eventIdParam } = useParams();
  const location = useLocation() as Location<EventPageState>;
  useBlocker(() => {
    modalContext.hide();
    return false; // Never actually blocks
  });
  const isLoggedIn = userContext.isLoggedIn();

  const eventId = eventIdProp ?? eventIdParam;
  const eventPageAsBackground = eventIdProp !== undefined;
  const event = eventId !== undefined ? eventCacheContext.getEvent(eventId) : undefined;
  const [hasRSVPed, setHasRSVPed] = useState<boolean>(false);
  const [showCopied, setShowCopied] = useState(false);
  const [userSeen, setUserSeen] = useState<boolean>(false);
  const [showPhotoViewer, setShowPhotoViewer] = useState<boolean>(false);

  const encodedEmailAddress = queryParams.get('ea');
  const token = getToken(eventId);
  const rsvpResponse = queryParams.get('r');
  const inviteeUuid = getInviteeUuid(eventId);
  const [separatedMessages, setSeparatedMessages] = useState<IUSeparatedMessages>({
    blasts: [],
    byInviteeId: new Map()
  });

  const pageScrollRef = useRef<HTMLDivElement | null>(null);

  const showCreatedModal = location.state?.showEventCreated && event?.albumDetails !== undefined;
  const showGuestOptionsModal = location.state?.showGuestOptions;
  const showRSVP = location.state?.showRSVP;
  const fromLogin = location.state?.fromLogin;
  const inviteeName = location.state?.inviteeName;

  useEffect(() => {
    if (event?.messages) {
      const separatedMessages: IUSeparatedMessages = {
        blasts: [],
        byInviteeId: new Map()
      };

      for (const message of event.messages) {
        if (message.textBlast) {
          separatedMessages.blasts.push(message);
        } else if (message.receiverInviteeUuid !== undefined) {
          const messagesById = separatedMessages.byInviteeId.get(message.receiverInviteeUuid);

          if (messagesById) {
            messagesById.unshift(message);
          } else {
            separatedMessages.byInviteeId.set(message.receiverInviteeUuid, [message]);
          }
        }
      }

      setSeparatedMessages(separatedMessages);
    }
  }, [event?.messages]);

  const handleTextBlast = () => {
    modalContext.hide();
    modalContext.show({
      contents: <SendMessageModal close={modalContext.hide} event={event} eventId={eventId}/>
    });
  };

  const onShare = async () => {
    modalContext.hide();
    if (event?.isHost) {
      onManageInviteClick();
    } else {
      modalContext.show({
        contents: <ShareWithFriendsModal close={modalContext.hide} event={event}/>
      });
    }
  };

  const onShareLink = async () => {
    if (navigator.share) {
      try {
        void logSumoEvent({
          app: ULogApplication.ELK,
          severity: ULogSeverity.INFO,
          userId: userContext.id,
          tag: ULogTag.UserAction,
          message: '[EventPage] Clicked on share link with share API'
        });

        await navigator.share({
          title: event?.title ?? 'Shine',
          url: event?.url ?? (event ? `https://${ELK_WEBAPP_URL}/event/${event.id}` : `https://${ELK_WEBAPP_URL}/`),
        });
      } catch (e) {
        if (e.name !== 'AbortError') {
          void logSumoEvent({
            app: ULogApplication.ELK,
            severity: ULogSeverity.WARN,
            userId: userContext.id,
            tag: ULogTag.UserError,
            message: `[EventPage] Error during user share: ${stringifyError(e)}`
          });
        }
      }
    } else {
      // Fallback for browsers that do not support the Web Share API
      void logSumoEvent({
        app: ULogApplication.ELK,
        severity: ULogSeverity.INFO,
        userId: userContext.id,
        tag: ULogTag.UserAction,
        message: '[EventPage] Clicked on share link without share API'
      });

      modalContext.show({
        contents: <ShareWithFriendsModal close={modalContext.hide} event={event}/>
      });
    }
  };

  const handleChangeRSVP = () => {
    modalContext.show({
      contents: <ChangeRSVPModal close={modalContext.hide} event={event} eventId={eventId}
                                 clickedChangeResponse={true} hasToken={token !== undefined}/>
    });
  };

  const handleRSVPYes = () => {
    modalContext.show({
      contents: <RSVPYesModal close={modalContext.hide} event={event} eventId={eventId} clickedYes={true}
                              hasToken={token !== undefined} showUpdateRSVP={showRSVP}/>
    });
  };

  const handleRSVPNo = () => {
    modalContext.show({
      contents: <RSVPNoModal close={modalContext.hide} event={event} eventId={eventId} clickedYes={false}
                             hasToken={token !== undefined}/>
    });
  };

  const todayStart = new Date();
  todayStart.setHours(0, 0, 0, 0); // Reset to the start of the day

  useEffect(() => {
    if (encodedEmailAddress) {
      userContext.setEmail(b64tos(encodedEmailAddress));
    }
  }, [encodedEmailAddress]);

  useEffect(() => {
    if (event !== undefined) {
      document.title = `Shine - ${event.title}`;
      endUserEvent(userContext);
      backgroundContext.setColors(
        {
          colors: event?.colors,
          animation: event?.animation,
          animationUrl: event?.videoBackgroundDisabled ? undefined : event?.backgroundAnimationUrl
        }
      );
    }
  }, [event]);

  useEffect(() => {
    if (eventId !== undefined) {
      void eventCacheContext.fetchEvent({ eventId, inviteeUuid, isPreview: !isLoggedIn, token });
      if (isLoggedIn) {
        // void fetchUserData();
      }
    } else {
      // User came directly to /event without an id, but no event is in context (reload or bookmark)
      navigate('/event/create');
    }
  }, [event?.id, eventId]);

  useEffect(() => {
    if (event) {
      setHasRSVPed(hasUserRSVPed(event, userContext.id ?? '', inviteeUuid, userContext.phoneNumber ?? getPhone(),
        userContext.getEmail() ?? getEmail()));
      if ((userContext.id || inviteeUuid) && !event.messagesArePersonal) {
        let inviteeId: string | undefined = inviteeUuid;
        if (userContext.id) {
          inviteeId = getRSVPedInvitee(event, userContext.id, undefined, userContext.phoneNumber ?? getPhone(),
            userContext.getEmail() ?? getEmail())?.inviteeId;
        }

        void eventCacheContext.refreshMessages(event.id, inviteeId);
      }
      if (event.albumDetails === undefined) {
        void eventCacheContext.refreshAlbumDetails(event.id, token);
      }
    }
  }, [event?.id, userContext.id]);

  useEffect(() => {
    if (hasRSVPed && event && event.albumDetails?.albumCode === undefined) {
      void eventCacheContext.refreshAlbumDetails(event.id);
    }
  }, [hasRSVPed]);

  useEffect(() => {
    void updateSeen();
  }, [event?.attendees, userContext.id, inviteeUuid]);

  useEffect(() => {
    if (showCopied === true) {
      const timerId = setTimeout(() => {
        setShowCopied(false);
      }, 2000);
      return () => {
        clearTimeout(timerId);
      };
    }
    return () => {
      // noop
    };
  }, [showCopied]);

  useEffect(() => {
    if (location.state?.showPhotosPromo && event && !eventPageAsBackground) {
      modalContext.show({
        contents: <PostRsvpModal event={event} close={onPhotoPromoClose} afterLogin={fromLogin}/>
      });
    }
  }, [location.state?.showPhotosPromo, event]);

  useEffect(() => {
    if (event && showCreatedModal && !eventPageAsBackground) {
      modalContext.show({
        contents: <EventCreatedModal
          event={event} close={onEventCreatedModalClose} onLinkClick={onShareLink} onEmailClick={onShare}/>
      });
    }
  }, [showCreatedModal]);

  useEffect(() => {
    if (event && showGuestOptionsModal && !eventPageAsBackground) {
      modalContext.show({
        contents: <GuestManagementModal close={modalContext.hide} event={event}/>
      });
    }
  }, [event, showGuestOptionsModal]);

  useEffect(() => {
    if (eventId && token) {
      setToken(eventId, token);
    }
  }, [eventId, token]);

  useEffect(() => {
    if (event && showRSVP && !eventPageAsBackground) {
      if (rsvpResponse === 'yes') {
        handleRSVPYes();
      } else if (rsvpResponse === 'no') {
        handleRSVPNo();
      }
    }
  }, [event, rsvpResponse, showRSVP]);

  const onEventCreatedModalClose = () => {
    navigate(`/event/${eventId}`, { replace: true, state: { showEventCreated: false } });
    modalContext.hide();
  };

  const onPhotoPromoClose = () => {
    navigate(`/event/${eventId}`, { state: { showPhotosPromo: false }, replace: true });
    modalContext.hide();
  };

  const handleEditClick = () => {
    modalContext.hide();
    if (event === undefined || !event.isHost) {
      return;
    }
    navigate('/event/edit/' + event.id);
  };

  const cloneEvent = () => {
    modalContext.hide();
    if (event?.id) {
      navigate(`/event/create/from/${event.id}`);
    }
  };

  const onManageInviteClick = () => {
    modalContext.hide();
    if (eventId !== undefined) {
      navigate(`/event/invite/${eventId}`);
    }
  };

  const updateSeen = async () => {
    if (eventId === undefined || userSeen || !event || event.attendees.length === 0) {
      return;
    }

    const userId = userContext.id;

    let myInvitee: IUInvitee | undefined;
    if (userContext.isLoggedIn()) {
      const myInviteeIndex = getAttendeeIndex(event.attendees, userContext.id, inviteeUuid, userContext.phoneNumber,
        userContext.getEmail());
      if (myInviteeIndex !== -1) {
        myInvitee = event.attendees[myInviteeIndex];
      }

      if (myInvitee !== undefined && myInvitee.rsvpStatus !== TAppElkAttendeeStatus.INVITED) {
        // User already is attending.
        return;
      }

      const userName = userContext.name;

      if (myInvitee === undefined && userName !== undefined && userName !== null) {
        myInvitee = new IUInvitee({
          inviteeId: uuidv4(),
          rsvpStatus: TAppElkAttendeeStatus.SEEN,
          role: TAppElkAttendeeRole.UNDEFINED,
          additionalGuestCount: 0,
          userId,
          name: inviteeName ?? userName,
        });
      }
    } else if (inviteeUuid) {
      myInvitee = event?.attendees
        .filter(attendee => {
            return attendee?.inviteeId === inviteeUuid;
          }
        )?.[0];

      if (myInvitee !== undefined && myInvitee.rsvpStatus !== TAppElkAttendeeStatus.INVITED) {
        // User already is attending.
        return;
      }
    }

    if (myInvitee) {
      myInvitee.rsvpStatus = TAppElkAttendeeStatus.SEEN;
      myInvitee.userId = userId;
      if (!myInvitee.phone || myInvitee.phone.trim() === '') {
        myInvitee.phone = userContext.phoneNumber;
      }
      if (!myInvitee.email || myInvitee.email.trim() === '') {
        myInvitee.email = userContext.getEmail();
      }
      setUserSeen(true);
      if (userContext.isLoggedIn()) {
        void updateAttendance(userContext, eventId, myInvitee.toTAppElkInvitee(), undefined, undefined, undefined,
          false);
      } else if (token) {
        void updateAttendanceThroughEmail(eventId, myInvitee.toTAppElkInvitee(), undefined, token, false);
      }
    }
  };

  const handleOptIn = () => {
    void logSumoEvent({
      app: ULogApplication.ELK,
      severity: ULogSeverity.INFO,
      userId: userContext.id,
      tag: ULogTag.UserAction,
      message: '[EventPage] Clicked on accept'
    });

    if (userContext.isLoggedIn()) {
      const currInvitee = findCurrentInvitee(getInviteeUuid(eventId), userContext.id, event, userContext.name, true,
        userContext.phoneNumber ?? getPhone(), userContext.getEmail() ?? getEmail());

      const encodedName = stob64(currInvitee.name ?? userContext.name);
      const encodedEmail = stob64(currInvitee.email ?? userContext.getEmail() ?? '');
      const encodedPhone = stob64(currInvitee.phone ?? userContext.phoneNumber ?? '');

      //TODO: ensure title is present in all cases
      const encodedTitle = stob64(event?.title ?? '');
      navigate(
        `/event/rsvp/action/${eventId}?r=yes&et=${encodedTitle}&en=${encodedName}&np=${currInvitee.notificationPreference}&iu=${currInvitee.inviteeId}&ee=${encodedEmail}&ep=${encodedPhone}`,
        {
          replace: true,
          state: {
            finishingRSVP: false
          }
        });
    } else {
      modalContext.show({
        contents: <RSVPYesModal close={modalContext.hide} event={event} eventId={eventId} clickedYes={true}
                                clickedChangeResponse={false} hasToken={token !== undefined}/>
      });
    }
  };

  const handleOptOut = () => {
    void logSumoEvent({
      app: ULogApplication.ELK,
      severity: ULogSeverity.INFO,
      userId: userContext.id,
      tag: ULogTag.UserAction,
      message: '[EventPage] Clicked on decline'
    });

    if (userContext.isLoggedIn()) {
      const currInvitee = findCurrentInvitee(getInviteeUuid(eventId), userContext.id, event, userContext.name, true,
        userContext.phoneNumber ?? getPhone(), userContext.getEmail() ?? getEmail());

      const encodedName = stob64(currInvitee.name ?? userContext.name);
      const encodedEmail = stob64(currInvitee.email ?? userContext.getEmail() ?? '');
      const encodedPhone = stob64(currInvitee.phone ?? userContext.phoneNumber ?? '');

      //TODO: ensure title is present in all cases
      const encodedTitle = stob64(event?.title ?? '');
      navigate(
        `/event/rsvp/action/${eventId}?r=no&et=${encodedTitle}&en=${encodedName}&np=${currInvitee.notificationPreference}&iu=${currInvitee.inviteeId}&ee=${encodedEmail}&ep=${encodedPhone}`,
        {
          replace: true,
          state: {
            finishingRSVP: false
          }
        });
    } else {
      modalContext.show({
        contents: <RSVPNoModal close={modalContext.hide} event={event} eventId={eventId} clickedYes={false}
                               clickedChangeResponse={false} hasToken={token !== undefined}/>
      });
    }
  };

  const deleteEvent = () => {
    if (eventId) {
      modalContext.show({
        contents: <DeleteEventModal close={modalContext.hide} eventId={eventId}/>
      });
    }
  };

  const cancelEvent = () => {
    if (eventId) {
      modalContext.show({
        contents: <CancelEventModal close={modalContext.hide} eventId={eventId}/>
      });
    }
  };

  const menuOptions: KebabMenuOption[] = [
    {
      id: 'clone',
      label: 'Clone Event',
      icon: <Duplicate/>
    }
  ];

  if ((event?.attendees?.length ?? 0) > 1) {
    menuOptions.push({
      id: 'cancel',
      label: 'Cancel event',
      icon: <TrashOutline/>,
      color: '#F00'
    });
  } else {
    menuOptions.push({
      id: 'delete',
      label: 'Delete event',
      icon: <TrashOutline/>,
      color: '#F00'
    });
  }

  const onMenuClick = (id: string) => {
    if (id === 'clone') {
      cloneEvent();
    } else if (id === 'delete') {
      deleteEvent();
    } else if (id === 'cancel') {
      cancelEvent();
    }
  };

  let actions: ReactNode = <></>;

  let inviteeRsvped;
  if (event) {
    inviteeRsvped = getRSVPedInvitee(event, userContext.id ?? '', inviteeUuid, userContext.phoneNumber ?? getPhone(),
      userContext.getEmail() ?? getEmail());
  }
  if (event?.isHost) {
    if (event.cancelledTimestamp === undefined || event.cancelledTimestamp === 0) {
      actions =
        <ActionRow>
          <WhiteButton onClick={onShare} $noMin={true}>
            <MailOutline/> Guests
          </WhiteButton>
          {
            event.startTime > Date.now() &&

            <WhiteButton onClick={handleEditClick} $noMin={true}>
              <PencilAlt size={20}/> Edit
            </WhiteButton>
          }
          <WhiteButton onClick={onShareLink} $noMin={true}>
            <Share/> Share
          </WhiteButton>
          {event.attendees.length > 1 ? <WhiteButton onClick={handleTextBlast} $noMin={true}>
              <TextBlast size={20}/> Message
            </WhiteButton>
            : null}
          <MenuContainer>
            <KebabMenu options={menuOptions} onClick={onMenuClick}/>
          </MenuContainer>
        </ActionRow>;
    }
  } else if (inviteeRsvped?.rsvpStatus === TAppElkAttendeeStatus.YES) {
    actions = <ChangeResponse>
      <RSVPButton disabled={false} onClick={handleChangeRSVP} type={'button'} secondary={true}>You&apos;re
        going!</RSVPButton>
    </ChangeResponse>;
  } else if (inviteeRsvped?.rsvpStatus === TAppElkAttendeeStatus.NO) {
    actions = <ChangeResponse>
      <RSVPButton disabled={false} onClick={handleChangeRSVP} type={'button'} secondary={true}>You
        declined.</RSVPButton>
    </ChangeResponse>;
  } else if (!hasRSVPed) {
    actions =
      <ActionRow>
        <WhiteButton onClick={handleOptIn}>Accept</WhiteButton>
        <WhiteButton onClick={handleOptOut}>Decline</WhiteButton>
      </ActionRow>;
  }

  const showAttendeeBox = event && (!event.cancelledTimestamp);


  if (event) {
    const description = event.description.split('\n')
      .map((str: string, i: number) => <div key={i}>{parseHyperlinks(str)}<br/></div>);


    return <>
      <BasePageHeader page={UPageType.Event}/>
      <BasePageContent $wide={true} $full={true} $flush={true} $centered={true} containerRef={pageScrollRef}>
        <PageContentContainer>
          <CenteredContent>
            <TopModule event={event}>
              {actions}
            </TopModule>

            <EventModules>
              {event.description.trim() !== '' &&
                <NewContentDescription>
                  {description}
                </NewContentDescription>}

              <AlbumDetails event={event} setShowPhotoViewer={setShowPhotoViewer}/>

              <EventDetailsModule event={event}/>

              {showAttendeeBox ? <AttendeesList
                event={event}
                inviteeUuid={inviteeUuid}
                readOnly={false}
                messagesByInviteeId={separatedMessages.byInviteeId}
              /> : null}

              {separatedMessages.blasts.length > 0 &&
                <div>
                  <SectionSubheader>MESSAGES ({separatedMessages.blasts.length})</SectionSubheader>
                  <MessageGroup messages={separatedMessages.blasts}/>
                </div>
              }
            </EventModules>

            <FooterLinks/>
          </CenteredContent>
        </PageContentContainer>
      </BasePageContent>
      <PhotoViewer event={event} show={showPhotoViewer} close={() => setShowPhotoViewer(false)}/>
    </>;
  } else {
    return null;
  }
};

const NewContentDescription = styled.div`
  font-size: 22px;
  font-weight: 400;
  word-break: break-word;

  a, a:visited {
    color: #FFF;
  }

  @media (${DeviceQueries.mobile}) {
    font-size: 16px;
    line-height: 24px;
  }
`;

const ChangeResponse = styled.div`
  font-size: 18px;
  font-weight: 300;
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
  padding: 10px 0;
`;

const SectionSubheader = styled.div`
  font-size: 16px;
  margin: 10px 0;
`;

const PageContentContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 25px;
  box-sizing: border-box;
  color: #FFF;
`;

const CenteredContent = styled.div`
  max-width: 684px;
`;

const MenuContainer = styled.div`
  display: flex;
  align-items: center;
  height: 40px;
`;

export default EventPage;
