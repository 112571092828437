import React from 'react';

const DeleteIcon = ({ color = 'currentColor', size = 20 }: { color?: string, size?: number }) => {
  return <svg width={size} height={size} viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd"
          d="M9 2.5C8.81434 2.5001 8.63237 2.55188 8.47447 2.64955C8.31658 2.74722 8.18899 2.88692 8.106 3.053L7.382 4.5H4C3.73478 4.5 3.48043 4.60536 3.29289 4.79289C3.10536 4.98043 3 5.23478 3 5.5C3 5.76522 3.10536 6.01957 3.29289 6.20711C3.48043 6.39464 3.73478 6.5 4 6.5V16.5C4 17.0304 4.21071 17.5391 4.58579 17.9142C4.96086 18.2893 5.46957 18.5 6 18.5H14C14.5304 18.5 15.0391 18.2893 15.4142 17.9142C15.7893 17.5391 16 17.0304 16 16.5V6.5C16.2652 6.5 16.5196 6.39464 16.7071 6.20711C16.8946 6.01957 17 5.76522 17 5.5C17 5.23478 16.8946 4.98043 16.7071 4.79289C16.5196 4.60536 16.2652 4.5 16 4.5H12.618L11.894 3.053C11.811 2.88692 11.6834 2.74722 11.5255 2.64955C11.3676 2.55188 11.1857 2.5001 11 2.5H9ZM7 8.5C7 8.23478 7.10536 7.98043 7.29289 7.79289C7.48043 7.60536 7.73478 7.5 8 7.5C8.26522 7.5 8.51957 7.60536 8.70711 7.79289C8.89464 7.98043 9 8.23478 9 8.5V14.5C9 14.7652 8.89464 15.0196 8.70711 15.2071C8.51957 15.3946 8.26522 15.5 8 15.5C7.73478 15.5 7.48043 15.3946 7.29289 15.2071C7.10536 15.0196 7 14.7652 7 14.5V8.5ZM12 7.5C11.7348 7.5 11.4804 7.60536 11.2929 7.79289C11.1054 7.98043 11 8.23478 11 8.5V14.5C11 14.7652 11.1054 15.0196 11.2929 15.2071C11.4804 15.3946 11.7348 15.5 12 15.5C12.2652 15.5 12.5196 15.3946 12.7071 15.2071C12.8946 15.0196 13 14.7652 13 14.5V8.5C13 8.23478 12.8946 7.98043 12.7071 7.79289C12.5196 7.60536 12.2652 7.5 12 7.5Z"
          fill={color} fillOpacity="0.25"/>
  </svg>;
};

export default DeleteIcon;
