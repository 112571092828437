import React from 'react';

const ChartSquareBarOutline = ({ color = 'currentColor', size = 20 }: { color?: string, size?: number }) => {
  return <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.3337 6.66797L6.66699 6.66797M10.8337 10.0013L6.66699 10.0013M3.33366 15.0013L3.33366 5.0013C3.33366 4.55928 3.50925 4.13535 3.82181 3.82279C4.13437 3.51023 4.5583 3.33464 5.00033 3.33464L15.0003 3.33464C15.4424 3.33464 15.8663 3.51023 16.1788 3.82279C16.4914 4.13535 16.667 4.55928 16.667 5.0013L16.667 15.0013C16.667 15.4433 16.4914 15.8673 16.1788 16.1798C15.8663 16.4924 15.4424 16.668 15.0003 16.668L5.00033 16.668C4.5583 16.668 4.13438 16.4924 3.82181 16.1798C3.50925 15.8673 3.33366 15.4433 3.33366 15.0013Z"
      stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>;
};

export default ChartSquareBarOutline;
