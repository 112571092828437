import React, { createContext, PropsWithChildren, useContext, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

interface IUVersionCheckContext {
  checkVersion: () => void;
}

const VersionCheckContext = createContext<IUVersionCheckContext | null>(null);

export const VersionCheckContextProvider = (props: PropsWithChildren<object>) => {
  const launchTime = useRef<Date>(new Date());
  const location = useLocation();

  useEffect(() => {
    checkVersion();
  }, [location]);

  const checkVersion = () => {
    void fetchAndCheckVersion();
  };

  const fetchAndCheckVersion = async () => {
    const response = await fetch('/version.json');
    const version = await response.json();
    const buildDate = new Date(version.buildDate);

    if (launchTime.current < buildDate) {
      window.location.reload();
    }
  };

  const context: IUVersionCheckContext = {
    checkVersion
  };

  return <VersionCheckContext.Provider value={context}>
    {props.children}
  </VersionCheckContext.Provider>;
};

export const useVersionCheckContext = () => {
  const context = useContext(VersionCheckContext);
  if (context === null) {
    throw new Error('useVersionCheckContext must be used within a VersionCheckContextProvider');
  }
  return context;
};
