import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { BasePageContent } from './BasePage';
import { ColorScheme, DEFAULT_COLORS, useBackgroundContext } from '../contexts/BackgroundContext';
import PersonCropCircle from '../components/icons/PersonCropCircle';
import EventFormInput from '../components/EventForm/EventFormInput';
import { useUserContext } from '../contexts/UserContext';
import ActionButton from '../components/buttons/ActionButton';
import ImageUploader from '../components/ImageUploader';
import CallToAction from '../components/CallToAction';
import LogoutActionIcon from '../components/icons/LogoutActionIcon';
import LogoutModal from '../components/modals/LogoutModal';
import { useModalContext } from '../contexts/ModalContext';
import OptOutModal from '../components/modals/OptOutModal';
import { getNormalizedPhotoUrl, uploadToFirebase } from '../lib/images';
import { updateElkUser } from '../api/ElkEventService';
import BasePageHeader from './BasePageHeader';
import DeleteUserIcon from '../components/icons/DeleteUserIcon';
import FooterLinks from '../components/FooterLinks';

import { ORANGE, WHITE } from 'Common/src/lib/Colors';
import { logSumoEvent, ULogApplication, ULogSeverity, ULogTag } from 'Common/src/api/SumoLogicApi';

import { TAppElkTextPermissionStatus } from 'TProtocol/prototypes/events/messages';

const ProfileEditPage = () => {
  const backgroundContext = useBackgroundContext();
  const navigate = useNavigate();
  const userContext = useUserContext();
  const modalContext = useModalContext();
  const imgRef = useRef<HTMLImageElement>(null);

  const [name, setName] = useState(userContext.name);
  const [photoUrl, setPhotoUrl] = useState(userContext.photoUrl);
  const [hideSaveButton, setHideSaveButton] = useState(false);
  const [grantedTextPermission, setGrantedTextPermission] = useState(true);
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    document.title = 'Shine - Profile Edit';
    backgroundContext.setColors({ colors: DEFAULT_COLORS });
  }, []);

  useEffect(() => {
    backgroundContext.setScheme({ scheme: ColorScheme.Welcome });
  }, []);

  useEffect(() => {
    setPhotoUrl(userContext.photoUrl);
  }, [userContext.photoUrl]);

  useEffect(() => {
    setName(userContext.name);
  }, [userContext.name]);

  useEffect(() => {
    setGrantedTextPermission(userContext.grantedTextPermission ?? false);
  }, [userContext.grantedTextPermission]);

  const handleNameChange = (name: string) => {
    setName(name);
  };

  const onImageSelect = (imgSrc: string) => {
    if (imgRef.current) {
      imgRef.current.src = imgSrc;
    }
    setPhotoUrl(imgSrc);
  };

  const onSaveClick = async () => {
    if (submitting) {
      return;
    }
    setSubmitting(true);
    // If a new field is added to this page, it needs to be added here, and also in UserContext.generateElkUser
    const user = userContext.generateElkUser({
      name,
      photoUrl,
      grantedTextPermission,
      textPermissionStatus: grantedTextPermission ? TAppElkTextPermissionStatus.GRANTED : TAppElkTextPermissionStatus.REJECTED
    });
    if (user && (imgRef.current?.src || name !== '')) {
      if (imgRef.current?.src) {
        const firebaseUrl = await uploadToFirebase(imgRef.current.src, 'profile');
        user.photoUrl = await getNormalizedPhotoUrl(firebaseUrl);
        userContext.setPhotoUrl(user.photoUrl);
      }
      userContext.setGrantedTextPermissions(grantedTextPermission);
      user.grantedTextPermission = grantedTextPermission;
      await updateElkUser(userContext.getAuthHeader(), user);
      navigate('/events');
    }
  };

  const onLogoutClick = () => {
    void logSumoEvent({
      app: ULogApplication.ELK,
      severity: ULogSeverity.INFO,
      userId: userContext.id,
      tag: ULogTag.UserAction,
      message: '[ProfileEditPage] Clicked on logout link'
    });

    modalContext.show({
      contents: <LogoutModal close={() => modalContext.hide()}/>
    });
  };

  const onOptOutClick = () => {
    void logSumoEvent({
      app: ULogApplication.ELK,
      severity: ULogSeverity.INFO,
      userId: userContext.id,
      tag: ULogTag.UserAction,
      message: '[ProfileEditPage] Clicked on opt out link'
    });

    modalContext.show({
      contents: <OptOutModal close={() => modalContext.hide()}/>
    });
  };

  return <>
    <BasePageHeader/>
    <BasePageContent $scrolling={false}>
      <Title>
        Profile
      </Title>
      <ProfileContainer>
        <ImageContainer>
          <div>
            <ImageUploader circular={true} onImageCropAvailable={onImageSelect}
                           onPopupOpen={() => setHideSaveButton(true)} onPopupClose={() => setHideSaveButton(false)}>
              <EmptyPhotoContainer>
                {
                  photoUrl ? <PhotoImg ref={imgRef} src={photoUrl} width={150} alt="Profile photo"/> :
                    <PersonCropCircle size={150} color="#afb0b4"/>
                }
              </EmptyPhotoContainer>
              <EditLink>
                Edit
              </EditLink>
            </ImageUploader>
          </div>
        </ImageContainer>
        <EventFormInput
          label="Name"
          value={name}
          onChange={handleNameChange}
        />
        <PermissionContainer>
          <input type="checkbox" id="text-permission" checked={grantedTextPermission}
                 onChange={(e) => setGrantedTextPermission(e.currentTarget.checked)}/>
          <label htmlFor="text-permission">Please send me texts for invitations, updates responses and reminders.
            Message and data rates may apply. To opt-out, reply STOP.
          </label>

        </PermissionContainer>
        <ButtonContainer>
          <ActionButton type="submit" onClick={onSaveClick} hidden={hideSaveButton}
                        disabled={submitting || name === ''}>
            Save
          </ActionButton>
        </ButtonContainer>
        <CallToAction icon={<LogoutActionIcon/>} text="Log out" onClick={onLogoutClick}/>

        <CallToAction icon={<DeleteUserIcon size={35}/>} text="Leave Shine" onClick={onOptOutClick}/>
        <ProfileSpacer/>
      </ProfileContainer>
      <FooterLinks/>
    </BasePageContent>
  </>;
};

const Title = styled.div`
  text-align: center;
  font-size: 30pt;
`;

const ImageContainer = styled.div`
  text-align: center;
  font-size: 20px;
  margin-bottom: 20px;
  cursor: pointer;
`;

const ProfileContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const ProfileSpacer = styled.div`
  flex-grow: 1;
`;

const ButtonContainer = styled.div`
  margin-top: 15px;
  --shine-bg-color: ${WHITE};
  color: ${ORANGE};
`;

const PermissionContainer = styled.div`
  //color: #fff;
  font-size: 13px;
`;

const EditLink = styled.div`
  cursor: pointer;
`;

const PhotoImg = styled.img`
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
`;

const EmptyPhotoContainer = styled.div`
  border: 1px solid transparent;
  border-radius: 50%;
  min-width: 150px;
  height: 150px;
`;

export default ProfileEditPage;
