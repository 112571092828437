import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';

import useSpotify from '../lib/spotify';

import useLocalStorage from 'Common/src/hooks/useLocalStorage';
import { VerticalFullContainer } from 'Common/src/components/styled';

enum ConnectedAccountType {
  SPOTIFY = 'spotify'
}

const OAuthPage = () => {
  const { authType } = useParams();
  const navigate = useNavigate();
  //TODO: use window.sessionstorage instead of useLocalStorage
  const [eventPageUrl] = useLocalStorage<string>('eventPageUrl', '');

  const spotify = useSpotify();

  const oAuthCallback = async () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    const code = urlParams.get('code');

    if (code === null) {
      //TODO: error
      return;
    }

    if (authType === ConnectedAccountType.SPOTIFY) {
      const connectionSuccessful = await spotify.setSpotifyAccessToken(code);
      const returnUrl = eventPageUrl.trim() === '' ? '/' : eventPageUrl;
      localStorage.removeItem('eventPageUrl');
      navigate(returnUrl, { state: { spotifySuccess: connectionSuccessful } });
    }
  };

  useEffect(() => {
    void oAuthCallback();
  }, []);

  return <Container>
    Connecting your {authType}...
  </Container>;
};

const Container = styled(VerticalFullContainer)`
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 24px;
`;

export default OAuthPage;
