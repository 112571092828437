import React, { useContext, useEffect, useState } from 'react';
import { getDatabase, onValue, ref, Unsubscribe } from 'firebase/database';

import { useUserContext } from './UserContext';
import { initializePayment } from '../api/ElkPaymentService';
import { fetchCoinBalance } from '../api/CoinServiceApi';

import useLocalStorage from 'Common/src/hooks/useLocalStorage';
import { logSumoEvent, stringifyError, ULogApplication, ULogSeverity, ULogTag } from 'Common/src/api/SumoLogicApi';
import { app } from '../core/libs/Firebase';

const DEFAULT_START_BALANCE = 60;

interface IUBalanceState {
  balance: number;
}

interface IUCoinContext {
  startPurchase: (priceId: string) => Promise<void>;
  cancelPurchase: () => void;
  getBalance: () => number;
  getGratisSpent: () => number;
  spendCoins: (spendCount: number) => void;
  clientSecret?: string;
  priceId?: string;
}

const CoinContext = React.createContext<IUCoinContext | null>(null);

export function CoinContextProvider(props: {
  children: React.ReactNode
}) {
  const userContext = useUserContext();
  const [gratisBalance, setGratisBalance] = useLocalStorage<IUBalanceState>('userBState',
    { balance: DEFAULT_START_BALANCE }, true);

  const [priceId, setPriceId] = useState<string | undefined>();
  const [clientSecret, setClientSecret] = useState<string | undefined>();
  const [coinBalance, setCoinBalance] = useState<number | undefined>();

  useEffect(() => {
    let unsubscribe: Unsubscribe | null = null;

    if (userContext.id === undefined) {
      return;
    }

    const db = getDatabase(app);
    const valueRef = ref(db, `users/${userContext.id}`);

    unsubscribe = onValue(valueRef, (snapshot) => {
      // const data = snapshot.val() as { COIN_BALANCE_MODIFICATION_ID: string } | null;
      // This is blindly fetching coin balance because that's the only possible thing that can update for now.
      if (userContext.id !== undefined) {
        // void getCoinBalance();
      }
    });

    return () => {
      if (unsubscribe !== null) {
        unsubscribe();
      }
    };
  }, [userContext.id]);

  useEffect(() => {
    if (priceId !== undefined) {
      void getClientSecret(priceId);
    }
  }, [priceId]);

  const getCoinBalance = async () => {
    const response = await fetchCoinBalance(userContext.getAuthHeader());

    const balance = response.balance.toNumber();
    setCoinBalance(balance);
    setGratisBalance({ ...gratisBalance, balance });
  };

  const getClientSecret = async (priceId: string) => {
    try {
      const response = await initializePayment(userContext.getAuthHeader(), priceId);

      setClientSecret(response.clientSecret);
    } catch (e) {
      void logSumoEvent({
        app: ULogApplication.ELK,
        severity: ULogSeverity.ERROR,
        userId: userContext.id,
        tag: ULogTag.Stripe,
        message: `[CoinContext] Error initializing payment: ${stringifyError(e)}`
      });
    }
  };

  const getBalance = (): number => {
    if (userContext.isLoggedIn()) {
      return coinBalance === undefined ? 0 : coinBalance;
    }
    return gratisBalance.balance;
  };

  const getGratisSpent = (): number => {
    return DEFAULT_START_BALANCE - getBalance();
  };

  const spendCoins = (spendCount: number) => {
    gratisBalance.balance -= spendCount;
    setGratisBalance({ ...gratisBalance });
  };

  const startPurchase = async (priceId: string) => {
    setPriceId(priceId);
    return;
  };

  const cancelPurchase = () => {
    setPriceId(undefined);
    setClientSecret(undefined);
    // TODO: make RPC call to cancel PaymentIntent
  };

  const context: IUCoinContext = {
    startPurchase,
    cancelPurchase,
    getBalance,
    getGratisSpent,
    spendCoins,
    clientSecret,
    priceId
  };

  return <CoinContext.Provider value={context}>
    {props.children}
  </CoinContext.Provider>;
}

export const useCoinContext = () => {
  const context = useContext(CoinContext);
  if (context === null) {
    throw new Error('useCoinContext must be used within a CoinContextProvider');
  }
  return context;
};
