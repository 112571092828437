import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import SingleFieldError from '../SingleFieldForm/SingleFieldError';
import { useUserContext } from '../../contexts/UserContext';
import { ULoginStatus } from '../../state/reducers/user';
import { CodeType } from '../../lib/login';

interface Props {
  error: string;
  codeType: CodeType;
}

const ConfirmCode = ({ error, codeType }: Props) => {
  const userContext = useUserContext();

  const [code, setCode] = useState<string[]>(['', '', '', '', '', '']);
  const [codeTimer, setCodeTimer] = useState<number | undefined>();
  const [codeResendTimestamp, setCodeResendTimestamp] = useState<number | undefined>(undefined);
  const [codeSendCount, setCodeSendCount] = useState(1);
  const interval = useRef<number | null>(null);
  const [submittingCode, setSubmittingCode] = useState(false);

  useEffect(() => {
    if (interval.current !== null) {
      window.clearInterval(interval.current);
    }
    if (codeResendTimestamp !== undefined) {
      const calculateCodeTimer = () => {
        const diff = Math.floor((Date.now() - codeResendTimestamp) / 1000);
        if (diff < 30) {
          setCodeTimer(30 - diff);
        } else {
          setCodeTimer(undefined);
        }
      };
      interval.current = window.setInterval(calculateCodeTimer, 1000);
      calculateCodeTimer();
    }

    return () => {
      if (interval.current !== null) {
        window.clearInterval(interval.current);
      }
    };
  }, [codeResendTimestamp]);

  useEffect(() => {
    if (userContext.loginState.status === ULoginStatus.CodeSent) {
      setSubmittingCode(false);
    }
  }, [userContext.loginState.status]);

  const codeInputRefs = [
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null)
  ];

  const onConfirm = () => {
    setSubmittingCode(true);
    userContext.confirmCode(code.join(''), codeType);
  };

  const onResend = () => {
    codeInputRefs.forEach((ref) => {
      const ele = ref.current;
      if (ele) {
        ele.value = '';
      }
    });
    setCode(['', '', '', '', '', '']);

    if (codeTimer === undefined) {
      setCodeSendCount(codeSendCount + 1);
      setCodeResendTimestamp(Date.now());
      setSubmittingCode(false);
      userContext.submitPhone(userContext.phoneNumber, true);
    }
  };

  useEffect(() => {
    codeInputRefs.forEach((ref, index) => {
      const ele = ref.current;

      if (ele) {
        ele.addEventListener('keydown', (e: KeyboardEvent) => {
          if (e.key === 'Backspace' && ele.value === '') {
            codeInputRefs[Math.max(0, index - 1)].current?.focus();
          }
        });

        ele.addEventListener('input', (e: Event) => {
          const [first, ...rest] = (e.currentTarget as HTMLInputElement)?.value || '';
          code[index] = ele.value = first ?? '';
          setCode([...code]);

          const lastInputBox = index === 5;
          const didInsertContent = first !== undefined;
          if (didInsertContent) {
            if (lastInputBox) {
              onConfirm();
            } else {
              const nextEle = codeInputRefs[index + 1].current;
              if (nextEle) {
                nextEle.focus();
                nextEle.value = rest.join('');
                nextEle.dispatchEvent(new Event('input'));
              }
            }
          }
        });

        ele.addEventListener('focus', () => {
          for (let i = 0; i < index; i++) {
            if (codeInputRefs[i].current?.value === '') {
              codeInputRefs[i].current?.focus();
              break;
            }
          }
        });
      }
    });

    codeInputRefs[0].current?.focus();

    // Don't make the dependency list [codeInputRefs]. Really. Don't do it. It'll blow up.
  }, [codeInputRefs[0], codeInputRefs[1], codeInputRefs[2], codeInputRefs[3], codeInputRefs[4], codeInputRefs[5]]);

  return <>
    <LoginCodeInput>
      <CodeBox name="code" autoComplete="one-time-code" required ref={codeInputRefs[0]} disabled={submittingCode}/>
      <CodeBox name="code" required ref={codeInputRefs[1]} disabled={submittingCode}/>
      <CodeBox name="code" required ref={codeInputRefs[2]} disabled={submittingCode}/>
      <CodeBox name="code" required ref={codeInputRefs[3]} disabled={submittingCode}/>
      <CodeBox name="code" required ref={codeInputRefs[4]} disabled={submittingCode}/>
      <CodeBox name="code" required ref={codeInputRefs[5]} disabled={submittingCode}/>
    </LoginCodeInput>
    <LoginSecondaryButtonText $disabled={codeTimer !== undefined} onClick={onResend}>
      {codeTimer === undefined ? 'Resend Code' : `Resend Code in ${('' + codeTimer).padStart(2, '0')}s`}
    </LoginSecondaryButtonText>
    {/* Maybe? */}
    <SingleFieldError error={error}/>
  </>;
};

const CodeBox = styled.input.attrs({
  type: 'text', // Change the input type to text
  inputMode: 'numeric', // Set the input mode to numeric
})`
  padding: 6px 10px;
  font-size: 16px;
  border-radius: 6px;
  text-align: center;
  box-sizing: border-box;
  height: 39px;
  width: 39px;
  border: 0.5px solid rgba(0, 0, 0, 0.40);

  &:focus {
    outline: 0.5px solid #000;
    border: 0.5px solid #000;
  }
`;

const LoginCodeInput = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: auto;
  gap: 8px;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  justify-content: space-between;
  max-width: 276px;
`;

const LoginSecondaryButtonText = styled.div<{ $disabled: boolean }>`
  font-size: 14px;
  font-weight: 500;
  color: black;
  ${({ $disabled }) => $disabled ? 'opacity: 0.3;' : 'cursor: pointer;'}
`;

export default ConfirmCode;
