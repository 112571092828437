import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

import Plus from '../../components/icons/Plus';

import { DeviceQueries } from 'Common/src/components/styled';

const CreateEventRow = () => {
  const navigate = useNavigate();

  const onClick = () => {
    navigate('/event/create/ai');
  };

  return <ContentContainer onClick={onClick}>
    <Plus color="rgba(0, 0, 0, 0.30)" size={79}/>
    <CreateText>Create an event</CreateText>
  </ContentContainer>;
};


const ContentContainer = styled.div`
  justify-content: center;
  text-align: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 311px;
  height: 311px;
  box-sizing: border-box;
  cursor: pointer;
  padding: 44px 76px;
  border-radius: 10px;
  border: 2px solid rgba(0, 0, 0, 0.20);
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.10);

  @media (${DeviceQueries.mobile}) {
    width: 242px;
    height: 242px;
  }
`;

const CreateText = styled.div`
  margin-top: 10px;
  color: rgba(0, 0, 0, 0.30);
  font-size: 20px;
  font-weight: 500;
`;


export default CreateEventRow;
