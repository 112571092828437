import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import { b64tos } from '../util/stringutils';
import { useUserContext } from '../contexts/UserContext';
import { verifyCoinPurchase } from '../api/CoinServiceApi';

import { logSumoEvent, stringifyError, ULogApplication, ULogSeverity, ULogTag } from 'Common/src/api/SumoLogicApi';

const PaymentSuccessAction = () => {
  const userContext = useUserContext();
  const navigate = useNavigate();
  const [queryParams] = useSearchParams();

  useEffect(() => {

    const state = queryParams.get('state');
    const paymentIntentId = queryParams.get('payment_intent');
    if (state !== null && paymentIntentId !== null) {
      try {
        const decodedState = JSON.parse(b64tos(state)) as { location: string, priceId: string };

        void verifyCoinPurchase(
          userContext.getAuthHeader(),
          uuidv4(),
          paymentIntentId,
          decodedState.priceId
        );

        navigate(decodedState.location ?? '/');
        return;
      } catch (e) {
        void logSumoEvent({
          app: ULogApplication.ELK,
          severity: ULogSeverity.WARN,
          userId: userContext.id,
          tag: ULogTag.Stripe,
          message: `[PaymentSuccessAction] Error during verify coin purchase: ${stringifyError(e)}`
        });
      }
    }

    navigate('/');
  }, []);

  return null;
};

export default PaymentSuccessAction;
