import React from 'react';

const Download = ({ color = 'currentColor', size = 17 }: { color?: string, size?: number }) => {
  return <svg width={size} height={size} viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.66699 11.168V11.8346C2.66699 12.3651 2.87771 12.8738 3.25278 13.2488C3.62785 13.6239 4.13656 13.8346 4.66699 13.8346H11.3337C11.8641 13.8346 12.3728 13.6239 12.7479 13.2488C13.1229 12.8738 13.3337 12.3651 13.3337 11.8346V11.168M10.667 8.5013L8.00033 11.168M8.00033 11.168L5.33366 8.5013M8.00033 11.168V3.16797"
      stroke={color} strokeOpacity="0.6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>;
};

export default Download;
