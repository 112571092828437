import React from 'react';

const Duplicate = ({ color = 'currentColor', size = 20 }: {
  color?: string,
  size?: number
}) => {
  return <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.66634 13.3359H4.99967C4.55765 13.3359 4.13372 13.1603 3.82116 12.8478C3.5086 12.5352 3.33301 12.1113 3.33301 11.6693V5.0026C3.33301 4.56058 3.5086 4.13665 3.82116 3.82409C4.13372 3.51153 4.55765 3.33594 4.99967 3.33594H11.6663C12.1084 3.33594 12.5323 3.51153 12.8449 3.82409C13.1574 4.13665 13.333 4.56058 13.333 5.0026V6.66927M8.33301 16.6693H14.9997C15.4417 16.6693 15.8656 16.4937 16.1782 16.1811C16.4907 15.8686 16.6663 15.4446 16.6663 15.0026V8.33594C16.6663 7.89391 16.4907 7.46999 16.1782 7.15743C15.8656 6.84487 15.4417 6.66927 14.9997 6.66927H8.33301C7.89098 6.66927 7.46706 6.84487 7.1545 7.15743C6.84194 7.46999 6.66634 7.89391 6.66634 8.33594V15.0026C6.66634 15.4446 6.84194 15.8686 7.1545 16.1811C7.46706 16.4937 7.89098 16.6693 8.33301 16.6693Z"
      stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>;
};

export default Duplicate;
