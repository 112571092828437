import styled from 'styled-components';

export const DeviceQueries = {
  smallMobile: 'max-width: 550px',
  mobile: 'max-width: 767px',
  laptop: 'min-width: 1020px'
};

export const isMobile = () => {
  return window.innerWidth <= 767;
};

export const ContentContainer = styled.div`
  padding-left: 20px;
  padding-right: 24px;
  max-width: 1200px;
  height: 100%;

  @media (${DeviceQueries.mobile}) {
    width: 100%;
  }
`;

export const ScrollableContent = styled.div`
  padding-left: 4px;
  overflow-x: hidden;
  overflow-y: scroll;
  height: 100%;
`;

export const Separator = styled.hr`
  height: 1px;
  border-radius: 0.5px;
  background-image: linear-gradient(to right, #ffcc00, #f58918, #f06724, #ef5826, #ed1b2e, #bc206e, #a3238e, #7a469b, #72479c, #5a4b9f, #3551a4, #0159aa, #0059aa, #0183c4, #02abdd, #08acd0, #19afaf, #33b379, #4bb749);
  border: 0;
  margin: 15px 6px;
`;

export const SeparatorMobile = styled.hr`
  height: 1px;
  border-radius: 0.5px;
  background-image: linear-gradient(to right, #ffcc00, #f58918, #f06724, #ef5826, #ed1b2e, #bc206e, #a3238e, #7a469b, #72479c, #5a4b9f, #3551a4, #0159aa, #0059aa, #0183c4, #02abdd, #08acd0, #19afaf, #33b379, #4bb749);
  border: 0;
  margin: 6px 6px;
`;

export const FormContainer = styled.div`
  padding-top: 16px;
  max-width: 600px;
`;

export const FormSubtitle = styled.h4`
  font-size: 14px;
  margin-top: 1rem;
`;

export const CeilingLabel = styled.label`
  position: absolute;
  top: -7px;
  left: 20px;
  background-color: white;
  font-size: 12px;
  padding: 0 5px;
  color: #009bdf;
  z-index: 1;
`;

export const CeilingLabelContainer = styled.div`
  position: relative;
`;

export const VerticalFullContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  flex-grow: 1;
  overflow: auto;
`;
