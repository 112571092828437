import { isBrightColor } from './colorPicker';

import { DEFAULT_COLORS } from '../contexts/BackgroundContext';

import { BLACK } from 'Common/src/lib/Colors';

interface CalculateButtonColorsProps {
  color: string;
  outline?: boolean;
  inverse?: boolean;
  loading?: boolean;
  overrideColor?: boolean;
}

export const calculateButtonColors = ({
                                        color,
                                        outline,
                                        inverse,
                                        overrideColor
                                      }: CalculateButtonColorsProps) => {
  let borderColor: string;
  let bgColor: string;
  let textColor: string;

  const bright = isBrightColor(color);

  if (overrideColor) {
    borderColor = 'rgba(15, 8, 22, 0.16)';
    textColor = BLACK;
    bgColor = 'rgba(255, 255, 255, 0.48)';
  } else if (outline) {
    if (bright) {
      borderColor = color;
      textColor = color;
      bgColor = 'var(--shine-system-button-text-color)';
    } else {
      borderColor = 'var(--shine-system-button-text-color)';
      textColor = 'var(--shine-system-button-text-color)';
      bgColor = color;
      if (color === DEFAULT_COLORS.highlightText) {
        textColor = '#ffffff';
        borderColor = '#ffffff';
        bgColor = 'transparent';
      } else {
        borderColor = 'var(--shine-system-button-text-color)';
        textColor = 'var(--shine-system-button-text-color)';
        bgColor = color;
      }
    }
  } else if (inverse) {
    if (bright) {
      borderColor = 'var(--shine-system-button-text-color)';
      textColor = color;
      bgColor = 'var(--shine-system-button-text-color)';
    } else {
      borderColor = color;
      textColor = 'var(--shine-system-button-text-color)';
      bgColor = color;
    }
  } else {
    if (bright) {
      borderColor = color;
      textColor = 'var(--shine-system-button-text-color)';
      bgColor = color;
    } else {
      borderColor = 'var(--shine-system-button-text-color)';
      textColor = color;
      bgColor = 'var(--shine-system-button-text-color)';
    }

    if (color === DEFAULT_COLORS.highlightText || bgColor === DEFAULT_COLORS.highlightText) {
      textColor = '#ffffff';
      borderColor = DEFAULT_COLORS.highlightText;
      bgColor = color;
    }
  }

  return {
    borderColor,
    textColor,
    bgColor
  };
};
