import { v4 as uuidv4 } from 'uuid';

import { TAppElkAttendeeRole, TAppElkAttendeeStatus, TAppElkInviteStatus } from 'TProtocol/prototypes/events/messages';

import { IUEvent, IUInvitee } from '../lib/event';

const EMAIL_REGEX = /^[\w!#$%&'*+/=?`{|}~^-]+(?:\.[\w!#$%&'*+/=?`{|}~^-]+)*@(?:[A-Z0-9-]+\.)+[A-Z]{2,32}$/i;

// TODO: some of this is copied from Duck, should move into common
export const validateEmail = (email?: string): boolean => {
  if (!email) {
    return false;
  }
  return !!email.match(EMAIL_REGEX);
};

export const emailExists = (email?: string, event?: IUEvent): boolean => {
  if (email === undefined || event === undefined) {
    return false;
  }

  for (const invitee of event.attendees) {
    if (invitee.email?.toLowerCase() === email.toLowerCase()) {
      return true;
    }
  }

  return false;
};

export const parseBulkEmails = (emails?: string): IUInvitee[] | null => {
  if (!emails) {
    return null;
  }

  const invitees: IUInvitee[] = [];

  const emailRegex = new RegExp(
    '(?:^|,\\s+|[\\n\\r]+)(?:("?)([^@,\\n\\r"]+)\\1\\s+<([^>]*)>|([^\\s,]+))(?=,|[\\n\\r]+|$)',
    'g'
  );

  const trimmedEmails = emails.trim();
  let match;
  while (match = emailRegex.exec(trimmedEmails)) {
    let name: string | undefined;
    let email: string;
    if (match[2] && match[3]) {
      if (EMAIL_REGEX.test(match[3])) {
        name = match[2];
        email = match[3];
      } else {
        return null;
      }
    } else if (match[4]) {
      if (EMAIL_REGEX.test(match[4])) {
        email = match[4];
      } else {
        return null;
      }
    } else {
      return null;
    }

    invitees.push(new IUInvitee({
      name,
      email,
      additionalGuestCount: 0,
      inviteeId: uuidv4(),
      role: TAppElkAttendeeRole.ATTENDEE,
      rsvpStatus: TAppElkAttendeeStatus.INVITED,
      inviteStatus: TAppElkInviteStatus.PENDING
    }));
  }

  return invitees.length ? invitees : null;
};
