import React from 'react';

const MiniXCircle = ({ color = 'currentColor', opacity = 1, size = 17 }: {
  color?: string,
  opacity?: number,
  size?: number
}) => {
  return <svg width={size} height={size} viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd"
          d="M8 16.5C12.4183 16.5 16 12.9183 16 8.5C16 4.08172 12.4183 0.5 8 0.5C3.58172 0.5 0 4.08172 0 8.5C0 12.9183 3.58172 16.5 8 16.5ZM6.28033 5.71967C5.98744 5.42678 5.51256 5.42678 5.21967 5.71967C4.92678 6.01256 4.92678 6.48744 5.21967 6.78033L6.93934 8.5L5.21967 10.2197C4.92678 10.5126 4.92678 10.9874 5.21967 11.2803C5.51256 11.5732 5.98744 11.5732 6.28033 11.2803L8 9.56066L9.71967 11.2803C10.0126 11.5732 10.4874 11.5732 10.7803 11.2803C11.0732 10.9874 11.0732 10.5126 10.7803 10.2197L9.06066 8.5L10.7803 6.78033C11.0732 6.48744 11.0732 6.01256 10.7803 5.71967C10.4874 5.42678 10.0126 5.42678 9.71967 5.71967L8 7.43934L6.28033 5.71967Z"
          fill={color} fillOpacity={opacity}/>
  </svg>;
};

export default MiniXCircle;
