import React from 'react';
import styled from 'styled-components';
import { Outlet } from 'react-router-dom';

const MainLayout = () => {
  return <Layout className="main-layout">
    <Container>
      <Outlet/>
    </Container>
  </Layout>;
};

const Layout = styled.div`
  display: flex;
  height: 100vh;
  height: 100svh;
  min-height: fit-content;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  -webkit-box-pack: justify;
  justify-content: space-between;
  align-items: center;
  position: relative;
  height: 100%;
  width: 100%;
`;

export default MainLayout;

