import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';

import { useUserContext } from '../../contexts/UserContext';
import { useBackgroundContext } from '../../contexts/BackgroundContext';
import { BasePageContent } from '../BasePage';
import { getRSVPedInvitee, hasUserRSVPed } from '../../lib/attendance';
import { useEventCacheContext } from '../../contexts/EventCacheContext';
import { useModalContext } from '../../contexts/ModalContext';
import useInviteeUuidStorage from '../../hooks/useInviteeUuidStorage';
import BasePageHeader from '../BasePageHeader';
import { b64tos } from '../../util/stringutils';
import AttendeesList from './AttendeesList';
import AlbumDetails from './AlbumDetails';
import FooterLinks from '../../components/FooterLinks';

import { TAppElkMessage } from 'TProtocol/prototypes/events/messages';
import PhotoViewer from '../../components/PhotoViewer';
import TopModule from './TopModule';
import { EventModules } from '../../components/EventForm/Common';
import EventDetailsModule from './EventDetailsModule';

export interface EventPageState {
  showPhotosPromo: boolean;
  showEventCreated: boolean;
  showRSVP: boolean;
}

interface IUSeparatedMessages {
  blasts: TAppElkMessage[];
  byInviteeId: Map<string, TAppElkMessage[]>;
}

const EventRoPage = () => {
  const userContext = useUserContext();
  const eventCacheContext = useEventCacheContext();
  const backgroundContext = useBackgroundContext();
  const { getInviteeUuid, getToken } = useInviteeUuidStorage();

  const navigate = useNavigate();
  const [queryParams] = useSearchParams();
  const { eventId } = useParams();
  const isLoggedIn = userContext.isLoggedIn();

  const event = eventId !== undefined ? eventCacheContext.getEvent(eventId) : undefined;
  // const userId = userContext.getAuthHeader().userId;
  const [hasRSVPed, setHasRSVPed] = useState<boolean>(false);
  const [showPhotoViewer, setShowPhotoViewer] = useState<boolean>(false);

  const inviteeUuidParam = queryParams.get('iu');
  const token = queryParams.get('token') ?? getToken(eventId) ?? undefined;
  const inviteeUuid = inviteeUuidParam ? b64tos(inviteeUuidParam) : getInviteeUuid(eventId);
  const [separatedMessages, setSeparatedMessages] = useState<IUSeparatedMessages>({
    blasts: [],
    byInviteeId: new Map()
  });

  const pageScrollRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (event?.messages) {
      const separatedMessages: IUSeparatedMessages = {
        blasts: [],
        byInviteeId: new Map()
      };

      for (const message of event.messages) {
        if (message.textBlast) {
          separatedMessages.blasts.push(message);
        } else if (message.receiverInviteeUuid !== undefined) {
          const messagesById = separatedMessages.byInviteeId.get(message.receiverInviteeUuid);

          if (messagesById) {
            messagesById.unshift(message);
          } else {
            separatedMessages.byInviteeId.set(message.receiverInviteeUuid, [message]);
          }
        }
      }

      setSeparatedMessages(separatedMessages);
    }
  }, [event?.messages]);

  const todayStart = new Date();
  todayStart.setHours(0, 0, 0, 0); // Reset to the start of the day

  useEffect(() => {
    if (event !== undefined) {
      document.title = `Shine - ${event.title}`;
      backgroundContext.setColors(
        { colors: event?.colors, animation: event?.animation, animationUrl: event?.backgroundAnimationUrl }
      );
    }
  }, [event]);

  useEffect(() => {
    if (eventId !== undefined) {
      void eventCacheContext.fetchEvent({ eventId, inviteeUuid, isPreview: !isLoggedIn, token });
    } else {
      // User came directly to /event without an id, but no event is in context (reload or bookmark)
      navigate('/event/create');
    }
  }, [event?.id, eventId]);

  useEffect(() => {
    if (event) {
      setHasRSVPed(hasUserRSVPed(event, userContext.id ?? '', inviteeUuid));
      if ((userContext.id || inviteeUuid) && !event.messagesArePersonal) {
        let inviteeId: string | undefined = inviteeUuid;
        if (userContext.id) {
          inviteeId = getRSVPedInvitee(event, userContext.id)?.inviteeId;
        }
        void eventCacheContext.refreshMessages(event.id, inviteeId);
      }
      if (event.albumDetails === undefined) {
        void eventCacheContext.refreshAlbumDetails(event.id, token);
      }
    }
  }, [event?.id, userContext.id]);

  useEffect(() => {
    if (hasRSVPed && event && event.albumDetails?.albumCode === undefined) {
      void eventCacheContext.refreshAlbumDetails(event.id);
    }
  }, [hasRSVPed]);

  const showAttendeeBox = event && (!event.cancelledTimestamp);

  if (event) {
    const description = event.description.split('\n')
      .map((str: string, i: number) => <div key={i}>{str}<br/></div>);

    return <>
      <BasePageHeader/>
      <BasePageContent $wide={true} $full={true} $flush={true} $centered={true} containerRef={pageScrollRef}>
        <TopModule event={event}/>

        <EventModules>
          {event.description.trim() !== '' &&
            <NewContentDescription>
              {description}
            </NewContentDescription>}

          <AlbumDetails event={event} setShowPhotoViewer={setShowPhotoViewer}/>

          <EventDetailsModule event={event}/>

          {showAttendeeBox ? <AttendeesList
            event={event}
            inviteeUuid={inviteeUuid}
            readOnly={false}
            messagesByInviteeId={separatedMessages.byInviteeId}
          /> : null}

          {/*Messages*/}

        </EventModules>
        <FooterLinks/>
      </BasePageContent>
      <PhotoViewer event={event} show={showPhotoViewer} close={() => setShowPhotoViewer(false)}/>
    </>;
  } else {
    return null;
  }
};

const NewContentDescription = styled.div`
  font-size: 22px;
  font-weight: 400;
`;

export default EventRoPage;
