import React from 'react';

const PencilAlt = ({ color = 'currentColor', opacity = 1, size = 20 }: {
  color?: string,
  opacity?: number,
  size?: number
}) => {
  return <svg width={size} height={size} viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17.414 3.08557C17.0389 2.71063 16.5303 2.5 16 2.5C15.4697 2.5 14.9611 2.71063 14.586 3.08557L7 10.6716V13.4996H9.828L17.414 5.91357C17.7889 5.53852 17.9996 5.0299 17.9996 4.49957C17.9996 3.96924 17.7889 3.46063 17.414 3.08557Z"
      fill={color} fillOpacity={opacity}/>
    <path fillRule="evenodd" clipRule="evenodd"
          d="M2 6.5C2 5.96957 2.21071 5.46086 2.58579 5.08579C2.96086 4.71071 3.46957 4.5 4 4.5H8C8.26522 4.5 8.51957 4.60536 8.70711 4.79289C8.89464 4.98043 9 5.23478 9 5.5C9 5.76522 8.89464 6.01957 8.70711 6.20711C8.51957 6.39464 8.26522 6.5 8 6.5H4V16.5H14V12.5C14 12.2348 14.1054 11.9804 14.2929 11.7929C14.4804 11.6054 14.7348 11.5 15 11.5C15.2652 11.5 15.5196 11.6054 15.7071 11.7929C15.8946 11.9804 16 12.2348 16 12.5V16.5C16 17.0304 15.7893 17.5391 15.4142 17.9142C15.0391 18.2893 14.5304 18.5 14 18.5H4C3.46957 18.5 2.96086 18.2893 2.58579 17.9142C2.21071 17.5391 2 17.0304 2 16.5V6.5Z"
          fill={color} fillOpacity={opacity}/>
  </svg>;
};

export default PencilAlt;
