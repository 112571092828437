import React from 'react';

const Edit = ({ color = 'currentColor', size = 23, onClick }: {
  color?: string,
  size?: number,
  onClick?: () => void
}) => {
  return <svg
    onClick={onClick}
    width={size}
    height={size}
    viewBox="0 0 6.085741 6.0853443"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg">
    <g
      id="layer1"
      transform="translate(-126.58,-136.64801)">
      <path
        d="m 572.93552,111.74136 -0.41645,0.41857 -0.30507,-0.31194 -0.78052,-0.77893 -0.30956,-0.30745 0.41407,-0.4146 a 0.45852291,0.45852291 0 0 1 0.40772,-0.11774 c 0.1405,0.0265 0.26908,0.0958 0.36857,0.19844 l 0.55351,0.54107 a 0.54715833,0.54715833 0 0 1 0.0677,0.77258 z m -1.8116,-0.36248 -0.30745,-0.30956 -3.54012,3.54304 a 0.21960416,0.21960416 0 0 0 -0.059,0.11774 l -0.23547,1.31762 a 0.21986875,0.21986875 0 0 0 0.12461,0.23813 c 0.0294,0.0132 0.0614,0.0201 0.0934,0.0196 h 0.037 l 1.31895,-0.23124 a 0.22886458,0.22886458 0 0 0 0.11562,-0.0611 l 3.54251,-3.54753 -0.31194,-0.30533 z"
        fill={color}
        transform="translate(-440.3985,26.427875)"/>
    </g>
  </svg>;
};

export default Edit;
