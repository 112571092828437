import React, { ReactNode, useEffect, useState } from 'react';
import styled from 'styled-components';

import { shareEvent } from '../../util/share';
import Modal from './Modal';
import IconCircle from '../IconCircle';
import Upload from '../icons/Upload';
import Message from '../icons/Message';
import ActionButton from '../buttons/ActionButton';
import { EventPreview, getEventPreview, getHangoutEmailTemplate } from '../../api/EventPreviewApi';
import { useUserContext } from '../../contexts/UserContext';
import { sendTemplateEmail } from '../../api/ElkEventService';
import { useNavigate } from 'react-router-dom';
import { IUEvent } from '../../lib/event';
import { extractSameRGBWithAlpha } from '../../lib/colorPicker';
import { useBackgroundContext } from '../../contexts/BackgroundContext';

import { getIsIOS, getIsMacOS, getIsWindows } from 'Common/src/utils/Browser';
import { logSumoEvent, stringifyError, ULogApplication, ULogSeverity, ULogTag } from 'Common/src/api/SumoLogicApi';

const ShareWithFriendsModal = ({ close, event }: {
  close: () => void,
  event: IUEvent | undefined,
  hideManage?: boolean
}) => {
  const userContext = useUserContext();
  const navigate = useNavigate();
  const backgroundContext = useBackgroundContext();

  const [shareText, setShareText] = useState<string>('Share to...');
  const [showMailCopied, setShowMailCopied] = useState(false);
  const [showMailOptions, setShowMailOptions] = useState(false);
  const [emailAddress, setEmailAddress] = useState('');
  const [eventPreview, setEventPreview] = useState<EventPreview>();
  const [copyToClipboardAction, setCopyToClipboardAction] = useState(false);

  useEffect(() => {
    if (showMailCopied === true) {
      const timerId = setTimeout(() => {
        setShowMailCopied(false);
      }, 2000);
      return () => {
        clearTimeout(timerId);
      };
    }
    return () => {
      // noop
    };
  }, [showMailCopied]);

  useEffect(() => {
    if (copyToClipboardAction && eventPreview !== undefined) {
      void copyToClipboard();
    }
  }, [eventPreview, copyToClipboardAction]);

  const handleShareSheet = async () => {
    setShareText('Link copied!');

    if (event !== undefined) {
      await shareEvent(userContext, event);
    }
  };

  const handleMailSend = async () => {
    if (event?.id) {
      await sendTemplateEmail(userContext, event.id, emailAddress);
      close();
    }
  };

  const copyToClipboard = async () => {
    if (!eventPreview || !event) {
      return;
    }

    const htmlToCopy = await getHangoutEmailTemplate(event.id) ?? '';

    // Create a new ClipboardItem with the HTML content
    const clipboardItem = new ClipboardItem({ 'text/html': new Blob([htmlToCopy], { type: 'text/html' }) });

    // Write the ClipboardItem to the clipboard
    try {
      await navigator.clipboard.write([clipboardItem]);
      setShowMailCopied(true);
    } catch (e) {
      void logSumoEvent({
        app: ULogApplication.ELK,
        severity: ULogSeverity.WARN,
        userId: userContext.id,
        tag: ULogTag.UserError,
        message: `[ShareWithFriendsModal] Error during copyToClipboard: ${stringifyError(e)}`
      });
    }

    setCopyToClipboardAction(false);
  };

  const isWindows = getIsWindows();

  let smsBody = '';
  if (!isWindows && event?.url) {
    if (getIsIOS() || getIsMacOS()) {
      smsBody = 'sms:-&body=' + event.url;
    } else {
      smsBody = 'sms:?body=' + event.url;
    }
  }

  let content: ReactNode;
  if (showMailOptions) {
    content = <EmailOptions>
      <EmailOption>
        <EmailLabel>
          We will email you an invite that you can edit/forward
        </EmailLabel>
        <EmailInput type="email" autoComplete="off" value={emailAddress}
                    onChange={e => setEmailAddress(e.currentTarget.value)}/>
        <ActionButton type="button" onClick={handleMailSend}>Send Email</ActionButton>
      </EmailOption>
    </EmailOptions>;
  } else {
    content = <ButtonGroup>
      <ButtonContainer>
        <IconCircle icon={<Upload size={40}/>} text={shareText}
                    color={extractSameRGBWithAlpha(backgroundContext.systemColors.backgroundText, 0.3)}
                    onClick={handleShareSheet} isButtonCentered={true}/>
      </ButtonContainer>

      {smsBody &&
        <ButtonContainer>
          <ButtonLink href={smsBody}>
            <IconCircle icon={<Message size={40}/>} text="Messages"
                        color={extractSameRGBWithAlpha(backgroundContext.systemColors.backgroundText, 0.3)}
                        isButtonCentered={true}/>
          </ButtonLink>
        </ButtonContainer>
      }
    </ButtonGroup>;
  }

  return <Modal title="Share Link" onClickOutside={close} showClose={true}>
    {content}
  </Modal>;
};

const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-evenly;
  padding: 0 10px;
`;

const ButtonContainer = styled.div`
  width: 40%;
  padding: 0 10px;

`;

const ButtonLink = styled.a`
  text-decoration: none;
  color: currentColor;
`;

const EmailOptions = styled.div`
  padding: 20px 20px;

`;

const EmailOption = styled.div`
  margin-top: 15px;
  display: flex;
  flex-direction: column;
`;

const EmailLabel = styled.div`
  margin-bottom: 5px;
  font-size: 14pt;
`;

const EmailInput = styled.input`
  margin-bottom: 5px;
  flex-grow: 1;
  height: 30px;
`;

export default ShareWithFriendsModal;

