export const WHITE = '#ffffff';
export const BLACK = '#000000';
export const ORANGE = '#EF6623';

export const LogoColor1 = '#fdcc3b';
export const LogoColor2 = '#ec6733';
export const LogoColor3 = '#e81d35';
export const LogoColor4 = '#9f2289';
export const LogoColor5 = '#784696';
export const LogoColor6 = '#1258a7';
export const LogoColor7 = '#25abda';
export const LogoColor8 = '#52b754';
