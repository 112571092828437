import { v4 as uuidv4 } from 'uuid';

import { IUUserContext } from '../contexts/UserContext';

import { getDeviceId } from 'Common/src/lib/DeviceId';
import { logSumoEvent, ULogApplication, ULogSeverity, ULogTag } from 'Common/src/api/SumoLogicApi';

import { TAppProduct, TUnauthHeader, TUserAgent } from 'TProtocol/common/models';

export const createUnauthHeader = (isBlocking?: boolean): TUnauthHeader => {
  return new TUnauthHeader({
    deviceId: getDeviceId(ULogApplication.ELK),
    agent: new TUserAgent({
      platform: 'web',
      version: '0.0.0.2',
      product: TAppProduct.ELK
    }),
    timestamp: Date.now(),
    requestId: `${isBlocking === false ? 'nonblocking-' : 'blocking-'}${activeEvent?.requestId}`
  });
};

export enum UserEventType {
  WhenSubmitted = 'WhenSubmitted',
  EventCreated = 'EventCreated',
  FirebaseCodeSubmitted = 'FirebaseCodeSubmitted',
  UserNameSubmitted = 'UserNameSubmitted',
  LoadEvent = 'LoadEvent',
  LoadCreateEventFromRoot = 'LoadCreateEventFromRoot',
  LoadEventFromShortlink = 'LoadEventFromShortlink',
  LoadEventFromEvents = 'LoadEventFromEvents',
  LoadEventsFromRoot = 'LoadEventsFromRoot',
  BackFromEventToEvents = 'BackFromEventToEvents',
  UserRSVP = 'UserRSVP',
  SaveEvent = 'SaveEvent',
  InitialLoadEvents = 'InitialLoadEvents',
  InitialLoadEvent = 'InitialLoadEvent',
  InitialLoadCreateEvent = 'InitialLoadCreateEvent',
}

interface UserEvent {
  userEventType: UserEventType;
  startTime: number;
  requestId: string;
}

let activeEvent: UserEvent | null = null;
export const startUserEvent = (userEventType: UserEventType) => {
  if (activeEvent !== null) {
    console.log(
      `Error: Tried to start a user event (${userEventType}) when one is still running (${activeEvent.userEventType}), old event will be ignored.`);
  }

  activeEvent = {
    userEventType,
    startTime: Date.now(),
    requestId: uuidv4()
  };
};

const startEventFromPageLoad = () => {
  const path = window.location.pathname;
  if (path === '/') {
    // RootPage will figure out what to start.
    return;
  } else if (path === '/events') {
    startUserEvent(UserEventType.InitialLoadEvents);
  } else if (path === '/event/create') {
    startUserEvent(UserEventType.InitialLoadCreateEvent);
  } else if (path.match(/\/event\/[^/]+/)) {
    startUserEvent(UserEventType.InitialLoadEvent);
  }
};

export const endUserEvent = (userContext: IUUserContext) => {
  if (activeEvent === null) {
    // console.log('Error: Tried to end a user event when one is not running.');
    return;
  }

  const now = Date.now();
  const message = `reqId=${activeEvent.requestId},eventType=${activeEvent.userEventType},startTime=${activeEvent.startTime},endTime=${now}`;
  void logSumoEvent({
    app: ULogApplication.ELK,
    severity: ULogSeverity.INFO,
    userId: userContext.id,
    tag: ULogTag.Network,
    message: message
  });
  // console.log(message);

  activeEvent = null;
};

export const getCurrentRequestId = (): string | undefined => {
  return activeEvent?.requestId ?? undefined;
};

startEventFromPageLoad();
