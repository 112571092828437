import { TAppElkEventAnswerValue, TAppElkSpotifySong } from 'TProtocol/prototypes/events/messages';

const tAppElkSpotifySongToString = (song: TAppElkSpotifySong) => {
  return `${song.songName} • ${song.artistName}`;
};

export const tAppElkEventAnswerValueToString = (answerValue: TAppElkEventAnswerValue) => {
  if (answerValue.song !== undefined) {
    return tAppElkSpotifySongToString(answerValue.song);
  } else if (answerValue.isYes !== undefined) {
    return answerValue.isYes ? 'Yes' : 'No';
  } else if (answerValue.text) {
    return answerValue.text;
  } else {
    return '';
  }
};
