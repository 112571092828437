import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { IUEvent } from '../../lib/event';
import { useUserContext } from '../../contexts/UserContext';
import AppleLogo from '../../assets/apple@1x.png';
import GoogleLogo from '../../assets/google@1x.png';
import AddToCal from '../icons/AddToCal';

import { logSumoEvent, ULogApplication, ULogSeverity, ULogTag } from 'Common/src/api/SumoLogicApi';
import { DeviceQueries } from 'Common/src/components/styled';

const THREE_HOURS_IN_MS = 3 * 60 * 60 * 1000;

const AddToCalendar = ({ color = 'currentColor', event }: {
  color?: string,
  event: IUEvent | undefined
}) => {
  const [showOptions, setShowOptions] = useState(false);
  const [appleCalendarUrl, setAppleCalendarUrl] = useState('');
  const [googleCalendarUrl, setGoogleCalendarUrl] = useState('');

  const userContext = useUserContext();

  useEffect(() => {
    if (event?.id) {

      // Generate URLs for Google and Apple Calendar
      const eventTitle = encodeURIComponent(event.title || '');
      const eventLocation = encodeURIComponent(event.location.displayName || '');
      const eventDescription = encodeURIComponent(event.description || '');
      const eventStartTime = encodeURIComponent(new Date(event.startTime).toISOString().replace(/-|:|\.\d{3}/g, ''));

      const endTs = !event.endTime ? event.startTime + THREE_HOURS_IN_MS : event.endTime;
      const eventEndTime = encodeURIComponent(new Date(endTs).toISOString().replace(/-|:|\.\d{3}/g, ''));

      const google = `https://calendar.google.com/calendar/r/eventedit?text=${eventTitle}&dates=${eventStartTime}/${eventEndTime}&details=${eventDescription}&location=${eventLocation}`;
      const apple = `data:text/calendar;charset=utf8,BEGIN:VCALENDAR%0D%0AVERSION:2.0%0D%0ABEGIN:VEVENT%0D%0ASUMMARY:${eventTitle}%0D%0ADESCRIPTION:${eventDescription}%0D%0ALOCATION:${eventLocation}%0D%0ADTSTART:${eventStartTime}%0D%0ADTEND:${eventEndTime}%0D%0AEND:VEVENT%0D%0AEND:VCALENDAR`;

      setAppleCalendarUrl(apple);
      setGoogleCalendarUrl(google);
    }
  }, [event]);

  const handleGoogleClick = () => {
    setShowOptions(false);
  };

  const handleAppleClick = () => {
    setShowOptions(false);
  };

  const onCalendarClick = () => {
    if (event?.isPreview) {
      return;
    }

    // Logging event (assuming logSumoEvent function exists)
    logSumoEvent({
      app: ULogApplication.ELK,
      severity: ULogSeverity.INFO,
      userId: userContext.id,
      tag: ULogTag.UserAction,
      message: '[EventPageEvent] Clicked on add to calendar'
    });

    // Show the dropdown with calendar options
    setShowOptions(true);

  };

  const handleMouseEnter = () => {
    setShowOptions(true);
  };

  const handleMouseLeave = () => {
    setShowOptions(false);
  };

  return (
    <>
      <Wrapper onClick={onCalendarClick} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        <AddToCal color={color}/>
      </Wrapper>
      <Wrapper>
        {showOptions ? <CalendarOptions onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
          <CalendarOption href={googleCalendarUrl} target="_blank" rel="noopener noreferrer"
                          onClick={handleGoogleClick}>
            <Logo src={GoogleLogo} alt="Google Calendar"/>
            Google Calendar
          </CalendarOption>
          <CalendarOption href={appleCalendarUrl} download="event.ics" onClick={handleAppleClick}>
            <Logo src={AppleLogo} alt="Apple Calendar"/>
            Apple Calendar
          </CalendarOption>
        </CalendarOptions> : <></>}
      </Wrapper>
    </>

  );
};

export default AddToCalendar;

const Wrapper = styled.div`
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 5px;
  cursor: pointer;
  position: relative;
`;

const CalendarOptions = styled.div`
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-100%);
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  white-space: nowrap;
  z-index: 10;

  @media not (${DeviceQueries.laptop}) {
    transform: translateX(-50%);
    margin-top: 0;
  }
`;

const CalendarOption = styled.a`
  display: block;
  font-size: 12px;
  font-family: Poppins, sans-serif;
  text-decoration: none;
  color: black;
  text-align: left;
  justify-content: center;
  align-items: center;
  padding: 10px;

  &:hover {
    background-color: rgba(0, 0, 0, 0.2);
  }
`;

const Logo = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 10px;
`;

