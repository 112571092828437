import { IUEvent, IUInvitee } from './event';

import { TAppElkAttendeeRole, TAppElkAttendeeStatus, TAppElkInviteStatus } from 'TProtocol/prototypes/events/messages';

export const updateEventAttendance = (event: IUEvent, attendee: IUInvitee): IUEvent => {

  const existingAttendeeIndex = event.attendees.findIndex(
    (existingAttendee) => existingAttendee.inviteeId === attendee.inviteeId
  );

  if (existingAttendeeIndex !== -1) {
    if (event.attendees[existingAttendeeIndex].role === TAppElkAttendeeRole.ORGANIZER) {
      // Organizer should not be updated.
      return event;
    }

    // If the attendee exists, update the existing attendee
    const updatedAttendees = [...event.attendees];
    updatedAttendees[existingAttendeeIndex] = attendee;

    return {
      ...event,
      attendees: updatedAttendees,
    };
  } else {
    const newAttendees: IUInvitee[] = event.attendees;
    newAttendees.push(attendee);
    // If the attendee doesn't exist, add the new attendee
    return {
      ...event,
      attendees: newAttendees,
    };
  }
};

export const getHostedByString = (event: IUEvent) => {
  const host = event.attendees.find(attendee => attendee.role === TAppElkAttendeeRole.ORGANIZER);
  let hostName = '';
  if (event?.hostedBy !== undefined && event?.hostedBy !== '') {
    hostName = event.hostedBy;
  } else if (host?.name !== undefined && host?.name != '') {
    hostName = host.name;
  }
  // const hostUserId = host?.profile.userId
  if (hostName != '' && hostName != undefined) {
    return 'Hosted by ' + hostName;
  } else if (event.isHost) {
    return 'Hosted by you';
  } else return '';
};

export const generateAttendeeString = (attendees: IUInvitee[], isPast?: boolean, userId?: string, userPhone?: string,
                                       userEmail?: string): string => {

  if (attendees.length === 0) {
    return '';
  }

  const userAttendeeIndex = getAttendeeIndex(attendees, userId, undefined, userPhone, userEmail);
  const userAttendee = userAttendeeIndex === -1 ? undefined : attendees[userAttendeeIndex];

  if (userAttendee?.rsvpStatus === TAppElkAttendeeStatus.YES) {
    if (isPast ?? false) {
      return 'You went';
    } else return 'You are going';
  } else if (userAttendee?.rsvpStatus === TAppElkAttendeeStatus.NO) {
    if (isPast ?? false) {
      return 'You didn\'t make it';
    } else return 'You can\'t make it';
  } else {
    return 'You have not responded';
  }
};

export const getAttendeeIndex = (attendees: IUInvitee[] | undefined, userId: string | undefined,
                                 inviteeUuid: string | undefined, phone: string | undefined,
                                 email: string | undefined) => {
  let index = -1;

  if (attendees !== undefined) {
    // Check for user id match
    if (userId !== undefined) {
      index = attendees.findIndex((attendee) => attendee?.userId === userId);
    }
    if (index === -1) {
      // Check for invitee id match
      index = attendees.findIndex((attendee) => attendee?.inviteeId === inviteeUuid);
    }
    if (index === -1) {
      // Check for phone match
      index = attendees.findIndex((attendee) => attendee.phone && phone && attendee?.phone === phone);
    }
    if (index === -1) {
      // Check for email match
      index = attendees.findIndex((attendee) => attendee.email && email && attendee?.email === email);
    }
  }

  return index;
};

export const hasUserRSVPed = (event: IUEvent, userId: string, inviteeUuid?: string | undefined,
                              phone?: string | undefined, email?: string | undefined) => {
  const inviteeUuid1 = inviteeUuid ?? 'missing';
  const attendees = event.attendees.filter(
    attendee => (attendee.rsvpStatus !== TAppElkAttendeeStatus.SEEN && attendee.rsvpStatus !== TAppElkAttendeeStatus.INVITED));
  const youAttendeeIndex = getAttendeeIndex(attendees, userId, inviteeUuid1, phone, email);
  return youAttendeeIndex !== -1;
};

export const getRSVPedInvitee = (event: IUEvent, userId: string, inviteeUuid?: string | undefined,
                                 phone?: string | undefined, email?: string | undefined) => {
  const inviteeUuid1 = inviteeUuid ?? 'missing';
  const attendees = event.attendees.filter(
    attendee => (attendee.rsvpStatus !== TAppElkAttendeeStatus.SEEN && attendee.rsvpStatus !== TAppElkAttendeeStatus.INVITED));
  const youAttendeeIndex = getAttendeeIndex(attendees, userId, inviteeUuid1, phone, email);
  if (youAttendeeIndex === -1) {
    return undefined;
  } else return attendees[youAttendeeIndex];
};

export const isRSVPVerified = (event: IUEvent, userId: string | undefined, inviteeUuid: string | undefined) => {
  const attendeeUserId = userId ?? 'missing';
  const youAttendee = event.attendees.find(
    (attendee) => ((attendee.userId === attendeeUserId) || (attendee.inviteeId === inviteeUuid ?? 'missing')));
  if (youAttendee) {
    return youAttendee.userId !== undefined;
  } else return false;
};

export const amIUnverified = (attendee: IUInvitee | undefined, inviteeUuid: string | undefined) => {
  const decodedUuid = inviteeUuid ?? 'missing';
  const matchingInviteeUuid = (attendee?.inviteeId === decodedUuid);
  const missingUserId = attendee?.userId === undefined;
  if (matchingInviteeUuid && missingUserId) {
    return true;
  } else return false;
};

export const hasRsvpedUsers = (event?: IUEvent): boolean => {
  if (!event) {
    return false;
  }

  const attendees = event.attendees.filter(attendee =>
    (attendee.rsvpStatus !== TAppElkAttendeeStatus.SEEN && attendee.rsvpStatus !== TAppElkAttendeeStatus.INVITED) &&
    attendee.role !== TAppElkAttendeeRole.ORGANIZER
  );

  return attendees.length > 0;
};

export const isUserHost = (event: IUEvent, userId?: string) => {
  if (userId === undefined) {
    return false;
  }

  const attendees = event.attendees.filter(attendee => attendee.rsvpStatus !== TAppElkAttendeeStatus.SEEN);

  const youAttendeeIndex = attendees.findIndex(
    attendee => attendee.userId === userId
  );

  return youAttendeeIndex !== -1 && attendees[youAttendeeIndex].role === TAppElkAttendeeRole.ORGANIZER;
};

export const getPendingInvitees = (event?: IUEvent): IUInvitee[] => {
  if (event === undefined) {
    return [];
  }

  return (event.attendees.filter((attendee) => {
    return attendee.inviteStatus === TAppElkInviteStatus.PENDING;
  }));
};

export const getInviteeById = (event: IUEvent, inviteeId: string): IUInvitee | undefined => {
  return event.attendees.find((attendee) => attendee.inviteeId === inviteeId);
};
