import React, { ReactNode } from 'react';
import styled from 'styled-components';

import Mail from '../icons/Mail';
import AttendeeCircle from '../attendees/AttendeeCircle';
import { isMutualMember, IUAutoCompleteContact } from '../../util/contact';
import Clock from '../icons/Clock';

import { IUAutoCompleteItem } from 'Common/src/components/AutocompleteDropdown/AutocompleteDropdown';

const InviteAutocompleteRow = ({ value }: { value: IUAutoCompleteItem<IUAutoCompleteContact> }) => {
  const myVal = value.value;
  const mutualMember = isMutualMember(myVal);

  let firstRow: ReactNode;
  let secondRow: ReactNode;
  if (myVal.name && (mutualMember || myVal.email)) {
    firstRow = <ContactName>
      {myVal.name}
    </ContactName>;

    if (mutualMember) {
      secondRow = <ContactEmail>
        <Clock size={14}/>
        <EmailText>
          {myVal.sharedEvent}
        </EmailText>

      </ContactEmail>;
    } else {
      secondRow = <ContactEmail>
        <Mail size={14}/>
        <EmailText>
          {myVal.email}
        </EmailText>
      </ContactEmail>;
    }
  } else {
    firstRow = <ContactName>
      {myVal.email}
    </ContactName>;
  }

  return <ContactRow>
    <ContactPhoto>
      <AttendeeCircle attendee={myVal} small={true} size={35}/>
    </ContactPhoto>
    <div>
      {firstRow}
      {secondRow}
    </div>
  </ContactRow>;
};

const ContactRow = styled.div`
  font-weight: 300;
  display: flex;
  align-items: center;
  gap: 8px;
`;

const ContactPhoto = styled.div`
  width: 35px;
  height: 35px;
`;

const ContactName = styled.div`
  font-size: 16px;
  color: rgba(0, 0, 0, 0.60);
`;

const ContactEmail = styled.div`
  font-size: 12px;
  display: flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.30);
  gap: 4px;
`;

const EmailText = styled.span`
`;

export default InviteAutocompleteRow;
