import styled from 'styled-components';

const WhiteButton = styled.button<{ $noMin?: boolean }>`
  display: flex;
  padding: 6px 12px;
  align-items: center;
  justify-content: center;
  gap: 10px;
  font-size: 16px;
  line-height: 24px;
  font-family: Poppins, sans-serif;
  width: fit-content;
  cursor: pointer;
  ${({ $noMin = false }) => $noMin ? '' : '  min-width: 150px;'}

  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.10);
  color: #000;
  background: #FFF;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.05);

  &:hover:not([disabled]) {
    filter: brightness(95%);
  }

  &[disabled] {
    color: rgba(0, 0, 0, 0.30);
    background: rgba(255, 255, 255, 0.60);
  }
`;

export const WhiteFormButton = styled(WhiteButton)`
  height: 39px;
  padding: 6px 15px;
  min-width: auto;
`;

export default WhiteButton;
