import React from 'react';
import styled from 'styled-components';

import { useEditEventContext } from '../../contexts/EditEventContext';
import { ActionMenuLabel } from '../EventForm/ActionMenuCommon';
import ColorSelect from '../../assets/ColorSelect.png';

const COLORS = [
  '#FF4747',
  '#FF0000',
  '#FF9800',
  '#FFC008',
  '#8BC34B',
  '#009688',
  '#448AFF',
  '#5A36E9',
  '#2B2B2B'
];

const MultiColorPicker = () => {
  const eventContext = useEditEventContext();

  const colors = eventContext.event?.colors;

  const onColorChange = (color: string, key: 'primary' | 'secondary' | 'highlightText' | 'text') => {
    if (eventContext.event?.colors) {
      eventContext.setColors({ ...eventContext.event.colors, [key]: color });
    }
  };

  if (!colors) {
    return null;
  }

  const colorIndex = COLORS.indexOf(colors.primary);

  return <ThemeBox>
    <ActionMenuLabel>Background tint</ActionMenuLabel>
    <ColorBoxes>
      {
        COLORS.map((color, index) => {
          if (colorIndex === index) {
            return <SelectedDotWrapper $color={color} key={color}>
              <ColorDot $color={color}/>
            </SelectedDotWrapper>;
          } else {
            return <DotWrapper key={color}>
              <ColorDot $color={color} onClick={() => onColorChange(color, 'primary')}/>
            </DotWrapper>;
          }
        })
      }
      {
        colorIndex === -1 ?
          <SelectedDotWrapper $color={colors.primary}>
            <ColorSelectLabel htmlFor="custom-color">
              <ColorDot $color={colors.primary}/>
            </ColorSelectLabel>
          </SelectedDotWrapper> : <DotWrapper>
            <ColorSelectLabel htmlFor="custom-color">
              <ColorSelectImg src={ColorSelect}/>
            </ColorSelectLabel>
          </DotWrapper>
      }
    </ColorBoxes>
    <InvisibleInput id="custom-color" type="color" value={colors.primary}
                    onChange={(e) => onColorChange(e.currentTarget.value, 'primary')}/>
  </ThemeBox>;
};


const ThemeBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
`;

const ColorBoxes = styled.div`
  display: flex;
  flex-direction: row;
  gap: 2px;
  align-items: center;
  text-align: center;
  justify-content: space-between;
  width: 100%;
`;

const DotWrapper = styled.div`
  padding: 2px;
  border-radius: 100px;
  border: 2px solid transparent;
`;

const SelectedDotWrapper = styled(DotWrapper)<{ $color: string }>`
  border: 2px solid ${({ $color }) => $color};
`;

const Dot = styled.div`
  display: flex;
  width: 24px;
  height: 24px;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 100px;
  cursor: pointer;
`;

const ColorDot = styled(Dot)<{ $color: string }>`
  background: ${({ $color }) => $color};
`;

const InvisibleInput = styled.input`
  height: 0;
  width: 0;
  border: 0;
  opacity: 0;
  padding: 0;
`;

const ColorSelectLabel = styled.label`
  display: flex;
`;

const ColorSelectImg = styled.img`
  width: 24px;
  height: 24px;
`;

export default MultiColorPicker;
