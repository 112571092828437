import React, { ReactNode } from 'react';
import styled from 'styled-components';

import { IUEvent } from '../../lib/event';
import { EventDetails, EventDetailsCell, EventDetailsIconWrapper } from '../../components/EventForm/Common';
import LocationMarker from '../../components/icons/LocationMarker';
import AddToCal from '../../components/icons/AddToCal';
import UserCircle from '../../components/icons/UserCircle';
import { convertTimestampToDateTimeString, generateSingleTimeString, isMultidayEvent } from '../../lib/time';
import { getAddressString } from '../../lib/location';

const EventDetailsModule = ({ event }: { event: IUEvent }) => {
  const address = event.location.address ? getAddressString(event.location.address) : event.location.displayName;

  let fullDateTime: ReactNode;
  if (isMultidayEvent(event)) {
    fullDateTime = <div>{convertTimestampToDateTimeString(event.startTime, event.timeZone, true,
      false)} - {convertTimestampToDateTimeString(
      event.endTime, event.timeZone, true, false)}</div>;
  } else if (event.endTime) {
    fullDateTime =
      <div>{convertTimestampToDateTimeString(event.startTime, event.timeZone, true, false)} - {generateSingleTimeString(
        event.endTime, event.timeZone)}</div>;
  } else {
    fullDateTime = <div>{convertTimestampToDateTimeString(event.startTime, event.timeZone, true, false)}</div>;
  }

  return <EventDetails>
    <EventDetailsCell>
      <EventDetailsIconWrapper>
        <LocationMarker/>
      </EventDetailsIconWrapper>
    </EventDetailsCell>
    <EventDetailsCell>
      <LocationLink
        href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(address)}`}
        target="_blank" rel="noreferrer noopener"
      >
        {address}
      </LocationLink>
    </EventDetailsCell>

    <EventDetailsCell>
      <EventDetailsIconWrapper>
        <AddToCal/>
      </EventDetailsIconWrapper>
    </EventDetailsCell>
    <EventDetailsCell>
      {fullDateTime}
    </EventDetailsCell>

    <EventDetailsCell>
      <EventDetailsIconWrapper>
        <UserCircle/>
      </EventDetailsIconWrapper>
    </EventDetailsCell>
    <EventDetailsCell>
      Hosted by {event.hostedBy}
    </EventDetailsCell>
  </EventDetails>;
};

const LocationLink = styled.a`
  text-decoration: none;
  color: currentColor;

  &:visited {
    color: currentColor;
  }
`;

export default EventDetailsModule;
