import React, { ReactNode, useEffect, useState } from 'react';
import styled from 'styled-components';

import { formatShortStatus } from '../../util/attendee';
import PencilAlt from '../icons/PencilAlt';
import DeleteIcon from '../icons/DeleteIcon';
import { IUEvent, IUEventQuestion, IUInvitee } from '../../lib/event';
import HeaderDropdown, { HeaderDropdownItem } from './HeaderDropdown';
import Download from '../icons/Download';
import { tAppElkEventAnswerValueToString } from '../../lib/answer';
import SystemButton from '../buttons/SystemButton';

import { DeviceQueries, VerticalFullContainer } from 'Common/src/components/styled';

import { TAppElkAttendeeRole, TAppElkAttendeeStatus, TAppElkInviteStatus } from 'TProtocol/prototypes/events/messages';

interface Props {
  event?: IUEvent;
  invites: IUInvitee[];
  onChange: (invite: IUInvitee) => void;
  onDelete: (invite: IUInvitee) => void;
  onEdit: (inviteId: string) => void;
  onSend: (inviteId: string) => void;
  onSkip: () => void;
  onDownload: () => void;
  questions: IUEventQuestion[];
  sending: boolean;
  fromCreate: boolean;
}

const InviteTable = ({
                       invites,
                       onDelete,
                       onEdit,
                       onSend,
                       onSkip,
                       onDownload,
                       questions,
                       event,
                       fromCreate
                     }: Props) => {
  const [questionUuid, setQuestionUuid] = useState<string>();

  const activeQuestion = event?.questions.find((q) => q.questionUuid === questionUuid);

  useEffect(() => {
    if (questionUuid === undefined && (event?.questions.length ?? 0) > 0) {
      setQuestionUuid(event?.questions[0].questionUuid);
    }
  }, [event?.questions]);

  const questionDropdownItems: HeaderDropdownItem[] = questions.map(
    (question) => {
      return {
        value: question.questionUuid,
        display: question.question
      };
    }
  );

  return <TableContainer>
    <Table $hasQuestions={questions.length > 0} $hasRows={invites.length > 0}>
      <TableRow>
        <TableHeader/>
        <TableHeader>Name</TableHeader>
        <TableDesktopHeader>Contact</TableDesktopHeader>
        {activeQuestion !== undefined ? <TableDesktopHeader>
          {questions.length > 1 ? <HeaderDropdown
            value={activeQuestion.questionUuid}
            items={questionDropdownItems}
            onSelect={(questionUuid) => setQuestionUuid(questionUuid)}
          /> : activeQuestion.question}
        </TableDesktopHeader> : null}
        <TableHeader>
          <DownloadContainer onClick={onDownload}>
            <Download color="#000"/>
          </DownloadContainer>
        </TableHeader>
      </TableRow>
      {
        invites.length > 0 ? invites.map((invite) => {
          const editable = invite.rsvpStatus === TAppElkAttendeeStatus.INVITED ||
            invite.rsvpStatus === TAppElkAttendeeStatus.SEEN;
          const sendable = invite.rsvpStatus === TAppElkAttendeeStatus.INVITED &&
            invite.inviteStatus === TAppElkInviteStatus.PENDING;
          const isHost = invite.role === TAppElkAttendeeRole.ORGANIZER;

          let contact: ReactNode;
          if (isHost) {
            contact = <>&mdash;</>;
          } else if (invite.userId !== undefined) {
            contact = <ConnectedThrough>Connected through Shine</ConnectedThrough>;
          } else {
            contact = invite.email || <>&mdash;</>;
          }
          if (invite.inviteStatus === TAppElkInviteStatus.FAILED) {
            contact = <FailedContact>
              {contact}
              <EditEmailPrompt onClick={() => onEdit(invite.inviteeId)}>Edit email and resend</EditEmailPrompt>
            </FailedContact>;
          }

          const answer = invite.answers?.find(answer => answer.questionUuid === activeQuestion?.questionUuid);

          return <TableRow key={invite.inviteeId} $hasFooter={invites.length <= 1}>
            <TableCell data-first="true" $hasFooter={invites.length <= 1}>
              {
                sendable ? <ActionStatusPill onClick={() => onSend(invite.inviteeId)}>
                  {formatShortStatus(invite)}
                </ActionStatusPill> : <StatusPill $failed={invite.inviteStatus === TAppElkInviteStatus.FAILED}>
                  {formatShortStatus(invite)}
                </StatusPill>
              }
            </TableCell>
            <TableCell $hasFooter={invites.length <= 1}>
              {invite.name || <>&mdash;</>}
            </TableCell>
            <TableDesktopCell $hasFooter={invites.length <= 1}>
              {contact}
            </TableDesktopCell>
            {
              activeQuestion !== undefined && <TableDesktopCell $hasFooter={invites.length <= 1}>
                {(answer && tAppElkEventAnswerValueToString(answer.answerValue)) ?? <>&mdash;</>}
              </TableDesktopCell>
            }
            <TableCell $hasFooter={invites.length <= 1}>
              {
                <IconsContainer>
                  {editable ? <ActionIconContainer onClick={() => onEdit(invite.inviteeId)}>
                    <PencilAlt opacity={0.25}/>
                  </ActionIconContainer> : null}
                  {!isHost ? <ActionIconContainer onClick={() => onDelete(invite)}>
                    <DeleteIcon/>
                  </ActionIconContainer> : null}
                </IconsContainer>
              }
            </TableCell>
          </TableRow>;
        }) : null
      }
    </Table>
    {invites.length <= 1 ?
      <EmptyTable>
        <div>You have not invited anyone yet.</div>
        {
          fromCreate ? <div>
            <SystemButton onClick={onSkip}>
              Skip and view invite
            </SystemButton>
          </div> : null
        }
      </EmptyTable> : null}
  </TableContainer>;
};

const TableContainer = styled(VerticalFullContainer)`
`;

const Table = styled.div<{
  $hasQuestions: boolean,
  $hasRows: boolean
}>`
  display: grid;
  grid-template-columns: max-content repeat(${({ $hasQuestions }) => $hasQuestions ? '2' : '1'}, minmax(20%, max-content)) 1fr max-content;
  overflow-y: scroll;
  max-height: 100%;

  @media (${DeviceQueries.mobile}) {
    grid-template-columns: max-content 1fr max-content;
  }
`;

const TableHeader = styled.div`
  padding: 10px;
  font-size: 14px;
  font-weight: 400;
  background: #FFF;
  border-top: 1px solid #E6E6E6;
  border-bottom: 1px solid #E6E6E6;
  color: rgba(0, 0, 0, 0.60);
  //box-shadow: 0 1px 4px 0 rgba(148, 148, 148, 0.10);
  text-transform: uppercase;
  position: sticky;
  top: 0;
  display: flex;
  align-items: center;
  height: 33px;
  box-sizing: border-box;

  &:first-child {
    border-left: 1px solid #E6E6E6;
    border-top-left-radius: 5px;
  }

  &:last-child {
    border-right: 1px solid #E6E6E6;
    border-top-right-radius: 5px;
  }
`;

const TableDesktopHeader = styled(TableHeader)`
  @media (${DeviceQueries.mobile}) {
    display: none;
  }
`;

const TableCell = styled.div<{ $hasFooter?: boolean }>`
  padding: 10px 16px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow-wrap: anywhere;
  ${({ $hasFooter }) => $hasFooter ? '' : 'border-bottom: 1px solid #E6E6E6;'}
  background: rgba(255, 255, 255, 0.60);

  &:first-child {
    border-left: 1px solid #E6E6E6;
  }

  &:last-child {
    border-right: 1px solid #E6E6E6;
  }

  @media (${DeviceQueries.mobile}) {
    font-size: 14px;
    height: 46px;
  }
`;

const TableDesktopCell = styled(TableCell)`
  @media (${DeviceQueries.mobile}) {
    display: none;
  }
`;

const IconsContainer = styled.span`
  display: flex;
  gap: 8px;
  align-items: center;
  width: 100%;
`;

const TableRow = styled.div<{
  $hasFooter?: boolean;
}>`
  display: contents;

  ${({ $hasFooter = false }) => $hasFooter ? '' : `
  &:last-child {
    ${TableCell}[data-first="true"] {
      border-bottom-left-radius: 6px;
    }

    ${TableCell}:last-child {
      border-bottom-right-radius: 6px;
    }
  }
  `}
`;

const EmptyTable = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 10px;
  align-items: center;
  justify-content: center;
  border: 1px solid #e6e6e6;
  border-radius: 0 0 6px 6px;
`;

const ActionIconContainer = styled.span`
  cursor: pointer;
  width: 100%;
  text-align: right;
`;

const StatusPill = styled.div<{
  $failed?: boolean
}>`
  box-sizing: border-box;
  display: flex;
  width: 65px;
  padding: 4px 8px;
  justify-content: center;
  text-align: center;
  align-items: center;
  gap: 3px;
  flex-shrink: 0;
  font-size: 14px;
  font-weight: 600;
  color: ${({ $failed = false }) => $failed ? 'red' : 'rgba(0, 0, 0, 0.80)'};

  border-radius: 100px;
  border: 1px solid ${({ $failed = false }) => $failed ? 'red' : 'rgba(0, 0, 0, 0.20)'};
  background: ${({ $failed = false }) => $failed ? 'rgba(255, 0, 0, 0.05)' : '#FFFFFF'};
`;

const ActionStatusPill = styled(StatusPill)`
  background: #FF6422;
  color: #FFF;
  cursor: pointer;
  font-size: 13px;
  border: none;
  line-height: 16px;
`;

const DownloadContainer = styled.div`
  text-align: right;
  padding: 0 6px;
  cursor: pointer;

  width: 33px;
  height: 33px;
  display: flex;
  align-items: center;
`;

const ConnectedThrough = styled.span`
  color: rgba(0, 0, 0, 0.30);
`;

const FailedContact = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

const EditEmailPrompt = styled.div`
  font-size: 12px;
  color: #FF6422;
  cursor: pointer;
`;

export default InviteTable;
