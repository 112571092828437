import React, { lazy, Suspense } from 'react';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Outlet,
  Route,
  RouterProvider
} from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';

import RequireLogin from './RequireLogin';
import RootPage from '../pages/RootPage';
import EventsPage from '../pages/Events/EventsPage';
import EventPage from '../pages/Event/EventPage';
import WelcomePage from '../pages/WelcomePage';
import CreateEventAction from '../pages/CreateEventAction';
import UpdateAttendanceEventAction from '../pages/UpdateAttendanceEventAction';
import MainLayout from '../layout/MainLayout';
import ProfileEditPage from '../pages/ProfileEditPage';
import UpdateAttendanceThroughEmailAction from '../pages/UpdateAttendanceThroughEmailAction';
import UpdateAttendeeUserAction from '../pages/UpdateAttendeeUserAction';
import PaymentSuccessAction from '../pages/PaymentSuccessAction';
import InspirationGalleryPage from '../pages/InspirationGalleryPage';
import { UserContextProvider } from '../contexts/UserContext';
import { EditEventContextProvider } from '../contexts/EditEventContext';
import { EventCacheContextProvider } from '../contexts/EventCacheContext';
import { BackgroundContextProvider } from '../contexts/BackgroundContext';
import { ModalContextProvider } from '../contexts/ModalContext';
import { VersionCheckContextProvider } from '../contexts/VersionCheckContext';
import { ButterBarContextProvider } from '../contexts/ButterBarContext';
import { ToastContextProvider } from '../contexts/ToastContext';
import { CoinContextProvider } from '../contexts/CoinContext';
// import { CoinModalContextProvider } from '../contexts/CoinModalContext';
import { AutoSaveContextProvider } from '../contexts/AutoSaveContext';
import GlobalStyle from '../components/styled/Global';
import Performance from '../components/Performance';
import HostRedirect from '../components/HostRedirect';
import PhotosUserTextPermissionCheck from '../components/PhotosUserTextPermissionCheck';
import EventRoPage from '../pages/Event/EventRoPage';
import OAuthPage from '../pages/OAuthPage';
import UpdateAttendanceUnauthEventAction from '../pages/UpdateAttendanceUnauthEventAction';
import DraftPage from '../pages/Event/DraftPage';
import CreateDraftAction from '../pages/CreateDraftAction';

import './App.css';

const EditEventPage = lazy(() => import(
  /* webpackChunkName: "editEvent" */
  /* webpackPrefetch: true */
  '../pages/EditEventPage'
  ));

const InvitePage = lazy(() => import(
  /* webpackChunkName: "editEvent" */
  /* webpackPrefetch: true */
  '../pages/InvitePage'
  ));

const CreateEventPage = lazy(() => import(
  /* webpackChunkName: "editEvent" */
  /* webpackPrefetch: true */
  '../pages/Create/CreateEventPage'
  ));

const QuickCreatePage = lazy(() => import(
  /* webpackChunkName: "editEvent" */
  /* webpackPrefetch: true */
  '../pages/Create/QuickCreatePage'
  ));

const LoginPage = lazy(() => import(
  /* webpackChunkName: "login" */
  /* webpackPrefetch: true */
  '../pages/Login/LoginPage'
  ));

/*
 * When adding a new dynamic route (a route with a variable in it) make sure to update LoggingContext so
 * Analytics page views for that page can be aggregated.
 */

function App() {

  const ContextStack = () => <>
    <GlobalStyle/>
    <HostRedirect/>
    <QueryParamProvider adapter={ReactRouter6Adapter} options={{ enableBatching: true }}>
      <UserContextProvider>
        <BackgroundContextProvider>
          <CoinContextProvider>
            <Performance/>
            <PhotosUserTextPermissionCheck/>
            <AutoSaveContextProvider>
              <EventCacheContextProvider>
                <EditEventContextProvider>
                  <ButterBarContextProvider>
                    <ToastContextProvider>
                      <ModalContextProvider>
                        {/*<CoinModalContextProvider>*/}
                        <VersionCheckContextProvider>
                          <Outlet/>
                        </VersionCheckContextProvider>
                        {/*</CoinModalContextProvider>*/}
                      </ModalContextProvider>
                    </ToastContextProvider>
                  </ButterBarContextProvider>
                </EditEventContextProvider>
              </EventCacheContextProvider>
            </AutoSaveContextProvider>
          </CoinContextProvider>
        </BackgroundContextProvider>
      </UserContextProvider>
    </QueryParamProvider>
  </>;

  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route element={<ContextStack/>}>
        <Route element={<MainLayout/>}>
          <Route path="/login" element={<Suspense fallback={<div/>}><LoginPage/></Suspense>}/>
          <Route path="/welcome" element={<WelcomePage/>}/>
          <Route path="/gallery" element={<InspirationGalleryPage/>}/>

          <Route path="/profile/edit" element={<RequireLogin><ProfileEditPage/></RequireLogin>}/>
          <Route path="/event/create/ai" element={<Suspense fallback={<div></div>}><QuickCreatePage/></Suspense>}/>

          <Route path="/event/create/action/:encodedTitle" element={<RequireLogin><CreateEventAction/></RequireLogin>}/>
          <Route path="/event/create/from/:baseEventId"
                 element={<Suspense fallback={<div></div>}><RequireLogin><CreateEventPage/></RequireLogin></Suspense>}/>
          <Route path="/event/create/fromGallery/:baseInspirationId"
                 element={<Suspense fallback={<div></div>}><CreateEventPage/></Suspense>}/>
          <Route path="/event/create" element={<Suspense fallback={<div></div>}><CreateEventPage/></Suspense>}/>

          <Route path="/event/draft/:draftId"
                 element={<Suspense fallback={<div></div>}><RequireLogin><DraftPage/></RequireLogin></Suspense>}/>
          <Route path="/event/draft/edit/:draftId"
                 element={<Suspense fallback={<div></div>}><RequireLogin><CreateEventPage/></RequireLogin></Suspense>}/>
          <Route path="/event/draft/create/action" element={<RequireLogin><CreateDraftAction/></RequireLogin>}/>

          <Route path="/event/edit/:eventId"
                 element={<Suspense fallback={<div></div>}><RequireLogin><EditEventPage/></RequireLogin></Suspense>}/>
          <Route path="/event/invite/:eventId" element={<Suspense fallback={<div></div>}><InvitePage/></Suspense>}/>
          <Route path="/event/:eventIdParam" element={<EventPage/>}/>
          <Route path="/event/:eventId/ro" element={<EventRoPage/>}/>

          <Route path="/event/rsvp/action/:eventId"
                 element={<RequireLogin><UpdateAttendanceEventAction/></RequireLogin>}/>
          <Route path="/event/updateemail/action/:eventId" element={<UpdateAttendanceThroughEmailAction/>}/>
          <Route path="/event/updateuser/action/:eventId"
                 element={<RequireLogin><UpdateAttendeeUserAction/></RequireLogin>}/>
          <Route path="/event/unauthrsvp/action/:eventId" element={<UpdateAttendanceUnauthEventAction/>}/>

          <Route path="/stripeConfirm" element={<PaymentSuccessAction/>}/>

          <Route path="/oauth/:authType" element={<OAuthPage/>}/>

          <Route path="/events" element={<RequireLogin><EventsPage/></RequireLogin>}/>
          <Route path="/" element={<RootPage/>}/>
          <Route path="*" element={<Navigate to="/" replace/>}/>
        </Route>
      </Route>
    )
  );

  return <RouterProvider router={router}/>;
}

export default App;
