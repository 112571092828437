
declare const WEBSERVER_URL: string;
declare const WEBSERVER_PORT: number;
declare const WEBSERVER_HTTPS: boolean;

export interface EventPreview {
  title: string,
  description: string,
  url: string,
  imagePreviewUrl: string
}

export const getEventPreview = async (eventId: string) => {
  try {
    const response = await fetch(
      `http${WEBSERVER_HTTPS ? 's' : ''}://${WEBSERVER_URL}:${WEBSERVER_PORT}/AnnotatedHangout/GetMessagePreview/${eventId}`);

    return await response.json() as EventPreview;
  } catch (e) {
    return undefined;
  }
};

export const getHangoutEmailTemplate = async (eventId: string) => {
  try {
    const response = await fetch(
      `http${WEBSERVER_HTTPS ? 's' : ''}://${WEBSERVER_URL}:${WEBSERVER_PORT}/AnnotatedHangout/GetHangoutEmailTemplate/${eventId}`);

    return await response.text();
  } catch (e) {
    return undefined;
  }
};

