import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useUserContext } from '../../contexts/UserContext';
import Modal from './Modal';
import { optOut } from '../../api/UserServiceApi';

import { logSumoEvent, ULogApplication, ULogSeverity, ULogTag } from 'Common/src/api/SumoLogicApi';

const OptOutModal = ({ close }: { close: () => void }) => {
  const navigate = useNavigate();
  const userContext = useUserContext();
  const [submitting, setSubmitting] = useState(false);

  const handleOptOut = async () => {
    void logSumoEvent({
      app: ULogApplication.ELK,
      severity: ULogSeverity.INFO,
      userId: userContext.id,
      tag: ULogTag.UserAction,
      message: '[OptOutModal] Opted out'
    });

    setSubmitting(true);
    await optOut(userContext.getAuthHeader(true));
    await userContext.logout();
    navigate('/');
    close();
  };

  return <Modal
    title="Permanently Leave Shine"
    subtitle="Are you sure you want to leave? Your data will be deleted, this cannot be undone."
    secondaryButtonLabel="Cancel"
    onSecondaryClick={close}
    primaryButtonLabel="Leave"
    primaryButtonDisabled={submitting}
    onPrimaryClick={handleOptOut}
    onClickOutside={close}
    useSystemColors={true}
  />;
};

export default OptOutModal;

