enum Season {
  Winter = 1,
  Spring,
  Summer,
  Autumn
}

export const calculateSeason = (date: Date): string => {
  let season: Season;
  switch (date.getMonth()) {
    case 0:
    case 1:
      season = Season.Winter;
      break;
    case 2:
      if (date.getDate() < 20) {
        season = Season.Winter;
      } else {
        season = Season.Spring;
      }
      break;
    case 3:
    case 4:
      season = Season.Spring;
      break;
    case 5:
      if (date.getDate() < 21) {
        season = Season.Spring;
      } else {
        season = Season.Summer;
      }
      break;
    case 6:
    case 7:
      season = Season.Summer;
      break;
    case 8:
      if (date.getDate() < 23) {
        season = Season.Summer;
      } else {
        season = Season.Autumn;
      }
      break;
    case 9:
    case 10:
      season = Season.Autumn;
      break;
    case 11:
      if (date.getDate() < 21) {
        season = Season.Autumn;
      } else {
        season = Season.Winter;
      }
      break;
    default:
      season = Season.Spring;
  }

  // if ((location?.coordinate?.lat ?? 45) <= 0) {
  //   season = (season + 2) % 4;
  // }

  return {
    [Season.Spring]: 'Spring',
    [Season.Summer]: 'Summer',
    [Season.Autumn]: 'Autumn',
    [Season.Winter]: 'Winter'
  }[season];
};
