import React from 'react';

const PhotographOutline = ({ color }: {
  color?: string
}) => {
  return <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="PhotographOutline">
      <path id="Vector"
            d="M4 16.5L8.586 11.914C8.96106 11.5391 9.46967 11.3284 10 11.3284C10.5303 11.3284 11.0389 11.5391 11.414 11.914L16 16.5M14 14.5L15.586 12.914C15.9611 12.5391 16.4697 12.3284 17 12.3284C17.5303 12.3284 18.0389 12.5391 18.414 12.914L20 14.5M14 8.5H14.01M6 20.5H18C18.5304 20.5 19.0391 20.2893 19.4142 19.9142C19.7893 19.5391 20 19.0304 20 18.5V6.5C20 5.96957 19.7893 5.46086 19.4142 5.08579C19.0391 4.71071 18.5304 4.5 18 4.5H6C5.46957 4.5 4.96086 4.71071 4.58579 5.08579C4.21071 5.46086 4 5.96957 4 6.5V18.5C4 19.0304 4.21071 19.5391 4.58579 19.9142C4.96086 20.2893 5.46957 20.5 6 20.5Z"
            stroke={color} strokeOpacity="0.7" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </g>
  </svg>;

};

export default PhotographOutline;
