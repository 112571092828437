import { TAppElkImageStyle } from 'TProtocol/prototypes/events/messages';

export const StyleLabels: {
  [key in TAppElkImageStyle]: string;
} = {
  [TAppElkImageStyle.ABSTRACT_ART]: 'Abstract Art',
  [TAppElkImageStyle.BAROQUE]: 'Baroque',
  [TAppElkImageStyle.CLASSICAL]: 'Classical',
  [TAppElkImageStyle.CUBIST]: 'Cubist',
  [TAppElkImageStyle.FAIRY_TALE]: 'Fairy Tale',
  [TAppElkImageStyle.FANTASY]: 'Fantasy',
  [TAppElkImageStyle.GOTHIC]: 'Gothic',
  [TAppElkImageStyle.NOIR]: 'Noir',
  [TAppElkImageStyle.PAINTERLY]: 'Painterly',
  [TAppElkImageStyle.POP_ART]: 'Pop Art',
  [TAppElkImageStyle.RENAISSANCE]: 'Renaissance',
  [TAppElkImageStyle.SOPHISTICATED]: 'Sophisticated',
  [TAppElkImageStyle.STREET_ART]: 'Street Art',
  [TAppElkImageStyle.VAN_GOGH]: 'Van Gogh',
  [TAppElkImageStyle.DEFAULT]: 'None'
};
