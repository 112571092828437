import React, { createContext, PropsWithChildren, useContext, useEffect, useReducer, useRef, useState } from 'react';
import { NavigateOptions, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useCookies, withCookies } from 'react-cookie';
import {
  AuthCredential,
  getAuth,
  getRedirectResult,
  GoogleAuthProvider,
  OAuthProvider,
  signInWithCredential,
  signInWithCustomToken,
  signInWithRedirect,
  User,
  UserCredential
} from 'firebase/auth';
import { FirebaseError } from 'firebase/app';
import { v4 as uuidv4 } from 'uuid';
import { Thrift } from 'thrift';

import { getCurrentRequestId } from '../lib/performance';
import {
  createElkUser,
  fetchContacts,
  fetchMutualMembers,
  getElkUser,
  getEventImageHistory,
  getHasElkProfile
} from '../api/ElkEventService';
import { app, auth } from '../core/libs/Firebase';
import { CodeType, loginUrl } from '../lib/login';
import { ULoginState, ULoginStatus, UserReducer } from '../state/reducers/user';

import useLocalStorage from 'Common/src/hooks/useLocalStorage';
import { logSumoEvent, stringifyError, ULogApplication, ULogSeverity, ULogTag } from 'Common/src/api/SumoLogicApi';
import { promiseTimeout, TimeoutError } from 'Common/src/utils/PromiseTimeout';
import {
  authenticate,
  refetchAccessToken,
  sendEmailSignInCode,
  sendPhoneSignInCode,
  signInWithEmailAndCode,
  signInWithPhoneAndCode
} from 'Common/src/api/AuthenticationApi';
import { NetworkErrorException, RateLimitException } from 'Common/src/lib/ThriftServiceAugmentor';

import { TAppProduct, TAuthHeader, TUserAgent } from 'TProtocol/common/models';
import {
  TAppElkMutualMember,
  TAppElkPhotoUploadMode,
  TAppElkTextPermissionStatus,
  TAppElkUser,
  TElkGetImageHistoryResponse
} from 'TProtocol/prototypes/events/messages';
import { TAppContactLite } from 'TProtocol/contactlite/messages';
import { TAuthFailed, TInvalidRequest } from 'TProtocol/common/exceptions';
import { TSignInWithPhoneAndCodeResponse } from 'TProtocol/authentication/messages';
import TApplicationException = Thrift.TApplicationException;

export const ELK_REDIRECT_AUTH_SS_PROVIDER_KEY = 'elk.redirect.auth.provider.name';

export interface IUUserContext {
  login: () => void;
  loginState: ULoginState;
  userCredential?: UserCredential;
  error?: string;
  id?: string;
  token?: string;
  photoUrl?: string;
  getEmail: () => string | undefined;
  emails?: string[];
  name: string;
  phoneNumber: string;
  hasNudgedToAllowTexts: boolean;
  accountExistsForPhone?: boolean;
  askForTextPermission: boolean;
  grantedTextPermission?: boolean;
  deviceId: string;
  contacts?: TAppContactLite[];
  mutualMembers?: TAppElkMutualMember[];
  setAskForTextPermission: (askForTextPermission: boolean) => void;
  isLoggedIn: () => boolean;
  confirmCode: (code: string, codeType: CodeType) => Promise<void>;
  loginToGoogle: () => Promise<void>;
  loginToApple: () => Promise<void>;
  restartLogin: () => void;
  handleLogin: (firebaseUser: User, goTo?: string) => Promise<void>;
  continueLogin: () => Promise<void>;
  finishLogin: (goTo?: string, options?: NavigateOptions) => void;
  submitPhone: (simplePhone: string, force?: boolean) => Promise<void>;
  submitEmail: (email: string, force?: boolean) => Promise<void>;
  getAuthHeader: (isBlocking?: boolean) => TAuthHeader;
  logout: () => Promise<void>;
  setPhoneNumber: (phone?: string) => void;
  setName: (name: string) => void;
  setPhotoUrl: (photoUrl?: string) => void;
  createUser: (name: string, grantedTextPermissions: boolean, coinsSpent: number) => Promise<void>;
  generateElkUser: (partialUser?: Partial<TAppElkUser>) => TAppElkUser | undefined;
  setHasNudgedToAllowTexts: (nudged: boolean) => void;
  setGrantedTextPermissions: (permissionGranted: boolean) => void;
  setEmail: (emailAddress?: string) => void;
  fetchEventImageHistory: () => Promise<TElkGetImageHistoryResponse>;
  defaultUploadMode?: TAppElkPhotoUploadMode;
}

export interface UUserInputs {
  name: string;
  grantedTextPermissions: boolean;
  coinsSpent: number;
}

const sixtyDays = 60 * 60 * 24 * 60;

const UserContext = createContext<IUUserContext | null>(null);

export const UserContextProvider = withCookies((props: PropsWithChildren<object>) => {
  const [cookies, setCookie, deleteCookie] = useCookies<'userId' | 'authToken', {
    userId?: string,
    authToken?: string
  }>(
    ['userId', 'authToken']
  );
  const [deviceId, setDeviceId] = useLocalStorage<string>('deviceId', '');
  const navigate = useNavigate();
  const location = useLocation();
  const [queryParams] = useSearchParams();

  const queryUserId = queryParams.get('userId');
  const queryFirebaseToken = queryParams.get('refreshToken');

  const getUserRequest = useRef<Promise<void>>();
  const [error, setError] = useState<string>();
  const [userCredential, setUserCredential] = useState<UserCredential>();
  // const [email, setEmail] = useState<undefined | string>(undefined);
  const [accountExists, setAccountExists] = useState<boolean | undefined>();
  const [askForTextPermission, setAskForTextPermission] = useState(false);
  const [hasNudgedToAllowTexts, setHasNudgedToAllowTexts] = useState<boolean>(false);
  const [goToAfter, setGoToAfter] = useState<string | null>(null);
  const [navigateOptionsAfter, setNavigateOptionsAfter] = useState<NavigateOptions>();
  const [eventImageHistory, setEventImageHistory] = useState<TElkGetImageHistoryResponse>();

  const [contacts, setContacts] = useState<TAppContactLite[] | undefined>(undefined);
  const [contactsPageToken, setContactsPageToken] = useState<string | undefined>(undefined);

  const [mutualMembers, setMutualMembers] = useState<TAppElkMutualMember[] | undefined>(undefined);
  const [mutualMembersPageToken, setMutualMembersPageToken] = useState<string | undefined>(undefined);

  const contactsActive = useRef(true);
  const mutualMembersActive = useRef(true);

  const [user, dispatch] = useReducer(UserReducer, {
    id: cookies.userId,
    authToken: cookies.authToken ?? '',
    name: undefined,
    phoneNumber: '',
    loginState: {
      status: (cookies.userId !== undefined && cookies.authToken !== undefined) ? ULoginStatus.LoggedIn : ULoginStatus.NotLoggedIn
    }
  });

  const userCreateActive = useRef(false);

  const loginState: ULoginState = user.loginState ?? {
    status: ULoginStatus.NotLoggedIn
  };

  useEffect(() => {
    if (cookies.userId && getUserRequest.current === undefined) {
      getUserRequest.current = fetchAndSaveUser();
    }
  }, [cookies.userId]);

  useEffect(() => {
    if (isLoggedIn() && goToAfter) {
      navigate(goToAfter, navigateOptionsAfter);
      setGoToAfter(null);
      setNavigateOptionsAfter(undefined);
    }
  }, [goToAfter, user.loginState.status]);

  useEffect(() => {
    if (queryUserId !== null && queryFirebaseToken !== null) {
      try {
        void loginFromQueryString(queryUserId, atob(queryFirebaseToken));
      } catch (e) {
        void logSumoEvent({
          app: ULogApplication.ELK,
          severity: ULogSeverity.INFO,
          userId: user.id,
          tag: ULogTag.Network,
          message: `[UserContext] useEffect(queryFirebaseToken) - Error decoding firebase token "${queryFirebaseToken}": ${stringifyError(
            e)}`
        });

      }
    }
  }, [queryFirebaseToken]);

  useEffect(() => {
    if (loginState.status === ULoginStatus.WaitingForAccountCheck) {
      if (accountExists === true) {
        logEvent(ULogSeverity.INFO, ULogTag.UserAuth, 'Waited for account check, account exists.');
        handleLogin();
      } else if (accountExists === false) {
        logEvent(ULogSeverity.INFO, ULogTag.UserAuth,
          'Waited for account check, account does not exist. Asking for name.');
        dispatch({ type: 'setLoginStatus', status: ULoginStatus.AskingForName });
      } else {
        logEvent(ULogSeverity.WARN, ULogTag.UserAuth, 'Waited for account check, check still undefined.');
        void checkForAccount();
      }
    } else if (loginState.status === ULoginStatus.SignedInToFirebase) {
      logEvent(ULogSeverity.INFO, ULogTag.UserAuth, 'Signed in to Firebase, continuing login.');
      void continueLogin();
    }
  }, [loginState.status, accountExists]);

  useEffect(() => {
    void fetchContactsPage();
  }, [contactsPageToken]);

  useEffect(() => {
    void fetchMutualMembersPage();
  }, [mutualMembersPageToken]);

  const logEvent = (severity: ULogSeverity, tag: ULogTag, message: string) => {
    void logSumoEvent({
      app: ULogApplication.ELK,
      userId: user?.id,
      severity,
      tag,
      message: `[UserContext] ${message}`
    });
  };

  const getAuthHeader = (isBlocking?: boolean): TAuthHeader => {
    if (user?.id !== undefined && user?.authToken !== undefined) {
      return new TAuthHeader({
        userId: user.id,
        accessToken: user.authToken,
        timestamp: Math.floor(new Date().getTime() / 1000),
        deviceId,
        agent: new TUserAgent({
          platform: 'web',
          version: '0.0.0.2',
          // deviceVersion: detectBrowser(),
          // osVersion: detectOS(),
          product: TAppProduct.ELK
        }),
        requestId: `${isBlocking === false ? 'nonblocking-' : 'blocking-'}${getCurrentRequestId()}`
      });
    }

    logEvent(ULogSeverity.ERROR, ULogTag.UserAuth,
      `AuthHeader not available if user is not logged in: ${new Error().stack}`);

    throw new Error('AuthHeader not available if user is not logged in.');
  };

  const isLoggedIn = () => {
    return loginState.status === ULoginStatus.LoggedIn;
  };

  const login = () => {
    navigate(loginUrl(location.pathname));
  };


  const createUser = async (name: string, grantedTextPermissions: boolean, coinsSpent: number) => {
    if (!userCreateActive.current) {
      logEvent(ULogSeverity.INFO, ULogTag.UserAuth, 'createUser called.');
      dispatch({ type: 'nameSubmitting', name });

      userCreateActive.current = true;
      try {

        const tAppElkUser = new TAppElkUser({
          userId: user.id ?? '',
          name: name,
          phoneNumber: user.phoneNumber ?? '',
          grantedTextPermission: grantedTextPermissions,
          textPermissionStatus: grantedTextPermissions ? TAppElkTextPermissionStatus.GRANTED : TAppElkTextPermissionStatus.UNDEFINED,
        });

        dispatch({ type: 'setLoginStatus', status: ULoginStatus.CreatingUser });

        await createElkUser(
          getAuthHeader(),
          tAppElkUser,
          coinsSpent
        );

        saveUser(tAppElkUser, undefined, ULoginStatus.UserCreated);
      } catch (e) {
        logEvent(ULogSeverity.ERROR, ULogTag.Network, `Error during createElkUser: ${stringifyError(e)}`);
        dispatch({
          type: 'setLoginStatus',
          status: accountExists === false ? ULoginStatus.AskingForName : ULoginStatus.WaitingForAccountCheck,
          error: 'An error occurred trying to create your user, please try again. If this error persists, please contact us at support@sunshine.com.'
        });
      }
      userCreateActive.current = false;
    } else {
      logEvent(ULogSeverity.WARN, ULogTag.UserAuth, 'Called createUser when create already in progress.');
    }
  };

  const submitPhone = async (simplePhone: string, force?: boolean) => {
    if (loginState.status !== ULoginStatus.PhoneSubmitting || force) {
      dispatch({ type: 'setLoginStatus', status: ULoginStatus.PhoneSubmitting });
      try {
        const signInResponse = await sendPhoneSignInCode(TAppProduct.ELK, simplePhone);
        dispatch({ type: 'codeSent', phone: simplePhone, signInResponse });
      } catch (e) {
        if (e instanceof FirebaseError && e.code === 'auth/invalid-phone-number') {
          dispatch({
            type: 'setLoginStatus',
            status: ULoginStatus.NotLoggedIn,
            error: 'Invalid number, please try again.'
          });
        } else {
          logEvent(ULogSeverity.ERROR, ULogTag.UserAuth, `Firebase login error: ${e} ${stringifyError(e)}`);

          dispatch({
            type: 'setLoginStatus',
            status: ULoginStatus.NotLoggedIn,
            error: `Something went wrong during sign in: ${e}`
          });
        }
        return;
      }

      logEvent(ULogSeverity.INFO, ULogTag.UserAuth, 'Firebase code send success');
    }
  };

  const submitEmail = async (email: string, force?: boolean) => {
    if (loginState.status !== ULoginStatus.EmailSubmitting || force) {
      dispatch({ type: 'setLoginStatus', status: ULoginStatus.EmailSubmitting });
      try {
        const signInResponse = await sendEmailSignInCode(TAppProduct.ELK, email);
        dispatch({ type: 'emailCodeSent', email, signInResponse });
      } catch (e) {
        if (e instanceof FirebaseError && e.code === 'auth/invalid-email') {
          dispatch({
            type: 'setLoginStatus',
            status: ULoginStatus.NotLoggedIn,
            error: 'Invalid email, please try again.'
          });
        } else {
          logEvent(ULogSeverity.ERROR, ULogTag.UserAuth, `Firebase login error: ${e} ${stringifyError(e)}`);

          dispatch({
            type: 'setLoginStatus',
            status: ULoginStatus.NotLoggedIn,
            error: `Something went wrong during sign in: ${e}`
          });
        }
        return;
      }

      logEvent(ULogSeverity.INFO, ULogTag.UserAuth, 'Firebase code send success');
    }
  };

  const confirmCode = async (code: string, codeType: CodeType) => {
    if (!loginState.signInResponse) {
      logEvent(ULogSeverity.INFO, ULogTag.UserAuth, 'Firebase confirmationResult not set in onConfirmCode');

      dispatch({
        type: 'setLoginStatus',
        status: ULoginStatus.CodeSent,
        error: 'An error occurred trying to authenticate, please try again. If this error persists, please contact us at support@sunshine.com.'
      });
      return;
    }

    const isPhone = codeType === CodeType.Phone;
    if (loginState.status === ULoginStatus.CodeSubmitting ||
      (isPhone && !loginState.phone) ||
      (!isPhone && !loginState.email)) {
      return;
    }

    try {
      const startTime = Date.now();

      dispatch({ type: 'setLoginStatus', status: ULoginStatus.CodeSubmitting });

      let signInResponse: TSignInWithPhoneAndCodeResponse;
      if (isPhone && loginState.phone) {
        signInResponse = await signInWithPhoneAndCode(TAppProduct.ELK, loginState.phone, code);
      } else if (loginState.email) {
        signInResponse = await signInWithEmailAndCode(TAppProduct.ELK, loginState.email, code);
      } else {
        return;
      }

      if (!signInResponse.success) {
        if (signInResponse.errorMessage) {
          dispatch({
            type: 'setLoginStatus',
            status: ULoginStatus.CodeSent,
            error: `Failed to verify ${isPhone ? 'phone' : 'email'} code due to ${signInResponse.errorMessage}`
          });
        } else {
          dispatch({
            type: 'setLoginStatus',
            status: ULoginStatus.CodeSent,
            error: 'Invalid code, please try again.'
          });
        }
        return;
      }

      if (signInResponse.firebaseCustomToken === undefined) {
        logEvent(ULogSeverity.WARN, ULogTag.UserAuth,
          `${isPhone ? 'signInWithPhoneAndCode' : 'signInWithEmailAndCode'} did not return a token`);

        dispatch({
          type: 'setLoginStatus',
          status: ULoginStatus.CodeSent,
          error: 'An error occurred trying to authenticate, please try again. If this error persists, please contact us at support@sunshine.com.'
        });
        return;
      }

      await loginToFirebase(signInResponse.firebaseCustomToken, startTime);
    } catch (e) {
      if (e instanceof TimeoutError) {
        logEvent(ULogSeverity.ERROR, ULogTag.UserAuth, 'Firebase code verification timeout');
        dispatch({
          type: 'setLoginStatus',
          status: ULoginStatus.CodeSent,
          error: 'Request timed out, try again later.'
        });
      } else {
        logEvent(ULogSeverity.ERROR, ULogTag.UserAuth, `Firebase code verification error: ${e} ${stringifyError(e)}`);
        dispatch({
          type: 'setLoginStatus',
          status: ULoginStatus.CodeSent,
          error: 'An error occurred trying to authenticate, please try again. If this error persists, please contact us at support@sunshine.com.'
        });
      }
    }
  };

  const loginFromQueryString = async (userId: string, token: string) => {
    try {
      setAccountExists(true);
      const response = await refetchAccessToken(userId, deviceId, token);
      if (response.customFirebaseToken) {
        await loginToFirebase(response.customFirebaseToken);
      } else {
        dispatch({
          type: 'setLoginStatus',
          status: ULoginStatus.ErrorDuringLogin,
          error: 'Missing custom firebase token'
        });
      }
    } catch (e) {
      logEvent(ULogSeverity.WARN, ULogTag.Network, `loginFromQueryString - Error: ${stringifyError(e)}`);

      dispatch({
        type: 'setLoginStatus',
        status: ULoginStatus.ErrorDuringLogin,
        error: e?.message ?? e?.name ?? e?.reason ?? e.toString()
      });
    }
  };

  const loginToFirebase = async (firebaseCustomToken: string, startTime?: number) => {
    const result: UserCredential = await promiseTimeout(
      signInWithCustomToken(auth, firebaseCustomToken),
      10000
    );

    if (startTime !== undefined) {
      logEvent(ULogSeverity.INFO, ULogTag.Network,
        `FirebaseCodeConfirmEnd - elapsed=${Date.now() - startTime}, reqId=${getCurrentRequestId()}, FirebaseUser=${result.user.uid}`);
    }

    // Save for later -- to possibly be used in createUser after user has supplied their name and we create the ElkUser
    setUserCredential(result);

    dispatch({ type: 'setLoginStatus', status: ULoginStatus.SignedInToFirebase });
  };

  const loginToFirebaseWithRedirect = async (provider: GoogleAuthProvider | OAuthProvider, providerName: string,
                                             startTime?: number) => {
    logEvent(ULogSeverity.INFO, ULogTag.UserAuth, `Starting login via ${providerName}.`);

    window.sessionStorage.setItem(ELK_REDIRECT_AUTH_SS_PROVIDER_KEY, providerName);

    const result: UserCredential = await promiseTimeout(
      signInWithRedirect(auth, provider),
      10000
    );

    if (startTime !== undefined) {
      logEvent(ULogSeverity.INFO, ULogTag.UserAuth,
        `FirebaseCodeConfirmEnd (${providerName}) - elapsed=${Date.now() - startTime}, reqId=${getCurrentRequestId()}, FirebaseUser=${result.user.uid}`);
    }

    // Save for later -- to possibly be used in createUser after user has supplied their name and we create the ElkUser
    setUserCredential(result);

    dispatch({ type: 'setLoginStatus', status: ULoginStatus.SignedInToFirebase });
  };

  const loginToGoogle = async () => {
    await loginToFirebaseWithRedirect(new GoogleAuthProvider(), 'Google', Date.now());
  };

  const loginToApple = async () => {
    const provider = new OAuthProvider('apple.com');
    provider.addScope('name');
    provider.addScope('email');
    await loginToFirebaseWithRedirect(provider, 'Apple', Date.now());
  };

  const restartLogin = async () => {
    const providerName = window.sessionStorage.getItem(ELK_REDIRECT_AUTH_SS_PROVIDER_KEY) ?? 'unknown';
    if (providerName === 'unknown') {
      dispatch({
        type: 'setLoginStatus',
        status: (cookies.userId !== undefined && cookies.authToken !== undefined) ? ULoginStatus.LoggedIn : ULoginStatus.NotLoggedIn,
        error: undefined
      });
      return;
    }

    try {
      dispatch({ type: 'setLoginStatus', status: ULoginStatus.WaitingForRedirectResult });

      const redirectResult = await getRedirectResult(auth);
      if (!redirectResult) {
        logEvent(ULogSeverity.INFO, ULogTag.UserAuth, `${providerName} redirect result is null`);
        window.sessionStorage.removeItem(ELK_REDIRECT_AUTH_SS_PROVIDER_KEY);
        dispatch({ type: 'setLoginStatus', status: ULoginStatus.NotLoggedIn });
        return;
      }
      logEvent(ULogSeverity.INFO, ULogTag.UserAuth, `Received ${providerName} redirect response`);
      const credential = OAuthProvider.credentialFromResult(redirectResult);
      if (!credential) {
        logEvent(ULogSeverity.WARN, ULogTag.UserAuth, `${providerName} credentials is null`);
        return;
      }
      await processCredentials(credential, providerName);
    } catch (error) {
      loginErrorHandler(error);
      logEvent(ULogSeverity.ERROR, ULogTag.UserAuth,
        `${providerName} Error code: ${error.code}, errorMessage: ${error.message}`);
    } finally {
      // setRedirectResultsAvailable(false);
    }

    window.sessionStorage.removeItem(ELK_REDIRECT_AUTH_SS_PROVIDER_KEY);
  };

  const processCredentials = async (credential: AuthCredential, providerName: string) => {
    try {
      dispatch({ type: 'setLoginStatus', status: ULoginStatus.SigningInToFirebase });

      logEvent(ULogSeverity.INFO, ULogTag.UserAuth, `Signing into firebase for  ${providerName}`);
      const signedInUser = await promiseTimeout(signInWithCredential(auth, credential), 10000);
      logEvent(ULogSeverity.INFO, ULogTag.UserAuth,
        `Received firebase object with id ${signedInUser.user.uid}, calling server login for ${providerName}`);

      setUserCredential(signedInUser);
      await authenticateUser(signedInUser.user);
      // setLoginSuccess(true);
      logEvent(ULogSeverity.INFO, ULogTag.UserAuth, `Completed server login for ${providerName}`);
    } catch (error) {
      loginErrorHandler(error);
    }
  };

  const loginErrorHandler = (e: Error | TAuthFailed | TInvalidRequest | TApplicationException) => {
    if (e instanceof FirebaseError && e.code === 'auth/invalid-verification-code') {
      logEvent(ULogSeverity.INFO, ULogTag.UserAuth, 'User entered invalid verification code');
      // setInvalidCode(true);
    } else if (e instanceof FirebaseError && e.code === 'auth/user-cancelled') {
      logEvent(ULogSeverity.INFO, ULogTag.UserAuth, 'User cancelled Firebase login');
      dispatch({ type: 'setLoginStatus', status: ULoginStatus.UserCancelled });
    } else if (e instanceof FirebaseError) {
      logEvent(ULogSeverity.ERROR, ULogTag.UserAuth, `Failed to login due to ${stringifyError(e)}`);
      setError(`Failed to login due to ${e.message}`);
    } else if (e instanceof TimeoutError) {
      logEvent(ULogSeverity.ERROR, ULogTag.UserAuth, 'signInWithCredential timed out');
      setError('Request timed out, please try again.');
    } else if (e instanceof NetworkErrorException || e instanceof RateLimitException) {
      logEvent(ULogSeverity.ERROR, ULogTag.UserAuth, `handleLogin failed due to ${e.getErrorMessage()}`);
      setError('Failed to login due to network issues, please try again later.');
    } else if (e instanceof TAuthFailed || e instanceof TInvalidRequest) {
      logEvent(ULogSeverity.ERROR, ULogTag.UserAuth, `handleLogin failed due to auth failure ${e.reason}`);
      setError('Failed to login due to auth failure, please try again later.');
    } else if (e instanceof TApplicationException) {
      logEvent(ULogSeverity.ERROR, ULogTag.UserAuth,
        `handleLogin failed due to TApplicationException ${e.code} ${e.message}`);
      setError('Failed to login due to server issues, please try again later.');
    } else {
      logEvent(ULogSeverity.ERROR, ULogTag.UserAuth, `handleLogin failed due to ${e}`);
      setError('Something went wrong. Please try again.');
    }
  };

  const authenticateUser = async (firebaseUser: User, goTo?: string) => {
    if (goTo) {
      setGoToAfter(goTo);
    }

    try {
      dispatch({ type: 'setLoginStatus', status: ULoginStatus.Authenticating });

      const authenticateResponse = await authenticate({
          deviceId,
          verifiedPhoneNumber: firebaseUser.phoneNumber ?? undefined,
          firebaseIDToken: await firebaseUser.getIdToken(),
          product: TAppProduct.ELK
        }
      );

      dispatch({
        type: 'handleAuthenticate',
        id: authenticateResponse.userId,
        authToken: authenticateResponse.accessToken,
        email: firebaseUser.email ?? undefined,
        phoneNumber: firebaseUser.phoneNumber ?? undefined
      });
    } catch (e) {
      logEvent(ULogSeverity.ERROR, ULogTag.UserAuth, `Error during Sunshine authenticate: ${stringifyError(e)}`);
    }
  };

  const handleLogin = () => {
    setCookie('authToken', user.authToken, {
      path: '/',
      maxAge: sixtyDays
    });

    setCookie('userId', user.id, {
      path: '/',
      maxAge: sixtyDays
    });

    dispatch({
      type: 'setLoginStatus',
      status: ULoginStatus.AfterLogin
    });
  };

  const continueLogin = async () => {
    if (userCredential) {
      await authenticateUser(userCredential.user);
    }
  };

  const finishLogin = (goTo?: string, options?: NavigateOptions) => {
    if (loginState.status === ULoginStatus.AfterLogin || loginState.status === ULoginStatus.UserCreated) {
      logEvent(ULogSeverity.INFO, ULogTag.UserAuth,
        `Status = ${loginState.status} -- calling handleLogin and logging in`);
      handleLogin();
      dispatch({ type: 'setLoginStatus', status: ULoginStatus.LoggedIn });
      setGoToAfter(goTo ?? null);
      setNavigateOptionsAfter(options);
    } else if (goTo) {
      navigate(goTo, options);
    }
  };

  const setName = (name: string) => {
    dispatch({ type: 'setName', name });
  };

  const setEmail = (email?: string) => {
    dispatch({ type: 'setEmail', email });
  };

  const getEmail = (): string | undefined => {
    if ((user.emails?.length ?? 0) > 0) {
      return user.emails?.[0];
    }
    return undefined;
  };

  const setGrantedTextPermissions = (grantedTextPermissions: boolean) => {
    dispatch({ type: 'setGrantedTextPermissions', grantedTextPermissions });
  };

  const setPhoneNumber = (phoneNumber?: string) => {
    dispatch({ type: 'setPhoneNumber', phoneNumber });
  };

  const setPhotoUrl = (photoUrl?: string) => {
    dispatch({ type: 'setPhotoUrl', photoUrl });
  };

  const fetchAndSaveUser = async (): Promise<void> => {
    const response = await getElkUser(getAuthHeader());
    const uploadMode = response.defaultPhotoUploadMode;

    saveUser(response.elkUser, uploadMode, ULoginStatus.LoggedIn);
  };

  const saveUser = (elkUser: TAppElkUser, uploadMode: TAppElkPhotoUploadMode | undefined, nextStatus: ULoginStatus) => {
    dispatch({
      type: 'storeElkUser',
      elkUser,
      uploadMode,
      authToken: user.authToken ?? cookies.authToken ?? '',
      status: nextStatus
    });

    getUserRequest.current = undefined;

    setAskForTextPermission(
      elkUser.phoneNumber !== '' &&
      elkUser.textPermissionStatus === TAppElkTextPermissionStatus.UNDEFINED
    );
  };

  const checkForAccount = async (): Promise<void> => {
    setAccountExists(undefined);
    if (user !== undefined) {
      const res = await getHasElkProfile(context);
      setAccountExists(res.existingElkProfile);
    }
  };

  const generateElkUser = (partialUser?: Partial<TAppElkUser>): TAppElkUser | undefined => {
    let newUser = user;
    if (partialUser) {
      dispatch({
        type: 'updateProfile',
        ...partialUser
      });
      newUser = UserReducer(user, {
        type: 'updateProfile',
        ...partialUser
      });
    }
    return new TAppElkUser({
      userId: newUser.id ?? '',
      name: newUser?.name ?? user.name ?? '',
      phoneNumber: newUser.phoneNumber ?? '',
      photoUrl: newUser?.photoUrl,
      grantedTextPermission: newUser?.grantedTextPermission ?? false,
      textPermissionStatus: partialUser?.textPermissionStatus ?? TAppElkTextPermissionStatus.UNDEFINED,
      emails: newUser?.emails ?? user.emails ?? undefined
    });
  };

  const getDeviceId = () => {
    let thisDeviceId = deviceId;
    if (deviceId === '') {
      thisDeviceId = `WEB-${uuidv4()}`;
      console.log('[UserContext] Setting device id to', thisDeviceId);
      setDeviceId(thisDeviceId);
    }
    return thisDeviceId;
  };

  const fetchEventImageHistory = async (): Promise<TElkGetImageHistoryResponse> => {
    if (eventImageHistory !== undefined) {
      return eventImageHistory;
    }

    const history = await getEventImageHistory(context);

    setEventImageHistory(history);
    return history;
  };

  const logout = async () => {
    deleteCookie('authToken', { path: '/' });
    deleteCookie('userId', { path: '/' });

    clearLocalStorage();
    // Removing invitee uuid local storage items
    localStorage.removeItem('invite_uuids');
    localStorage.removeItem('token_infos');
    localStorage.removeItem('phone');
    localStorage.removeItem('email');
    localStorage.removeItem('question_uuids_and_answers');

    logEvent(ULogSeverity.INFO, ULogTag.UserAuth, 'User logout');

    try {
      await getAuth(app).signOut();
    } catch (e) {
      logEvent(ULogSeverity.ERROR, ULogTag.UserAuth, `Error during Firebase signOut: ${stringifyError(e)}`);
    }

    navigate('/');
    window.location.reload();
  };

  const clearLocalStorage = () => {
    window.sessionStorage.removeItem(ELK_REDIRECT_AUTH_SS_PROVIDER_KEY);

    const entries = Object.entries(window.sessionStorage);
    for (const [key] of entries) {
      if (key.match(/^autoSavedEvent-/)) {
        window.sessionStorage.removeItem(key);
      }
    }
  };

  const fetchContactsPage = async () => {
    if (!contactsActive.current || !isLoggedIn()) {
      return;
    }
    const response = await fetchContacts(context, contactsPageToken);

    const newContacts = contacts ?? [];
    setContacts([
      ...newContacts,
      ...response.contacts
    ]);

    if (response.hasMore) {
      setContactsPageToken(response.nextPageToken);
    }
  };

  const fetchMutualMembersPage = async () => {
    if (!mutualMembersActive.current || !isLoggedIn()) {
      return;
    }

    const response = await fetchMutualMembers(context);

    const newMutualMembers = mutualMembers ?? [];
    setMutualMembers([
      ...newMutualMembers,
      ...response.mutualMembers
    ]);

    if (response.hasMore) {
      setMutualMembersPageToken(response.pageToken);
    }
  };

  const context: IUUserContext = {
    login,
    loginState,
    userCredential,
    error,
    id: user.id,
    token: user.authToken,
    phoneNumber: user.phoneNumber ?? '',
    name: user.name ?? '',
    emails: user.emails,
    photoUrl: user.photoUrl,
    askForTextPermission,
    setAskForTextPermission,
    grantedTextPermission: user.grantedTextPermission,
    hasNudgedToAllowTexts: hasNudgedToAllowTexts,
    accountExistsForPhone: accountExists,
    contacts,
    mutualMembers,
    isLoggedIn,
    confirmCode,
    loginToGoogle,
    loginToApple,
    restartLogin,
    handleLogin: authenticateUser,
    continueLogin,
    finishLogin,
    submitPhone,
    submitEmail,
    getAuthHeader,
    logout,
    setName,
    setPhoneNumber,
    setPhotoUrl,
    createUser,
    generateElkUser,
    setHasNudgedToAllowTexts,
    setGrantedTextPermissions,
    setEmail,
    getEmail,
    deviceId: getDeviceId(),
    fetchEventImageHistory
  };

  return (
    <UserContext.Provider value={context}>
      {props.children}
    </UserContext.Provider>
  );
});

export const useUserContext = () => {
  const context = useContext(UserContext);
  if (context === null) {
    throw new Error('useUserContext must be used within a UserContextProvider');
  }
  return context;
};
