import React from 'react';

const PersonCropCircle = ({ color = 'currentColor', size = 50 }: { color?: string, size?: number }) => {
  return <svg width={size} height={size} viewBox="0 0 50 50" direction="ltr" xmlns="http://www.w3.org/2000/svg"
              version="1.1">
    <g fillRule="nonzero" transform="scale(1,-1) translate(0,-52.400390625)">
      <path fill={color} fillOpacity="1.0" d="
    M 24.6640625,5.37109375
    C 36.115234375,5.37109375 45.482421875,14.73828125 45.482421875,26.2109375
    C 45.482421875,37.662109375 36.115234375,47.029296875 24.6640625,47.029296875
    C 13.212890625,47.029296875 3.845703125,37.662109375 3.845703125,26.2109375
    C 3.845703125,14.73828125 13.212890625,5.37109375 24.6640625,5.37109375
    Z
    M 24.6640625,38.9296875
    C 28.703125,38.9296875 31.904296875,35.3203125 31.904296875,31.044921875
    C 31.904296875,26.490234375 28.7890625,22.966796875 24.6640625,22.966796875
    C 20.560546875,22.966796875 17.423828125,26.490234375 17.423828125,31.044921875
    C 17.40234375,35.3203125 20.646484375,38.9296875 24.6640625,38.9296875
    Z
    M 38.693359375,13.578125
    L 38.693359375,13.27734375
    C 35.578125,9.7109375 30.80859375,6.982421875 24.6640625,6.982421875
    C 18.51953125,6.982421875 13.75,9.7109375 10.634765625,13.27734375
    L 10.634765625,13.578125
    C 13.341796875,16.88671875 19.185546875,19.25 24.6640625,19.25
    C 30.1640625,19.25 35.986328125,16.88671875 38.693359375,13.578125
    Z
"/>
    </g>
  </svg>;
};

export default PersonCropCircle;
