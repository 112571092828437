import React, { ReactNode, useEffect, useState } from 'react';
import { Location, useBlocker, useLocation, useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';

import InviteForm from '../components/invite/InviteForm';
import InviteTable from '../components/invite/InviteTable';
import { useEventCacheContext } from '../contexts/EventCacheContext';
import { useBackgroundContext } from '../contexts/BackgroundContext';
import { useUserContext } from '../contexts/UserContext';
import {
  deletePendingInvites,
  editInvitee,
  queueInvites,
  sendInvites,
  sendTemplateEmail,
  updateAttendance
} from '../api/ElkEventService';
import { useModalContext } from '../contexts/ModalContext';
import InviteUploadModal from '../components/modals/InviteUploadModal';
import { BasePageContent } from './BasePage';
import BasePageHeader from './BasePageHeader';
import BulkEmailInviteModal from '../components/modals/BulkEmailInviteModal';
import ShareWithFriendsModal from '../components/modals/ShareWithFriendsModal';
import { filterInvitees, FilterType, removeDuplicateInvitees, separateInvitees, sortInvitees } from '../util/attendee';
import Modal from '../components/modals/Modal';
import { UButterBarLevel, useButterBarContext } from '../contexts/ButterBarContext';
import EditInviteeModal from '../components/modals/EditInviteeModal';
import AddList from '../components/icons/AddList';
import FramedPage, { FrameBody, FrameFooter, FrameHeader, FrameHeaderLeft, FrameHeaderRight } from './FramedPage';
import WhiteButton from '../components/buttons/WhiteButton';
import LeftArrow from '../components/icons/LeftArrow';
import ChartSquareBarOutline from '../components/icons/ChartSquareBarOutline';
import LinkIcon from '../components/icons/LinkIcon';
import SystemButton from '../components/buttons/SystemButton';
import UserEmailModal from '../components/invite/UserEmailModal';
import DownloadCSVModal from '../components/invite/DownloadCSVModal';
import { IUInvitee } from '../lib/event';

import { DeviceQueries } from 'Common/src/components/styled';
import KebabMenu, { KebabMenuOption } from 'Common/src/components/KebabMenu';

import { TAppElkInviteStatus } from 'TProtocol/prototypes/events/messages';

interface InvitePageState {
  fromCreate?: boolean;
}

const InvitePage = () => {
  const userContext = useUserContext();
  const eventCacheContext = useEventCacheContext();
  const backgroundContext = useBackgroundContext();
  const modalContext = useModalContext();
  const butterBarContext = useButterBarContext();
  const { eventId } = useParams();
  const navigate = useNavigate();
  const location = useLocation() as Location<InvitePageState>;
  const blocker = useBlocker(() => {
    return unsentInvites > 0;
  });

  const [invitees, setInvitees] = useState<IUInvitee[]>([]);
  const [inviteesByType, setInviteesByType] = useState(separateInvitees([]));
  const [filteredInvitees, setFilteredInvitees] = useState<IUInvitee[]>([]);
  const [unsentInvites, setUnsentInvites] = useState(0);
  const [showUserEmailModal, setShowUserEmailModal] = useState(false);
  const [showDownloadCSVModal, setShowDownloadCSVModal] = useState(false);
  const [sending, setSending] = useState(false);
  const [activeFilters, setActiveFilters] = useState<Set<FilterType>>(new Set([FilterType.All]));

  const event = eventId !== undefined ? eventCacheContext.getEvent(eventId) : undefined;

  useEffect(() => {
    return () => {
      modalContext.hide();
    }
  }, []);

  useEffect(() => {
    if (event !== undefined) {
      document.title = `Shine - Manage Invites - ${event.title}`;
      backgroundContext.setColors(
        {
          colors: event?.colors,
          animation: event?.animation,
          animationUrl: event?.backgroundAnimationUrl,
          subdued: true
        }
      );
      const newInvitees = sortInvitees(event.attendees);
      setInvitees(newInvitees);
      const pendingInvitees = newInvitees.filter(
        (invitee) => invitee.inviteStatus === TAppElkInviteStatus.PENDING
      ).length;
      setUnsentInvites(pendingInvitees);
    }
  }, [event]);

  useEffect(() => {
    if (eventId !== undefined) {
      eventCacheContext.fetchEvent({ eventId });
    }
  }, [eventId]);

  useEffect(() => {
    setInviteesByType(separateInvitees(invitees));
    setFilteredInvitees(filterInvitees(invitees, activeFilters));
  }, [invitees, activeFilters]);

  const createInvite = async (invitee: IUInvitee) => {
    if (event !== undefined) {
      invitees.unshift(invitee);
      setInvitees([...invitees]);
      event.attendees = invitees;
      eventCacheContext.setEvent(event);

      await queueInvites(userContext, event.id, [invitee.toTAppElkInvitee()]);
    }
  };

  const onUploadClick = () => {
    if (eventId) {
      modalContext.show({
        contents: <InviteUploadModal eventId={eventId} close={() => modalContext.hide()}
                                     onComplete={() => modalContext.hide()}/>
      });
    }
  };

  const onPasteClick = () => {
    modalContext.show({
      contents: <BulkEmailInviteModal onSubmit={onBulkEmailInviteSubmit} close={modalContext.hide}/>
    });
  };

  const onBulkEmailInviteSubmit = async (invitees: IUInvitee[]) => {
    if (event !== undefined) {
      const newInvitees = removeDuplicateInvitees(invitees, event);

      if (newInvitees.length === 0) {
        butterBarContext.show({
          level: UButterBarLevel.WARN,
          contents: 'No new invites found in pasted text.'
        });
      } else {
        await queueInvites(userContext, event.id, newInvitees.map((invitee) => invitee.toTAppElkInvitee()));
      }
    }
  };

  const onShareClick = () => {
    modalContext.show({
      contents: <ShareWithFriendsModal close={modalContext.hide} event={event} hideManage={true}/>
    });
  };

  const onChange = (invitee: IUInvitee) => {
    if (event) {
      void updateAttendance(userContext, event.id, invitee.toTAppElkInvitee(), undefined, undefined, undefined, false);
    }
  };

  const onDelete = (invitee: IUInvitee) => {
    modalContext.show({
      contents: <Modal title="Delete Invitee"
                       subtitle={`Are you sure you want to remove ${invitee.name ?? invitee.email} from the event?`}
                       primaryButtonLabel="Yes" secondaryButtonLabel="No"
                       onPrimaryClick={() => deleteInvitee(invitee.inviteeId)}
                       onSecondaryClick={modalContext.hide}
                       useSystemColors={true}/>
    });
  };

  const deleteInvitee = async (inviteId: string) => {
    if (event) {
      const newInvitees = invitees.filter((attendee) => attendee.inviteeId !== inviteId);
      event.attendees = invitees;
      setInvitees(newInvitees);
      eventCacheContext.setEvent(event);

      modalContext.hide();

      await deletePendingInvites(userContext, event.id, [inviteId]);
    }
  };

  const onEdit = async (inviteeId: string) => {
    const invitee = invitees.find((invitee) => invitee.inviteeId === inviteeId);

    if (event && invitee) {
      modalContext.show({
        contents: <EditInviteeModal
          close={modalContext.hide}
          onSave={handleUpdate}
          event={event}
          invitee={invitee}
        />
      });
    }
  };

  const onSend = async (inviteeId: string) => {
    setSending(true);
    await sendInvites(userContext, eventId ?? '', [inviteeId]);
    butterBarContext.show({
      contents: 'Guest invited successfully.'
    });
    setSending(false);
  };

  const handleUpdate = async (invitee: IUInvitee) => {
    if (eventId) {
      invitees.forEach((inv) => {
        if (inv.inviteeId === invitee.inviteeId) {
          inv.name = invitee.name;
          inv.email = invitee.email;
        }
      });
      setInvitees([...invitees]);

      modalContext.hide();
      await editInvitee(userContext, eventId, invitee.toTAppElkInvitee());
    }
  };

  const handleSend = async () => {
    const inviteeIds: string[] = [];

    invitees.forEach((invitee) => {
      if (invitee.inviteStatus === TAppElkInviteStatus.PENDING) {
        inviteeIds.push(invitee.inviteeId);
        invitee.inviteStatus = TAppElkInviteStatus.TRIGGERED;
      }
    });

    setInvitees([...invitees]);
    await sendInvites(userContext, eventId ?? '', inviteeIds);
    butterBarContext.show({
      contents: `${inviteeIds.length} guest${inviteeIds.length !== 1 ? 's' : ''} invited successfully.`
    });
  };

  const handleSendTest = async () => {
    setShowUserEmailModal(true);
  };

  const submitUserEmail = async (email: string) => {
    if (!eventId) {
      return;
    }
    setSending(true);

    await sendTemplateEmail(userContext, eventId, email);
    butterBarContext.show({
      contents: `Invite sent to ${email}.`
    });

    setSending(false);
    setShowUserEmailModal(false);
  };

  const onBackClick = () => {
    navigate(`/event/${eventId}`);
  };

  const onDownload = async () => {
    if (event !== undefined) {
      setShowDownloadCSVModal(true);
    }
  };

  const onFilterChange = (filterType: FilterType, status: boolean) => {
    if (filterType === FilterType.All) {
      setActiveFilters(new Set([filterType]));
      return;
    } else if (status) {
      activeFilters.delete(FilterType.All);
      activeFilters.add(filterType);
    } else {
      activeFilters.delete(filterType);
      if (activeFilters.size === 0) {
        activeFilters.add(FilterType.All);
      }
    }
    setActiveFilters(new Set(activeFilters));
  };

  const onMenuClick = (id: string) => {
    if (id === 'import') {
      onUploadClick();
    } else if (id === 'paste') {
      onPasteClick();
    } else if (id === 'copyLink') {
      onShareClick();
    }
  };

  const menuOptions: KebabMenuOption[] = [
    {
      id: 'import',
      label: 'Import CSV',
      icon: <ChartSquareBarOutline size={20}/>
    },
    {
      id: 'paste',
      label: 'Paste list',
      icon: <AddList size={20}/>
    },
    {
      id: 'copyLink',
      label: 'Copy link',
      icon: <LinkIcon size={20}/>
    }
  ];


  let sendText: ReactNode;
  if (unsentInvites > 0) {
    sendText = <>Send to {unsentInvites} pending guest{unsentInvites === 1 ? '' : 's'}</>;
  } else {
    sendText = <>No pending invites</>;
  }

  return <>
    {blocker.state === 'blocked' ? (
      <Modal title={`You have ${unsentInvites} unsent invite${unsentInvites !== 1 ? 's' : ''}`}
             subtitle={`Are you sure you want to leave? ${unsentInvites} unsent invite${unsentInvites !== 1 ? 's' : ''} will remain unsent.`}
             primaryButtonLabel={'Yes'} secondaryButtonLabel={'No'}
             onPrimaryClick={() => blocker.proceed()} onSecondaryClick={() => blocker.reset()}
             useSystemColors={true}/>
    ) : null}
    {showUserEmailModal ? <UserEmailModal
      onCancel={() => setShowUserEmailModal(false)}
      onSubmit={submitUserEmail}
      saving={sending}
    /> : null}
    {(showDownloadCSVModal && event !== undefined) ? <DownloadCSVModal
      event={event}
      onCancel={() => setShowDownloadCSVModal(false)}
    /> : null}
    <BasePageHeader/>
    <BasePageContent $wide={true} $full={true} $centered={true}>
      <FramedPage>
        <FrameHeader>
          <FrameHeaderLeft>
            <BackArrowContainer onClick={onBackClick}>
              <LeftArrow/>
            </BackArrowContainer>
            <span>
              {invitees.length} total invited
            </span>
          </FrameHeaderLeft>
          <FrameHeaderRight>
            <TopActionContainer>
              <WhiteButton onClick={onUploadClick}>
                <ChartSquareBarOutline/> Import CSV
              </WhiteButton>
              <WhiteButton onClick={onPasteClick}>
                <AddList/> Paste list
              </WhiteButton>
              <WhiteButton onClick={onShareClick}>
                <LinkIcon/> Copy link
              </WhiteButton>
            </TopActionContainer>
            <TopMobileMenu>
              <KebabMenu options={menuOptions} onClick={onMenuClick}/>
            </TopMobileMenu>
          </FrameHeaderRight>
        </FrameHeader>

        <FrameBody>
          <InviteForm
            inviteesByType={inviteesByType}
            event={event}
            onCreate={createInvite}
            activeFilters={activeFilters}
            onFilterChange={onFilterChange}
          />

          <InviteTable event={event} invites={filteredInvitees} onChange={onChange} onDelete={onDelete}
                       onEdit={onEdit} onSend={onSend} onDownload={onDownload} questions={event?.questions ?? []}
                       sending={sending} fromCreate={!!location.state?.fromCreate} onSkip={onBackClick}/>
        </FrameBody>
        <FrameFooter>
          <FooterButtonContainer>
            <SystemButton outline={true} onClick={handleSendTest}>Send preview</SystemButton>
            <SystemButton onClick={handleSend} disabled={unsentInvites === 0 || sending}>{sendText}</SystemButton>
          </FooterButtonContainer>
        </FrameFooter>
      </FramedPage>
    </BasePageContent>
  </>;
};

export const BackArrowContainer = styled.div`
  cursor: pointer;
  width: 25px;
  display: flex;
`;

const TopActionContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;

  @media (${DeviceQueries.mobile}) {
    display: none;
  }
`;

const TopMobileMenu = styled.div`
  display: none;

  @media (${DeviceQueries.mobile}) {
    display: flex;
  }
`;

const FooterButtonContainer = styled.div`
  display: flex;
  justify-content: end;
  gap: 10px;

  @media (${DeviceQueries.mobile}) {
    flex-direction: column;
  }
`;

export default InvitePage;
