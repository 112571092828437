import React, { MouseEvent, useState } from 'react';
import styled from 'styled-components';

import { ModalContent, ModalHeader, ModalTitle, Overlay, SystemModalContainer } from './CommonModal';
import EventFormInput from '../EventForm/EventFormInput';
import { emailExists, validateEmail } from '../../util/email';
import { IUEvent, IUInvitee } from '../../lib/event';
import { BackArrowContainer } from '../../pages/InvitePage';
import BackArrow from '../icons/BackArrow';
import SystemButton, { ButtonInternal } from '../buttons/SystemButton';

interface Props {
  close: () => void;
  onSave: (invitee: IUInvitee) => void;
  event: IUEvent;
  invitee: IUInvitee;
}

const EditInviteeModal = ({ close, onSave, event, invitee }: Props) => {
  const [name, setName] = useState(invitee.name);
  const [email, setEmail] = useState(invitee.email);
  const [error, setError] = useState('');

  const onModalClick = (e: MouseEvent) => {
    e.stopPropagation();
  };

  const onUpdateClick = () => {
    if (!name) {
      setError('Please enter a valid name.');
      return;
    }

    if (!email || !validateEmail(email)) {
      setError('Please enter a valid email.');
      return;
    }

    if (email !== invitee.email && emailExists(email, event)) {
      setError('Email has already been invited.');
      return;
    }

    onSave(new IUInvitee({
      ...invitee,
      name,
      email
    }));
  };

  return (
    <Overlay onClick={close}>
      <SystemModalContainer onClick={onModalClick}>
        <ModalContent>
          <ModalHeader>
            <BackArrowContainer onClick={close}>
              <BackArrow/>
            </BackArrowContainer>
            <ModalTitle>Edit invitee</ModalTitle>
          </ModalHeader>
          <div>
            <EventFormInput
              placeholder={'Guest name'}
              value={name ?? ''}
              onChange={setName}
              maxLength={128}
              inModal={true}
              isLabelOutside={true}
              excludeBackground={true}
              noVertMargin={true}
            />
            <EventFormInput
              placeholder={'Email'}
              value={email ?? ''}
              onChange={setEmail}
              maxLength={128}
              inModal={true}
              isLabelOutside={true}
              excludeBackground={true}
              noVertMargin={true}
            />
            {error === '' ? null : <InviteError>{error}</InviteError>}
          </div>
        </ModalContent>

        <Footer>
          <SystemButton secondary={true} onClick={close}>Cancel</SystemButton>
          <SystemButton onClick={onUpdateClick}>Save</SystemButton>
        </Footer>
      </SystemModalContainer>
    </Overlay>
  );
};

const InviteError = styled.div`
  color: var(--shine-system-error-color);
  padding: 5px 10px;
`;

const Footer = styled.div`
  display: flex;
  gap: 20px;

  ${ButtonInternal} {
    width: 100%;
  }
`;

export default EditInviteeModal;

