import logRpc from '../util/logRpc';

import Net from 'Common/src/lib/Net';
import { augmentService } from 'Common/src/lib/ThriftServiceAugmentor';

import { TAuthHeader } from 'TProtocol/common/models';
import { TUserService } from 'TProtocol/user';
import { TOptOutRequest, TOptOutResponse } from 'TProtocol/user/messages';

const createUserService = (): TUserService.Client => {
  return augmentService(Net.createService(TUserService, '/json/UserService'));
};

export const optOut = async (header: TAuthHeader): Promise<TOptOutResponse> => {
  logRpc(null, 'optOut');
  const request: TOptOutRequest = new TOptOutRequest();
  const client: TUserService.Client = createUserService();
  return client.optOut(header, request);
};
