import React, { FormEvent, ReactNode, useEffect, useRef, useState } from 'react';
import { Location, useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { StringParam, useQueryParam } from 'use-query-params';
import { isPossiblePhoneNumber } from 'libphonenumber-js';

import { ELK_REDIRECT_AUTH_SS_PROVIDER_KEY, useUserContext } from '../../contexts/UserContext';
import { endUserEvent, startUserEvent, UserEventType } from '../../lib/performance';
import { ColorScheme, useBackgroundContext } from '../../contexts/BackgroundContext';
import AllowTextReminderModal from '../../components/modals/AllowTextReminderModal';
import { useModalContext } from '../../contexts/ModalContext';
import { CodeType, getDecodedUrl } from '../../lib/login';
import ConfirmCode from '../../components/login/ConfirmCode';
import BasePageHeader, { UPageType } from '../BasePageHeader';
import useInviteeUuidStorage from '../../hooks/useInviteeUuidStorage';
import { stripPlusOne } from '../../lib/phone';
import { useCoinContext } from '../../contexts/CoinContext';
import { ULoginStatus } from '../../state/reducers/user';
import PhoneInput from '../../components/SingleFieldForm/PhoneInput';
import { validateEmail } from '../../util/email';
import ErrorIcon from '../../components/icons/ErrorIcon';
import WhiteInput from '../../components/forms/WhiteInput';
import { useEventCacheContext } from '../../contexts/EventCacheContext';

import GoogleIcon from 'Common/src/components/icons/GoogleIcon';
import AppleIcon from 'Common/src/components/icons/AppleIcon';
import Checkbox from 'Common/src/components/Checkbox';
import { logSumoEvent, ULogApplication, ULogSeverity, ULogTag } from 'Common/src/api/SumoLogicApi';
import SinglePhoneEmailInput from '../../components/login/SinglePhoneEmailInput';
import EventPage from '../Event/EventPage';
import { Overlay } from '../../components/EventForm/ActionMenu';

export enum PageEnum {
  Phone = 1,
  Email,
  Code,
  Name,
  LinkPhone
}

export interface LoginPageState {
  page?: PageEnum;
  showPhotosPromo?: boolean;
  sentTo?: string;
  codeType?: CodeType;
  hostedBy?: string;
  eventTitle?: string;
  eventId?: string;
  forceLogin?: boolean;
}

const LoginPage = () => {
  const modalContext = useModalContext();
  const userContext = useUserContext();
  const coinContext = useCoinContext();
  const backgroundContext = useBackgroundContext();
  const eventCacheContext = useEventCacheContext();
  const [encodedState] = useQueryParam('s', StringParam);
  const { deletePhone, getPhone, clearAllInviteeUuidStorage, getEmail } = useInviteeUuidStorage();
  const location = useLocation() as Location<LoginPageState>;
  const navigate = useNavigate();

  const initialPhone = stripPlusOne(userContext.phoneNumber || getPhone() || '');
  const initialEmail = userContext.getEmail() || getEmail() || '';

  const [phone, setPhone] = useState<string>(initialPhone);
  const [email, setEmail] = useState<string>(initialEmail);
  const [name, setName] = useState('');
  const [error, setError] = useState<string>('');
  const [isChecked, setIsChecked] = useState(true);
  const [submittingName, setSubmittingName] = useState(false);
  const [validPhone, setValidPhone] = useState(isPossiblePhoneNumber(initialPhone));
  const [validEmail, setValidEmail] = useState(validateEmail(initialEmail));

  const isMounted = useRef(true);
  const previousStatus = useRef<ULoginStatus>();
  const previousAccountExists = useRef<boolean | undefined>();

  const providerName = window.sessionStorage.getItem(ELK_REDIRECT_AUTH_SS_PROVIDER_KEY);
  const page = location.state?.page ?? PageEnum.Phone;
  const eventId = location.state?.eventId;
  const eventTitle = location.state?.eventTitle;
  const hasEventBackground = eventId !== undefined;
  const isForcedLogin = location.state?.forceLogin;

  const goToPage = (page: PageEnum, update?: Partial<LoginPageState>) => {
    navigate('/login' + location.search, { state: { ...location.state, ...update, page } });
  };

  const logEvent = (severity: ULogSeverity, tag: ULogTag, message: string) => {
    void logSumoEvent({
      app: ULogApplication.ELK,
      userId: userContext.id,
      severity,
      tag,
      message: `[LoginPage] ${message}`
    });
  };

  useEffect(() => {
    modalContext.hide();
    document.title = 'Shine - Login';

    userContext.restartLogin();

    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (page === PageEnum.Name && userContext.userCredential === undefined) {
      // User probably hit reload on the name page, we have to start back at the beginning
      goToPage(PageEnum.Phone);
    }
  }, [page]);

  const status = userContext.loginState.status;
  const accountExists = userContext.accountExistsForPhone;
  const signingIn = providerName !== null && (
    status === ULoginStatus.WaitingForRedirectResult || status === ULoginStatus.SigningInToFirebase ||
    status === ULoginStatus.Authenticating || status === ULoginStatus.CreatingUser ||
    status === ULoginStatus.NotLoggedIn || status === ULoginStatus.WaitingForAccountCheck ||
    status === ULoginStatus.AfterLogin || status === ULoginStatus.LoggedIn
  );
  const busy = status === ULoginStatus.WaitingForAccountCheck ||
    status === ULoginStatus.NameSubmitting || status === ULoginStatus.EmailSubmitting ||
    status === ULoginStatus.CodeSubmitting || status === ULoginStatus.PhoneSubmitting ||
    status === ULoginStatus.WaitingForRedirectResult || status === ULoginStatus.SigningInToFirebase ||
    status === ULoginStatus.Authenticating || status === ULoginStatus.CreatingUser;

  useEffect(() => {
    if (previousStatus.current === status && previousAccountExists.current === accountExists) {
      return;
    }
    previousStatus.current = status;
    previousAccountExists.current = accountExists;
    if (status === ULoginStatus.CodeSent) {
      const sentTo = location.state?.sentTo ?? (page === PageEnum.Email ? email : phone);
      const codeType = page === PageEnum.Email ? CodeType.Email : CodeType.Phone;
      logEvent(ULogSeverity.INFO, ULogTag.UserAuth, 'CodeSent, going to code page.');
      goToPage(PageEnum.Code, { sentTo, codeType });
    } else if (status === ULoginStatus.AfterLogin) {
      const decodedUrl = getDecodedUrl(userContext, encodedState ?? undefined);

      logEvent(ULogSeverity.INFO, ULogTag.UserAuth, 'AfterLogin, calling finishLogin.');

      eventCacheContext.clearAll();
      clearAllInviteeUuidStorage();
      const localStorageLocationState = JSON.parse(window.sessionStorage.getItem('locationState') ?? '{}');
      window.sessionStorage.removeItem('locationState');
      userContext.finishLogin(decodedUrl, {
        state: { ...location.state, ...localStorageLocationState, fromLogin: true }
      });
    } else if (status === ULoginStatus.AskingForName) {
      endUserEvent(userContext);
      setError('');
      logEvent(ULogSeverity.INFO, ULogTag.UserAuth, 'AskingForName, going to name page.');
      goToPage(PageEnum.Name);
    } else if (status === ULoginStatus.UserCreated) {
      const decodedUrl = getDecodedUrl(userContext, encodedState ?? undefined);
      logEvent(ULogSeverity.INFO, ULogTag.UserAuth, 'UserCreated, calling finishLogin.');

      eventCacheContext.clearAll();
      clearAllInviteeUuidStorage();
      const localStorageLocationState = JSON.parse(window.sessionStorage.getItem('locationState') ?? '{}');
      window.sessionStorage.removeItem('locationState');
      userContext.finishLogin(decodedUrl, {
        state: { ...location.state, ...localStorageLocationState, fromLogin: true }
      });
    } else if (status === ULoginStatus.UserCancelled) {
      goToPage(PageEnum.Phone);
    }
  }, [status, accountExists]);

  useEffect(() => {
    if (userContext.phoneNumber && !phone) {
      // This will only happen if the user goes to the login page, and they're already logged in.
      const phoneMatch = userContext.phoneNumber.match(/^\+1(\d{10})$/);
      if (phoneMatch) {
        setPhone(phoneMatch[1]);
      }
    }
  }, [userContext.phoneNumber]);

  useEffect(() => {
    setError(userContext.loginState.error ?? '');
  }, [userContext.loginState.error]);

  useEffect(() => {
    backgroundContext.setScheme({ scheme: ColorScheme.Welcome });
  }, []);

  useEffect(() => {
    if (page === PageEnum.Code && !userContext.loginState.signInResponse) {
      logEvent(ULogSeverity.WARN, ULogTag.UserAuth, 'On code page, but no signInResponse, returning to start.');
      goToPage(PageEnum.Phone);
    }
  }, [page]);

  useEffect(() => {
    if (userContext.userCredential?.user.displayName && !name) {
      setName(userContext.userCredential.user.displayName);
    }
  }, [userContext.userCredential]);

  const onCheckboxChange = (checked: boolean) => {
    setIsChecked(checked);
  };

  const moveUserForward = async () => {
    if (name.match(/^\s*$/)) {
      setError('Name is a required field');
      return;
    }

    startUserEvent(UserEventType.UserNameSubmitted);
    void userContext.createUser(name, isChecked, coinContext.getGratisSpent());
  };

  const onSubmitName = async (e: FormEvent) => {
    e.preventDefault();
    if (submittingName) {
      return;
    }

    if (userContext.hasNudgedToAllowTexts || isChecked || location.state?.codeType !== CodeType.Phone) {
      if (name.match(/^\s*$/)) {
        setError('Name is a required field');
        return;
      }
      setSubmittingName(true);

      startUserEvent(UserEventType.UserNameSubmitted);
      void userContext.createUser(name, isChecked, coinContext.getGratisSpent());
    } else {
      //reminder modal
      modalContext.show({
        contents: <AllowTextReminderModal close={modalContext.hide} setIsChecked={setIsChecked}
                                          allowTexts={moveUserForward}/>
      });
    }
  };

  const handlePhoneChange = (value: string, fullValue: string) => {
    userContext.setPhoneNumber(fullValue);
    setPhone(value);
    setValidPhone(isPossiblePhoneNumber(fullValue));
    setError('');
  };

  const handleEmailChange = (email: string) => {
    userContext.setEmail(email);
    setEmail(email);
    setValidEmail(validateEmail(email));
    setError('');
  };

  const onPhoneFormSubmit = async (e: FormEvent) => {
    e.preventDefault();
    await onSubmitPhone();
  };

  const onSubmitPhone = async (force?: boolean) => {
    if (!validPhone) {
      setError('Please enter a valid phone number.');
      return;
    }

    userContext.setEmail(undefined);
    await userContext.submitPhone(userContext.phoneNumber, force);
  };

  const onEmailFormSubmit = async (e: FormEvent) => {
    e.preventDefault();
    await onSubmitEmail();
  };

  const onSubmitEmail = async (force?: boolean) => {
    if (!validEmail) {
      setError('Please enter a valid email address.');
      return;
    }

    deletePhone();
    userContext.setPhoneNumber(undefined);
    await userContext.submitEmail(email, force);
  };

  const onLoginToGoogle = () => {
    logEvent(ULogSeverity.INFO, ULogTag.UserAuth, 'Starting Google OAuth flow.');
    deletePhone();
    userContext.setPhoneNumber(undefined);
    window.sessionStorage.setItem('locationState', JSON.stringify(location.state));
    userContext.loginToGoogle();
  };

  const onLoginToApple = () => {
    logEvent(ULogSeverity.INFO, ULogTag.UserAuth, 'Apple Google OAuth flow.');
    deletePhone();
    userContext.setPhoneNumber(undefined);
    window.sessionStorage.setItem('locationState', JSON.stringify(location.state));
    userContext.loginToApple();
  };

  const goToEmail = () => {
    setError('');
    goToPage(PageEnum.Email);
  };

  const goToPhone = () => {
    setError('');
    goToPage(PageEnum.Phone);
  };

  let content: ReactNode;
  let additionalContent: ReactNode = null;
  let title: ReactNode;
  let subtitle: ReactNode;

  if (signingIn) {
    content = <>
      Signing in to {providerName}...
    </>;
    additionalContent = <div/>;
  } else if (page === PageEnum.Name) {
    const checkboxLabel = 'Yes! By checking this box, I authorize Shine to text me invitations, updates, responses and reminders. Message and data rates may apply. To opt-out at any time reply "STOP".';

    title = 'Last step!';
    subtitle = 'Enter your name.';
    content = <>
      <LoginInput>
        <LoginInputHeader>
          <LoginInputHeaderTitle $isInvalid={error !== ''}>Name</LoginInputHeaderTitle>
        </LoginInputHeader>
        <InputForm onSubmit={onSubmitName}>
          <WhiteInput
            autoFocus={true}
            placeholder="Your name"
            $isInvalid={error !== ''}
            value={name}
            onChange={(e) => setName(e.currentTarget.value)}
            disabled={submittingName}
          />
        </InputForm>
        {
          error && <ErrorContainer>
            <ErrorIcon/> <span>{error}</span>
          </ErrorContainer>
        }
      </LoginInput>
      <LoginNextButton onClick={onSubmitName}>
        Continue
      </LoginNextButton>

      {
        location.state?.codeType === CodeType.Phone && <CheckboxContainer>
          <Checkbox id="contact-opt-out" checked={isChecked} onInputChange={onCheckboxChange}>
            {checkboxLabel}
          </Checkbox>
        </CheckboxContainer>
      }
    </>;
  } else if (page === PageEnum.Code && location.state.codeType !== undefined) {
    title = 'Enter code';
    subtitle = `We sent a code to ${location.state.sentTo}.`;
    content = <ConfirmCode
      error={userContext.loginState.error ?? error}
      codeType={location.state.codeType}
    />;
  } else if (page === PageEnum.LinkPhone) {
    // Not used yet
    title = 'Link phone number';
    subtitle = 'Get reminders via SMS.';
    content = <>
      <LoginInput>
        <LoginInputHeader>
          <LoginInputHeaderTitle>Phone Number</LoginInputHeaderTitle>
        </LoginInputHeader>
        <PhoneInput
          autoFocus={true}
          placeholder="(123) 555-1234"
          disabled={busy}
          autoComplete="tel"
          value={phone}
          required={true}
          onChange={handlePhoneChange}
        />
      </LoginInput>
      <LoginNextButton>
        Continue
      </LoginNextButton>
      <LoginSecondaryButtonText>Not now</LoginSecondaryButtonText>
    </>;
  } else if (page === PageEnum.Phone) {
    if (isForcedLogin) {
      title = 'Sign in to RSVP';
      subtitle = 'Sign in to RSVP for the event and stay connected.';
    } else if (hasEventBackground) {
      title = 'Sign in or sign up for Shine';
      subtitle = <>Thank you for RSVPing for <strong>{eventTitle ?? 'this event'}.</strong> Sign in to stay connected
        and share photos.</>;
    } else {
      title = 'Let\'s do it!';
      subtitle = 'Sign in or sign up below.';
    }
    content = <>
      <SinglePhoneEmailInput phone={phone} email={email} showPhoneInput={true} switchToEmail={goToEmail}
                             switchToPhone={goToPhone} handleEmailChange={handleEmailChange}
                             handlePhoneChange={handlePhoneChange} submitPhone={onPhoneFormSubmit}
                             submitEmail={onEmailFormSubmit} error={error} setError={setError}/>
      <LoginNextButton onClick={() => onSubmitPhone()} disabled={busy}>
        Continue with Phone
      </LoginNextButton>
    </>;
  } else if (page === PageEnum.Email) {
    if (isForcedLogin) {
      title = 'Sign in to RSVP';
      subtitle = 'Sign in to RSVP for the event and stay connected.';
    } else if (hasEventBackground) {
      title = 'Sign in or sign up for Shine';
      subtitle = <>Thank you for RSVPing for <strong>{eventTitle ?? 'this event'}.</strong> Sign in to stay connected
        and share photos.</>;
    } else {
      title = 'Let\'s do it!';
      subtitle = 'Sign in or sign up below.';
    }
    content = <>
      <SinglePhoneEmailInput phone={phone} email={email} showPhoneInput={false} switchToEmail={goToEmail}
                             switchToPhone={goToPhone} handleEmailChange={handleEmailChange}
                             handlePhoneChange={handlePhoneChange} submitPhone={onPhoneFormSubmit}
                             submitEmail={onEmailFormSubmit} error={error} setError={setError}/>
      <LoginNextButton onClick={() => onSubmitEmail()}>
        Continue with Email
      </LoginNextButton>
    </>;
  }

  if (!signingIn && (page === PageEnum.Phone || page === PageEnum.Email)) {
    additionalContent = <>
      <LoginDivider/>
      <LoginGoogleButtonContainer>
        <LoginGoogleButton onClick={onLoginToGoogle} disabled={busy} $hasEventBackground={hasEventBackground}>
          <GoogleIcon size={14}/> Sign in with Google
        </LoginGoogleButton>
      </LoginGoogleButtonContainer>
      <LoginGoogleButtonContainer>
        <LoginAppleButton onClick={onLoginToApple} disabled={busy} $hasEventBackground={hasEventBackground}>
          <AppleIcon size={14} color={hasEventBackground ? '#000' : '#FFF'}/> Sign in with Apple
        </LoginAppleButton>
      </LoginGoogleButtonContainer>
    </>;
  }

  if (hasEventBackground) {
    return <>
      <Container>
        <BackgroundEventPageWrapper>
          <EventPage eventIdProp={eventId}/>
        </BackgroundEventPageWrapper>
        <Overlay/>
        <ForegroundContentContainer>
          <LoginContainer onClick={() => navigate(`/event/${eventId}`)}>
            <Login $wide={true} onClick={(e) => e.stopPropagation()}>
              <LoginHeader>
                <LoginTitle>{title}</LoginTitle>
                <LoginSubtitle>{subtitle}</LoginSubtitle>
              </LoginHeader>
              <LoginMain>
                <LoginInputContainer>
                  {content}
                </LoginInputContainer>
                {additionalContent}
              </LoginMain>
            </Login>
          </LoginContainer>
        </ForegroundContentContainer>
      </Container>
    </>;
  } else {
    return <>
      <Container>
        <BasePageHeader page={UPageType.Login}/>
        <LoginContainer>
          <Login>
            <LoginHeader>
              <LoginTitle>{title}</LoginTitle>
              <LoginSubtitle>{subtitle}</LoginSubtitle>
            </LoginHeader>
            <LoginMain>
              <LoginInputContainer>
                {content}
              </LoginInputContainer>
              {additionalContent}
            </LoginMain>
          </Login>
        </LoginContainer>
        <Spacer/>
      </Container>
    </>;
  }
};

const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
`;

const LoginContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000000;
`;

const Spacer = styled.div`
  height: 100px;
  width: 100%;
`;

const Login = styled.div<{
  $wide?: boolean
}>`
  height: auto;
  width: ${({ $wide }) => $wide ? '470px' : '330px'};
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-top: 18px;
  padding-bottom: 18px;
  border-radius: 20px;
  border: 1px solid rgba(0, 0, 0, 0.10);
  background: rgba(255, 255, 255, 0.80);
  box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(40px);
  -webkit-backdrop-filter: blur(40px); // Safari
`;

const LoginDivider = styled.div`
  width: 100%;
  background: black;
  height: 1px;
  opacity: 10%;
`;

const LoginHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 0 24px;
  //color: #000000;
`;

const LoginTitle = styled.div`
  font-size: 28px;
  font-family: Poppins, sans-serif;
  font-weight: 600;
  width: 100%;
`;

const LoginSubtitle = styled.div`
  font-size: 16px;
  font-family: Poppins, sans-serif;
  font-weight: 500;
  width: 100%;
  opacity: 60%;
`;

const LoginMain = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const LoginInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: auto;
  gap: 12px;
  padding: 0 24px;

  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
`;

const LoginInput = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  gap: 6px;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
`;

const LoginInputHeader = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: auto;
  justify-content: space-between;
`;

const LoginInputHeaderTitle = styled.div<{
  $isInvalid?: boolean
}>`
  font-size: 14px;
  font-weight: 500;
  ${({ $isInvalid }) => $isInvalid ? 'color: #B3251E;' : 'opacity: 40%;'}
`;

const LoginSecondaryButtonText = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: black;
  opacity: 30%;
`;

const LoginGoogleButtonContainer = styled.div`
  display: flex;
  padding: 0 24px;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
`;

const LoginButton = styled.button<{
  $hasEventBackground?: boolean
}>`
  font-family: Poppins, sans-serif;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  gap: 10px;
  width: 100%;
  height: auto;
  padding: ${({ $hasEventBackground }) => $hasEventBackground ? '7px 20px;' : '6px 10px;'};
  border-radius: ${({ $hasEventBackground }) => $hasEventBackground ? '12px' : '6px'};
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;

  &:hover {
    filter: brightness(90%);
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const LoginNextButton = styled(LoginButton)`
  background: #FF6422;
  border: 1px solid #FF6422;
  color: white;
`;

const LoginGoogleButton = styled(LoginButton)<{
  $hasEventBackground?: boolean
}>`
  background: #FFF;
  border: ${({ $hasEventBackground }) => $hasEventBackground ? 'none' : '1px solid rgba(0, 0, 0, 0.20);'};
  color: #313131;
`;

const LoginAppleButton = styled(LoginButton)<{
  $hasEventBackground?: boolean
}>`
  background: ${({ $hasEventBackground }) => $hasEventBackground ? '#FFF' : '#000'};
  border: ${({ $hasEventBackground }) => $hasEventBackground ? 'none' : '1px solid #000;'};
  color: ${({ $hasEventBackground }) => $hasEventBackground ? '#313131' : '#FFF'};
`;

const CheckboxContainer = styled.div`

`;

const InputForm = styled.form`
  display: contents;
`;

const ErrorContainer = styled.div`
  display: flex;
  gap: 6px;
  color: #B3251E;
  font-size: 12px;
  align-items: center;
`;

const BackgroundEventPageWrapper = styled.div`
  top: 0;
  left: 0;
  position: absolute;
  z-index: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const ForegroundContentContainer = styled(Container)`
  z-index: 100;
`;

export default LoginPage;
