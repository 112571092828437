import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { useEditEventContext } from '../contexts/EditEventContext';
import { useUserContext } from '../contexts/UserContext';
import { useEventCacheContext } from '../contexts/EventCacheContext';
import { editEventDetails } from '../api/ElkEventService';
import { endUserEvent } from '../lib/performance';
import { UButterBarLevel, useButterBarContext } from '../contexts/ButterBarContext';
import { useAutoSaveContext } from '../contexts/AutoSaveContext';
import { IUEvent } from '../lib/event';

import { logSumoEvent, stringifyError, ULogApplication, ULogSeverity, ULogTag } from 'Common/src/api/SumoLogicApi';
import { VerticalFullContainer } from 'Common/src/components/styled';

const CreateEventAction = () => {
  const userContext = useUserContext();
  const editEventContext = useEditEventContext();
  const eventCacheContext = useEventCacheContext();
  const autoSaveContext = useAutoSaveContext();
  const butterBarContext = useButterBarContext();
  const navigate = useNavigate();

  const submitting = useRef(false);
  const [afterCreate, setAfterCreate] = useState(false);

  useEffect(() => {
    document.title = 'Shine - Create Event';

    if (editEventContext.event !== undefined && !submitting.current) {
      if (userContext.name !== '') {
        void saveEvent();
      }
      // Otherwise, wait for name to be set
    }
  }, [editEventContext.event, userContext.name]);

  useEffect(() => {
    if (afterCreate && editEventContext.event) {
      void afterCreateEvent(editEventContext.event);
    }
  }, [afterCreate]);

  const saveEvent = async () => {
    submitting.current = true;
    const event = await editEventContext.generateEvent();
    if (event !== undefined) {
      try {
        if (editEventContext.event) {
          eventCacheContext.setEvent(editEventContext.event);
        }

        if (editEventContext.event?.isCreate) {
          await editEventContext.createEvent(event, editEventContext.event.hostUploadMode,
            editEventContext.event?.existingAlbumId);
          setAfterCreate(true);
        } else {
          await editEventDetails(userContext, event, editEventContext.event?.hostUploadMode, false, true);

          endUserEvent(userContext);
          navigate(`/event/${event.id}`, { replace: true, state: { showGuestOptions: true } });
        }
      } catch (e) {
        void logSumoEvent({
          app: ULogApplication.ELK,
          severity: ULogSeverity.ERROR,
          userId: userContext.id,
          tag: ULogTag.Network,
          message: `[CreateEventAction] Error during event creation: ${stringifyError(e)}`
        });
        butterBarContext.show({
          level: UButterBarLevel.ERROR,
          contents: 'Sorry, something went wrong saving your event. Please try again. If the problem persists, please contact us at support@sunshine.com.',
          duration: 20000
        });
        editEventContext.setError(
          'Sorry, something went wrong saving your event. Please try again. If the problem persists, please contact us at support@sunshine.com.');
        // TEMP - redirect to /events just in case the submitting ref doesn't prevent the multiple submits
        navigate('/events', { replace: true });
      }
    }
  };

  const afterCreateEvent = async (event: IUEvent) => {
    await autoSaveContext.remove(event.id);

    endUserEvent(userContext);
    navigate(`/event/${event.id}`, { replace: true, state: { showGuestOptions: true } });
  };

  return <Container>
    Saving your invite...
  </Container>;
};

const Container = styled(VerticalFullContainer)`
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 24px;
`;

export default CreateEventAction;
