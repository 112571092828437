import React from 'react';

const Mail = ({ color = 'currentColor', size = 24 }: { color?: string, size?: number }) => {
  return <svg width={size} height={size} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_6_680)">
      <path
        d="M13.8768 2.78149L9.63086 7.00006L13.8768 11.2186C13.9535 11.0582 14.0001 10.8808 14.0001 10.6915V3.30865C14.0001 3.11924 13.9535 2.94192 13.8768 2.78149Z"
        fill={color}/>
      <path
        d="M12.7693 2.07812H1.23029C1.04087 2.07812 0.863551 2.12469 0.703125 2.20145L6.12985 7.60082C6.60968 8.08065 7.38996 8.08065 7.86978 7.60082L13.2965 2.20145C13.1361 2.12469 12.9588 2.07812 12.7693 2.07812Z"
        fill={color}/>
      <path
        d="M0.12332 2.78149C0.0465664 2.94192 0 3.11924 0 3.30865V10.6915C0 10.8809 0.0465664 11.0582 0.12332 11.2186L4.36923 7.00006L0.12332 2.78149Z"
        fill={color}/>
      <path
        d="M9.0506 7.57996L8.44977 8.18078C7.65027 8.98028 6.34934 8.98028 5.54983 8.18078L4.94903 7.57996L0.703125 11.7985C0.863551 11.8753 1.04087 11.9218 1.23029 11.9218H12.7693C12.9588 11.9218 13.1361 11.8753 13.2965 11.7985L9.0506 7.57996Z"
        fill={color}/>
    </g>
    <defs>
      <clipPath id="clip0_6_680">
        <rect width="14" height="14" fill="white"/>
      </clipPath>
    </defs>
  </svg>
    ;
};

export default Mail;
