/* eslint no-console: "off" */

import Thrift, {TClientConstructor} from 'thrift';
import {v4 as uuidv4} from 'uuid';

import { getDeviceId } from './DeviceId';
import { applicationFromProduct } from '../api/SumoLogicApi';

import {SunshineXhrConnection} from 'Common/src/lib/SunshineXhrConnection';

import { TAppProduct, TUnauthHeader, TUserAgent } from 'TProtocol/common/models';

declare const WEBSERVER_URL: string;
declare const WEBSERVER_PORT: number;
declare const WEBSERVER_HTTPS: boolean;

export default class Net {
  static createService<TClient>(service: TClientConstructor<TClient>, path: string): TClient {
    const transport = Thrift.TBufferedTransport;
    const protocol = Thrift.TJSONProtocol;
    const connection = new SunshineXhrConnection(WEBSERVER_URL, WEBSERVER_PORT, {
      transport: transport,
      protocol: protocol,
      path: path,
      https: WEBSERVER_HTTPS,
      timeout: 60000 // 1 minute timeout for network calls
    });

    connection.on('Connection Error: ', () => console.log('Failed to create ' + path));
    return Thrift.createXHRClient(service, connection);
  }
}

export const createUnauthHeader = (product: TAppProduct, requestId = uuidv4()): TUnauthHeader => {
  return new TUnauthHeader({
    deviceId: getDeviceId(applicationFromProduct(product)),
    agent: new TUserAgent({
      platform: 'web',
      version: '0.0.0.2',
      product
    }),
    timestamp: Math.floor(new Date().getTime() / 1000),
    requestId
  });
}
