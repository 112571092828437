import React from 'react';
import styled from 'styled-components';

import { ExternalLabel } from '../forms/Input';

import { TAppElkNotificationPreference } from 'TProtocol/prototypes/events/messages';

interface Props {
  value: TAppElkNotificationPreference;
  onChange: (value: TAppElkNotificationPreference) => void;
}

const NotificationPreferenceSelector = ({ value, onChange }: Props) => {

  return <Container>
    <ExternalLabel>
      You&apos;d like to receive updates via
    </ExternalLabel>
    <div>
      <InputLabel htmlFor="phone_update">
        <Radio type="radio" name="updates" id="phone_update"
               value={TAppElkNotificationPreference.PHONE}
               checked={value === TAppElkNotificationPreference.PHONE}
               onChange={() => onChange(TAppElkNotificationPreference.PHONE)}
        />
        Text
      </InputLabel>

      <InputLabel htmlFor="email_update">
        <Radio type="radio" name="updates" id="email_update"
               value={TAppElkNotificationPreference.EMAIL}
               checked={value === TAppElkNotificationPreference.EMAIL}
               onChange={() => onChange(TAppElkNotificationPreference.EMAIL)}
        />
        Email
      </InputLabel>

      <InputLabel htmlFor="both_update">
        <Radio type="radio" name="updates" id="both_update"
               value={TAppElkNotificationPreference.BOTH}
               checked={value === TAppElkNotificationPreference.BOTH}
               onChange={() => onChange(TAppElkNotificationPreference.BOTH)}
        />
        Both
      </InputLabel>
    </div>
  </Container>;
};

const Container = styled.div`
  font-family: Poppins, sans-serif;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Radio = styled.input`
`;

const InputLabel = styled.label`
  margin-right: 20px;
`;

export default NotificationPreferenceSelector;
