import React, { PropsWithChildren, ReactNode, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import { StyleLabels } from '../../lib/styles';
import { ButtonContainer } from './GenerateButton';
import { useBackgroundContext } from '../../contexts/BackgroundContext';
import DropdownSelector from '../icons/DropdownSelector';
import { getEventTintColor } from '../../lib/colorPicker';

import { TAppElkImageStyle } from 'TProtocol/prototypes/events/messages';

interface Props {
  buttonText: ReactNode;
  disabled: boolean;
  style: TAppElkImageStyle;
  onStyleChange: (style: TAppElkImageStyle) => void;
}

const MENU_ITEMS = (Object.keys(StyleLabels) as unknown as (keyof typeof StyleLabels)[]).map((id) => {
  return {
    id,
    label: StyleLabels[id]
  };
}).sort((a, b) => {
  if (+a.id === TAppElkImageStyle.DEFAULT) {
    return -1;
  }
  if (+b.id === TAppElkImageStyle.DEFAULT) {
    return 1;
  }
  return (a.label < b.label) ? -1 : 1;
});

const ImageStyleDropdownButton = ({
                                    buttonText,
                                    disabled,
                                    style,
                                    onStyleChange
                                  }: PropsWithChildren<Props>) => {
  const backgroundContext = useBackgroundContext();

  const [showMenu, setShowMenu] = useState(false);
  const menuDivRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClick = (event: MouseEvent) => {
      if (menuDivRef.current && !menuDivRef.current.contains(event.target as Node)) {
        setShowMenu(false);
      }
    };

    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, []);

  const onDropdownClick = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setShowMenu(!showMenu);
  };

  const handleMenuClick = (id: TAppElkImageStyle) => {
    setShowMenu(false);
    onStyleChange(id);
  };

  return <DropdownButtonContainer>
    <ButtonContainer>
      <DropdownButton disabled={disabled} onClick={onDropdownClick}>
        <ContentContainer>
          <StyleLabel>Style</StyleLabel>
          <TextWrapper>{buttonText}</TextWrapper>
        </ContentContainer>
        <DropdownSelector/>
      </DropdownButton>
    </ButtonContainer>
    <DropdownMenuAnchor>
      {showMenu && <DropdownMenu ref={menuDivRef}>
        {
          MENU_ITEMS.map((menuItem) => <MenuItem
            key={`${menuItem.id}`}
            onClick={() => handleMenuClick(menuItem.id)}
            $selected={menuItem.id === style}
            $highlightColor={getEventTintColor(backgroundContext.colors.primary)}
          >
            {menuItem.label}
          </MenuItem>)
        }
      </DropdownMenu>}
    </DropdownMenuAnchor>
  </DropdownButtonContainer>;
};

const TextWrapper = styled.div`
  justify-content: center;
  align-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: 400;
  line-height: 160%; /* 28.8px */
  color: #000000;
`;

const StyleLabel = styled(TextWrapper)`
  color: rgba(0, 0, 0, 0.60);
`;

const ContentContainer = styled.span`
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
`;

const DropdownButton = styled.button`
  border: none;
  border-radius: 12px;
  background: rgba(255, 255, 255, 0.30);
  box-shadow: 0 2px 5px 0 rgba(103, 110, 118, 0.08), 0 0 0 1px rgba(103, 110, 118, 0.16), 0 1px 1px 0 rgba(0, 0, 0, 0.12);
  padding: 8px 10px;
  font-family: Poppins, sans-serif;
  display: flex;
  align-items: center;
  cursor: pointer;

  &:hover {
    filter: brightness(90%);
  }
`;

const DropdownMenuAnchor = styled.div`
  height: 0;
  position: relative;
  z-index: 200;
`;

const DropdownMenu = styled.div`
  position: absolute;
  top: 12px;
  right: 0;
  color: black;
  border-radius: 12px;
  background: rgba(255, 255, 255, 0.90);
  backdrop-filter: blur(40px);
  -webkit-backdrop-filter: blur(40px); // Safari
  box-shadow: 0 2px 5px 0 rgba(103, 110, 118, 0.08), 0 0 0 1px rgba(103, 110, 118, 0.16), 0 1px 1px 0 rgba(0, 0, 0, 0.12);
  width: 100%;
  max-height: 225px;
  overflow-y: scroll;
`;

const MenuItem = styled.div<{
  $selected: boolean,
  $highlightColor: string
}>`
  cursor: pointer;
  padding: 8px 10px;
  font-size: 16px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.60);
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);

  ${({ $selected, $highlightColor }) => $selected && `
    background-color: ${$highlightColor};
    color: #FFFFFF;
  `};

  &:hover {
    background-color: ${({ $highlightColor }) => $highlightColor};
    color: #FFFFFF;
  }

  &:first-of-type {
    border-radius: 12px 12px 0 0;
  }

  &:last-of-type {
    border-radius: 0 0 12px 12px;
    border-bottom: none;
  }
`;

const DropdownButtonContainer = styled.div`
  width: 100%;
`;

export default ImageStyleDropdownButton;
