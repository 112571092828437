import { TAppAddress } from 'TProtocol/common/models';
import PlaceResult = google.maps.places.PlaceResult;
import GeocoderAddressComponent = google.maps.GeocoderAddressComponent;

import { IUEvent } from './event';

import { convertResultToAddress, searchPlace } from 'Common/src/api/GooglePlacesApi';

export const addLocationToEvent = async (event?: IUEvent) => {
  if (event === undefined) {
    return;
  }

  if (event.location.address === undefined) {
    // We showed the Google widget when first creating the event or if the user edited it.
    // But if the user made no changes while editing, then the address data was lost, so we need it again.
    const place = await searchPlace(
      document.createElement('div'),
      event.location.displayName
    );
    if (place !== undefined && validEventLocation(place)) {
      event.location.address = convertResultToAddress(place);
    }
  }
};

export const validEventLocation = (place: PlaceResult): boolean => {
  if (!place.address_components) {
    return false;
  }

  comp: for (const component of place.address_components) {
    for (const type of component.types) {
      if ([
        'locality',
        'sublocality',
        'administrative_area_level_1',
        'administrative_area_level_2',
        'administrative_area_level_3',
        'administrative_area_level_4',
        'administrative_area_level_5',
        'country'
      ].indexOf(type) !== -1) {
        continue comp;
      }
    }
    return true;
  }

  return false;
};

export const getAddressString = (address: TAppAddress): string => {
  let addStr = '';
  if (address.address1) {
    addStr = address.address1;
  }

  if (address.address2 && address.address1) {
    addStr += ', ' + address.address2;
  } else if (address.address2) {
    addStr += address.address2;
  }

  if ((address.city && address.address1) || (address.city && address.address2)) {
    addStr += ', ' + address.city;
  } else if (address.city) {
    addStr += address.city;
  }

  if (((address.admin1 && address.address1) || (address.admin1 && address.address2)) && (address.city)) {
    addStr += ' ' + address.admin1;
  } else if (((address.admin1 && address.address1) || (address.admin1 && address.address2)) && (!address.city)) {
    addStr += ', ' + address.admin1;
  } else if ((address.admin1 && address.city)) {
    addStr += ' ' + address.admin1;
  }

  if (address.postalCode && !address.city && !address.admin1 && (address.address1 || address.address2)) {
    addStr += ', ' + address.postalCode;
  } else if (address.postalCode) {
    addStr += ' ' + address.postalCode;
  }

  return addStr;
};
