import React from 'react';

const TrashOutline = ({ color = 'currentColor', size = 20 }: { color?: string, opacity?: number, size?: number }) => {
  return <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.833 5.83333L15.1105 15.9517C15.0806 16.3722 14.8924 16.7657 14.5839 17.053C14.2755 17.3403 13.8696 17.5 13.448 17.5H6.55134C6.12979 17.5 5.7239 17.3403 5.41541 17.053C5.10693 16.7657 4.91877 16.3722 4.88884 15.9517L4.16634 5.83333M8.33301 9.16667V14.1667M11.6663 9.16667V14.1667M12.4997 5.83333V3.33333C12.4997 3.11232 12.4119 2.90036 12.2556 2.74408C12.0993 2.5878 11.8874 2.5 11.6663 2.5H8.33301C8.11199 2.5 7.90003 2.5878 7.74375 2.74408C7.58747 2.90036 7.49967 3.11232 7.49967 3.33333V5.83333M3.33301 5.83333H16.6663"
      stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>;
};

export default TrashOutline;
