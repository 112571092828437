import React, { PropsWithChildren } from 'react';
import { IUEvent } from '../../lib/event';
import styled from 'styled-components';

import { BigImage, ImageContainer } from '../Create/GeneratedImageSelection';
import AddToCalendar from '../../components/EventForm/AddToCalendar';
import { convertTimestampToDateTimeString } from '../../lib/time';
import EventCancelledIndicator from '../../components/EventCancelledIndicator';

import { DeviceQueries } from 'Common/src/components/styled';

const TopModule = ({ event, children }: PropsWithChildren<{
  event: IUEvent
}>) => {
  return <Container>
    <DynamicImageContainer>
      {event.photoUrl ? <CoverImage src={event.photoUrl} $scrolled={scrollY > 200}></CoverImage> : <PlaceholderImage/>}
    </DynamicImageContainer>
    <Content>
      <Title>
        {event.title}
      </Title>

      {event.cancelledTimestamp ? <EventCancelledIndicator/> : <NewContentHeaderDetails>
        <LocationContainer>
          <LocationLink
            href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
              event.location.displayName)}`}
            target="_blank" rel="noreferrer noopener">
            {event.location.placeName ?? event.location.displayName}
          </LocationLink>
        </LocationContainer>
        <SeparatorDot>•</SeparatorDot>
        <DateTimeContainer>
          {convertTimestampToDateTimeString(event.startTime, event.timeZone, true, true)}
          <AddToCalendar event={event}/>
        </DateTimeContainer>
      </NewContentHeaderDetails>}
    </Content>

    {children}

  </Container>;
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  max-width: 684px;
`;

const Content = styled.div`
  width: 100%;
`;

const Title = styled.div`
  font-weight: 600;
  font-size: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
`;

const NewContentHeaderDetails = styled.div`
  font-size: 22px;

  @media not (${DeviceQueries.mobile}) {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  }
`;

const CoverImage = styled(BigImage)<{
  $scrolled: boolean
}>`
  transition: all 0.5s ease;
    // ${({ $scrolled }) => $scrolled && 'transform: scale(0.8)'};
`;

const PlaceholderImage = styled.div`
  width: 400px;
  height: 400px;
  border-radius: 30px;
  background: rgba(255, 255, 255, 0.20);
  backdrop-filter: blur(40px);
`;

const DynamicImageContainer = styled(ImageContainer)`
  //position: fixed;
  //transform: translateX(50%);
  display: flex;
  flex-direction: column;
`;

const LocationContainer = styled.div`
  @media not (${DeviceQueries.mobile}) {
    margin-right: 10px
  }
`;

const DateTimeContainer = styled.div`
  display: flex;
  justify-content: center;

  @media not (${DeviceQueries.mobile}) {
    margin-left: 10px
  }
`;

const LocationLink = styled.a`
  text-decoration: none;
  color: currentColor;

  &:visited {
    color: currentColor;
  }
`;

const SeparatorDot = styled.span`
  @media (${DeviceQueries.mobile}) {
    display: none;
  }
`;

export default TopModule;
