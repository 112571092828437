import React, { MouseEvent, PropsWithChildren, ReactNode } from 'react';
import styled from 'styled-components';

import Sparkles from '../icons/Sparkles';
import SplitButton from '../SplitButton';
import { StyleLabels } from '../../lib/styles';

import { TAppElkImageStyle } from 'TProtocol/prototypes/events/messages';

interface Props {
  buttonText: ReactNode;
  disabled: boolean;
  onClick?: (e: MouseEvent) => void;
  style: TAppElkImageStyle;
  onStyleChange: (style: TAppElkImageStyle) => void;
}

const MENU_ITEMS = (Object.keys(StyleLabels) as unknown as (keyof typeof StyleLabels)[]).map((id) => {
  return {
    id,
    label: StyleLabels[id]
  };
}).sort((a, b) => {
  if (+a.id === TAppElkImageStyle.DEFAULT) {
    return -1;
  }
  if (+b.id === TAppElkImageStyle.DEFAULT) {
    return 1;
  }
  return (a.label < b.label) ? -1 : 1;
});

const GenerateButton = ({ buttonText, disabled, onClick, style, onStyleChange }: PropsWithChildren<Props>) => {
  return <ButtonContainer>
    <SplitButton onClick={onClick} disabled={disabled} menuItems={MENU_ITEMS} onMenuClick={onStyleChange} value={style}>
      <SparklesContainer>
        <Sparkles size={24} color="var(--button-text-color)"></Sparkles>
      </SparklesContainer>
      <TextWrapper>{buttonText}</TextWrapper>
    </SplitButton>
  </ButtonContainer>;
};


export default GenerateButton;

const TextWrapper = styled.div`
  justify-content: center;
  align-content: center;
  align-items: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 160%; /* 28.8px */
  margin: 10px 10px;
`;

export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  z-index: 100;
  justify-content: center;
`;

export const SparklesContainer = styled.div`
  margin-top: 5px;
`;

