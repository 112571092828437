import React from 'react';
import styled from 'styled-components';

const Sparkles = ({ color, size = 24, includePadding }: {
  color?: string,
  size?: number,
  includePadding?: boolean
}) => {
  return <Container $includePadding={includePadding}>
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" fill="none">
      <g clipPath="url(#clip0_1_1301)">
        <path
          d="M13.9752 6.40617C11.0659 5.79628 8.79194 3.52233 8.18214 0.612984C8.10724 0.255844 7.79224 0 7.42722 0C7.06221 0 6.74721 0.255844 6.67235 0.613031C6.0625 3.52233 3.78855 5.79623 0.879252 6.40603C0.522065 6.48089 0.266174 6.79594 0.266174 7.16091C0.266174 7.52588 0.522018 7.84092 0.879252 7.91578C3.7885 8.52567 6.06236 10.7996 6.67221 13.7089C6.74707 14.0661 7.06207 14.322 7.42708 14.322C7.79205 14.322 8.1071 14.0661 8.18196 13.7089C8.79185 10.7996 11.0658 8.52567 13.9751 7.91592C14.3323 7.84106 14.5882 7.52606 14.5882 7.16105C14.5882 6.79608 14.3323 6.48103 13.9752 6.40617Z"
          fill={color}/>
        <path
          d="M12.7002 19.1477C11.3295 18.8604 10.2581 17.7891 9.97071 16.4183C9.89585 16.0611 9.58085 15.8052 9.21583 15.8052C8.85086 15.8052 8.53582 16.061 8.46096 16.4182C8.17357 17.789 7.10214 18.8603 5.73143 19.1477C5.37424 19.2226 5.11835 19.5376 5.11835 19.9026C5.11835 20.2675 5.37419 20.5826 5.73143 20.6574C7.1021 20.9448 8.17352 22.0162 8.46086 23.387C8.53572 23.7442 8.85072 24.0001 9.21574 24.0001C9.58071 24.0001 9.89575 23.7443 9.97061 23.387C10.258 22.0162 11.3294 20.9448 12.7002 20.6575C13.0574 20.5826 13.3133 20.2676 13.3133 19.9026C13.3133 19.5376 13.0574 19.2226 12.7002 19.1477Z"
          fill={color}/>
        <path
          d="M23.1208 12.0064C21.268 11.6179 19.8199 10.1698 19.4315 8.31706C19.3567 7.95987 19.0416 7.70398 18.6766 7.70398C18.3117 7.70398 17.9966 7.95978 17.9218 8.31701C17.5334 10.1698 16.0852 11.6179 14.2325 12.0063C13.8753 12.0811 13.6194 12.3962 13.6194 12.7611C13.6194 13.1261 13.8752 13.4411 14.2325 13.516C16.0852 13.9044 17.5333 15.3525 17.9217 17.2053C17.9965 17.5625 18.3115 17.8184 18.6765 17.8184C19.0415 17.8184 19.3566 17.5626 19.4314 17.2053C19.8199 15.3525 21.268 13.9044 23.1207 13.5161C23.4779 13.4412 23.7338 13.1262 23.7338 12.7612C23.7338 12.3963 23.4779 12.0812 23.1208 12.0064Z"
          fill={color}/>
      </g>
      <defs>
        <clipPath id="clip0_1_1301">
          <rect width="24" height="24" fill={color}/>
        </clipPath>
      </defs>
    </svg>
  </Container>;
};

export default Sparkles;

export const Container = styled.div<{ $includePadding?: boolean }>`
  ${({ $includePadding }) => $includePadding && 'padding: 5px;'}
`;
