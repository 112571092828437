import { TAuthHeader } from 'TProtocol/common/models';

declare const WEBSERVER_URL: string;
declare const WEBSERVER_PORT: string;
declare const WEBSERVER_HTTPS: boolean

export interface ICsvUploadResponse {
  isValid: boolean;
  fileName: string;
  rowsImported: number;
  rowsDropped: number;
}

const generateUrl = () => {
  return `${WEBSERVER_HTTPS ? 'https' : 'http'}://${WEBSERVER_URL}:${WEBSERVER_PORT}`;
};

export const upload = async (authHeader: TAuthHeader, file: File, eventId: string): Promise<ICsvUploadResponse> => {
  const formData = new FormData();
  formData.append('multipartFile', file);
  formData.append('authHeader', JSON.stringify(authHeader));
  formData.append('eventId', eventId);

  const url = `${generateUrl()}/ElkCSVFileUploadService`;
  const response = await fetch(url,
    {
      method: 'post',
      body: formData
    }
  );

  if (response.status !== 200) {
    throw new Error(`HTTP response status: ${response.status}`);
  }

  const body = await response.json();
  if (body === undefined || body === '') {
    throw new Error('Empty body');
  }

  return body as ICsvUploadResponse;
};
