/* copied from duck-web, move to common when productionizing */

import styled from 'styled-components';

import { DeviceQueries } from 'Common/src/components/styled';

export const SunshineH3 = styled.h3`
  font-size: 42px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #000;
`;

export const SunshineH4 = styled.h4`
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #000;
`;

export const SunshineH4Regular = styled(SunshineH4)`
  font-weight: normal;
`;

export const MobileShow = styled.div`
  display: contents;

  @media not (${DeviceQueries.mobile}) {
    display: none;
  }
`;

export const DesktopShow = styled.div`
  display: contents;

  @media (${DeviceQueries.mobile}) {
    display: none;
  }
`;
