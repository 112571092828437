import React from 'react';

const LightBulbOutline = ({ color = 'currentColor', size = 21 }: { color?: string, size?: number }) => {
  return <svg width={size} height={size} viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.0525 14.6667H11.9467M10 3V3.83333M15.3033 5.19667L14.7142 5.78583M17.5 10.5H16.6667M3.33333 10.5H2.5M5.28583 5.78583L4.69667 5.19667M7.05333 13.4467C6.47072 12.8639 6.074 12.1215 5.91331 11.3132C5.75263 10.505 5.83521 9.6673 6.15061 8.90601C6.466 8.14471 7.00006 7.49403 7.68524 7.03624C8.37042 6.57845 9.17596 6.33411 10 6.33411C10.824 6.33411 11.6296 6.57845 12.3148 7.03624C12.9999 7.49403 13.534 8.14471 13.8494 8.90601C14.1648 9.6673 14.2474 10.505 14.0867 11.3132C13.926 12.1215 13.5293 12.8639 12.9467 13.4467L12.49 13.9025C12.2289 14.1636 12.0218 14.4736 11.8806 14.8148C11.7393 15.1559 11.6666 15.5216 11.6667 15.8908V16.3333C11.6667 16.7754 11.4911 17.1993 11.1785 17.5118C10.866 17.8244 10.442 18 10 18C9.55797 18 9.13405 17.8244 8.82149 17.5118C8.50893 17.1993 8.33333 16.7754 8.33333 16.3333V15.8908C8.33333 15.145 8.03667 14.4292 7.51 13.9025L7.05333 13.4467Z"
      stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>;
};

export default LightBulbOutline;
