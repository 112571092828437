import React from 'react';

const LeftArrow = ({ color = 'currentColor', size = 20 }: { color?: string, size?: number }) => {
  return <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="ArrowLeftOutline">
      <path id="Vector" d="M8.33333 15.8346L2.5 10.0013M2.5 10.0013L8.33333 4.16797M2.5 10.0013H17.5" stroke={color}
            strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </g>
  </svg>;
};

export default LeftArrow;
