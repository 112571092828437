import React from 'react';

const LogoutActionIcon = ({ color = 'CurrentColor' }: { color?: string }) => {
  return <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd"
          d="M35 17.5C35 27.165 27.165 35 17.5 35C7.83502 35 0 27.165 0 17.5C0 7.83502 7.83502 0 17.5 0C27.165 0 35 7.83502 35 17.5ZM17.0929 26C19.1663 26 21.2397 25.1711 22.8181 23.5133C23.0606 23.2588 23.0606 22.8461 22.8181 22.5916C22.5756 22.3371 22.1828 22.3368 21.9405 22.5916C19.2671 25.3987 14.9182 25.3985 12.2453 22.5916C9.57241 19.7839 9.57241 15.216 12.2453 12.4086C14.9185 9.6012 19.2674 9.6012 21.9405 12.4086C22.1831 12.6633 22.5758 12.6633 22.8181 12.4086C23.0606 12.1541 23.0606 11.7414 22.8181 11.4869C19.6611 8.17104 14.5245 8.17104 11.3678 11.4869C8.21075 14.8025 8.21075 20.1977 11.3678 23.5136C12.9461 25.1711 15.0195 26 17.0929 26ZM28.5263 17.0284L28.5268 17.0289L28.5276 17.0297L29 17.5001L25.6808 20.8051C25.5501 20.935 25.3792 21 25.2081 21C25.037 21 24.8659 20.935 24.7355 20.8051C24.4743 20.5453 24.4743 20.1242 24.7355 19.8641L26.4412 18.1656H16.6684C16.2992 18.1656 16 17.8677 16 17.5001C16 17.1325 16.2992 16.8346 16.6684 16.8346H26.4414L24.7357 15.1361C24.4745 14.8763 24.4745 14.4549 24.7357 14.1951C24.9967 13.935 25.4196 13.935 25.6808 14.1951L28.5263 17.0284Z"
          fill={color}/>
  </svg>
    ;
};

export default LogoutActionIcon;
