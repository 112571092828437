import React from 'react';

const Clock = ({ color = 'currentColor', size = 17, opacity = 1 }: {
  color?: string,
  size?: number,
  opacity?: number
}) => {
  return <svg width={size} height={size} viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd"
          d="M7.99998 14.9016C9.69736 14.9016 11.3252 14.2273 12.5255 13.027C13.7257 11.8268 14.4 10.1989 14.4 8.50156C14.4 6.80418 13.7257 5.17631 12.5255 3.97608C11.3252 2.77585 9.69736 2.10156 7.99998 2.10156C6.30259 2.10156 4.67473 2.77585 3.47449 3.97608C2.27426 5.17631 1.59998 6.80418 1.59998 8.50156C1.59998 10.1989 2.27426 11.8268 3.47449 13.027C4.67473 14.2273 6.30259 14.9016 7.99998 14.9016ZM8.79998 5.30156C8.79998 5.08939 8.71569 4.88591 8.56566 4.73588C8.41563 4.58585 8.21215 4.50156 7.99998 4.50156C7.7878 4.50156 7.58432 4.58585 7.43429 4.73588C7.28426 4.88591 7.19998 5.08939 7.19998 5.30156V8.50156C7.20002 8.71372 7.28434 8.91717 7.43438 9.06716L9.69678 11.3304C9.7711 11.4047 9.85934 11.4637 9.95646 11.5039C10.0536 11.5441 10.1577 11.5648 10.2628 11.5648C10.3679 11.5648 10.472 11.5441 10.5691 11.5039C10.6662 11.4637 10.7544 11.4047 10.8288 11.3304C10.9031 11.256 10.9621 11.1678 11.0023 11.0707C11.0425 10.9736 11.0632 10.8695 11.0632 10.7644C11.0632 10.6592 11.0425 10.5552 11.0023 10.458C10.9621 10.3609 10.9031 10.2727 10.8288 10.1984L8.79998 8.17036V5.30156Z"
          fill={color} fillOpacity={opacity}/>
  </svg>;
};

export default Clock;
