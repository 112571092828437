import React, { MouseEvent, useEffect, useState } from 'react';
import { useEditEventContext } from '../../contexts/EditEventContext';
import PhotosLink from '../../pages/Event/PhotosLink';
import { endUserEvent, startUserEvent, UserEventType } from '../../lib/performance';
import { isUserHost } from '../../lib/attendance';
import { useNavigate } from 'react-router-dom';
import { useUserContext } from '../../contexts/UserContext';
import { useEventCacheContext } from '../../contexts/EventCacheContext';
import { editEventDetails } from '../../api/ElkEventService';
import { logSumoEvent, stringifyError, ULogApplication, ULogSeverity, ULogTag } from 'Common/src/api/SumoLogicApi';
import { UButterBarLevel, useButterBarContext } from '../../contexts/ButterBarContext';
import { useBackgroundContext } from '../../contexts/BackgroundContext';
import BackArrow from '../icons/BackArrow';
import { BackArrowContainer } from '../../pages/InvitePage';
import styled from 'styled-components';
import EventFormInput from '../EventForm/EventFormInput';
import Checkbox from 'Common/src/components/Checkbox';
import {
  BottomModalContainer,
  ModalContainer,
  ModalHeader,
  ModalTitle,
  Overlay,
  TransparentOverlayBottomModal
} from './CommonModal';
import { IUEvent } from '../../lib/event';
import { DesktopShow, MobileShow } from '../styled/Common';
import ColorButton from '../buttons/ColorButton';

const GuestManagementModal = ({ close, event }: {
  close: () => void,
  event: IUEvent,
}) => {
  const navigate = useNavigate();
  const userContext = useUserContext();
  const editEventContext = useEditEventContext();
  const eventCacheContext = useEventCacheContext();
  const butterBarContext = useButterBarContext();
  const backgroundContext = useBackgroundContext();

  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    if (event !== undefined) {
      document.title = `Shine - Manage Invites - ${event.title}`;
      backgroundContext.setColors(
        { colors: event.colors, animation: event.animation, animationUrl: event.backgroundAnimationUrl }
      );
      editEventContext.setEvent(event);
    }
  }, [event]);

  const onModalClick = (e: MouseEvent) => {
    e.stopPropagation();
  };

  const saveEvent = async () => {
    startUserEvent(UserEventType.SaveEvent);
    setSubmitting(true);

    const event = await editEventContext.generateEvent();
    const editEvent = editEventContext.event;
    if (event !== undefined) {
      if (editEvent !== undefined) {
        // Should always be true, but let's be sure?
        editEvent.isHost = isUserHost(editEvent, userContext.id);
        eventCacheContext.setEvent(editEvent);
      }
      try {
        await editEventDetails(userContext, event, editEvent?.hostUploadMode, true, true);

        editEventContext.clearEvent();
        endUserEvent(userContext);
        setSubmitting(false);
        close();
        navigate(`/event/invite/${event.id}`, { replace: true, state: { fromCreate: true } });
      } catch (e) {
        void logSumoEvent({
          app: ULogApplication.ELK,
          severity: ULogSeverity.ERROR,
          userId: userContext.id,
          tag: ULogTag.Network,
          message: `[GuestManagementModal] Error during event save: ${stringifyError(e)}`
        });
        butterBarContext.show({
          level: UButterBarLevel.ERROR,
          contents: 'Sorry, something went wrong saving your event. Please try again. If the problem persists, please contact us at support@sunshine.com.',
          duration: 20000
        });
        setSubmitting(false);
      }
    }
  };

  const modalContent = <>
    <CardBody>
      <ModalHeader>
        <BackArrowContainer onClick={close}>
          <BackArrow/>
        </BackArrowContainer>
        <ModalTitle>Guest options</ModalTitle>
      </ModalHeader>
      <PhotosLink event={event} useMarginBottom={false}/>
      <CheckboxContainer>
        <Checkbox id={'field-add'}
                  checked={editEventContext.event?.openInvite ?? false}
                  onInputChange={(checked) => editEventContext.setIsOpenInvite(checked)}
                  $labelFontSize={'16px'}>
          Allow guests to invite more people
        </Checkbox>
        <Checkbox id={'GuestList'}
                  checked={editEventContext.event?.publicGuestList ?? false}
                  onInputChange={(checked) => editEventContext.setShowGuestList(checked)}
                  $labelFontSize={'16px'}>
          Show guest list
        </Checkbox>
        <Checkbox id={'Reminders'}
                  checked={editEventContext.event?.remindersEnabled ?? false}
                  onInputChange={(checked) => editEventContext.setRemindersEnabled(checked)}
                  $labelFontSize={'16px'}>
          Send guests automatic reminders
        </Checkbox>
      </CheckboxContainer>
      <EventFormInput
        placeholder={'Host name'}
        value={editEventContext.event?.hostedBy ?? ''}
        onChange={(value) => editEventContext.setHostedBy(value)}
        label={'Guests will see the following host name:'}
        isLabelOutside={true}
        excludeBackground={true}
        noVertMargin={true}
        inModal={true}/>
    </CardBody>
    <ButtonWrapper>
      <ColorButton onClick={saveEvent} disabled={submitting}>
        Confirm
      </ColorButton>
    </ButtonWrapper>
  </>;

  return <>
    <DesktopShow>
      <Overlay onClick={close}>
        <ModalContainer onClick={onModalClick}>
          {modalContent}
        </ModalContainer>
      </Overlay>
    </DesktopShow>
    <MobileShow>
      <TransparentOverlayBottomModal onClick={close}>
        <BottomModalContainer onClick={onModalClick}>
          {modalContent}
        </BottomModalContainer>
      </TransparentOverlayBottomModal>
    </MobileShow>
  </>;
};

const CardBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
`;

const CheckboxContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
`;

export default GuestManagementModal;
