import { toZonedTime } from 'date-fns-tz';
import { format } from 'date-fns';

import { IUEvent } from './event';

const timeFormat = (timeZone: string) => {
  return new Intl.DateTimeFormat('en-US',
    { hour: 'numeric', minute: 'numeric', hour12: true, timeZone: timeZone || undefined });
};

export const convertUserTime = (timeString: string, tz?: string): number | undefined => {
  const utcDate = toZonedTime(timeString, tz ?? Intl.DateTimeFormat().resolvedOptions().timeZone);
  if (!isNaN(utcDate.getTime())) {
    return utcDate.getTime();
  }
  return undefined;
};

export const convertTimestampToDate = (timestamp: number, timeZone: string): Date => {
  return toZonedTime(timestamp, timeZone);
};

export const convertTimestampToTimeString = (timestamp: number, timeZone: string): string => {
  const date = toZonedTime(timestamp, timeZone);
  return format(date, 'yyyy-MM-dd\'T\'HH:mm');
};

export const convertTimestampToDateString = (timestamp: number, timeZone: string, includeWeekday?: boolean): string => {
  const date = toZonedTime(timestamp, timeZone);
  if (includeWeekday) {
    const dateTimeFormat = new Intl.DateTimeFormat('default', { weekday: 'long', month: 'short', day: 'numeric' });
    return dateTimeFormat.format(date);
  } else {
    const dateTimeFormat = new Intl.DateTimeFormat('default', { month: 'short', day: 'numeric' });
    return dateTimeFormat.format(date);
  }
}

export const convertTimestampToDateTimeString = (timestamp: number, timeZone: string, includeWeekday?: boolean, includeTimeZone?: boolean): string => {
  const dateString = convertTimestampToDateString(timestamp, timeZone, includeWeekday);
  const timeString = generateSingleTimeString(timestamp, timeZone);
  const timeZoneString = includeTimeZone ? ` ${getShortTimeZone(timeZone, timestamp)}` : '';
  return `${dateString}, ${timeString}${timeZoneString}`;
}

export const dateToUserMidnight = (ts: number): Date => {
  const date = new Date(ts);
  date.setHours(0);
  date.setMinutes(0);
  date.setSeconds(0);
  date.setMilliseconds(0);

  return date;
};

export const generateTimeString = (event?: IUEvent) => {
  if (event) {
    if (event.startTime > 0 && event.endTime > 0) {
      return timeFormat(event.timeZone).format(event.startTime) + ' - ' + timeFormat(event.timeZone).format(
        event.endTime) + ' ' + getShortTimeZone(event.timeZone, event.startTime);
    } else if (event.startTime > 0) {
      return timeFormat(event.timeZone).format(event.startTime) + ' ' + getShortTimeZone(event.timeZone,
        event.startTime);
    } else return '';
  } else return '';
};

export const generateSingleTimeString = (timestamp: number, timeZone: string) => {
  return timeFormat(timeZone).format(timestamp);
};

export const generateDisplayStartTime = (event?: IUEvent) => {
  if (event && event.startTime > 0) {
    return timeFormat(event.timeZone).format(event.startTime) + ' ' + getShortTimeZone(event.timeZone, event.startTime);
  }
  return '';
};

export const isPast = (event: IUEvent): boolean => {
  const timeZone = event.timeZone;

  const todayStart = new Date();
  todayStart.setHours(0, 0, 0, 0); // Reset to the start of the day
  const todayStartTime = new Date(todayStart.toLocaleString('en-US', { timeZone: timeZone })).getTime();

  // date now - needed for comparison to end time
  const dateNowInUtc = new Date();
  const localDateNow = dateNowInUtc.toLocaleString('en-US', { timeZone: timeZone });
  const dateNow = new Date(localDateNow).getTime();


  //start time
  const eventStartTime = new Date(event.startTime).getTime();
  const localDate = new Date(eventStartTime).toLocaleString('en-US', { timeZone: timeZone });
  const zonedStartTime = new Date(localDate).getTime();

  if (event.endTime !== 0) {
    //end time
    const eventEndTime = new Date(event.endTime).getTime();
    const localDateEnd = new Date(eventEndTime).toLocaleString('en-US', { timeZone: timeZone });
    const zonedEndTime = new Date(localDateEnd).getTime();
    return zonedStartTime < todayStartTime || !!event.cancelledTimestamp || zonedEndTime < dateNow;
  }

  return zonedStartTime < todayStartTime || !!event.cancelledTimestamp;
};

export const getShortTimeZone = (timeZone: string, ts: number) => {
  return new Intl.DateTimeFormat('en-US', {
    timeZone: timeZone || undefined,
    timeZoneName: 'short'
  }).formatToParts(ts)
    .find(part => part.type == 'timeZoneName')
    ?.value;
};

export const isInvalidEventDuration = (startTime: number, endTime: number) => {
  const TWO_WEEKS_IN_MILLISECONDS = 2 * 7 * 24 * 60 * 60 * 1000;
  return endTime - startTime >= TWO_WEEKS_IN_MILLISECONDS;
};

export const isMultidayEvent = (event: IUEvent) => {
  const DAY_IN_MILLISECONDS = 24 * 60 * 60 * 1000;
  if (event.endTime === 0) {
    return false;
  } else {
    return event.endTime - event.startTime >= DAY_IN_MILLISECONDS;
  }
};

export const getDefaultEndTimeString = (startTime: string, timeZone: string) => {
  // Default end time = 3 hours after start time
  const THREE_HOURS_IN_MS = 3 * 60 * 60 * 1000;
  const startTs = convertUserTime(startTime);
  if (startTs !== undefined) {
    const endTs = startTs + THREE_HOURS_IN_MS;
    return convertTimestampToTimeString(endTs, timeZone);
  } else {
    return '';
  }
};
