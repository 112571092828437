import React from 'react';

import { useUserContext } from '../../contexts/UserContext';
import Modal from './Modal';

import { logSumoEvent, ULogApplication, ULogSeverity, ULogTag } from 'Common/src/api/SumoLogicApi';

const LogoutModal = ({ close }: { close: () => void }) => {
  const userContext = useUserContext();

  const handleLogout = async () => {
    void logSumoEvent({
      app: ULogApplication.ELK,
      severity: ULogSeverity.INFO,
      userId: userContext.id,
      tag: ULogTag.UserAction,
      message: '[LogoutModal] Logged out'
    });

    await userContext.logout();
  };

  return <Modal
    title="Logout"
    subtitle="Are you sure you want to logout? You won't need to verify your phone number every time if you stay logged in."
    secondaryButtonLabel="Cancel"
    onSecondaryClick={close}
    primaryButtonLabel="Logout"
    onPrimaryClick={handleLogout}
    onClickOutside={close}
    useSystemColors={true}
  />;
};

export default LogoutModal;

