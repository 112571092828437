import { initializeApp } from 'firebase/app';
import { Analytics, getAnalytics } from 'firebase/analytics';
import { getAuth } from 'firebase/auth';

declare const ELK_APP_API_KEY: string;
declare const ELK_APP_AUTH_DOMAIN: string;
declare const REACT_APP_DATABASE_URL: string;
declare const REACT_APP_PROJECT_ID: string;
declare const ELK_APP_STORAGE_BUCKET: string;
declare const REACT_APP_MESSAGING_SENDER_ID: string;
declare const ELK_APP_APP_ID: string;
declare const ELK_APP_MEASUREMENT_ID: string;

const config = {
  apiKey: ELK_APP_API_KEY,
  authDomain: ELK_APP_AUTH_DOMAIN,
  databaseURL: REACT_APP_DATABASE_URL,
  projectId: REACT_APP_PROJECT_ID,
  storageBucket: ELK_APP_STORAGE_BUCKET,
  messagingSenderId: REACT_APP_MESSAGING_SENDER_ID,
  appId: ELK_APP_APP_ID,
  measurementId: ELK_APP_MEASUREMENT_ID
};

export const app = initializeApp(config, 'shine');
export const auth = getAuth(app);
auth.useDeviceLanguage();

export const firebaseAnalytics: Analytics = getAnalytics(app);
