import React from 'react';
import styled from 'styled-components';

import EditableQuestion from './EditableQuestion';
import { ActionMenuLabel } from '../EventForm/ActionMenuCommon';
import { IUEvent, IUEventQuestion } from '../../lib/event';
import { ActionMenuButton } from '../EventForm/ActionMenuButton';
import SpotifyIcon from '../icons/SpotifyIcon';
import { MobileShow } from '../styled/Common';

import { DeviceQueries } from 'Common/src/components/styled';

import { TAppElkEventQuestionType } from 'TProtocol/prototypes/events/messages';

interface Props {
  event: IUEvent;
  questions: IUEventQuestion[];
  questionType: TAppElkEventQuestionType;
  handleAddQuestion: () => void;
  onSave?: () => void;
  onCancel?: () => void;
}

const Questions = ({ event, questions, questionType, handleAddQuestion, onSave, onCancel }: Props) => {

  // if (questions.length === 0) {
  //   return <Container>
  //     <AddAQuestion onClick={handleAddQuestion}>
  //       <PlusContainer>
  //         <Plus size={12}/>
  //       </PlusContainer>
  //       <AddQuestionLabel>{questionType === TAppElkEventQuestionType.SONG ? '  Request a song from guests' : '  Add a question for your guests'}</AddQuestionLabel>
  //     </AddAQuestion>
  //   </Container>;
  // } else {
  return <Container>
    {
      questions.map((question, index) =>
        <EditableQuestion key={question.questionUuid} question={question} event={event} index={index}/>)
    }
    <ActionMenuButton onClick={handleAddQuestion}>
      <ButtonContent>
        {questionType === TAppElkEventQuestionType.SONG && <SpotifyIcon color={'#1DB954'}/>}
        {questionType === TAppElkEventQuestionType.SONG ? 'Request a song from guests' : 'Add new question'}
      </ButtonContent>
    </ActionMenuButton>
    <MobileShow>
      <Message>
        Answers will not be viewable on mobile devices. Please use the desktop version of Shine to view guest answers.
      </Message>
    </MobileShow>
    {/*<AddAQuestion onClick={handleAddQuestion}>*/}
    {/*  <PlusContainer>*/}
    {/*    <Plus size={12}/>*/}
    {/*  </PlusContainer>*/}
    {/*  <AddQuestionLabel>{questionType === TAppElkEventQuestionType.SONG ? '  Request another song' : '  Add another question'}</AddQuestionLabel>*/}
    {/*</AddAQuestion>*/}
  </Container>;
  // }
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media not (${DeviceQueries.mobile}) {
    min-width: 300px;
  }
`;

const PlusContainer = styled.div`
  margin-right: 10px;
  padding-top: 15px;
`;

const AddAQuestion = styled.div`
  display: flex;
  text-align: center;
  font-size: 18px;
  font-weight: 300;
  cursor: pointer;
  color: BLACK;
`;

const AddQuestionLabel = styled(ActionMenuLabel)`
  cursor: pointer;
`;

const ButtonContent = styled.div`
  display: flex;
  gap: 10px;
`;

const Message = styled.div`
  font-size: 14px;
`;

export default Questions;
