import React from 'react';

const ErrorIcon = ({ color = 'currentColor', size = 14 }: { color?: string, size?: number }) => {
  return <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 13 14" fill="none">
    <path fillRule="evenodd" clipRule="evenodd"
          d="M12.8 6.9C12.8 8.59739 12.1257 10.2253 10.9255 11.4255C9.72525 12.6257 8.09739 13.3 6.4 13.3C4.70261 13.3 3.07475 12.6257 1.87452 11.4255C0.674284 10.2253 0 8.59739 0 6.9C0 5.20261 0.674284 3.57475 1.87452 2.37452C3.07475 1.17428 4.70261 0.5 6.4 0.5C8.09739 0.5 9.72525 1.17428 10.9255 2.37452C12.1257 3.57475 12.8 5.20261 12.8 6.9ZM7.2 10.1C7.2 10.3122 7.11571 10.5157 6.96569 10.6657C6.81566 10.8157 6.61217 10.9 6.4 10.9C6.18783 10.9 5.98434 10.8157 5.83431 10.6657C5.68429 10.5157 5.6 10.3122 5.6 10.1C5.6 9.88783 5.68429 9.68434 5.83431 9.53432C5.98434 9.38429 6.18783 9.3 6.4 9.3C6.61217 9.3 6.81566 9.38429 6.96569 9.53432C7.11571 9.68434 7.2 9.88783 7.2 10.1ZM6.4 2.9C6.18783 2.9 5.98434 2.98429 5.83431 3.13431C5.68429 3.28434 5.6 3.48783 5.6 3.7V6.9C5.6 7.11217 5.68429 7.31566 5.83431 7.46569C5.98434 7.61571 6.18783 7.7 6.4 7.7C6.61217 7.7 6.81566 7.61571 6.96569 7.46569C7.11571 7.31566 7.2 7.11217 7.2 6.9V3.7C7.2 3.48783 7.11571 3.28434 6.96569 3.13431C6.81566 2.98429 6.61217 2.9 6.4 2.9Z"
          fill={color}/>
  </svg>;
};

export default ErrorIcon;
