import React from 'react';

const Reply = ({ color = 'currentColor', size = 17 }: { color?: string, size?: number }) => {
  return <svg width={size} height={size} viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd"
          d="M16 2.5V10.5C16 11.0304 15.7893 11.5391 15.4142 11.9142C15.0391 12.2893 14.5304 12.5 14 12.5H9L4 16.5V12.5H2C1.46957 12.5 0.960859 12.2893 0.585786 11.9142C0.210714 11.5391 0 11.0304 0 10.5V2.5C0 1.96957 0.210714 1.46086 0.585786 1.08579C0.960859 0.710714 1.46957 0.5 2 0.5H14C14.5304 0.5 15.0391 0.710714 15.4142 1.08579C15.7893 1.46086 16 1.96957 16 2.5Z"
          fill={color}/>
  </svg>;
};

export default Reply;

