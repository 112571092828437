import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { useUserContext } from '../contexts/UserContext';
import { loginUrl } from '../lib/login';

function RequireLogin({ children }: { children: JSX.Element }) {
  const userContext = useUserContext();
  const isLoggedIn = userContext.isLoggedIn();
  const location = useLocation();

  return isLoggedIn === true ? children :
    <Navigate to={loginUrl(location.pathname, location.search)} replace state={location.state}/>;
}

export default RequireLogin;
