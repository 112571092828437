import React, { PropsWithChildren, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import { calculateButtonColors } from '../lib/buttonColors';
import { useBackgroundContext } from '../contexts/BackgroundContext';

export interface SplitButtonMenuItem<T> {
  id: T;
  label: string;
}

const SplitButton = <T, >({ children, onClick, disabled, menuItems, onMenuClick, value }: PropsWithChildren<{
  onClick?: (e: React.MouseEvent) => void,
  disabled: boolean,
  menuItems: SplitButtonMenuItem<T>[];
  onMenuClick: (id: T) => void;
  value: T;
}>) => {
  const backgroundContext = useBackgroundContext();

  const [showMenu, setShowMenu] = useState(false);
  const menuDivRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClick = (event: MouseEvent) => {
      if (menuDivRef.current && !menuDivRef.current.contains(event.target as Node)) {
        setShowMenu(false);
      }
    };

    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, []);

  const { borderColor, textColor, bgColor } = calculateButtonColors({
    color: backgroundContext.colors.highlightText
  });

  const onDropdownClick = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setShowMenu(true);
  };

  const handleMenuClick = (id: T) => {
    setShowMenu(false);
    onMenuClick(id);
  };

  return <SplitButtonContainer>
    <ButtonContainer>
      <ButtonAction $bgColor={bgColor} $borderColor={borderColor} $textColor={textColor} onClick={onClick}
                    disabled={disabled}>
        {children}
      </ButtonAction>
      <ButtonDropdown $bgColor={bgColor} $borderColor={borderColor} $textColor={textColor} disabled={disabled}
                      onClick={onDropdownClick}>
        <DropdownIcon $color={textColor}/>
      </ButtonDropdown>
    </ButtonContainer>
    <DropdownMenuAnchor>
      {showMenu && <DropdownMenu ref={menuDivRef}>
        {
          menuItems.map((menuItem) => <MenuItem
            key={`${menuItem.id}`}
            onClick={() => handleMenuClick(menuItem.id)}
            $selected={menuItem.id === value}
          >
            {menuItem.label}
          </MenuItem>)
        }
      </DropdownMenu>}
    </DropdownMenuAnchor>
  </SplitButtonContainer>;
};

const SplitButtonContainer = styled.div`
  width: 100%;
`;

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
`;

const Button = styled.button<{ $bgColor: string, $borderColor: string, $textColor: string, $lessPadding?: boolean }>`
  background-color: ${({ $bgColor }) => $bgColor};
  color: ${({ $textColor }) => $textColor};
  border: 1.5px solid ${({ $borderColor }) => $borderColor};
  border-radius: 8px;
  padding: ${({ $lessPadding }) => $lessPadding ? '5px 5px' : '15px 5px'};
  cursor: pointer;
  font-size: 18px;
  font-weight: 300;
  margin-top: 15px;
  margin-bottom: 8px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  --button-text-color: ${({ $textColor }) => $textColor};
  align-items: center;

  &:hover {
    filter: brightness(90%);
  }

  &:disabled {
    opacity: .5;
  }
`;

const ButtonAction = styled(Button)`
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  flex-grow: 1;
  border-right: 1px solid rgba(100, 100, 100, 0.8);
`;

const ButtonDropdown = styled(Button)`
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
`;

const DropdownIcon = styled.span<{ $color: string }>`
  display: inline-block;
  width: 0;
  height: 0;
  margin: 0 5px;
  content: "";
  border-color: ${({ $color }) => $color};
  border-top: .3em solid;
  border-right: .3em solid transparent;
  border-bottom: 0;
  border-left: .3em solid transparent;
`;

const DropdownMenuAnchor = styled.div`
  height: 0;
  position: relative;
`;

const DropdownMenu = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #c9c9c9;
  border: 1px solid black;
  color: black;
`;

const MenuItem = styled.div<{ $selected: boolean }>`
  cursor: pointer;
  padding: 5px;
  border-bottom: 1px solid black;

  ${({ $selected }) => $selected && `
    background-color: var(--shine-highlight-color);
    color: var(--shine-system-button-text-color);
  `}
  &:hover {
    background-color: var(--shine-highlight-color);
    color: var(--shine-system-button-text-color);
  }

  &:last-of-type {
    border-bottom: 0;
  }
`;

export default SplitButton;
