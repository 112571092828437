import React, { ChangeEvent, FormEvent, ReactNode, Ref, RefObject, useState } from 'react';

import {
  Container,
  ErrorContainer,
  ExternalContainer,
  ExternalLabel,
  Input,
  InputContainer,
  Label
} from '../forms/Input';
import { IUEvent } from '../../lib/event';

export enum InputType {
  Title = 1,
  StartTime,
  Other,
  Description,
  FromTime,
  Phone
}

interface Props {
  event?: IUEvent | undefined;
  icon?: ReactNode;
  label?: string;
  value: string;
  maxLength?: number;
  onChange: (value: string) => void;
  onInvalid?: (e: FormEvent) => void;
  onBlur?: () => void;
  inputType?: InputType;
  disabled?: boolean;
  required?: boolean;
  autoComplete?: string;
  optionNumber?: number;
  placeholder?: string;
  color?: string;
  error?: string;
  inputRef?: RefObject<HTMLInputElement>;
  errorRef?: Ref<HTMLDivElement>;
  excludeBackground?: boolean;
  isLabelOutside?: boolean;
  noVertMargin?: boolean;
  inModal?: boolean;
  customInputPadding?: string;
}

const EventFormInput = ({
                          icon,
                          label,
                          value,
                          maxLength,
                          onChange,
                          onBlur,
                          onInvalid,
                          inputType = InputType.Other,
                          disabled,
                          required,
                          autoComplete,
                          color = 'currentColor',
                          error,
                          inputRef,
                          errorRef,
                          placeholder,
                          excludeBackground,
                          isLabelOutside,
                          noVertMargin,
                          inModal
                        }: Props) => {

  const [invalid, setInvalid] = useState(false);
  const myOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    setInvalid(false);
    onChange(e.currentTarget.value);
  };

  const onInputInvalid = (e: FormEvent) => {
    e.preventDefault();
    setInvalid(true);
    if (onInvalid) {
      onInvalid(e);
    }
  };

  const [isFocused, setIsFocused] = useState(false);

  const handleFocus = () => setIsFocused(true);
  const handleBlur = () => {
    setIsFocused(false);
    if (onBlur !== undefined) {
      onBlur();
    }
  };

  if (!isLabelOutside) {
    return <Container $growing={true} ref={errorRef} $excludeBackground={excludeBackground}
                      $noVertMargin={noVertMargin}>
      <InputContainer $borderColor={invalid ? 'var(--shine-system-error-color)' : color}
                      $filled={value !== '' || isFocused}>
        {icon !== undefined ? <div>{icon}</div> : null}
        <Input
          value={value}
          maxLength={maxLength}
          $color={color}
          onChange={myOnChange}
          onInvalid={onInputInvalid}
          disabled={disabled}
          type={inputType === InputType.StartTime ? 'date' : inputType === InputType.FromTime ? 'time' : 'text'}
          inputMode={inputType === InputType.Phone ? 'numeric' : undefined}
          required={required}
          autoComplete={autoComplete}
          ref={inputRef}
          placeholder={placeholder}
          onFocus={handleFocus}
          onBlur={handleBlur}
          $noVerticalPadding={label === undefined}
          $noBackgroundContainer={excludeBackground}
          $inModal={inModal}
        />
        {label && <Label>{label}</Label>}
      </InputContainer>
      {error ? <ErrorContainer>{error}</ErrorContainer> : invalid ?
        <ErrorContainer>This is a required field</ErrorContainer> : null}
    </Container>;
  } else {
    return <Container $growing={true} ref={errorRef} $excludeBackground={excludeBackground}
                      $noVertMargin={noVertMargin}>
      <ExternalLabel>{label}</ExternalLabel>
      <ExternalContainer $borderColor={invalid ? 'var(--shine-system-error-color)' : color}
                         $filled={value !== '' || isFocused}
                         $inModal={inModal}>
        {icon !== undefined ? <div>{icon}</div> : null}
        <Input
          value={value}
          maxLength={maxLength}
          $color={color}
          onChange={myOnChange}
          onInvalid={onInputInvalid}
          disabled={disabled}
          type={inputType === InputType.StartTime ? 'date' : inputType === InputType.FromTime ? 'time' : 'text'}
          inputMode={inputType === InputType.Phone ? 'numeric' : undefined}
          required={required}
          autoComplete={autoComplete}
          ref={inputRef}
          placeholder={placeholder}
          onFocus={handleFocus}
          onBlur={handleBlur}
          $noBackgroundContainer={excludeBackground}
          $inModal={inModal}
        />
      </ExternalContainer>
      {error ? <ErrorContainer>{error}</ErrorContainer> : invalid ?
        <ErrorContainer>This is a required field</ErrorContainer> : null}
    </Container>;
  }


};

export default EventFormInput;
