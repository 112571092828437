import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { AsYouType } from 'libphonenumber-js';

import WhiteInput, { TallerWhiteInput } from '../forms/WhiteInput';

interface Props {
  autoComplete: string;
  disabled: boolean;
  required?: boolean;
  isInvalid?: boolean;
  minLength?: number;
  autoFocus?: boolean;
  id?: string;
  placeholder: string,
  value?: string;
  onChange?: (value: string, fullValue: string) => void;
  onBlur?: () => void;
  onFocus?: () => void;
  taller?: boolean;
}

const PhoneInput = ({
                      autoComplete,
                      disabled,
                      required,
                      isInvalid,
                      minLength,
                      autoFocus,
                      id,
                      placeholder,
                      value,
                      onChange,
                      onBlur,
                      onFocus,
                      taller
                    }: Props) => {
  const ref = useRef<HTMLInputElement | null>(null);

  const [phoneNumber, setPhoneNumber] = useState<string | undefined>(value);
  const [cursorPos, setCursorPos] = useState<number | null>(null);

  const formatPhone = (event: ChangeEvent<HTMLInputElement>) => {
    let previousValue = event.target.value;

    // Find where in the digits the cursor is
    const rawCursorPos = event.target.selectionStart ?? 0;
    const numberOfDigitsBeforeCursor = previousValue.substring(0, rawCursorPos).replace(/[^0-9+]/g, '').length;

    previousValue = previousValue.replace(/[^ 0-9()+-]/g, '');
    const asYouType = new AsYouType('US');
    let value = asYouType.input(previousValue);
    if (value.length == previousValue.length + 1 && value == phoneNumber) {
      // clicked backspace on a special character. Remove one extra.
      value = value.substring(0, value.length - 2);
    }

    // Prevent numbers with more than 15 digits
    const numbers = value.replace(/[^0-9]/g, '').length;
    if (numbers > 15) {
      return;
    }

    setPhoneNumber(value);
    setCursorPos(numberOfDigitsBeforeCursor);

    if (onChange !== undefined) {
      onChange(value, asYouType.getNumber()?.number ?? '');
    }
  };

  useEffect(() => {
    if (value) {
      const asYouType = new AsYouType('US');
      setPhoneNumber(asYouType.input(value));
      if (onChange !== undefined) {
        onChange(value, asYouType.getNumber()?.number ?? '');
      }
    } else {
      setPhoneNumber('');
    }
  }, [value]);

  useEffect(() => {
    if (ref.current && cursorPos !== null) {
      let seenDigits = 0;
      const value = ref.current.value;
      for (let i = 0; i < value.length; i++) {
        if (seenDigits === cursorPos) {
          ref.current.setSelectionRange(i, i);
          setCursorPos(null);
          break;
        }
        if (/[0-9]/.test(value[i])) {
          seenDigits++;
        }
      }
    }
  }, [cursorPos]);

  return <>
    {taller
      ? <TallerWhiteInput
        ref={ref}
        autoComplete={autoComplete}
        type="tel"
        name="phone"
        inputMode="numeric"
        disabled={disabled}
        $isInvalid={isInvalid}
        required={required}
        autoFocus={autoFocus}
        minLength={minLength}
        id={id}
        placeholder={placeholder}
        value={phoneNumber}
        onBlur={onBlur}
        onFocus={onFocus}
        onChange={formatPhone}/>
      : <WhiteInput
        ref={ref}
        autoComplete={autoComplete}
        type="tel"
        name="phone"
        inputMode="numeric"
        disabled={disabled}
        $isInvalid={isInvalid}
        required={required}
        autoFocus={autoFocus}
        minLength={minLength}
        id={id}
        placeholder={placeholder}
        value={phoneNumber}
        onBlur={onBlur}
        onFocus={onFocus}
        onChange={formatPhone}/>}
  </>;
};

export default PhoneInput;
