import React from 'react';

const Share = ({ color = 'currentColor' }: {
  color?: string
}) => {
  return <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
    <path
      d="M5.73592 9.61863C5.90425 9.28197 5.99925 8.90197 5.99925 8.5003C5.99925 8.09863 5.90425 7.71863 5.73592 7.38197M5.73592 9.61863C5.484 10.1223 5.06942 10.5261 4.55933 10.7648C4.04924 11.0034 3.47353 11.0628 2.92545 10.9334C2.37738 10.804 1.88906 10.4933 1.5396 10.0517C1.19013 9.61009 1 9.06344 1 8.5003C1 7.93715 1.19013 7.3905 1.5396 6.94891C1.88906 6.50731 2.37738 6.19664 2.92545 6.06722C3.47353 5.93779 4.04924 5.99721 4.55933 6.23583C5.06942 6.47445 5.484 6.87831 5.73592 7.38197M5.73592 9.61863L11.2626 12.382M5.73592 7.38197L11.2626 4.61863M11.2626 12.382C10.966 12.9752 10.9172 13.6619 11.1269 14.2911C11.3366 14.9203 11.7877 15.4404 12.3809 15.737C12.9741 16.0336 13.6608 16.0824 14.29 15.8726C14.9192 15.6629 15.4393 15.2118 15.7359 14.6186C16.0325 14.0254 16.0813 13.3387 15.8716 12.7095C15.6619 12.0803 15.2108 11.5602 14.6176 11.2636C14.3239 11.1168 14.0041 11.0292 13.6765 11.0059C13.349 10.9826 13.02 11.0241 12.7085 11.128C12.0793 11.3377 11.5592 11.7888 11.2626 12.382ZM11.2626 4.61863C11.4094 4.9123 11.6127 5.17417 11.8608 5.38928C12.1088 5.60439 12.3968 5.76854 12.7083 5.87235C13.0199 5.97616 13.3487 6.01759 13.6763 5.99429C14.0038 5.97099 14.3235 5.88341 14.6172 5.73655C14.9108 5.58969 15.1727 5.38642 15.3878 5.13836C15.6029 4.89029 15.7671 4.60229 15.8709 4.29079C15.9747 3.97928 16.0161 3.65039 15.9928 3.32287C15.9695 2.99535 15.8819 2.67563 15.7351 2.38197C15.4385 1.78888 14.9184 1.3379 14.2893 1.12825C13.6602 0.9186 12.9736 0.967448 12.3805 1.26405C11.7874 1.56065 11.3364 2.08071 11.1268 2.70981C10.9171 3.33892 10.966 4.02554 11.2626 4.61863Z"
      stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>;
};

export default Share;
