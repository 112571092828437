import { createClient, ErrorResponse, Video } from 'pexels';
import { logSumoEvent, stringifyError, ULogApplication, ULogSeverity, ULogTag } from 'Common/src/api/SumoLogicApi';
import { useUserContext } from '../contexts/UserContext';

// Docs: https://www.pexels.com/api/documentation/?language=javascript

declare const PEXELS_API_KEY: string;

const usePexels = () => {
  const userContext = useUserContext();

  const client = createClient(PEXELS_API_KEY);

  const getPexelsVideosWithSearchTerm = async (query: string) => {
    try {
      const videosResponse = await client.videos.search({ query });
      const errorResponse = videosResponse as ErrorResponse;
      if (errorResponse.error) {
        void logSumoEvent({
          app: ULogApplication.ELK,
          severity: ULogSeverity.WARN,
          userId: userContext.id,
          tag: ULogTag.Browser,
          message: `[useVideoBackgroundPicker] Error requesting Pexels videos: ${errorResponse.error}`
        });
        return undefined;
      } else {
        return videosResponse;
      }
    } catch (e) {
      void logSumoEvent({
        app: ULogApplication.ELK,
        severity: ULogSeverity.WARN,
        userId: userContext.id,
        tag: ULogTag.Browser,
        message: `[useVideoBackgroundPicker] Error requesting Giphy videos: ${stringifyError(e)}`
      });
      return undefined;
    }
  };

  const getPexelsVideoFileLink = (video: Video) => {
    // Sorting and returning video with smallest file width
    const videoFiles = video.video_files;
    const sortedFiles = videoFiles.sort((v1, v2) => v1.width === null || v2.width === null ? -1 : v1.width - v2.width);
    const videoFile = sortedFiles[0];
    return videoFile.link;
  };

  return {
    getPexelsVideosWithSearchTerm,
    getPexelsVideoFileLink
  };
};

export default usePexels;
