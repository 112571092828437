import React from 'react';

import { useUserContext } from '../../contexts/UserContext';
import Modal from './Modal';

const AllowTextReminder = ({ close, setIsChecked, allowTexts }: {
  close: () => void,
  setIsChecked: (checked: boolean) => void,
  allowTexts: () => void
}) => {
  const userContext = useUserContext();

  const handleTextOptOut = async () => {
    //update elk user profile to not include text messages
    //close modal
    userContext.setHasNudgedToAllowTexts(true);
    close();
  };

  const handleTextOptIn = async () => {
    //update elk user profile to not include text messages
    //close modal
    userContext.setHasNudgedToAllowTexts(true);
    setIsChecked(true);
    allowTexts();
    close();
  };

  return <Modal
    title="Text Message Consent"
    subtitle="By not authorizing text messages, you will not receive updates about this or future events."
    secondaryButtonLabel="Agree"
    onSecondaryClick={handleTextOptIn}
    primaryButtonLabel="No thanks"
    onPrimaryClick={handleTextOptOut}
    onClickOutside={close}
    secondaryButtonOutlined={true}
  />;
};


export default AllowTextReminder;

