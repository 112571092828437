import React, { MouseEvent, PropsWithChildren } from 'react';
import styled from 'styled-components';

import { useBackgroundContext } from '../../contexts/BackgroundContext';
import { calculateButtonColors } from '../../lib/buttonColors';

interface Props {
  onClick: (e: MouseEvent) => void;
  loading?: boolean;
  disabled?: boolean;
  outline?: boolean;
  type?: 'button' | 'submit' | 'reset';
  title?: string;
  wide?: boolean;
}

const SmallActionButton = ({
                             loading,
                             children,
                             onClick,
                             disabled,
                             outline,
                             type,
                             title,
                             wide = false
                           }: PropsWithChildren<Props>) => {
  const backgroundContext = useBackgroundContext();

  const { borderColor, textColor, bgColor } = calculateButtonColors(
    {
      color: backgroundContext.colors.highlightText,
      outline,
      loading
    }
  );

  return <Button $bgColor={bgColor} $borderColor={borderColor} $textColor={textColor} onClick={onClick}
                 disabled={disabled}
                 type={type} $wide={wide} title={title}>
    {children}
  </Button>;
};

const Button = styled.button<{ $bgColor: string, $borderColor: string, $textColor: string, $wide: boolean }>`
  background-color: ${({ $bgColor }) => $bgColor};
  color: ${({ $textColor }) => $textColor};
  border: 3px solid ${({ $borderColor }) => $borderColor};
  border-radius: 8px;
  padding: 6px 11px 5px 13px;
  cursor: pointer;
  font-size: 12px;
  transition: color 1s;
  max-height: 47px;
  font-family: Poppins, sans-serif;

  ${({ $wide }) => $wide ? '' : 'width: 70px;'}
  &:hover {
    filter: brightness(90%);
  }
`;

export default SmallActionButton;
