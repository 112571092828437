import React from 'react';

const File = () => {
  return <svg width="57" height="56" viewBox="0 0 57 56" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="ChartSquareBarOutline">
      <path id="Vector"
            d="M37.8337 18.668L19.167 18.668M30.8337 28.0013L19.167 28.0013M9.83366 42.0013L9.83366 14.0013C9.83366 12.7636 10.3253 11.5766 11.2005 10.7015C12.0757 9.82631 13.2626 9.33464 14.5003 9.33464L42.5003 9.33464C43.738 9.33464 44.925 9.8263 45.8002 10.7015C46.6753 11.5766 47.167 12.7636 47.167 14.0013L47.167 42.0013C47.167 43.239 46.6753 44.426 45.8002 45.3011C44.925 46.1763 43.738 46.668 42.5003 46.668L14.5003 46.668C13.2627 46.668 12.0757 46.1763 11.2005 45.3011C10.3253 44.426 9.83366 43.239 9.83366 42.0013Z"
            stroke="black" strokeOpacity="0.3" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
    </g>
  </svg>;
};

export default File;
