import React from 'react';
import styled from 'styled-components';

import { IUEvent } from '../../lib/event';
import { useUserContext } from '../../contexts/UserContext';

import sunshinePhotosPng from 'Common/src/assets/ShineIcon.svg';
import { logSumoEvent, ULogApplication, ULogSeverity, ULogTag } from 'Common/src/api/SumoLogicApi';

const PhotosLink = ({ event, useMarginBottom = true, isRo = false }: {
  event: IUEvent,
  useMarginBottom?: boolean,
  isRo?: boolean
}) => {
  const userContext = useUserContext();
  const albumDetails = event.albumDetails;

  const onLinkClick = () => {
    if (!isRo) {
      void logSumoEvent({
        app: ULogApplication.ELK,
        severity: ULogSeverity.INFO,
        userId: userContext.id,
        tag: ULogTag.UserAction,
        message: '[PhotosLink] Clicked on photos link'
      });
    }
  };

  if (albumDetails === undefined) {
    return null;
  }

  const message = <Text>
    We created a Shine for photos from {event.title}! View and share in the <BoldedText>Shine
    app</BoldedText>.
  </Text>

  if (albumDetails.albumUrl !== undefined) {
    return <AlbumLink href={!isRo ? `https://${albumDetails.albumUrl}` : ''} target="_blank" rel="noreferrer"
                      onClick={onLinkClick}
                      $includeMarginBottom={useMarginBottom}>
      <IconImg src={sunshinePhotosPng}/>
      {message}
    </AlbumLink>;
  } else {
    return <ContentsContainer $includeMarginBottom={useMarginBottom}>
      <IconImg src={sunshinePhotosPng}/>
      {message}
    </ContentsContainer>;
  }
};

const IconImg = styled.img`
  width: 69px;
  min-width: 69px;
  height: 68px;
  border-radius: 8px;
  overflow: hidden;
  display: inline-block;
  vertical-align: middle;
  margin-right: 14px;
`;

const ContentsContainer = styled.div<{ $includeMarginBottom: boolean }>`
  ${({ $includeMarginBottom }) => $includeMarginBottom && 'margin-bottom: 10px;'};
  display: flex;
  align-items: center;
  padding: 8px 12px;
`;

const AlbumLink = styled.a<{ $includeMarginBottom: boolean }>`
  text-decoration: none;
  color: currentColor;
  ${({ $includeMarginBottom }) => $includeMarginBottom && 'margin-bottom: 10px;'};
  display: flex;
  align-items: center;
  padding: 8px 12px;
`;

const Text = styled.div`
  font-size: 18px;
`;

const BoldedText = styled.span`
  font-weight: 600;
  font-size: 18px;
`;

export default PhotosLink;
