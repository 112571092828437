import { v4 as uuidv4 } from 'uuid';

import { getDefaultTextColor } from '../../lib/colorPicker';
import { copy, IUEvent, IUEventColors, IUEventErrors, IUEventQuestion, IUInvitee, IULocation } from '../../lib/event';
import { getNormalizedPhotoUrl } from '../../lib/images';

import {
  TAppElkAttendeeRole,
  TAppElkBackgroundAnimation,
  TAppElkDraftEvent,
  TAppElkEvent,
  TAppElkEventQuestionType,
  TAppElkMessage,
  TAppElkPhotoUploadMode,
  TElkCreateEventResponse,
  TElkGetAlbumDetailsResponse
} from 'TProtocol/prototypes/events/messages';

export interface IEventState {
  event: IUEvent | undefined;
  previousEvent: IUEvent | undefined;
  lastSavedEvent: IUEvent | undefined;
}

type IReducerAction = {
  type: 'setEventId',
  id: string
} | {
  type: 'handleCreationResponse',
  response: TElkCreateEventResponse
} | {
  type: 'setColors',
  colors: IUEventColors
} | {
  type: 'setAnimation',
  animation: TAppElkBackgroundAnimation
} | {
  type: 'setPhotoUrl',
  photoUrl: string
} | {
  type: 'clearPhotoUrls',
} | {
  type: 'setAIGeneratedPhotoUrls',
  photoUrls?: string[]
} | {
  type: 'addAIGeneratedPhotoUrls',
  photoUrls: string[]
} | {
  type: 'setTimeZone',
  tz: string
} | {
  type: 'setTitle',
  title: string
} | {
  type: 'setSubtitle',
  subtitle: string
} | {
  type: 'setDate',
  date: Date
} | {
  type: 'setDateQuery',
  dateQuery?: string
} | {
  type: 'setTimes',
  startTime?: number,
  endTime?: number
} | {
  type: 'setLocation',
  location: IULocation
} | {
  type: 'setIsHost',
  isHost: boolean
} | {
  type: 'setUrl',
  url: string
} | {
  type: 'setEvent',
  event: IUEvent
} | {
  type: 'setNewEvent',
  event: IUEvent
} | {
  type: 'saveCheckpoint'
} | {
  type: 'restoreCheckpoint'
} | {
  type: 'updateLastSaved'
} | {
  type: 'clearEvent'
} | {
  type: 'createNewEvent',
  defaults?: Partial<IUEvent>,
  prompt?: string | undefined
} | {
  type: 'updateUrl',
  url: string
} | {
  type: 'setIsOpenInvite',
  isOpenInvite: boolean
} | {
  type: 'setDescription',
  description: string
} | {
  type: 'setShowGuestList',
  showGuestList: boolean
} | {
  type: 'setHostUploadMode',
  hostUploadMode: TAppElkPhotoUploadMode
} | {
  type: 'setModificationId',
  modificationId: string
} | {
  type: 'updateFormErrors',
  value: IUEventErrors
} | {
  type: 'cancelEvent'
} | {
  type: 'setHostedBy',
  hostedBy: string
} | {
  type: 'setIsLoading',
  isLoading: boolean
} | {
  type: 'setHostEmail',
  hostEmail: string
} | {
  type: 'setMessage',
  message: string
} | {
  type: 'setRemindersEnabled',
  remindersEnabled: boolean
} | {
  type: 'addAQuestion'
} | {
  type: 'addSongRequestQuestion'
} | {
  type: 'modifyQuestion',
  question: IUEventQuestion
} | {
  type: 'deleteQuestion',
  question: IUEventQuestion
} | {
  type: 'setPrompt',
  prompt?: string
} | {
  type: 'setBackgroundAnimationUrl',
  animationUrl?: string
} | {
  type: 'setVideoBackgroundDisabled',
  disabled?: boolean;
} | {
  type: 'setBackgroundAnimationSearchQuery',
  query?: string
} | {
  type: 'createComplete'
} | {
  type: 'setPlaylistId',
  playlistId: string
}

export const convertToIUEvent = ({
                                   event,
                                   messages = null,
                                   albumDetails,
                                   modId,
                                   isPreview = false,
                                   isCreate = false,
                                   prompt,
                                   isDraft = false,
                                 }: {
                                   event: TAppElkEvent | TAppElkDraftEvent,
                                   messages?: TAppElkMessage[] | null,
                                   albumDetails?: TElkGetAlbumDetailsResponse,
                                   modId?: string,
                                   isPreview?: boolean,
                                   isCreate?: boolean,
                                   isDraft?: boolean,
                                   prompt?: string
                                 }
): IUEvent => {
  const remindersDisabled = event.metadata?.disableReminders ?? false;
  return new IUEvent({
    ...event,
    ...event.theme,
    ...event.metadata,
    ...event.details,
    questions: event.questions?.map((question) => new IUEventQuestion(question)),
    location: event.details?.location ? new IULocation(event.details.location) : undefined,
    attendees: event.attendees?.map((attendee) => new IUInvitee(attendee)),
    photoUrl: event.theme?.photoUrl && getNormalizedPhotoUrl(event.theme.photoUrl),
    description: event.details?.eventDescription,
    cancelledTimestamp: event.details?.cancelledTimestamp?.toNumber(),
    startTime: event.details?.startTime?.toNumber(),
    endTime: event.details?.endTime?.toNumber(),
    colors: convertColors(event.theme?.colors),
    isPreview,
    isCreate,
    isDraft,
    messages,
    albumDetails,
    modificationId: modId,
    message: '',
    timeZone: event.details?.timezone ?? 'America/Los_Angeles',
    openInvite: event.metadata?.openInvite,
    publicGuestList: event.metadata?.publicGuestList,
    remindersEnabled: !remindersDisabled,
    subtitle: event.theme?.backgroundAnimationSearchQuery,
    backgroundAnimationUrl: event.theme?.backgroundAnimationUrl,
    backgroundAnimationSearchQuery: event.theme?.backgroundAnimationSearchQuery,
    prompt: prompt,
    hostUploadMode: albumDetails?.photoUploadMode,
    playlistId: event.details?.playlistId,
    lastSavedTimestamp: event instanceof TAppElkDraftEvent ? event.lastSavedTimestamp?.toNumber() : undefined
  });
};

const convertColors = (colors?: string[]): IUEventColors | undefined => {
  if (colors !== undefined && colors?.length >= 3) {
    const defaultTextColor = getDefaultTextColor(colors[0]);

    return {
      primary: colors[0],
      secondary: colors[1],
      highlightText: colors[2],
      text: colors[3] || defaultTextColor,
      buttonTextColor: colors[4] || defaultTextColor
    };
  }
  return undefined;
};

const updateEvent = (state: IEventState, eventChange: Partial<IUEvent>, setCheckpoint?: boolean) => {
  if (setCheckpoint) {
    return {
      ...state,
      event: state.event ? new IUEvent({ ...state.event, ...eventChange }) : undefined
    };
  }
  return { ...state, event: state.event ? new IUEvent({ ...state.event, ...eventChange }) : undefined };
};

export const EventReducer = (state: IEventState, action: IReducerAction): IEventState => {
  switch (action.type) {
    case 'setIsOpenInvite':
      return updateEvent(state, { openInvite: action.isOpenInvite });
    case 'setMessage':
      return updateEvent(state, { message: action.message });
    case 'setShowGuestList':
      return updateEvent(state, { publicGuestList: action.showGuestList });
    case 'setEventId':
      return updateEvent(state, { id: action.id });
    case 'setColors':
      return updateEvent(state, { colors: action.colors });
    case 'setAnimation':
      return updateEvent(state, { animation: action.animation });
    case 'setBackgroundAnimationSearchQuery':
      return updateEvent(state, { backgroundAnimationSearchQuery: action.query });
    case 'setBackgroundAnimationUrl':
      return updateEvent(state, { backgroundAnimationUrl: action.animationUrl });
    case 'setVideoBackgroundDisabled':
      return updateEvent(state, { videoBackgroundDisabled: action.disabled });
    case 'setPhotoUrl':
      return updateEvent(state, { photoUrl: action.photoUrl });
    case 'clearPhotoUrls':
      return updateEvent(state, { photoUrl: undefined, aiGeneratedPhotoUrls: undefined });
    case 'setAIGeneratedPhotoUrls':
      return updateEvent(state, { aiGeneratedPhotoUrls: action.photoUrls });
    case 'addAIGeneratedPhotoUrls':
      return updateEvent(state,
        { aiGeneratedPhotoUrls: [...(state.event?.aiGeneratedPhotoUrls || []), ...action.photoUrls] }
      );
    case 'setTimeZone':
      return updateEvent(state, { timeZone: action.tz });
    case 'setTitle':
      return updateEvent(state, { title: action.title });
    case 'setSubtitle':
      return updateEvent(state, { subtitle: action.subtitle });
    case 'setDate':
      return updateEvent(state, { date: action.date });
    case 'setDateQuery':
      return updateEvent(state, { dateQuery: action.dateQuery });
    case 'setTimes':
      return updateEvent(state, { startTime: action.startTime, endTime: action.endTime, date: undefined });
    case 'setLocation':
      return updateEvent(state, { location: action.location });
    case 'setIsHost':
      return updateEvent(state, { isHost: action.isHost });
    case 'setHostedBy':
      return updateEvent(state, { hostedBy: action.hostedBy });
    case 'setIsLoading':
      return updateEvent(state, { loading: action.isLoading });
    case 'setHostEmail':
      if (state.event && (state.event.attendees?.length ?? 0) > 0) {
        for (const attendee of (state.event.attendees)) {
          if (attendee.role === TAppElkAttendeeRole.ORGANIZER) {
            attendee.email = action.hostEmail;
            break;
          }
        }
        return updateEvent(state, { attendees: [...state.event.attendees], hostEmail: action.hostEmail });
      }
      return updateEvent(state, { hostEmail: action.hostEmail });
    case 'setUrl':
      return updateEvent(state, { url: action.url, isPlaceholderUrl: false });
    case 'setEvent':
      return {
        ...state,
        event: copy(action.event),
        previousEvent: copy(action.event),
        lastSavedEvent: copy(action.event)
      };
    case 'setNewEvent':
      return { ...state, event: copy(action.event) };
    case 'saveCheckpoint':
      return { ...state, previousEvent: copy(state.event) };
    case 'restoreCheckpoint':
      return { ...state, event: state.previousEvent };
    case 'updateLastSaved':
      return { ...state, lastSavedEvent: copy(state.event) };
    case 'setHostUploadMode':
      return updateEvent(state, { hostUploadMode: action.hostUploadMode });
    case 'setModificationId':
      return updateEvent(state, { modificationId: action.modificationId });
    case 'clearEvent':
      return { ...state, event: undefined, previousEvent: undefined };
    case 'handleCreationResponse':
      const event = convertToIUEvent({
        event: action.response.event,
        messages: []
      });
      return {
        previousEvent: undefined,
        event,
        lastSavedEvent: event
      };
    case 'createNewEvent':
      return { ...state, event: new IUEvent(action.defaults), previousEvent: undefined };
    case 'updateFormErrors':
      return updateEvent(state, { errors: { ...state.event?.errors, ...action.value } });
    case 'updateUrl':
      return updateEvent(state, { url: action.url });
    case 'setRemindersEnabled' :
      return updateEvent(state, { remindersEnabled: action.remindersEnabled });
    case 'setDescription':
      return updateEvent(state, { description: action.description });
    case 'cancelEvent':
      return updateEvent(state, { cancelledTimestamp: new Date().getTime() });
    case 'addAQuestion':
      return updateEvent(state, {
        questions: [
          ...(state.event?.questions || []),
          new IUEventQuestion(
            { questionUuid: uuidv4(), question: '', isRequired: false, questionType: TAppElkEventQuestionType.TEXT })
        ]
      });
    case 'addSongRequestQuestion':
      return updateEvent(state, {
        questions: [
          ...(state.event?.questions || []),
          new IUEventQuestion(
            { questionUuid: uuidv4(), question: '', isRequired: false, questionType: TAppElkEventQuestionType.SONG })
        ]
      });
    case 'modifyQuestion':
      return updateEvent(state, {
        questions: state.event?.questions.map(q =>
          q.questionUuid === action.question.questionUuid ? action.question : q
        )
      });
    case 'deleteQuestion':
      return updateEvent(state, {
        questions: state.event?.questions.filter(q => q.questionUuid !== action.question.questionUuid)
      });
    case 'setPrompt':
      return updateEvent(state, { prompt: action.prompt });
    case 'createComplete':
      return updateEvent(state, { isCreate: false });
    case 'setPlaylistId':
      return updateEvent(state, { playlistId: action.playlistId });
  }
};

