import Thrift, {ConnectOptions} from 'thrift';


export class SunshineXhrConnection extends Thrift.XHRConnection {
  private host: string;
  private port: number | undefined;
  private url: string;

  constructor(host: string, port: number, options?: ConnectOptions) {
    super(host, port, options);
    this.host = host;
    this.port = port
  }

  override flush(): void {


    const prefix = this.options.https ? 'https://' : 'http://';
    const path = this.options.path || '/';

    if (this.port === 0) {
      this.port = undefined;
    }

    if (!this.port || this.port === 80) {
      this.url = prefix + this.host + path;
    } else {
      this.url = prefix + this.host + ':' + this.port + path;
    }

    if (this.url === undefined || this.url === '') {
      return;
    }

    const xreq = this.getXmlHttpRequestObject();
    // (Sunshine) Set timeout.
    if (this.options.timeout) {
      xreq.timeout = this.options.timeout
    }

    if (xreq.overrideMimeType) {
      xreq.overrideMimeType('application/json');
    }

    xreq.onreadystatechange = () => {
      if (xreq.readyState == 4 && xreq.status == 200) {
        this.setRecvBuffer(xreq.responseText);
      }
    };

    xreq.open('POST', this.url, true);

    Object.keys(this.headers).forEach((headerKey) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      xreq.setRequestHeader(headerKey, this.headers[headerKey]);
    });

    // (Sunshine): We override onerror and ontimeout here so that application can handle these errors
    xreq.onerror = (event) => {
      this.setRecvBuffer(`[1,"networkError",3,1,{"1":{"str":"Network error fetching ${this.url}"}}]`)
    }

    xreq.ontimeout = (event) => {
      this.setRecvBuffer(`[1,"timeoutError",3,1,{"1":{"str":"Timed out after ${xreq.timeout}ms fetching ${this.url}"}}]`)
    }

    xreq.send(this.send_buf);
  }
}