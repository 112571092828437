import React, { useContext, useEffect, useRef, useState } from 'react';
import { Unsubscribe, getDatabase, onValue, ref } from 'firebase/database';

import { useUserContext } from './UserContext';
import { convertToIUEvent } from '../state/reducers/event';
import { getRSVPedInvitee, isUserHost } from '../lib/attendance';
import {
  fetchEvents, getAlbumDetails, getEvent, getEventMessageHistory, getEventPreview,
  getPreviewAlbumDetails, getUserEventMessageHistory
} from '../api/ElkEventService';
import { IUEvent } from '../lib/event';
import { app } from '../core/libs/Firebase';

import { ULogApplication, ULogSeverity, ULogTag, logSumoEvent, stringifyError } from 'Common/src/api/SumoLogicApi';

import {
  TAppElkEvent,
  TAppElkMessage,
  TElkFetchEventsResponse,
  TElkGetAlbumDetailsResponse
} from 'TProtocol/prototypes/events/messages';

export interface IUEventCacheContext {
  cache: Map<string, IUEvent>,
  fetchEvent: ({ eventId, inviteeUuid, isPreview, force }: {
    eventId: string,
    inviteeUuid?: string,
    isPreview?: boolean,
    force?: boolean,
    token?: string
  }) => Promise<IUEvent | undefined>;
  refreshMessages: (eventId: string, inviteeId?: string) => Promise<IUEvent | undefined>;
  refreshAlbumDetails: (eventId: string, token?: string) => Promise<IUEvent | undefined>;
  fetchEvents: (modId?: string) => Promise<IUEvent[] | undefined>;
  getEvent: (eventId: string | undefined) => IUEvent | undefined;
  setEvent: (event: IUEvent) => void;
  deleteEvent: (eventId: string) => void;
  clearAll: () => void;
  events?: IUEvent[];
}

const EventCacheContext = React.createContext<IUEventCacheContext | null>(null);

export function EventCacheContextProvider(props: { children: React.ReactNode }) {
  const userContext = useUserContext();
  const [cache, setCache] = useState<Map<string, IUEvent>>(new Map());
  const [events, setEvents] = useState<IUEvent[]>();
  const homepageModId = useRef(new Date().getTime().toString());
  const unsubscribeHandlers = useRef<Map<string, () => void>>(new Map());
  const subscribedEventInvitees = useRef<Map<string, string | undefined>>(new Map());
  const eventCache = useRef<Map<string, IUEvent>>(new Map());
  const eventRequests = useRef<Map<string, Promise<TAppElkEvent>>>(new Map());
  const messageRequests = useRef<Map<string, Promise<TAppElkMessage[]>>>(new Map());
  const albumDetailsRequests = useRef<Map<string, Promise<TElkGetAlbumDetailsResponse>>>(new Map());
  const eventsRequest = useRef<Promise<TElkFetchEventsResponse> | null>(null);

  useEffect(() => {
    let unsubscribe: Unsubscribe | null = null;
    if (userContext.id !== undefined) {
      const db = getDatabase(app);
      const valueRef = ref(db, `events/users/${userContext.id}`);

      unsubscribe = onValue(valueRef, (snapshot) => {
        const data = snapshot.val() as { HOMEPAGE_DATA_MODIFICATION: string } | null;
        if (data) {
          void fetchEventsInternal(data.HOMEPAGE_DATA_MODIFICATION);
        }
      });
    }

    return () => {
      if (unsubscribe !== null) {
        unsubscribe();
      }
      unsubscribeHandlers.current.forEach(unsubscribe => {
        unsubscribe();
      });
    }
  }, [userContext.id]);

  const subscribeToEvent = (eventId: string, inviteeUuid?: string) => {
    if (unsubscribeHandlers.current.has(eventId)
      && subscribedEventInvitees.current.has(eventId)
      && subscribedEventInvitees.current.get(eventId) === inviteeUuid) {
      return;
    }

    const oldUnsubscribeHandler = unsubscribeHandlers.current.get(eventId);
    if (oldUnsubscribeHandler !== undefined) {
      oldUnsubscribeHandler();
    }

    subscribedEventInvitees.current.set(eventId, inviteeUuid);

    const db = getDatabase(app);
    const valueRef = ref(db, `events/${eventId}`);

    unsubscribeHandlers.current.set(eventId, onValue(valueRef, (snapshot) => {
      const data = snapshot.val() as { MODIFICATION_ID: string, MESSAGE_MODIFICATION_ID?: string };
      void fetchEvent(
        {
          eventId,
          inviteeUuid,
          eventModId: data?.MODIFICATION_ID,
          messagesModId: data?.MESSAGE_MODIFICATION_ID
        });
    }));
  };

  const fetchEvent = async ({ eventId, inviteeUuid, isPreview, force, eventModId, messagesModId }: {
    eventId: string,
    inviteeUuid?: string,
    isPreview?: boolean,
    force?: boolean,
    eventModId?: string,
    messagesModId?: string
  }): Promise<IUEvent | undefined> => {
    let eventOutOfDate = false;
    let messagesOutOfDate = false;

    const existingEvent = eventCache.current.get(eventId);
    if (!existingEvent || force) {
      eventOutOfDate = true;
      messagesOutOfDate = true;
    } else {
      if (eventModId && existingEvent.modificationId < eventModId || (!isPreview && existingEvent.isPreview)) {
        eventOutOfDate = true;
      }
      if ((messagesModId && existingEvent.messageModificationId < messagesModId) || existingEvent.messages === null) {
        messagesOutOfDate = true;
      }

      if (!eventOutOfDate && !messagesOutOfDate) {
        subscribeToEvent(eventId, inviteeUuid);
        return existingEvent;
      }
    }

    if (eventOutOfDate || !existingEvent) {

      let newHangout: TAppElkEvent;
      let newMessages: TAppElkMessage[] | null;
      let albumDetails: TElkGetAlbumDetailsResponse | undefined;

      if (messagesOutOfDate || !existingEvent) {
        const hangoutPromise = memoizedEventFetch(eventId, inviteeUuid);
        const messagesPromise = memoizedEventMessagesFetch(eventId, inviteeUuid);
        const albumDetailsPromise = memoizedAlbumDetailsFetch(eventId);
        [newHangout, newMessages, albumDetails] = await Promise.all([hangoutPromise, messagesPromise, albumDetailsPromise]);
      } else {
        const albumDetailsPromise = memoizedAlbumDetailsFetch(eventId);
        const hangoutPromise = memoizedEventFetch(eventId, inviteeUuid);
        newMessages = existingEvent.messages;
        [newHangout, albumDetails] = await Promise.all([hangoutPromise, albumDetailsPromise])
      }

      const event = convertToIUEvent({
        event: newHangout,
        messages: newMessages,
        albumDetails,
        isPreview,
        prompt: existingEvent?.prompt
      });
      subscribeToEvent(eventId, inviteeUuid);

      event.isHost = isUserHost(event, userContext.id);

      eventCache.current.set(eventId, event);
      eventCache.current = new Map(eventCache.current);
      setCache(eventCache.current);

      return event;
    } else {
      let inviteeId: string | undefined = undefined;
      if (userContext.id) {
        inviteeId = getRSVPedInvitee(existingEvent, userContext.id)?.inviteeId;
      }

      return refreshMessages(eventId, inviteeId);
    }
  };

  const refreshMessages = async (eventId: string, inviteeUuid?: string) => {
    const newMessages = await memoizedEventMessagesFetch(eventId, inviteeUuid);
    const existingEvent = eventCache.current.get(eventId);

    if (existingEvent) {
      const event: IUEvent = {
        ...existingEvent,
        messages: newMessages,
        messagesArePersonal: inviteeUuid !== undefined,
        messageModificationId: Date.now().toString()
      };

      eventCache.current.set(eventId, event);
      eventCache.current = new Map(eventCache.current);
      setCache(eventCache.current);

      return event;
    }
    return undefined;
  };

  const memoizedEventFetch = async (eventId: string, inviteeUuid?: string): Promise<TAppElkEvent> => {
    const cacheKey = `${eventId}|${inviteeUuid ?? 'none'}`;
    const currentRequest = eventRequests.current.get(cacheKey);
    let newHangout: TAppElkEvent | undefined;

    if (currentRequest) {
      newHangout = await currentRequest;
    } else {
      let promise: Promise<TAppElkEvent>;
      if (!userContext.isLoggedIn()) {
        promise = getEventPreview(eventId, inviteeUuid, true) as Promise<TAppElkEvent>;
      } else {
        promise = getEvent(userContext, eventId, inviteeUuid, true);
      }
      eventRequests.current.set(cacheKey, promise);
      newHangout = await promise;
      eventRequests.current.delete(cacheKey);
    }

    return newHangout;
  };

  const memoizedEventMessagesFetch = async (eventId: string, inviteeUuid?: string): Promise<TAppElkMessage[]> => {
    const cacheKey = `${eventId}|${inviteeUuid ?? 'none'}`;
    const currentRequest = messageRequests.current.get(cacheKey);
    let newMessages: TAppElkMessage[];

    if (currentRequest) {
      newMessages = await currentRequest;
    } else {
      let promise: Promise<TAppElkMessage[]>;
      if (userContext.isLoggedIn()) {
        promise = getUserEventMessageHistory(userContext, eventId, inviteeUuid);
      } else {
        promise = getEventMessageHistory(userContext, eventId, inviteeUuid);
      }
      messageRequests.current.set(cacheKey, promise);
      newMessages = await promise;
      messageRequests.current.delete(cacheKey);
    }

    return sortMessages(newMessages);
  };

  const memoizedAlbumDetailsFetch = async (eventId: string, token?: string): Promise<TElkGetAlbumDetailsResponse> => {
    const cacheKey = `${eventId}|${token ?? 'none'}`;
    const currentRequest = albumDetailsRequests.current.get(cacheKey);

    let albumDetails: TElkGetAlbumDetailsResponse;
    if (currentRequest) {
      return currentRequest;
    } else {
      let promise: Promise<TElkGetAlbumDetailsResponse>;
      if (userContext.isLoggedIn()) {
        promise = getAlbumDetails(userContext, eventId);
      } else {
        promise = getPreviewAlbumDetails(eventId, token);
      }
      albumDetailsRequests.current.set(cacheKey, promise);
      albumDetails = await promise;
      albumDetailsRequests.current.delete(cacheKey);
    }

    return albumDetails;
  };

  const refreshAlbumDetails = async (eventId: string, token?: string) => {
    const albumDetails = await memoizedAlbumDetailsFetch(eventId, token);
    const existingEvent = eventCache.current.get(eventId);
    if (existingEvent) {

      const event: IUEvent = { ...existingEvent, albumDetails };
      eventCache.current.set(existingEvent.id, event);
      eventCache.current = new Map(eventCache.current);
      setCache(eventCache.current);
      return event;
    }
    return undefined;
  };

  const memoizedEventsFetch = async (): Promise<TElkFetchEventsResponse> => {
    if (eventsRequest.current) {
      return eventsRequest.current;
    }

    const promise = fetchEvents(userContext, true);
    eventsRequest.current = promise;
    const events = await promise;
    eventsRequest.current = null;
    return events;
  };

  const sortMessages = (messages: TAppElkMessage[]) => {
    return messages.sort((a, b) => {
      return b.sentTime.toNumber() - a.sentTime.toNumber();
    });
  };

  const fetchEventsInternal = async (modId?: string): Promise<IUEvent[]> => {
    if (events !== undefined && events.length > 0 && (!modId || (homepageModId.current > modId))) {
      return events;
    }

    if (modId) {
      homepageModId.current = modId;
    }

    let response;
    try {
      response = await memoizedEventsFetch();
    } catch (e) {
      void logSumoEvent({
        app: ULogApplication.ELK,
        severity: ULogSeverity.ERROR,
        userId: userContext.id,
        tag: ULogTag.Network,
        message: `[EventCacheContext] Error fetching events: ${stringifyError(e)}`
      });
      return [];
    }

    const newEvents = response.events.map(
      event => convertToIUEvent({ event })
    );

    newEvents.forEach(event => {
      const existingEvent = eventCache.current.get(event.id);
      if (existingEvent !== undefined && (existingEvent.messages?.length ?? 0) > 0) {
        event.messages = existingEvent.messages;
      }
      event.albumDetails = existingEvent?.albumDetails;

      event.isHost = isUserHost(event, userContext.id);

      setEvent(event);
    });

    const now = Date.now();

    newEvents.sort((a, b) => {
      const startTimeA = a.startTime;
      const startTimeB = b.startTime;

      // Both times are in the future
      if (startTimeA > now && startTimeB > now) {
        return startTimeA - startTimeB;
      }

      // Both times are in the past
      if (startTimeA <= now && startTimeB <= now) {
        return startTimeB - startTimeA; // You can reverse this if you want past times in ascending order.
      }

      // One of them is in the past and the other in the future
      if (startTimeA <= now) {
        return 1; // `a` should come after `b`
      } else {
        return -1; // `b` should come after `a`
      }
    });
    setEvents(newEvents);

    return newEvents;
  };

  const getEventInternal = (eventId: string | undefined): IUEvent | undefined => {
    if (eventId === undefined) {
      return undefined;
    }
    return eventCache.current.get(eventId);
  };

  const setEvent = (event: IUEvent) => {
    eventCache.current.set(event.id, event);
    eventCache.current = new Map(eventCache.current);
    setCache(eventCache.current);
  };

  const deleteEvent = (eventId: string) => {
    eventCache.current.delete(eventId);
    if (events !== undefined) {
      setEvents(
        events.filter((event) => event.id !== eventId)
      );
    }
  };

  const clearAll = () => {
    eventCache.current = new Map();
  };

  const context: IUEventCacheContext = {
    cache,
    fetchEvent,
    refreshMessages,
    refreshAlbumDetails,
    fetchEvents: fetchEventsInternal,
    getEvent: getEventInternal,
    setEvent,
    deleteEvent,
    events,
    clearAll
  };

  return <EventCacheContext.Provider value={context}>
    {props.children}
  </EventCacheContext.Provider>;
}

export const useEventCacheContext = () => {
  const context = useContext(EventCacheContext);
  if (context === null) {
    throw new Error('useEventCacheContext must be used within a EventCacheContextProvider');
  }
  return context;
};
