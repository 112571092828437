import { IUEvent } from '../lib/event';

const STORAGE_KEY_PREFIX = 'autoSavedEvent-';

export const autoSaveEventToStorage = (event: IUEvent) => {
  window.sessionStorage.setItem(`${STORAGE_KEY_PREFIX}${event.id}`, JSON.stringify(event));
};

export const autoLoadEventFromStorage = (eventId: string): IUEvent | null => {
  const storageValue = window.sessionStorage.getItem(`${STORAGE_KEY_PREFIX}${eventId}`);
  try {
    return JSON.parse(storageValue ?? '') ?? null;
  } catch (e) {
    return null;
  }
};

export const clearSavedEventInStorage = (eventId: string) => {
  window.sessionStorage.removeItem(`${STORAGE_KEY_PREFIX}${eventId}`);
};
