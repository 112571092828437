import React, { FormEvent, ReactNode, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';

import {
  filterContextLites,
  filterMutualMembers,
  generateAutocompleteItemsFromContactLites,
  generateAutocompleteItemsFromMutualMembers,
  IUAutoCompleteContact
} from '../../util/contact';
import { emailExists, validateEmail } from '../../util/email';
import { IUEvent, IUInvitee } from '../../lib/event';
import { useUserContext } from '../../contexts/UserContext';
import InviteAutocompleteRow from './InviteAutocompleteRow';
import InviteCreateContact from './InviteCreateContact';
import { WhiteFormButton } from '../buttons/WhiteButton';
import { FilterType, InviteesByType } from '../../util/attendee';
import InviteInput from './InviteInput';
import { CarouselWrapper, FilterContainer, FilterPill, InactiveFilterPill } from '../PillToggle';

import { DeviceQueries } from 'Common/src/components/styled';
import AutocompleteDropdown, {
  DropdownContainer,
  IUAutoCompleteItem
} from 'Common/src/components/AutocompleteDropdown/AutocompleteDropdown';

import { TAppElkAttendeeRole, TAppElkAttendeeStatus, TAppElkInviteStatus } from 'TProtocol/prototypes/events/messages';

interface Props {
  inviteesByType: InviteesByType;
  event?: IUEvent;
  onCreate: (invite: IUInvitee) => void;
  activeFilters: Set<FilterType>;
  onFilterChange: (filterType: FilterType, status: boolean) => void;
}

const FilterLabels: {
  [key in FilterType]: string;
} = {
  [FilterType.All]: 'All',
  [FilterType.Unsent]: 'Unsent',
  [FilterType.Going]: 'Going',
  [FilterType.NotGoing]: 'Not Going',
  [FilterType.Awaiting]: 'Awaiting'
};

enum ContactAddStatus {
  None = 1,
  AddByName,
  AddByEmail
}

const InviteForm = ({ inviteesByType, event, onCreate, activeFilters, onFilterChange }: Props) => {
  const userContext = useUserContext();

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [search, setSearch] = useState('');
  const [error, setError] = useState('');
  const [contactAddStatus, setContactAddStatus] = useState(ContactAddStatus.None);

  const [autocompletions, setAutocompletions] = useState<IUAutoCompleteItem<IUAutoCompleteContact>[]>([]);

  const searchRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (search !== '') {
      onSearchChange(search);
    }
  }, [userContext.contacts, userContext.mutualMembers]);

  const onAutocompleteSelect = (value: IUAutoCompleteItem<IUAutoCompleteContact>) => {
    createInvitee(value.value);
  };

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();

    let thisEmail = email;
    if (contactAddStatus === ContactAddStatus.None) {
      thisEmail = search;
    }

    createInvitee({ name, email: thisEmail });
  };

  const createInvitee = ({ name, email, userId }: { name: string, email?: string, userId?: string }) => {
    if (name === '') {
      setError('Please enter a name.');
      return;
    }

    if (email !== undefined && !validateEmail(email)) {
      setError('Please enter a valid email.');
      return;
    }

    if (emailExists(email, event)) {
      setError('Email has already been invited.');
      return;
    }

    onCreate(new IUInvitee({
      inviteeId: uuidv4(),
      userId,
      email,
      name,
      role: TAppElkAttendeeRole.ATTENDEE,
      rsvpStatus: TAppElkAttendeeStatus.INVITED,
      inviteStatus: TAppElkInviteStatus.PENDING,
      additionalGuestCount: 0
    }));

    setSearch('');
    setError('');
    setName('');
    setEmail('');
    setContactAddStatus(ContactAddStatus.None);

    searchRef.current?.focus();
  };


  const onSearchChange = (search: string) => {
    setSearch(search);
    setError('');

    if (userContext.contacts !== undefined || userContext.mutualMembers !== undefined) {
      if (search === '') {
        setAutocompletions([]);
        return;
      }

      const contacts = filterContextLites(
        userContext.contacts,
        search
      );

      const mutualMembers = filterMutualMembers(
        userContext.mutualMembers,
        search
      );

      const byName = (a: IUAutoCompleteItem<IUAutoCompleteContact>, b: IUAutoCompleteItem<IUAutoCompleteContact>) => {
        const aVal = a.value.name ?? a.value.email;
        const bVal = b.value.name ?? b.value.email;

        return aVal < bVal ? -1 : 1;
      };

      const autoCompleteItems = [
        ...generateAutocompleteItemsFromContactLites(contacts),
        ...generateAutocompleteItemsFromMutualMembers(mutualMembers)
      ].sort(byName).slice(0, 10);

      setAutocompletions(
        autoCompleteItems
      );
    }
  };

  const onCreateCancel = () => {
    setSearch('');
    setContactAddStatus(ContactAddStatus.None);
  };

  const onCreateContactClick = () => {
    if (search.indexOf('@') > -1) {
      setEmail(search);
    } else {
      setName(search);
    }
    setContactAddStatus(ContactAddStatus.AddByName);
  };

  let contactsContent: ReactNode;
  if ((userContext.contacts?.length ?? 0) > 0) {
    contactsContent = <ContactsMessage>
      Your {userContext.contacts?.length} contacts from Shine are available to search!
    </ContactsMessage>;
  } else {
    contactsContent = <ContactsMessage>
      To add your contacts, get <AppStoreA href="https://shi.ne/photos" target="_blank" rel="noreferrer">
      Shine</AppStoreA> on iOS or Android!
    </ContactsMessage>;
  }

  let contactInputs: ReactNode;
  if (contactAddStatus === ContactAddStatus.AddByName) {
    contactInputs = <>
      <InviteInput width={131} value={name} onChange={setName} label="Name" onCancel={onCreateCancel}/>
      <InviteInput width={291} value={email} onChange={setEmail} label="Email" onCancel={onCreateCancel}/>
    </>;
  } else if (contactAddStatus === ContactAddStatus.AddByEmail) {
    contactInputs = <>
      <InviteInput width={291} value={email} onChange={setEmail} label="Email" onCancel={onCreateCancel}/>
      <InviteInput width={131} value={name} onChange={setName} label="Name" onCancel={onCreateCancel}/>
    </>;
  } else {
    contactInputs = <AutocompleteDropdown
      values={autocompletions}
      onSelect={onAutocompleteSelect}
      onCancel={() => setAutocompletions([])}
      Format={InviteAutocompleteRow}
    >
      <FormInput
        ref={searchRef}
        placeholder="Invite guests by name or email"
        value={search}
        onChange={(e) => onSearchChange(e.currentTarget.value)}
        onBlur={() => setAutocompletions([])}
        maxLength={128}
      />
      {search !== '' && autocompletions.length === 0 && error === '' &&
        <InviteCreateContact search={search} onClick={onCreateContactClick}/>}
    </AutocompleteDropdown>;
  }

  const enableAdd = (contactAddStatus === ContactAddStatus.None && search.indexOf('@') > -1) ||
    (contactAddStatus !== ContactAddStatus.None && email !== '');

  return <DropdownContainer>
    <ContainerRow>
      <FormContainer>
        <FormRow onSubmit={onSubmit}>
          {contactInputs}

          <InputGroupButtonWrapper>
            <WhiteFormButton type="submit" onClick={onSubmit} disabled={!enableAdd}>
              Add
            </WhiteFormButton>
          </InputGroupButtonWrapper>
        </FormRow>
        {error === '' ? null : <InviteError>{error}</InviteError>}
      </FormContainer>

      <CarouselWrapper>
        <FilterContainer>
          {
            Object.keys(FilterLabels).map((filterType: FilterType) => {
              const PillTag = activeFilters.has(filterType) ? FilterPill : InactiveFilterPill;
              return <PillTag
                key={filterType}
                onClick={() => onFilterChange(filterType, !activeFilters.has(filterType))}
              >
                {FilterLabels[filterType]} ({inviteesByType[filterType].length})
              </PillTag>;
            })
          }
        </FilterContainer>
      </CarouselWrapper>
    </ContainerRow>
    <ContactsContent>
      {contactsContent}
    </ContactsContent>
  </DropdownContainer>;
};

const ContainerRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  flex-wrap: wrap;

  @media (${DeviceQueries.mobile}) {
    display: block;
  }
`;

const FormContainer = styled.div``;

const FormRow = styled.form`
  display: flex;
  width: auto;
  align-items: flex-start;
  gap: 10px;

  @media (${DeviceQueries.mobile}) {
    flex-direction: column;

    ${DropdownContainer} {
      flex-grow: 1;
      width: 100%;
    }
  }
`;

const FormInput = styled.input`
  width: 529px;
  height: 39px;
  padding: 0 15px;
  font-size: 16px;
  border-radius: 12px;
  border: 1px solid #E6E6E6;
  background: rgba(255, 255, 255, 0.60);
  font-family: Poppins, sans-serif;

  &::placeholder {
    color: rgba(0, 0, 0, 0.40);
  }

  @media (${DeviceQueries.mobile}) {
    width: calc(100% - 35px);
  }
`;

const InviteError = styled.div`
  color: var(--shine-system-error-color);
  border-radius: 6px;
  padding: 5px 10px;
  grid-column-start: 1;
  grid-column-end: span 3;
`;

const InputGroupButtonWrapper = styled.div`

  @media (${DeviceQueries.mobile}) {
    min-width: 100%;
    margin-bottom: 10px;

    ${WhiteFormButton} {
      width: 100%;
      justify-content: center;
    }
  }
`;

const ContactsContent = styled.div`
  margin: 10px 0;
  font-size: 13px;
  font-weight: 400;
  padding: 0 15px;
`;

const AppStoreA = styled.a`
  color: #FF6422;
`;

const ContactsMessage = styled.div`
  color: rgba(0, 0, 0, 0.6);
`;

export default InviteForm;
