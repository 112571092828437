import React from 'react';

const Logout = ({ color = 'currentColor', size = 21 }: { color?: string, size?: number }) => {
  return <svg width={size} height={size} viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd"
          d="M17 3.5C16.7348 3.5 16.4804 3.60536 16.2929 3.79289C16.1054 3.98043 16 4.23478 16 4.5V16.5C16 16.7652 16.1054 17.0196 16.2929 17.2071C16.4804 17.3946 16.7348 17.5 17 17.5C17.2652 17.5 17.5196 17.3946 17.7071 17.2071C17.8946 17.0196 18 16.7652 18 16.5V4.5C18 4.23478 17.8946 3.98043 17.7071 3.79289C17.5196 3.60536 17.2652 3.5 17 3.5ZM9.293 6.793C9.10553 6.98053 9.00021 7.23484 9.00021 7.5C9.00021 7.76516 9.10553 8.01947 9.293 8.207L10.586 9.5H3C2.73478 9.5 2.48043 9.60536 2.29289 9.79289C2.10536 9.98043 2 10.2348 2 10.5C2 10.7652 2.10536 11.0196 2.29289 11.2071C2.48043 11.3946 2.73478 11.5 3 11.5H10.586L9.293 12.793C9.11084 12.9816 9.01005 13.2342 9.01233 13.4964C9.0146 13.7586 9.11977 14.0094 9.30518 14.1948C9.49059 14.3802 9.7414 14.4854 10.0036 14.4877C10.2658 14.49 10.5184 14.3892 10.707 14.207L13.707 11.207C13.8945 11.0195 13.9998 10.7652 13.9998 10.5C13.9998 10.2348 13.8945 9.98053 13.707 9.793L10.707 6.793C10.5195 6.60553 10.2652 6.50021 10 6.50021C9.73484 6.50021 9.48053 6.60553 9.293 6.793Z"
          fill={color}/>
  </svg>;
};

export default Logout;
