import React, { PropsWithChildren, ReactNode } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { useUserContext } from '../contexts/UserContext';
import PersonCropCircle from '../components/icons/PersonCropCircle';
import { loginUrl } from '../lib/login';
import ShineLogo from '../components/icons/ShineLogo';
import { useBackgroundContext } from '../contexts/BackgroundContext';
import { useEditEventContext } from '../contexts/EditEventContext';
import Home from '../components/icons/Home';
import WhiteButton from '../components/buttons/WhiteButton';
import Logout from '../components/icons/Logout';
import PlusCircleOutline from '../components/icons/PlusCircleOutline';
import LightBulbOutline from '../components/icons/LightBulbOutline';
import { DesktopShow, MobileShow } from '../components/styled/Common';
import { calculateColor } from '../util/attendee';

import { DeviceQueries } from 'Common/src/components/styled';
import { logSumoEvent, ULogApplication, ULogSeverity, ULogTag } from 'Common/src/api/SumoLogicApi';

const NameRegex = /^([^ ]*) (.*)$/;

export enum UPageType {
  Welcome = 1,
  Events,
  Event,
  Login,
  QuickCreate,
  CreateEvent,
  Gallery,
  EditEvent
}

const BasePageHeader = ({
                          children,
                          hide,
                          page
                        }: PropsWithChildren<{
  hide?: boolean,
  page?: UPageType
}>) => {
  const userContext = useUserContext();
  const backgroundContext = useBackgroundContext();
  const editEventContext = useEditEventContext();
  const navigate = useNavigate();
  const location = useLocation();

  const onHome = () => {
    navigate('/');
  };

  const onLogin = () => {
    if (location.pathname === '/welcome') {
      navigate(loginUrl('/'));
    } else {
      navigate(loginUrl(location.pathname + location.search));
    }
  };

  const onNew = () => {
    editEventContext.clearEvent();
    navigate('/event/create/ai');
  };

  const onGallery = () => {
    navigate('/gallery');
  };

  const onProfileClick = () => {
    void logSumoEvent({
      app: ULogApplication.ELK,
      severity: ULogSeverity.INFO,
      userId: userContext.id,
      tag: ULogTag.UserAction,
      message: '[BasePageHeader] Clicked on profile edit link'
    });
  };

  const backgroundTextColor = backgroundContext.systemColors.backgroundText;

  let createContent: ReactNode = null;
  if (page === UPageType.QuickCreate) {
    createContent = <DesktopShow>
      <WhiteButton onClick={onGallery}><LightBulbOutline size={20}/> Inspiration</WhiteButton>
    </DesktopShow>;
  } else if (page !== UPageType.Welcome && page !== UPageType.CreateEvent && page !== UPageType.Login && page !== UPageType.Event) {
    createContent = <WhiteButton onClick={onNew} $noMin={true}><PlusCircleOutline size={20}/> Create</WhiteButton>;
  }

  let rightContent: ReactNode = null;
  if (userContext.isLoggedIn()) {
    let profileIcon: ReactNode;
    if (userContext.photoUrl) {
      profileIcon = <UserPhoto $url={userContext.photoUrl}/>;
    } else if (userContext.name) {
      const names = (userContext?.name ?? '').match(NameRegex) ?? [userContext.name, userContext.name, ''];
      const firstName = names?.[1];
      const lastName = names?.[2];

      profileIcon = <UserCircle $color={calculateColor(userContext.generateElkUser())}>
        {firstName?.[0]?.toUpperCase() ?? ''}{lastName?.[0]?.toUpperCase() ?? ''}
      </UserCircle>;
    } else {
      profileIcon = <PersonCropCircle size={40} color={backgroundTextColor}/>;
    }

    rightContent = <NavContainer>
      {
        page !== UPageType.Events && <WhiteButton onClick={onHome} $noMin={true}><Home size={20}/> Events</WhiteButton>
      }
      <IconContainer>
        <HeaderLink to="/profile/edit" onClick={onProfileClick}>
          {profileIcon}
        </HeaderLink>
      </IconContainer>
    </NavContainer>;
  } else if (page !== UPageType.Login) {
    rightContent = <NavContainer>
      <WhiteButton onClick={onLogin} $noMin={true}><Logout/> Sign In</WhiteButton>
    </NavContainer>;
  }

  const desktopShowLogo = page !== UPageType.EditEvent && page !== UPageType.CreateEvent;

  return <BoxContainer $hide={hide}>
    <HeaderItem>
      {
        desktopShowLogo ? <HeaderLink to="/"><ShineLogo opacity={1}/></HeaderLink> : <MobileShow>
          <HeaderLink to="/"><ShineLogo opacity={1}/></HeaderLink>
        </MobileShow>
      }
    </HeaderItem>
    <HeaderItem>
      {children !== undefined ? children : <>
        {createContent}
        {rightContent}
      </>}
    </HeaderItem>
  </BoxContainer>;
};

const BoxContainer = styled.div<{ $hide?: boolean }>`
  position: relative;
  width: 100%;
  box-sizing: border-box;
  background-color: rgba(235, 235, 235, 0.0);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  gap: 10px;
  z-index: 10;
  font-family: Poppins, sans-serif;
  border-bottom: 0.5px solid rgba(255, 255, 255, 0.2);
  //backdrop-filter: blur(10px);

  @media (${DeviceQueries.mobile}) {
    overflow: hidden;
    padding: ${({ $hide = false }) => $hide ? '0' : '10px'};
    height: ${({ $hide = false }) => $hide ? '0' : '60px'};
    transition: height 0.3s cubic-bezier(.36, -0.01, 0, .77), padding 0.3s cubic-bezier(.36, -0.01, 0, .77);
  }
`;

const HeaderLink = styled(Link)`
  color: var(--shine-logo-color);
  text-decoration: none;
`;

const HeaderItem = styled.div`
  align-items: center;
  display: flex;
  gap: 12px;

  @media (${DeviceQueries.mobile}) {
    font-size: 8pt;
  }
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;

  a {
    display: flex;
  }
`;

const NavContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  //height: 35px;
`;

const UserPhoto = styled.div<{ $url: string }>`
  width: 38px;
  height: 38px;
  border-radius: 100px;
  background: url('${({ $url }) => $url}') lightgray 50% / cover no-repeat;;
`;

const UserCircle = styled.div<{ $color: string }>`
  background-color: ${({ $color }) => $color};
  width: 38px;
  height: 38px;
  border-radius: 100px;

  font-size: 22px;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;
`;

export default BasePageHeader;
