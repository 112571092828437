import React, { FormEvent } from 'react';
import styled from 'styled-components';
import ExpandingTextInput from '../ExpandingTextInput';
import { IUEvent, IUEventQuestion } from '../../lib/event';
import { TAppElkEventQuestionType } from 'TProtocol/prototypes/events/messages';
import { useEditEventContext } from '../../contexts/EditEventContext';
import MusicNote from '../icons/MusicNote';
import CloseIcon from 'Common/src/components/icons/CloseIcon';

interface Props {
  event: IUEvent;
  question: IUEventQuestion;
  index: number;
}

const EditableQuestion = ({ question, index }: Props) => {
  const editEventContext = useEditEventContext();

  const isSongRequestQuestion = question.questionType === TAppElkEventQuestionType.SONG;

  const handleQuestionTextChange = (value: string) => {
    question.question = value;
    editEventContext.modifyQuestion(question);
  };

  const handleQuestionDelete = () => {
    editEventContext.deleteQuestion(question);
  };

  return <Container>
    <QuestionLabel>
      <span>Question {index + 1}</span>
      <CloseContainer onClick={handleQuestionDelete}>
        <CloseIcon color="rgba(0, 0, 0, .3)"/>
      </CloseContainer>
    </QuestionLabel>
    <ExpandingTextInput
      placeholder={isSongRequestQuestion ? 'Request a song' : 'Question'}
      value={question.question}
      onChange={(value) => handleQuestionTextChange(value)}
      fontSize={'16px'}
      icon={isSongRequestQuestion ? <MusicNote/> : undefined}
      noVertMargin={true}
      isForSideBar={true}
    />
  </Container>;
};

const Container = styled.div`
  font-size: 24px;
  display: flex;
  flex-direction: column;
  max-width: 100vw;
  gap: 12px;
`;

const QuestionLabel = styled.label`
  color: #000;
  font-size: 16px;
  font-weight: 400;
  display: flex;
  gap: 10px;
  align-items: center;
`;

const CloseContainer = styled.span`
  display: flex;
  cursor: pointer;
`;

export default EditableQuestion;
