import React, { ReactNode } from 'react';
import styled from 'styled-components';

import AttendeeCircleRow from './AttendeeCircleRow';
import { amIUnverified } from '../../lib/attendance';
import { IUEvent, IUInvitee } from '../../lib/event';

import { TAppElkMessage } from 'TProtocol/prototypes/events/messages';

interface Props {
  event: IUEvent;
  attendees?: (IUInvitee | undefined)[];
  isInvited?: boolean;
  myInviteeUuid: string | undefined;
  messagesByInviteeId: Map<string, TAppElkMessage[]>;
  onVerifyClick: () => void;
  onReplyClick: (inviteeId: string) => void;
}

const AttendeeGroup = ({
  event,
  attendees,
  isInvited = false,
  myInviteeUuid,
  messagesByInviteeId,
  onVerifyClick,
  onReplyClick
}: Props) => {
  if (attendees === undefined) {
    const blanks: ReactNode[] = [];
    for (let i = 0; i < 12; i++) {
      blanks.push(<AttendeeCircleRow
        key={`blank-${i}`}
        isInvited={false}
        amIUnverified={false}
        amIHost={false}
        onVerifyClick={onVerifyClick}
        onReplyClick={onReplyClick}
      />);
    }

    return <Group>
      {blanks}
    </Group>;
  } else {
    return <Group>
      {attendees.map((attendee, index) =>
        <AttendeeCircleRow
          key={attendee?.userId ?? index}
          attendee={attendee}
          responseMessages={attendee?.inviteeId ? messagesByInviteeId.get(attendee?.inviteeId) : undefined}
          isInvited={isInvited}
          amIUnverified={amIUnverified(attendee, myInviteeUuid)}
          amIHost={event.isHost}
          onVerifyClick={onVerifyClick}
          onReplyClick={onReplyClick}
        />)}
    </Group>;
  }
};

const Group = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export default AttendeeGroup;
