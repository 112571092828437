import styled from 'styled-components';

import { DeviceQueries } from 'Common/src/components/styled';

export const ActionRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  margin-top: 10px;

  @media (${DeviceQueries.mobile}) {
    width: 100%;
  }
`;

export const EventModules = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 30px;
  margin-top: 20px;
  text-align: left;

  @media not (${DeviceQueries.mobile}) {
    width: 684px;
  }
`;

export const EventModule = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
`;

export const EventModuleHeading = styled.div`
  font-size: 18px;
  font-weight: 400;
  text-transform: uppercase;
`;

export const EventDetailsCell = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 8px;
  align-items: flex-start;

  &:nth-child(2n+1) {
    flex-direction: row;
    justify-self: center;
    align-items: center;
  }
`;

export const EventDetailsCellInner = styled.div`
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
`;

export const EventDetailsCellInnerForAutocomplete = styled(EventDetailsCellInner)`
  @media (${DeviceQueries.mobile}) {
    & > div:nth-child(1) {
      width: 100%;
    }
  }
`;

export const EventDetailsIconWrapper = styled.div`
  display: flex;
  height: 32px;
  align-items: center;
`;

export const PillInputRaw = styled.input<{ $withCancel?: boolean, $expanding?: boolean, $fontSize?: number }>`
  display: flex;
  padding: ${({ $withCancel = false }) => $withCancel ? '4px 30px 4px 8px' : '4px 8px'};
  align-items: center;
  gap: 10px;
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.30);
  border: 0;
  width: 260px;
  box-sizing: border-box;

  color: rgba(255, 255, 255, 0.90);
  font-family: Poppins, sans-serif;
  font-size: ${({ $fontSize = 22 }) => $fontSize}px;
  font-weight: 400;

  ${({ $expanding }) => $expanding && `
  @media (${DeviceQueries.mobile}) {
    width: 100%;
  }
  `}
`;

export const PillInputActionButton = styled(PillInputRaw).attrs({
  as: 'button'
})<{ $width?: string }>`
  width: ${({ $width }) => $width ?? 'auto'};
  cursor: pointer;
`;

export const PillDiv = styled(PillInputRaw).attrs({
  as: 'div'
})`
  width: auto;
  position: relative;
  flex-direction: column;
  align-items: flex-end;
  padding: 0;
`;

export const EventDetails = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 4px 8px;
  font-size: 22px;

  ${PillInputRaw} {
    margin-bottom: 4px;
  }

  @media (${DeviceQueries.mobile}) {
    font-size: 16px;
    line-height: 24px;
  }
`;
