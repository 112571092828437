import { IUAutoCompleteItem } from 'Common/src/components/AutocompleteDropdown/AutocompleteDropdown';
import { v4 as uuidv4 } from 'uuid';

import { TAppContactLite, TAppPhotoLite } from 'TProtocol/contactlite/messages';
import { TAppElkMutualMember } from 'TProtocol/prototypes/events/messages';

type UAutoCompleteContact = {
  isMember: false;
  name: string;
  email: string;
  photoUrl?: string;
}

type UAutoCompleteMutualMember = {
  isMember: true;
  userId: string;
  name: string;
  email?: string;
  photoUrl?: string;
  sharedEvent: string;
}

export type IUAutoCompleteContact = UAutoCompleteContact | UAutoCompleteMutualMember;

export const isMutualMember = (contact: IUAutoCompleteContact): contact is UAutoCompleteMutualMember => {
  return contact.isMember;
};

export const extractNameFromContactLite = (contact: TAppContactLite): string => {
  for (const field of contact.fields) {
    if (field.name !== undefined) {
      return field.name.fullName;
    }
  }

  return '';
};

export const extractPhoneFromContactLite = (contact: TAppContactLite): string => {
  for (const field of contact.fields) {
    if (field.phone !== undefined) {
      return field.phone.phoneNumber;
    }
  }

  return '';
};

export const extractEmailFromContactLite = (contact: TAppContactLite): string => {
  for (const field of contact.fields) {
    if (field.email !== undefined) {
      return field.email.emailAddress;
    }
  }

  return '';
};

export const extractEmailsFromContactLite = (contact: TAppContactLite): string[] => {
  const emails: string[] = [];

  for (const field of contact.fields) {
    if (field.email !== undefined) {
      emails.push(field.email.emailAddress);
    }
  }

  return emails;
};

export const extractPhotoFromContactLite = (contact: TAppContactLite): TAppPhotoLite | undefined => {
  for (const field of contact.fields) {
    if (field.photo !== undefined) {
      return field.photo;
    }
  }

  return undefined;
};

const byName = (a: TAppContactLite, b: TAppContactLite) => {
  return (extractNameFromContactLite(a) ?? '') < (extractNameFromContactLite(b) ?? '') ? -1 : 1;
};

export const filterContactLitesByName = (contacts: TAppContactLite[], nameFilter?: string) => {
  return contacts.filter(contact => {
    const name = extractNameFromContactLite(contact);
    return name && (!nameFilter || name.toLowerCase().indexOf(nameFilter.toLowerCase()) !== -1);
  }).sort(byName);
};

export const filterContactLitesByEmail = (contacts: TAppContactLite[], emailFilter?: string) => {
  return contacts.filter(contact => {
    if (emailFilter === undefined) {
      return true;
    }

    const emails = extractEmailsFromContactLite(contact);
    for (const email of emails) {
      if (email.toLowerCase().indexOf(emailFilter.toLowerCase()) !== -1) {
        return true;
      }
    }

    return false;
  });
};

export const filterContextLites = (contacts?: TAppContactLite[], filter?: string) => {
  if (contacts === undefined) {
    return [];
  }

  return contacts.filter(contact => {
    if (filter === undefined) {
      return true;
    }

    const emails = extractEmailsFromContactLite(contact);
    for (const email of emails) {
      if (email.toLowerCase().indexOf(filter.toLowerCase()) !== -1) {
        return true;
      }
    }

    const name = extractNameFromContactLite(contact);
    return name && (!filter || name.toLowerCase().indexOf(filter.toLowerCase()) !== -1);
  });
};

export const filterMutualMembers = (mutualMembers?: TAppElkMutualMember[], filter?: string) => {
  if (mutualMembers === undefined) {
    return [];
  }

  return mutualMembers.filter(mutualMember => {
    if (filter === undefined) {
      return true;
    }

    for (const email of mutualMember.member.emails ?? []) {
      if (email.toLowerCase().indexOf(filter.toLowerCase()) !== -1) {
        return true;
      }
    }

    const name = mutualMember.member.name;
    return !filter || name.toLowerCase().indexOf(filter.toLowerCase()) !== -1;
  });
};

export const generateAutocompleteItemsFromContactLites = (contacts: TAppContactLite[]): IUAutoCompleteItem<UAutoCompleteContact>[] => {
  return contacts.flatMap((contact) => {
    const name = extractNameFromContactLite(contact);
    const photo = extractPhotoFromContactLite(contact);
    return extractEmailsFromContactLite(contact).map((email) => {
      return {
        id: uuidv4(),
        displayString: '',
        value: {
          isMember: false,
          name,
          email,
          photoUrl: photo?.photoUrl
        }
      };
    });
  });
};

export const generateAutocompleteItemsFromMutualMembers = (mutualMembers: TAppElkMutualMember[]): IUAutoCompleteItem<UAutoCompleteMutualMember>[] => {
  return mutualMembers.map((mutualMember) => {
    return {
      id: uuidv4(),
      displayString: '',
      value: {
        isMember: true,
        userId: mutualMember.member.userId,
        name: mutualMember.member.name,
        email: mutualMember.member.emails?.[0],
        photoUrl: mutualMember.member.photoUrl,
        sharedEvent: mutualMember.sharedEvents[0]
      }
    };
  });
};
