import styled from 'styled-components';

const WhiteInput = styled.input<{
  $isInvalid?: boolean
}>`
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-weight: 400;
  padding: 5px 10px;
  border-radius: 12px;
  background: rgba(255, 255, 255, 0.30);
  box-shadow: 0 2px 5px 0 rgba(103, 110, 118, 0.08), 0 0 0 1px rgba(103, 110, 118, 0.16), 0 1px 1px 0 rgba(0, 0, 0, 0.12);
  border: ${({ $isInvalid }) => $isInvalid ? '0.5px solid #B3251E;' : 'none'};

  &:focus {
    outline: ${({ $isInvalid }) => $isInvalid ? '0.5px solid #B3251E' : 'none'};
    border: ${({ $isInvalid }) => $isInvalid ? '0.5px solid #B3251E' : 'none'};
  }
`;

export const TallerWhiteInput = styled(WhiteInput)`
  padding: 10px;
`;

export default WhiteInput;
