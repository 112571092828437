import React, { useRef } from 'react';
import styled from 'styled-components';
import ActionButton from './buttons/ActionButton';
import { DeviceQueries } from 'Common/src/components/styled';

import Cropper, { ReactCropperElement } from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import { ORANGE, WHITE } from 'Common/src/lib/Colors';
import { ModalContainer, Overlay } from './modals/CommonModal';

const ImageCropper = ({ image, circular, whenDone, close }: {
  image: string | ArrayBuffer,
  circular?: boolean
  whenDone(dataUrl: string): void
  close: () => void
}) => {
  const editorRef = useRef<ReactCropperElement>(null);

  const handleSave = () => {
    if (editorRef.current) {
      const canvasScaled = editorRef.current.cropper.getCroppedCanvas(
        { maxWidth: 1024, maxHeight: 1024, imageSmoothingQuality: 'high' });
      const dataURL = canvasScaled.toDataURL();
      whenDone(dataURL);
      close();
    }
  };

  const resetCropper = () => {
    if (editorRef.current) {
      editorRef.current.cropper.reset();
      editorRef.current.cropper.zoomTo(0.2);
    }
  };

  return <Overlay>
    <ModalContainer $maxWidth={'fit-content'}>
      <ImageContainer $circular={circular ?? false}>
        <Cropper
          ref={editorRef}
          src={image as any}
          style={{ width: '100%', height: '100%', borderRadius: '12px' }}
          initialAspectRatio={1}
          aspectRatio={circular ? 1 : NaN}
          viewMode={1}
          minCropBoxHeight={250}
          minCropBoxWidth={250}
          wheelZoomRatio={0.2}
          autoCrop={true}
          dragMode={'move'}
          background={false}
          responsive={false}
          toggleDragModeOnDblclick={false}
          checkOrientation={false}
          guides={!circular}
          zoomTo={0.2}
        />
      </ImageContainer>

      <ButtonsRow>
        <ActionButton type="button" outline={true} onClick={resetCropper}>
          Reset
        </ActionButton>
        <ActionButton type="button" outline={true} onClick={close}>
          Cancel
        </ActionButton>
        <ActionButton type="submit" onClick={handleSave}>
          Done
        </ActionButton>
      </ButtonsRow>
    </ModalContainer>
  </Overlay>;
};

const ImageContainer = styled.div<{ $circular: boolean }>`
  object-fit: contain;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(calc(100vh - 100px) * .7);
  width: 100vw;

  .cropper-view-box {
    border-radius: ${({ $circular }) => $circular ? '50%' : '12px'};
  }

  .cropper-drag-box {
    border-radius: 12px;
  }

  @media (${DeviceQueries.laptop}) {
    width: 80vh;
  }
`;

const ButtonsRow = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 33.3%);
  gap: 20px;
  margin: 25px;
  justify-content: center;
  --shine-bg-color: ${WHITE};
  color: ${ORANGE};

  @media (${DeviceQueries.laptop}) {
    margin-top: 80px;
  }
`;

export default ImageCropper;
