import React from 'react';

const ChevronUp = ({ color = 'currentColor', size = 15 }: { color?: string, size?: number }) => {
  return <svg width={size} height={size} viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.0837 9.25L7.00033 5.16667L2.91699 9.25" stroke={color} strokeLinecap="round" strokeLinejoin="round"/>
  </svg>;
};

export default ChevronUp;




