import React from 'react';

const AddList = ({ color = 'currentColor', size = 20 }: { color?: string, size?: number }) => {
  return <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.66634 4.16667H4.99967C4.55765 4.16667 4.13372 4.34226 3.82116 4.65482C3.5086 4.96738 3.33301 5.39131 3.33301 5.83333V15.8333C3.33301 16.2754 3.5086 16.6993 3.82116 17.0118C4.13372 17.3244 4.55765 17.5 4.99967 17.5H13.333C13.775 17.5 14.199 17.3244 14.5115 17.0118C14.8241 16.6993 14.9997 16.2754 14.9997 15.8333V15M6.66634 4.16667C6.66634 4.60869 6.84194 5.03262 7.1545 5.34518C7.46706 5.65774 7.89098 5.83333 8.33301 5.83333H9.99967C10.4417 5.83333 10.8656 5.65774 11.1782 5.34518C11.4907 5.03262 11.6663 4.60869 11.6663 4.16667M6.66634 4.16667C6.66634 3.72464 6.84194 3.30072 7.1545 2.98816C7.46706 2.67559 7.89098 2.5 8.33301 2.5H9.99967C10.4417 2.5 10.8656 2.67559 11.1782 2.98816C11.4907 3.30072 11.6663 3.72464 11.6663 4.16667M11.6663 4.16667H13.333C13.775 4.16667 14.199 4.34226 14.5115 4.65482C14.8241 4.96738 14.9997 5.39131 14.9997 5.83333V8.33333M16.6663 11.6667H8.33301M8.33301 11.6667L10.833 9.16667M8.33301 11.6667L10.833 14.1667"
      stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>;
};

export default AddList;
