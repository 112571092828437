import React, { MouseEvent, useState } from 'react';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';

import { IUEvent } from '../../lib/event';
import {
  BottomModalContainer,
  ModalContainer,
  ModalHeader,
  ModalTitle,
  Overlay,
  TransparentOverlayBottomModal
} from './CommonModal';
import ExpandingTextInput from '../ExpandingTextInput';
import { getInviteeById } from '../../lib/attendance';
import { useUserContext } from '../../contexts/UserContext';
import { sendEventComment } from '../../api/ElkEventService';
import { DesktopShow, MobileShow } from '../styled/Common';
import { BackArrowContainer } from '../../pages/InvitePage';
import BackArrow from '../icons/BackArrow';
import ColorButton from '../buttons/ColorButton';

import { logSumoEvent, ULogApplication, ULogSeverity, ULogTag } from 'Common/src/api/SumoLogicApi';

interface Props {
  event: IUEvent;
  inviteeId: string;
  close: () => void;
}

const AttendeeReplyModal = ({ event, inviteeId, close }: Props) => {
  const userContext = useUserContext();
  const [message, setMessage] = useState('');
  const [submitted, setSubmitted] = useState(false);

  const invitee = getInviteeById(event, inviteeId);

  const onModalClick = (e: MouseEvent) => {
    e.stopPropagation();
  };

  const sendMessage = async () => {
    setSubmitted(true);
    await sendEventComment({
      userContext,
      eventId: event.id,
      messageId: uuidv4(),
      message,
      inviteeId
    });

    close();
  };

  const onClose = () => {
    void logSumoEvent({
      app: ULogApplication.ELK,
      severity: ULogSeverity.INFO,
      userId: userContext.id,
      tag: ULogTag.UserAction,
      message: '[AttendeeReplyModal] Closed modal'
    });

    close();
  };

  const modalContent = <>
    <ModalHeader>
      <BackArrowContainer onClick={onClose}>
        <BackArrow/>
      </BackArrowContainer>
      <ModalTitle>Private message</ModalTitle>
    </ModalHeader>

    <div>
      <InputLabel>Message to {invitee?.name}</InputLabel>
      <ExpandingTextInput
        value={message}
        textColor="var(--shine-text-color)"
        placeholder="Write here..."
        onChange={setMessage}
        required={true}
        minHeight={140}
        isForSideBar={true}
        noVertMargin={true}
      />

      <ReminderText>
        {invitee?.name} will receive either an email or a text message based on their preferences.
      </ReminderText>
    </div>

    <ColorButton onClick={sendMessage} disabled={submitted}>Send</ColorButton>
  </>;

  return <>
    <DesktopShow>
      <Overlay onClick={onClose}>
        <ModalContainer onClick={onModalClick}>
          {modalContent}
        </ModalContainer>
      </Overlay>
    </DesktopShow>
    <MobileShow>
      <TransparentOverlayBottomModal onClick={onClose}>
        <BottomModalContainer onClick={onModalClick} $noOverflowScroll={true}>
          {modalContent}
        </BottomModalContainer>
      </TransparentOverlayBottomModal>
    </MobileShow>
  </>;
};

const ReminderText = styled.div`
  margin-top: 20px;
  font-size: 16px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.40);
`;

const InputLabel = styled.div`
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 12px;
`;

export default AttendeeReplyModal;
