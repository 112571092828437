import { GiphyFetch } from '@giphy/js-fetch-api';
import { IGif } from '@giphy/js-types';
import { logSumoEvent, stringifyError, ULogApplication, ULogSeverity, ULogTag } from 'Common/src/api/SumoLogicApi';
import { useUserContext } from '../contexts/UserContext';

// Docs: https://github.com/Giphy/giphy-js/blob/master/packages/fetch-api/README.md

declare const GIPHY_API_KEY: string;

const useGiphy = () => {
  const userContext = useUserContext();

  const getGifsWithSearchTerm = async (searchTerm: string, useVideos: boolean) => {
    const gf = new GiphyFetch(GIPHY_API_KEY);
    try {
      return await gf.search(searchTerm, { sort: 'relevant', type: useVideos ? 'videos' : 'gifs' });
    } catch (e) {
      void logSumoEvent({
        app: ULogApplication.ELK,
        severity: ULogSeverity.WARN,
        userId: userContext.id,
        tag: ULogTag.Browser,
        message: `[useVideoBackgroundPicker] Error requesting Giphy videos: ${stringifyError(e)}`
      });
      return undefined;
    }
  };

  const getGifVideoLink = (gif: IGif) => {
    if (gif.type === 'video') {
      return gif.video?.assets['360p'].url ?? '';
    } else if (gif.type === 'gif') {
      return gif.images.original_mp4.mp4;
    } else {
      return '';
    }
  };

  return {
    getGifsWithSearchTerm,
    getGifVideoLink
  };
};

export default useGiphy;


