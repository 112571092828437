import React, { MouseEvent, PropsWithChildren } from 'react';
import styled from 'styled-components';

import {
  ModalContainer,
  ModalContent,
  ModalHeader,
  ModalSubtitle,
  ModalTitle,
  Overlay,
  SystemModalContainer
} from './CommonModal';
import { useUserContext } from '../../contexts/UserContext';
import { BackArrowContainer } from '../../pages/InvitePage';
import BackArrow from '../icons/BackArrow';
import SystemButton, { ButtonInternal } from '../buttons/SystemButton';
import ColorButton from '../buttons/ColorButton';

import { logSumoEvent, ULogApplication, ULogSeverity, ULogTag } from 'Common/src/api/SumoLogicApi';

interface Props {
  title?: string;
  subtitle?: string;
  secondaryButtonLabel?: string;
  primaryButtonLabel?: string;
  primaryButtonDisabled?: boolean;
  secondaryButtonDisabled?: boolean;
  onClickOutside?: () => void;
  onSecondaryClick?: () => void;
  onPrimaryClick?: () => void;
  showClose?: boolean;
  secondaryButtonOutlined?: boolean;
  useSystemColors?: boolean;
}

const Modal: React.FC<PropsWithChildren<Props>> = ({
                                                     title,
                                                     subtitle,
                                                     secondaryButtonLabel,
                                                     primaryButtonLabel,
                                                     primaryButtonDisabled = false,
                                                     secondaryButtonDisabled = false,
                                                     onClickOutside,
                                                     onSecondaryClick,
                                                     onPrimaryClick,
                                                     children,
                                                     showClose,
                                                     secondaryButtonOutlined,
                                                     useSystemColors
                                                   }) => {
  const userContext = useUserContext();

  const onModalClick = (e: MouseEvent) => {
    e.stopPropagation();
  };

  const onOutsideClick = () => {
    void logSumoEvent({
      app: ULogApplication.ELK,
      severity: ULogSeverity.INFO,
      userId: userContext.id,
      tag: ULogTag.UserAction,
      message: '[Modal] Manually closed modal'
    });

    onClickOutside && onClickOutside();
  };

  const content = <ModalContent>
    <ModalHeader $centered={!showClose}>
      {showClose && <BackArrowContainer onClick={onOutsideClick}>
        <BackArrow/>
      </BackArrowContainer>}
      {title && <ModalTitle>{title}</ModalTitle>}
    </ModalHeader>

    {subtitle && <ModalSubtitle>{subtitle}</ModalSubtitle>}

    {children}
  </ModalContent>;

  return <Overlay onClick={onOutsideClick}>
    {useSystemColors
      ? <SystemModalContainer onClick={onModalClick}>
        {content}
        {primaryButtonLabel !== undefined || secondaryButtonLabel !== undefined ? <ButtonGroup>
          {secondaryButtonLabel &&
            <Wrapper>
              <SystemButton secondary={true} onClick={onSecondaryClick} disabled={secondaryButtonDisabled}
                            outline={secondaryButtonOutlined}>{secondaryButtonLabel}</SystemButton>
            </Wrapper>
          }
          {primaryButtonLabel &&
            <Wrapper>
              <SystemButton secondary={false} onClick={onPrimaryClick}
                            disabled={primaryButtonDisabled}>{primaryButtonLabel}</SystemButton>
            </Wrapper>
          }
        </ButtonGroup> : <></>}
      </SystemModalContainer>
      : <ModalContainer onClick={onModalClick}>
        {content}
        {primaryButtonLabel !== undefined || secondaryButtonLabel !== undefined ? <ButtonGroup>
          {secondaryButtonLabel &&
            <Wrapper>
              <ColorButton onClick={onSecondaryClick}
                           disabled={secondaryButtonDisabled}
                           outline={secondaryButtonOutlined}
                           noColor={!secondaryButtonOutlined}>{secondaryButtonLabel}</ColorButton>
            </Wrapper>
          }
          {primaryButtonLabel &&
            <Wrapper>
              <ColorButton onClick={onPrimaryClick}
                           disabled={primaryButtonDisabled}>{primaryButtonLabel}</ColorButton>
            </Wrapper>
          }
        </ButtonGroup> : <></>}
      </ModalContainer>}
  </Overlay>;
};

const ButtonGroup = styled.div`
  display: flex;
  margin-top: 11px;

  ${ButtonInternal} {
    width: 100%;
  }
`;

export const Wrapper = styled.div`
  margin: 0 5px;
  width: 100%;
  display: flex;
`;

export default Modal;
