// string to url encoded base64 string. allows unicode
// use this instead of btoa
export const stob64 = (s: string): string => {
  const encodedString = new TextEncoder().encode(s).reduce((str, byte) => str + String.fromCharCode(byte), '');
  const urlEncodedBase64 = encodeURIComponent(btoa(encodedString));
  return urlEncodedBase64;
};

// url encoded base64 string to decoded string
// use this instead of atob
export const b64tos = (urlEncodedBase64: string): string => {
  const b64 = decodeURIComponent(urlEncodedBase64);
  const binaryString = atob(b64);
  const byteArray = new Uint8Array(binaryString.length);
  for (let i = 0; i < binaryString.length; i++) {
    byteArray[i] = binaryString.charCodeAt(i);
  }
  const decodedStr = new TextDecoder('utf-8').decode(byteArray);
  return decodedStr;
};

export const blobToBase64 = async (blob: Blob): Promise<string | undefined> => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result?.toString());
    reader.readAsDataURL(blob);
  });
};
