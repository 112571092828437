import React from 'react';

const AddToCal = ({ color = 'currentColor', size = 21 }: { color?: string, size?: number }) => {
  return <svg width={size} height={size} viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd"
          d="M6.5 2.5C6.23478 2.5 5.98043 2.60536 5.79289 2.79289C5.60536 2.98043 5.5 3.23478 5.5 3.5V4.5H4.5C3.96957 4.5 3.46086 4.71071 3.08579 5.08579C2.71071 5.46086 2.5 5.96957 2.5 6.5V16.5C2.5 17.0304 2.71071 17.5391 3.08579 17.9142C3.46086 18.2893 3.96957 18.5 4.5 18.5H16.5C17.0304 18.5 17.5391 18.2893 17.9142 17.9142C18.2893 17.5391 18.5 17.0304 18.5 16.5V6.5C18.5 5.96957 18.2893 5.46086 17.9142 5.08579C17.5391 4.71071 17.0304 4.5 16.5 4.5H15.5V3.5C15.5 3.23478 15.3946 2.98043 15.2071 2.79289C15.0196 2.60536 14.7652 2.5 14.5 2.5C14.2348 2.5 13.9804 2.60536 13.7929 2.79289C13.6054 2.98043 13.5 3.23478 13.5 3.5V4.5H7.5V3.5C7.5 3.23478 7.39464 2.98043 7.20711 2.79289C7.01957 2.60536 6.76522 2.5 6.5 2.5ZM6.5 7.5C6.23478 7.5 5.98043 7.60536 5.79289 7.79289C5.60536 7.98043 5.5 8.23478 5.5 8.5C5.5 8.76522 5.60536 9.01957 5.79289 9.20711C5.98043 9.39464 6.23478 9.5 6.5 9.5H14.5C14.7652 9.5 15.0196 9.39464 15.2071 9.20711C15.3946 9.01957 15.5 8.76522 15.5 8.5C15.5 8.23478 15.3946 7.98043 15.2071 7.79289C15.0196 7.60536 14.7652 7.5 14.5 7.5H6.5Z"
          fill={color}/>
  </svg>;
};

export default AddToCal;
