import React from 'react';

const MailOutline = ({ color = 'currentColor', size = 21 }: { color?: string, size?: number }) => {
  return <svg width={size} height={size} viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.5 7.16406L9.075 11.5474C9.34888 11.7301 9.67075 11.8276 10 11.8276C10.3292 11.8276 10.6511 11.7301 10.925 11.5474L17.5 7.16406M4.16667 16.3307H15.8333C16.2754 16.3307 16.6993 16.1551 17.0118 15.8426C17.3244 15.53 17.5 15.1061 17.5 14.6641V6.33073C17.5 5.8887 17.3244 5.46478 17.0118 5.15222C16.6993 4.83966 16.2754 4.66406 15.8333 4.66406H4.16667C3.72464 4.66406 3.30072 4.83966 2.98816 5.15222C2.67559 5.46478 2.5 5.8887 2.5 6.33073V14.6641C2.5 15.1061 2.67559 15.53 2.98816 15.8426C3.30072 16.1551 3.72464 16.3307 4.16667 16.3307Z"
      stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>;
};

export default MailOutline;
