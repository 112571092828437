import React from 'react';

const QuestionMarkCircle = ({ color = 'currentColor' }: {
  color?: string
}) => {
  return <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="QuestionMarkCircleOutline">
      <path id="Vector"
            d="M8.228 9.5C8.777 8.335 10.258 7.5 12 7.5C14.21 7.5 16 8.843 16 10.5C16 11.9 14.722 13.075 12.994 13.407C12.452 13.511 12 13.947 12 14.5M12 17.5H12.01M21 12.5C21 13.6819 20.7672 14.8522 20.3149 15.9442C19.8626 17.0361 19.1997 18.0282 18.364 18.864C17.5282 19.6997 16.5361 20.3626 15.4442 20.8149C14.3522 21.2672 13.1819 21.5 12 21.5C10.8181 21.5 9.64778 21.2672 8.55585 20.8149C7.46392 20.3626 6.47177 19.6997 5.63604 18.864C4.80031 18.0282 4.13738 17.0361 3.68508 15.9442C3.23279 14.8522 3 13.6819 3 12.5C3 10.1131 3.94821 7.82387 5.63604 6.13604C7.32387 4.44821 9.61305 3.5 12 3.5C14.3869 3.5 16.6761 4.44821 18.364 6.13604C20.0518 7.82387 21 10.1131 21 12.5Z"
            stroke={color} strokeOpacity="0.7" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </g>
  </svg>;
};

export default QuestionMarkCircle;
