import { TAppAddress } from 'TProtocol/common/models';
import GeocoderAddressComponent = google.maps.GeocoderAddressComponent;
import PlaceResult = google.maps.places.PlaceResult;

export const getPredictions = async (mapDiv: HTMLDivElement, input: string) => {
  const service = new google.maps.places.AutocompleteService();

  return new Promise<PlaceResult | undefined>((resolve) => {
    service.getPlacePredictions({
      input
    }, (results) => {
      if (results?.length === 1 && results[0].place_id) {
        resolve(getDetails(mapDiv, results[0].place_id));
      } else {
        resolve(undefined);
      }
    });
  })
};

export const searchPlace = async (mapDiv: HTMLDivElement, query: string): Promise<PlaceResult | undefined> => {
  const service = new google.maps.places.PlacesService(mapDiv);

  return new Promise((resolve) => {
    service.findPlaceFromQuery({
      query,
      fields: ['place_id']
    }, (results) => {
      if ((results?.length ?? 0) > 0 && results?.[0].place_id) {
        resolve(getDetails(mapDiv, results[0].place_id));
      } else {
        resolve(undefined);
      }
    });
  });
};

const getDetails = async (mapDiv: HTMLDivElement, placeId: string): Promise<PlaceResult | undefined> => {
  const service = new google.maps.places.PlacesService(mapDiv);

  return new Promise((resolve) => {
    service.getDetails({ placeId, fields: ['name', 'address_components'] }, (result) => {
      if (result) {
        resolve(result);
      } else {
        resolve(undefined);
      }
    });
  });
};

export const convertResultToAddress = (placeResult: PlaceResult): TAppAddress | undefined => {
  const address = new TAppAddress({});
  const addressComponents = placeResult.address_components;

  if (!addressComponents) {
    return undefined;
  }

  const city = addressComponents.filter((component: GeocoderAddressComponent) =>
    component.types.includes('locality') || component.types.includes('sublocality')
  )[0];
  const country = addressComponents.filter((component: GeocoderAddressComponent) =>
    component.types.includes('country')
  )[0];
  const admin1 = addressComponents.filter((component: GeocoderAddressComponent) =>
    component.types.includes('administrative_area_level_1')
  )[0];
  const zip = addressComponents.filter((component: GeocoderAddressComponent) =>
    component.types.includes('postal_code')
  )[0];

  let subPremiseObj;
  if (addressComponents[0].types.includes('subpremise')) {
    subPremiseObj = addressComponents.shift();
  }
  address.address2 = subPremiseObj?.long_name;

  const name = (!placeResult?.name?.match(/^\d/) && placeResult.name + ', ') || '';
  const isStreetAddress = addressComponents[0].types.includes(
    'street_number') && addressComponents[1].types.includes('route');

  let fullAddress = '';
  if (isStreetAddress) {
    fullAddress = `${name}${addressComponents[0].long_name} ${addressComponents[1].long_name}`;
  }

  address.address1 = fullAddress;
  address.city = city?.long_name ?? '';
  address.country = country?.long_name ?? '';
  address.admin1 = admin1?.short_name ?? '';
  address.postalCode = zip?.short_name ?? '';

  return address;
};

export const convertResultToString = (place: PlaceResult): string => {
  let fullAddress = '';

  if (place.address_components) {

    const cityObj = place.address_components.find(
      component => component.types.includes('locality') || component.types.includes('sublocality')
    );
    const stateObj = place.address_components.find(
      component => component.types.includes('administrative_area_level_1')
    );

    const city = cityObj ? cityObj.long_name : '';
    const state = stateObj ? stateObj.short_name : '';
    const streetNumberObj = place.address_components.find(
      component => component.types.includes('street_number')
    );
    const routeObj = place.address_components.find(
      component => component.types.includes('route')
    );

    const streetNumber = streetNumberObj ? streetNumberObj.long_name : '';
    const route = routeObj ? routeObj.long_name : '';

    const streetAddress = `${streetNumber} ${route}`.trim();

    // If place.name exists and is not a part of the streetAddress
    if (place.name && !(areOverlappingAddresses(place.name, streetAddress))) {
      if (fullAddress !== '' && fullAddress !== null) {
        fullAddress = `${place.name}, ${streetAddress}, ${city}, ${state}`;
      } else fullAddress = `${place.name}, ${city}, ${state}`;
    } else {
      // For generic addresses, use the full formatted address
      fullAddress = place.formatted_address || '';
    }
  }
  return fullAddress;
}

const areOverlappingAddresses = (name: string, address: string) => {
  //if subset, there is overlap
  if (address.includes(name)) {
    return true;
  }
  const nameWords = name.split(' ');
  const addressWords = address.split(' ');

  // Check if the first words are the same or
  if (nameWords[0] === addressWords[0]) {
    // Check for at least one more overlapping word
    for (let i = 1; i < nameWords.length; i++) {
      if (addressWords.includes(nameWords[i])) {
        // If there's an overlapping word, return true - similar addresses
        return true;
      }
    }
  }

  return false;
};
