import { useEffect } from 'react';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import { useUserContext } from '../contexts/UserContext';
import { useEventCacheContext } from '../contexts/EventCacheContext';
import { updateEventAttendance } from '../lib/attendance';
import { b64tos } from '../util/stringutils';
import { EventPageState } from './Event/EventPage';
import { updateAttendance } from '../api/ElkEventService';
import useInviteeUuidStorage from '../hooks/useInviteeUuidStorage';
import { useModalContext } from '../contexts/ModalContext';
import { IUInvitee } from '../lib/event';

import { logSumoEvent, stringifyError, ULogApplication, ULogSeverity, ULogTag } from 'Common/src/api/SumoLogicApi';

import {
  TAppElkAttendeeRole,
  TAppElkAttendeeStatus,
  TAppElkNotificationPreference,
  TAppElkPhotoUploadMode
} from 'TProtocol/prototypes/events/messages';

const UpdateAttendanceEventAction = () => {
  const userContext = useUserContext();
  const eventCacheContext = useEventCacheContext();
  const modalContext = useModalContext();
  const { getInviteeUuid, deleteInviteeUuid, getToken, getQuestionUuidsAndAnswers } = useInviteeUuidStorage();
  const [queryParams] = useSearchParams();
  const params = useParams();
  const location = useLocation();

  const eventId: string | undefined = params.eventId;
  const answers = getQuestionUuidsAndAnswers(eventId ?? '');

  const showRSVP = !location.state?.finishingRSVP;

  let response: string | undefined | null;
  let photoUploadModeString: string | undefined | null;
  let photoUploadMode: TAppElkPhotoUploadMode | undefined;

  let encodedMessage: string | undefined | null;
  let numGuests: string | undefined | null;
  let notificationPreference: TAppElkNotificationPreference | undefined;
  let encodedName: string | undefined | null;
  let encodedEmail: string | undefined | null;
  let encodedPhone: string | undefined | null;

  let inviteeUuid: string | undefined | null;
  if (queryParams.has('r')) {
    response = queryParams.get('r');
  }
  if (queryParams.has('em')) {
    encodedMessage = queryParams.get('em');
  }
  if (queryParams.has('ng')) {
    numGuests = queryParams.get('ng');
  }
  if (queryParams.has('en')) {
    encodedName = queryParams.get('en');
  }

  if (queryParams.has('ee')) {
    encodedEmail = queryParams.get('ee');
  }

  if (queryParams.has('ep')) {
    encodedPhone = queryParams.get('ep');
  }

  if (queryParams.has('pu')) {
    photoUploadModeString = queryParams.get('pu');

    if (photoUploadModeString === 'manual') {
      photoUploadMode = TAppElkPhotoUploadMode.MANUAL;
    } else if (photoUploadModeString === 'magic') {
      photoUploadMode = TAppElkPhotoUploadMode.AUTOMATIC;
    }
  }

  if (queryParams.has('iu')) {
    inviteeUuid = queryParams.get('iu');
  } else {
    inviteeUuid = getInviteeUuid(eventId);
  }


  if (queryParams.has('np')) {
    const notifPrefString = queryParams.get('np');
    if (notifPrefString === 'phone') {
      notificationPreference = TAppElkNotificationPreference.PHONE;
    } else if (notifPrefString === 'email') {
      notificationPreference = TAppElkNotificationPreference.EMAIL;
    } else if (notifPrefString === 'both') {
      notificationPreference = TAppElkNotificationPreference.BOTH;
    }
  }

  let updatedMessage = '';

  if (encodedMessage) {
    let decodedMessage = '';
    try {
      decodedMessage = b64tos(encodedMessage);
    } catch (e) {
      void logSumoEvent({
        app: ULogApplication.ELK,
        severity: ULogSeverity.WARN,
        userId: userContext.id,
        tag: ULogTag.Browser,
        message: `[UpdateAttendanceEventAction] Error during message decoding "${encodedMessage}": ${stringifyError(e)}`
      });
    }
    if (decodedMessage === 'm') {
      updatedMessage = '';
    } else if (decodedMessage !== undefined) {
      updatedMessage = decodedMessage;
    }
  }
  let decodedName: string | undefined = undefined;
  if (encodedName) {
    try {
      decodedName = b64tos(encodedName);
    } catch (e) {
      void logSumoEvent({
        app: ULogApplication.ELK,
        severity: ULogSeverity.WARN,
        userId: userContext.id,
        tag: ULogTag.Browser,
        message: `[UpdateAttendanceEventAction] Error during name decoding "${encodedName}": ${stringifyError(e)}`
      });
    }
  }

  let decodedEmail: string | undefined = undefined;
  if (encodedEmail) {
    try {
      decodedEmail = b64tos(encodedEmail);
    } catch (e) {
      void logSumoEvent({
        app: ULogApplication.ELK,
        severity: ULogSeverity.WARN,
        userId: userContext.id,
        tag: ULogTag.Browser,
        message: `[UpdateAttendanceEventAction] Error during email decoding "${encodedEmail}": ${stringifyError(e)}`
      });
    }
  }

  let decodedPhone: string | undefined = undefined;
  if (encodedPhone) {
    try {
      decodedPhone = b64tos(encodedPhone);
    } catch (e) {
      void logSumoEvent({
        app: ULogApplication.ELK,
        severity: ULogSeverity.WARN,
        userId: userContext.id,
        tag: ULogTag.Browser,
        message: `[UpdateAttendanceEventAction] Error during phone decoding "${encodedPhone}": ${JSON.stringify(e)}`
      });
    }
  }

  useEffect(() => {
    if (eventId !== undefined) {
      void handleRSVP(eventId);
    } else {
      void logSumoEvent({
        app: ULogApplication.ELK,
        severity: ULogSeverity.WARN,
        userId: userContext.id,
        tag: ULogTag.Browser,
        message: '[UpdateAttendanceEventAction] eventId is undefined'
      });
      // Possibly show an error instead?
      userContext.finishLogin('/');
    }
  }, [userContext.name]);

  const handleRSVP = async (eventId: string) => {
    const name = userContext.name;
    if (!name) {
      return;
    }
    let additionalGuests = 0;
    if (numGuests) {
      additionalGuests = parseInt(numGuests);
    }
    let stat: TAppElkAttendeeStatus;
    if (response === 'yes') {
      stat = TAppElkAttendeeStatus.YES;
    } else if (response === 'no') {
      stat = TAppElkAttendeeStatus.NO;
    } else {
      stat = TAppElkAttendeeStatus.SEEN;
    }

    const inviteeId = inviteeUuid || uuidv4();

    const attendee = new IUInvitee({
      inviteeId,
      rsvpStatus: stat,
      role: TAppElkAttendeeRole.ATTENDEE,
      additionalGuestCount: additionalGuests,
      userId: userContext.id,
      photoUrl: userContext.photoUrl,
      name: decodedName ?? name,
      notificationPreference: notificationPreference,
      answers: answers
    });

    if (updatedMessage) {
      attendee.rsvpMessage = updatedMessage;
    }
    if (decodedEmail !== undefined) {
      attendee.email = decodedEmail ?? userContext.getEmail();
    }
    if (decodedPhone !== undefined) {
      attendee.phone = decodedPhone ?? userContext.phoneNumber;
    }

    deleteInviteeUuid(eventId);

    const event = await eventCacheContext.fetchEvent({ eventId, inviteeUuid: inviteeId });
    const showPhotosPromo = response === 'yes' && !showRSVP;

    if (name && event?.id) {
      eventCacheContext.setEvent(updateEventAttendance(event, attendee));
    }
    void updateAttendance(userContext, eventId, attendee.toTAppElkInvitee(), undefined, undefined, photoUploadMode,
      false);

    modalContext.hide();

    let goTo = `/event/${eventId}`;
    if (showRSVP) {
      goTo = `/event/${eventId}?r=${response}`;
    }

    userContext.finishLogin(
      goTo,
      {
        state: {
          showPhotosPromo,
          showCodeConfirm: false,
          showEventCreated: false,
          showGuestOptions: false,
          showRSVP,
          finishingRSVP: false
        } as EventPageState,
        replace: true
      }
    );
  };
  return null;
};

export default UpdateAttendanceEventAction;
