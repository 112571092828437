import React from 'react';
import styled from 'styled-components';

import { IUToastConfig } from '../contexts/ToastContext';
import InformationCircleOutline from './icons/InformationCircleOutline';

import { DeviceQueries } from 'Common/src/components/styled';

const Toast = ({ config }: { config: IUToastConfig }) => {
  return <Notification>
    <InformationCircleOutline/>
    <TextContainer>
      {config.contents}
      {config.actionText && <>{' '}<ActionText onClick={config.onActionClick}>{config.actionText}</ActionText></>}
    </TextContainer>
  </Notification>;
};

const Notification = styled.div`
  position: absolute;
  bottom: 24px;
  right: 24px;
  display: flex;
  align-items: center;
  padding: 6px 12px;
  gap: 10px;
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.10);
  background: #FFF;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.05);
  color: #000;
  max-width: 50%;
  box-sizing: border-box;
  z-index: 5;

  @media (${DeviceQueries.mobile}) {
    position: static;
    bottom: auto;
    right: auto;
    border-radius: 0;
    max-width: 100vw;
  }
`;

const TextContainer = styled.div`
  flex-grow: 1;
`;

const ActionText = styled.span`
  color: #FF4747;
  cursor: pointer;
`;

export default Toast;
