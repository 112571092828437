import React, { MouseEvent, useEffect, useState } from 'react';
import styled from 'styled-components';

import { IUEvent } from '../lib/event';
import { ModalClose, ModalContainer, Overlay, Title } from './modals/CommonModal';

import CloseIcon from 'Common/src/components/icons/CloseIcon';
import { DeviceQueries } from 'Common/src/components/styled';

const PhotoViewer = ({ event, show, close }: {
  event: IUEvent,
  show: boolean,
  close: () => void
}) => {
  const [currentPhotoIndex, setCurrentPhotoIndex] = useState<number>(-1);

  const albumDetails = event.albumDetails;
  const photoMetas = albumDetails?.photoMetas ?? [];

  const stopClickPropagation = (e: MouseEvent) => {
    e.stopPropagation();
  };

  const getPreviousImage = (e?: MouseEvent) => {
    e && e.stopPropagation();
    if (currentPhotoIndex > 0) {
      setCurrentPhotoIndex(currentPhotoIndex - 1);
    }
  };

  const getNextImage = (e?: MouseEvent) => {
    e && e.stopPropagation();
    if (currentPhotoIndex < photoMetas.length - 1) {
      setCurrentPhotoIndex(currentPhotoIndex + 1);
    }
  };

  const closeImageView = () => {
    setCurrentPhotoIndex(-1);
  };

  const handleEscapeKey = () => {
    if (currentPhotoIndex === -1) {
      close();
    } else {
      setCurrentPhotoIndex(-1);
    }
  };

  const handleNavigateBetweenPhotos = (eventKey: string) => {
    if (eventKey === 'ArrowRight') {
      getNextImage();
    } else if (eventKey === 'ArrowLeft') {
      getPreviousImage();
    }
  };

  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        handleEscapeKey();
      } else if (currentPhotoIndex !== -1) {
        handleNavigateBetweenPhotos(event.key);
      }
    };
    window.addEventListener('keydown', handleKeyPress);
    return () => window.removeEventListener('keydown', handleKeyPress);
  }, [currentPhotoIndex, photoMetas]);

  if (!show || albumDetails === undefined || albumDetails.photoMetas === undefined) {
    return null;
  }

  if (currentPhotoIndex === -1) {
    return <Overlay onClick={close}>
      <FullScreenModalContainer onClick={close} $wide={true}>
        <ModalClose onClick={close}><CloseIcon/></ModalClose>
        {event.title !== undefined ? <EventTitle>{event.title}</EventTitle> : <></>}
        <ImagesContainer>
          {photoMetas.map((photoMeta, index) =>
            <GalleryViewImage key={photoMeta.photoId} src={photoMeta.thumbnailUrl}
                              onClick={(e: MouseEvent) => {
                                e.stopPropagation();
                                setCurrentPhotoIndex(index);
                              }}/>)}
        </ImagesContainer>
      </FullScreenModalContainer>
    </Overlay>;
  } else {
    return <Overlay onClick={closeImageView}>
      <ImageModal>
        <ModalClose onClick={closeImageView} style={{ margin: 0, padding: '10px' }}><CloseIcon/></ModalClose>
        <ModalContent>
          <ChevronContainer onClick={getPreviousImage}
                            $show={currentPhotoIndex > 0}>&lt;</ChevronContainer>
          <IndividualImage
            src={photoMetas[currentPhotoIndex].fullsizeUrl ?? photoMetas[currentPhotoIndex].thumbnailUrl}
            onClick={stopClickPropagation}/>
          <ChevronContainer onClick={getNextImage}
                            $show={currentPhotoIndex < photoMetas.length - 1}>&gt;</ChevronContainer>
        </ModalContent>
      </ImageModal>
    </Overlay>;

  }
};

const GalleryViewImage = styled.img`
  max-width: 100%;
  margin: 5px;
  border-radius: 6px;
  cursor: pointer;

  @media not (${DeviceQueries.mobile}) {
    max-height: 250px;
  }
`;

const ImageModal = styled(ModalContainer)`
  width: 100%;
  max-width: 100%;
  height: 100%;
  border: none;
  border-radius: 0;
  padding: 0;
`;

const ModalContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: calc(100% - 20px);
  width: 100%;
`;

const IndividualImage = styled.img`
  max-height: 99%;
  max-width: calc(100% - 60px);
`;

const ChevronContainer = styled.div<{ $show: boolean }>`
  font-size: 50px;
  width: 30px;
  ${({ $show }) => $show ? 'cursor: pointer;' : 'opacity: 0;'};

  @media not (${DeviceQueries.mobile}) {
    padding: 20px;
  }
`;

const FullScreenModalContainer = styled(ModalContainer)`
  height: 100%;
  min-width: 0;
  max-width: 100%;
  border: none;
  border-radius: 0;
`;

const EventTitle = styled(Title)`
  justify-content: center;
`;

const ImagesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

export default PhotoViewer;
