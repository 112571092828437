import React, { KeyboardEvent, RefObject } from 'react';
import styled from 'styled-components';

import MiniXCircle from '../icons/MiniXCircle';

import { DeviceQueries } from 'Common/src/components/styled';

interface Props {
  inputRef?: RefObject<HTMLInputElement>;
  width: number;
  placeholder?: string;
  label?: string;
  value: string;
  onChange?: (value: string) => void;
  onCancel?: () => void;
}

const InviteInput = ({ inputRef, width, placeholder, label, value, onChange, onCancel }: Props) => {

  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.key === 'Escape' && onCancel !== undefined) {
      onCancel();
      e.preventDefault();
    }
  };

  if (label === undefined) {
    return <Container>
      <Input
        $width={width}
        $withLabel={false}
        ref={inputRef}
        onKeyDown={handleKeyDown}
        placeholder={placeholder}
        value={value}
        onChange={(e) => onChange && onChange(e.currentTarget.value)}
      />
      {onCancel !== undefined && <Cancel onClick={onCancel}>
        <MiniXCircle opacity={0.1}/>
      </Cancel>}
    </Container>;
  } else {
    return <Container>
      <Label>
        {label}
      </Label>
      <Input
        $width={width}
        $withLabel={true}
        ref={inputRef}
        onKeyDown={handleKeyDown}
        placeholder={placeholder}
        value={value}
        onChange={(e) => onChange && onChange(e.currentTarget.value)}
      />
      {onCancel !== undefined && <Cancel onClick={onCancel}>
        <MiniXCircle opacity={0.1}/>
      </Cancel>}
    </Container>;
  }
};

const Container = styled.div`
  display: flex;
  position: relative;

  @media (${DeviceQueries.mobile}) {
    width: 100%;
  }
`;

const Label = styled.span`
  display: flex;
  padding: 4px 8px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: rgba(0, 0, 0, 0.40);
  font-size: 16px;
  border: 1px solid rgba(0, 0, 0, 0.11);
  border-right: 0;
  border-radius: 10px 0 0 10px;
  background: rgba(0, 0, 0, 0.08);
`;

const Input = styled.input<{ $width: number, $withLabel: boolean }>`
  width: ${({ $width }) => $width}px;
  height: 39px;
  padding: 0 30px 0 15px;
  font-size: 16px;
  border-radius: ${({ $withLabel }) => $withLabel ? '0 10px 10px 0' : '10px'};
  border: 1px solid #E6E6E6;
  background: rgba(255, 255, 255, 0.60);
  font-family: Poppins, sans-serif;

  &::placeholder {
    color: rgba(0, 0, 0, 0.40);
  }

  @media (${DeviceQueries.mobile}) {
    width: 100%;
  }
`;

const Cancel = styled.div`
  position: absolute;
  right: 11px;
  top: 11px;
  cursor: pointer;
`;

export default InviteInput;
