import { IUUserContext } from '../contexts/UserContext';
import { b64tos, stob64 } from '../util/stringutils';

import { logSumoEvent, stringifyError, ULogApplication, ULogSeverity, ULogTag } from 'Common/src/api/SumoLogicApi';

export enum CodeType {
  Phone = 1,
  Email
}

export const loginUrl = (path: string, search?: string) => {
  const state = stob64(JSON.stringify({ path, search }));

  return `/login?s=${state}`;
};

export const getDecodedUrl = (userContext: IUUserContext, encodedState?: string | null) => {
  if (encodedState) {
    try {
      const state = JSON.parse(b64tos(encodedState)) as { path?: string, search?: string };
      return (state?.path ?? '') + (state?.search ?? '');
    } catch (e) {
      void logSumoEvent({
        app: ULogApplication.ELK,
        severity: ULogSeverity.WARN,
        userId: userContext.id,
        tag: ULogTag.Browser,
        message: `[lib/login] getDecodedUrl - Error during parse of state "${encodedState}": ${stringifyError(e)}`
      });
    }
  }
  return '/';
};

export const getDecodedPath = (userContext: IUUserContext, encodedState?: string | null) => {
  if (encodedState) {
    try {
      const state = JSON.parse(b64tos(encodedState)) as { path?: string, search?: string };
      return state?.path ?? '/';
    } catch (e) {
      void logSumoEvent({
        app: ULogApplication.ELK,
        severity: ULogSeverity.WARN,
        userId: userContext.id,
        tag: ULogTag.Browser,
        message: `[lib/login] getDecodedPath - Error during parse of state "${encodedState}": ${stringifyError(e)}`
      });
    }
  }
  return '/';
};

export const getDecodedParam = (userContext: IUUserContext, encodedState: string | null | undefined, param: string) => {
  try {
    const url = new URL(getDecodedUrl(userContext, encodedState), window.location.href);
    return url.searchParams.get(param) ?? undefined;
  } catch (e) {
    void logSumoEvent({
      app: ULogApplication.ELK,
      severity: ULogSeverity.WARN,
      userId: userContext.id,
      tag: ULogTag.Browser,
      message: `[lib/login] getDecodedParam - Error during parse of state "${encodedState}": ${stringifyError(e)}`
    });
  }
  return undefined;
};
