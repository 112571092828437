import { TAppProduct } from 'TProtocol/common/models';
import { getDeviceId } from '../lib/DeviceId';

declare const APP_ENV: string;
declare const SUMO_LOGIC_MOUSE_COLLECTION_URL: string;
declare const SUMO_LOGIC_DUCK_COLLECTION_URL: string;
declare const SUMO_LOGIC_FISH_COLLECTION_URL: string;
declare const SUMO_LOGIC_ELK_COLLECTION_URL: string;

export enum ULogApplication {
  MOUSE = 1,
  DUCK,
  ELK,
  FISH,
}

export enum ULogSeverity {
  INFO = 'INFO',
  DEBUG = 'DEBUG',
  WARN = 'WARN',
  ERROR = 'ERROR'
}

export enum ULogTag {
  Browser = 'Browser',
  General = 'General',
  PageView = 'PageView',
  CircleAction = 'CircleAction',
  CircleEdit = 'CircleEdit',
  UserInfo = 'UserInfo',
  UserAuth = 'UserAuth',
  UserAction = 'UserAction',
  Network = 'Network',
  HangoutAction = 'HangoutAction',
  UserError = 'UserError',
  Stripe = 'Stripe',
  ImageGen = 'ImageGen'
}

export const applicationFromProduct = (
  product: TAppProduct
): ULogApplication => {
  if (product === TAppProduct.MOUSE) {
    return ULogApplication.MOUSE;
  } else if (product === TAppProduct.DUCK) {
    return ULogApplication.DUCK;
  } else if (product === TAppProduct.FISH) {
    return ULogApplication.FISH;
  } else {
    // In practice, we should never get anything other than these four, but one has to be the default for TypeScript to
    // be happy.
    return ULogApplication.ELK;
  }
}

export const logSumoEvent = async ({app, severity, userId = 'unauthenticated', tag = ULogTag.General, message}: {
                                     app: ULogApplication,
                                     severity: ULogSeverity,
                                     userId?: string,
                                     tag: ULogTag,
                                     message: string
                                   }
) => {
  let endpoint: string | undefined;
  if (app === ULogApplication.MOUSE) {
    endpoint = SUMO_LOGIC_MOUSE_COLLECTION_URL;
  } else if (app === ULogApplication.DUCK) {
    endpoint = SUMO_LOGIC_DUCK_COLLECTION_URL;
  } else if (app === ULogApplication.ELK) {
    endpoint = SUMO_LOGIC_ELK_COLLECTION_URL;
  } else if (app === ULogApplication.FISH) {
    endpoint = SUMO_LOGIC_FISH_COLLECTION_URL;
  }

  if (endpoint === undefined) {
    // eslint-disable-next-line no-console
    // console.log(`${severity} : ${tag} : ${userId} : ${message}`)
    return;
  }

  const timestamp = Date.now();
  const deviceId = getDeviceId(app);

  // timestamp
  // thread (main, background)
  // severity
  // device ID (n/a for web)
  // app distribution/environment
  // Sunshine UUID | "unauthenticated"
  // Tag
  const body = `${timestamp} | main | ${severity} | ${deviceId} | ${navigator.userAgent} | ${APP_ENV} | ${userId} | ${tag} | ${document.location.pathname}${document.location.search} | ${message}`;

  try {
    await fetch(endpoint, {
      method: 'POST',
      mode: 'no-cors',
      cache: 'no-cache',
      credentials: 'include',
      headers: {
        'Content-Type': 'text/plain'
      },
      referrerPolicy: 'no-referrer',
      body,
    });
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(`[SumoLogicApi] Error while logging to Sumo Logic: ${stringifyError(e)}`);
  }
};

export const stringifyError = (e: Error): string => {
  return JSON.stringify(e, [...(Array.from(Object.keys(e))), 'message', 'arguments', 'type', 'name']);
}

