import React from 'react';

const Phone = ({ color = 'currentColor', size = 10 }: { color?: string, size?: number }) => {
  return <svg width={size} height={size} viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0.200012 0.799219C0.200012 0.640089 0.263226 0.487476 0.375748 0.374955C0.48827 0.262433 0.640882 0.199219 0.800012 0.199219H2.09181C2.23384 0.199284 2.37123 0.249727 2.47956 0.341575C2.58789 0.433422 2.66012 0.560718 2.68341 0.700819L3.12741 3.36182C3.14863 3.48855 3.1286 3.61874 3.07027 3.73324C3.01194 3.84773 2.9184 3.94047 2.80341 3.99782L1.87461 4.46162C2.20768 5.28701 2.70371 6.03679 3.33308 6.66615C3.96244 7.29552 4.71222 7.79155 5.53761 8.12462L6.00201 7.19582C6.05932 7.08094 6.15197 6.98748 6.26634 6.92916C6.38071 6.87084 6.51077 6.85074 6.63741 6.87182L9.29841 7.31582C9.43851 7.33911 9.56581 7.41134 9.65766 7.51967C9.7495 7.628 9.79995 7.76539 9.80001 7.90742V9.19922C9.80001 9.35835 9.7368 9.51096 9.62428 9.62348C9.51175 9.736 9.35914 9.79922 9.20001 9.79922H8.00001C3.69201 9.79922 0.200012 6.30722 0.200012 1.99922V0.799219Z"
      fill={color}/>
  </svg>;
};

export default Phone;
