import { b64tos, stob64 } from '../util/stringutils';
import useSpotify from '../lib/spotify';

import useLocalStorage from 'Common/src/hooks/useLocalStorage';

import { TAppElkEventAnswer, TAppElkEventAnswerValue } from 'TProtocol/prototypes/events/messages';

interface Props {
  getInviteeUuid: (eventId?: string) => string | undefined;
  setInviteeUuid: (eventId: string, inviteeUuid: string) => void;
  deleteInviteeUuid: (eventId: string) => void;
  getToken: (eventId?: string) => string | undefined;
  setToken: (eventId: string, token: string) => void;
  deleteToken: (eventId: string) => void;
  setPhone: (phoneNumber: string) => void;
  getPhone: () => string | undefined;
  deletePhone: () => void;
  setEmail: (email: string) => void;
  getEmail: () => string | undefined;
  deleteEmail: () => void;
  setQuestionResponse: (eventId: string, answer: TAppElkEventAnswer) => void;
  getQuestionUuidsAndAnswers: (eventId: string) => Array<TAppElkEventAnswer> | undefined;
  clearAllInviteeUuidStorage: () => void;
}

const useInviteeUuidStorage = (): Props => {
  const [inviteeUuids, setInviteeUuids] = useLocalStorage<{
    [key: string]: string
  }>('invite_uuids', {});

  const [tokenInfos, setTokenInfos] = useLocalStorage<{
    [key: string]: string
  }>('token_infos', {});
  const [phoneNumber, setPhoneNumber] = useLocalStorage<string | undefined>('phone', undefined);
  const [email, setEmail] = useLocalStorage<string | undefined>('email', undefined);

  const [questionUuidsAndAnswers, setQuestionUuidsAndAnswers] = useLocalStorage<{
    [eventId: string]: {
      [questionUuid: string]: {
        type: string,
        answer: string
      }
    }
  }>('question_uuids_and_answers', {});

  const spotify = useSpotify();

  const getQuestionUuidsAndAnswers = (eventId: string): Array<TAppElkEventAnswer> | undefined => {
    const eventAnswers = questionUuidsAndAnswers[eventId];
    if (!eventAnswers) {
      return undefined;
    }

    return Object.entries(eventAnswers).map(([questionUuid, encodedAnswer]) => {
      let answerValue;
      const answer = encodedAnswer.answer;
      switch (encodedAnswer.type) {
        case 'song':
          answerValue = new TAppElkEventAnswerValue({ song: spotify.decodeTAppElkSpotifySong(answer) });
          break;
        case 'isYes':
          answerValue = new TAppElkEventAnswerValue({ isYes: b64tos(answer) === 'true' });
          break;
        default:
          answerValue = new TAppElkEventAnswerValue({ text: b64tos(answer) });
          break;
      }

      return new TAppElkEventAnswer({ questionUuid, answerValue });
    });
  };

  const setQuestionResponse = (eventId: string, answer: TAppElkEventAnswer) => {
    const eventAnswers = questionUuidsAndAnswers[eventId] || {};
    const answerValue = answer.answerValue;
    let encodedAnswer: { answer: string; type: string };
    if (answerValue.song) {
      encodedAnswer = {
        type: 'song',
        answer: spotify.encodeTAppElkSpotifySong(answerValue.song)
      };
    } else if (answerValue.isYes) {
      encodedAnswer = {
        type: 'isYes',
        answer: stob64(answerValue.isYes.toString())
      };
    } else {
      encodedAnswer = {
        type: 'text',
        answer: answerValue.text ? stob64(answerValue.text) : ''
      };
    }
    eventAnswers[answer.questionUuid] = encodedAnswer; // Encode and store the answer
    setQuestionUuidsAndAnswers({ ...questionUuidsAndAnswers, [eventId]: eventAnswers });
  };
  const getInviteeUuid = (eventId: string): string | undefined => {
    if (eventId !== undefined) {
      return inviteeUuids[eventId];
    }
    return undefined;
  };

  const getToken = (eventId: string): string | undefined => {
    if (eventId !== undefined) {
      return tokenInfos[eventId];
    }
    return undefined;
  };

  const getPhone = (): string | undefined => {
    return phoneNumber;
  };

  const setToken = (eventId: string, token: string) => {
    if (eventId !== undefined && token) {
      try {
        tokenInfos[eventId] = token;
        setTokenInfos({ ...tokenInfos });
      } catch (e) {
        // do nothing
      }
    }
  };

  const setPhone = (phone: string,) => {
    setPhoneNumber(phone);
  };

  const setInviteeUuid = (eventId: string, inviteeUuid: string) => {
    if (eventId !== undefined && inviteeUuid) {
      inviteeUuids[eventId] = inviteeUuid;
      setInviteeUuids({ ...inviteeUuids });
    }
  };

  const deleteInviteeUuid = (eventId: string) => {
    if (eventId !== undefined) {
      delete inviteeUuids[eventId];
      setInviteeUuids(inviteeUuids);
    }
  };

  const deleteToken = (eventId: string) => {
    if (eventId !== undefined) {
      delete tokenInfos[eventId];
      setTokenInfos(tokenInfos);
    }
  };

  const deletePhone = () => {
    setPhoneNumber('');
  };

  const getEmail = () => {
    return email;
  };

  const deleteEmail = () => {
    setEmail('');
  };

  const clearAllInviteeUuidStorage = () => {
    localStorage.removeItem('invite_uuids');
    localStorage.removeItem('token_infos');
    localStorage.removeItem('phone');
    localStorage.removeItem('email');
    localStorage.removeItem('question_uuids_and_answers');
  };

  return {
    getInviteeUuid,
    setInviteeUuid,
    deleteInviteeUuid,
    getToken,
    setToken,
    deleteToken,
    setPhone,
    getPhone,
    deletePhone,
    setEmail,
    getEmail,
    deleteEmail,
    setQuestionResponse,
    getQuestionUuidsAndAnswers,
    clearAllInviteeUuidStorage
  };
};

export default useInviteeUuidStorage;
