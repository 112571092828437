import styled from 'styled-components';
import { InputContainer } from '../forms/Input';

export const ActionMenuLabel = styled.label`
  font-family: Poppins, sans-serif;
  color: BLACK;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 12px;
`;

export const ContentTitleWrapper = styled.div<{
  $withMarginBottom: boolean
}>`
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-direction: row;
  align-items: flex-start;

  ${({ $withMarginBottom }) => $withMarginBottom && 'margin-bottom: 20px'};
`;

export const ContentTitle = styled.div`
  color: #0A050F;
  font-family: Poppins, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  margin-bottom: 10px;
`;

export const ActionMenuInput = styled(InputContainer)`
  padding: 8px 10px;
  border-radius: 12px;
  background: rgba(255, 255, 255, 0.30);
  box-shadow: 0 2px 5px 0 rgba(103, 110, 118, 0.08), 0 0 0 1px rgba(103, 110, 118, 0.16), 0 1px 1px 0 rgba(0, 0, 0, 0.12);
  box-sizing: border-box;
  justify-content: space-between;
  border: 1px solid rgba(15, 8, 22, 0.06);
  font-size: 16px;
`;
