import React, { MouseEvent, ReactNode, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { isPossiblePhoneNumber } from 'libphonenumber-js';

import { useUserContext } from '../../contexts/UserContext';
import EventFormInput, { InputType } from '../EventForm/EventFormInput';
import {
  BottomModalContainer,
  ModalContainer,
  ModalHeader,
  ModalTitle,
  Overlay,
  TransparentOverlayBottomModal
} from './CommonModal';
import { stob64 } from '../../util/stringutils';
import AdditionalGuestsPicker from '../rsvp/AdditionalGuestPicker';
import NotificationPreferenceSelector from '../rsvp/NotificationPreferenceSelector';
import useInviteeUuidStorage from '../../hooks/useInviteeUuidStorage';
import { stripPlusOne } from '../../lib/phone';
import { IUEvent, IUInvitee } from '../../lib/event';
import EditableAnswer from '../questions/EditableAnswer';
import { BackArrowContainer } from '../../pages/InvitePage';
import BackArrow from '../icons/BackArrow';
import { DesktopShow, MobileShow } from '../styled/Common';
import ColorButton from '../buttons/ColorButton';
import PhoneInput from '../SingleFieldForm/PhoneInput';
import SinglePhoneEmailInput, { ErrorContainer, LoginInput } from '../login/SinglePhoneEmailInput';
import { validateEmail } from '../../util/email';
import { findCurrentInvitee } from '../../util/attendee';
import { ExternalLabel } from '../forms/Input';
import { TallerWhiteInput } from '../forms/WhiteInput';
import ErrorIcon from '../icons/ErrorIcon';

import { logSumoEvent, ULogApplication, ULogSeverity, ULogTag } from 'Common/src/api/SumoLogicApi';
import { DeviceQueries } from 'Common/src/components/styled';

import {
  TAppElkEventAnswer,
  TAppElkNotificationPreference,
  TAppElkPhotoUploadMode
} from 'TProtocol/prototypes/events/messages';

enum UnauthRsvpModes {
  PHONE,
  EMAIL
}

enum ModalState {
  AUTH,
  UNAUTH_INITIAL,
  UNAUTH_UPDATE
}

const RSVPYesModal = ({
                        close,
                        event,
                        inviteeInput,
                        eventId,
                        clickedYes,
                        clickedChangeResponse,
                        hasToken,
                        showUpdateRSVP
                      }: {
  close: () => void,
  event: IUEvent | undefined,
  inviteeInput?: IUInvitee | undefined,
  eventId: string | undefined,
  clickedYes?: boolean,
  clickedChangeResponse?: boolean,
  hasToken: boolean,
  showUpdateRSVP?: boolean
}) => {
  const navigate = useNavigate();
  const userContext = useUserContext();
  const formRef = useRef<HTMLFormElement>(null);
  const { getInviteeUuid, getPhone, getEmail, setQuestionResponse } = useInviteeUuidStorage();
  const location = useLocation();

  const [invitee, setInvitee] = useState<IUInvitee | undefined>(undefined); //inviteeInput param not used anywhere
  const [phoneNum, setPhoneNum] = useState<string>('');
  const [invalidPhone, setInvalidPhone] = useState(false);
  const [invalidEmail, setInvalidEmail] = useState(false);
  const [invalidName, setInvalidName] = useState(false);
  const [uploadMode, setUploadMode] = useState(event?.albumDetails?.photoUploadMode ?? userContext.defaultUploadMode);
  const [piiError, setPiiError] = useState<string>('');

  const [message, setMessage] = useState<string>(invitee?.rsvpMessage ?? '');
  const [hasChanged, setHasChanged] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [unauthRsvpMode, setUnauthRsvpMode] = useState<UnauthRsvpModes>(UnauthRsvpModes.PHONE);
  const [modalState, setModalState] = useState<ModalState>(
    userContext.isLoggedIn() || hasToken ? ModalState.AUTH : showUpdateRSVP ? ModalState.UNAUTH_UPDATE : ModalState.UNAUTH_INITIAL);

  useEffect(() => {
    if (userContext.isLoggedIn()) {
      const phone = userContext.phoneNumber;

      if (phone) {
        setPhoneNum(stripPlusOne(phone));
      } else if (invitee?.phone) {
        setPhoneNum(stripPlusOne(invitee.phone));
      }
    }

  }, [userContext.phoneNumber, invitee?.phone]);

  useEffect(() => {
    const currInvitee = findCurrentInvitee(getInviteeUuid(eventId), userContext.id, event, userContext.name, true,
      userContext.phoneNumber ?? getPhone(), userContext.getEmail() ?? getEmail());
    if (!currInvitee.phone || currInvitee.phone.trim() === '') {
      currInvitee.phone = getPhone() ?? userContext.phoneNumber;
    }
    if (!currInvitee.email || currInvitee.email.trim() === '') {
      currInvitee.email = getEmail() ?? userContext.getEmail();
    }
    setInvitee(new IUInvitee(currInvitee));
    setHasChanged(true);
    setMessage(currInvitee.rsvpMessage ?? '');
    setPhoneNum(stripPlusOne(currInvitee.phone ?? ''));
    setUnauthRsvpMode(currInvitee.email ? UnauthRsvpModes.EMAIL : UnauthRsvpModes.PHONE);
  }, []);

  if (invitee === undefined) {
    return null;
  }

  const additionalGuestCount = invitee.additionalGuestCount;

  const handleImIn = async () => {
    void logSumoEvent({
      app: ULogApplication.ELK,
      severity: ULogSeverity.INFO,
      userId: userContext.id,
      tag: ULogTag.UserAction,
      message: '[RSVPYesModal] Handle Im in'
    });
    const numGuests = additionalGuestCount;
    let messageSlot: string;
    if (message === '') {
      messageSlot = 'm';
    } else {
      messageSlot = message;
    }

    const encodedMessage = stob64(messageSlot);
    const encodedName = stob64(invitee.name ?? '');
    const encodedEmail = stob64(invitee.email ?? '');
    const encodedPhone = stob64(invitee.phone ?? '');

    let notificationPreferenceString;
    if (invitee.notificationPreference === TAppElkNotificationPreference.EMAIL) {
      notificationPreferenceString = 'email';
    } else if (invitee.notificationPreference === TAppElkNotificationPreference.BOTH) {
      notificationPreferenceString = 'both';
    } else {
      notificationPreferenceString = 'phone';
    }

    setSubmitting(true);

    //TODO: ensure title is present in all cases
    const encodedTitle = stob64(event?.title ?? '');
    const uploadModeString = uploadMode === TAppElkPhotoUploadMode.MANUAL ? 'manual' : uploadMode === TAppElkPhotoUploadMode.AUTOMATIC ? 'magic' : '';
    if (!userContext.isLoggedIn()) {
      if (hasToken) {
        navigate(
          `/event/updateemail/action/${eventId}?r=yes&em=${encodedMessage}&ng=${numGuests}&et=${encodedTitle}&en=${encodedName}&np=${notificationPreferenceString}&iu=${invitee.inviteeId}&ee=${encodedEmail}&ep=${encodedPhone}`,
          {
            replace: true,
            state: {
              finishingRSVP: true
            }
          });
      } else {
        navigate(
          `/event/unauthrsvp/action/${eventId}?r=yes&em=${encodedMessage}&ng=${numGuests}&et=${encodedTitle}&en=${encodedName}&np=${notificationPreferenceString}&iu=${invitee.inviteeId}&ee=${encodedEmail}&ep=${encodedPhone}`,
          {
            replace: true,
            state: {
              finishingRSVP: true
            }
          });
      }
    } else {
      navigate(
        `/event/rsvp/action/${eventId}?r=yes&em=${encodedMessage}&ng=${numGuests}&et=${encodedTitle}&en=${encodedName}&np=${notificationPreferenceString}&iu=${invitee.inviteeId}&ee=${encodedEmail}&pu=${uploadModeString}&ep=${encodedPhone}`,
        {
          replace: true,
          state: {
            finishingRSVP: true
          }
        });
    }
  };

  const handleUnauthRSVP = async (finishingRSVP: boolean) => {
    void logSumoEvent({
      app: ULogApplication.ELK,
      severity: ULogSeverity.INFO,
      userId: userContext.id,
      tag: ULogTag.UserAction,
      message: '[RSVPYesModal] Handle Unauth RSVP Yes'
    });
    const numGuests = additionalGuestCount;
    let messageSlot: string;
    if (message === '') {
      messageSlot = 'm';
    } else {
      messageSlot = message;
    }

    const encodedMessage = stob64(messageSlot);
    const encodedName = stob64(invitee.name ?? '');
    const encodedEmail = stob64(invitee.email ?? '');
    const encodedPhone = stob64(invitee.phone ?? '');

    let notificationPreferenceString;
    if (invitee.notificationPreference === TAppElkNotificationPreference.EMAIL) {
      notificationPreferenceString = 'email';
    } else if (invitee.notificationPreference === TAppElkNotificationPreference.BOTH) {
      notificationPreferenceString = 'both';
    } else {
      notificationPreferenceString = 'phone';
    }

    setSubmitting(true);

    //TODO: ensure title is present in all cases
    const encodedTitle = stob64(event?.title ?? '');
    navigate(
      `/event/unauthrsvp/action/${eventId}?r=yes&em=${encodedMessage}&ng=${numGuests}&et=${encodedTitle}&en=${encodedName}&np=${notificationPreferenceString}&iu=${invitee.inviteeId}&ee=${encodedEmail}&ep=${encodedPhone}`,
      {
        replace: true,
        state: {
          finishingRSVP
        }
      });
  };

  const onModalClick = (e: MouseEvent) => {
    void logSumoEvent({
      app: ULogApplication.ELK,
      severity: ULogSeverity.INFO,
      userId: userContext.id,
      tag: ULogTag.UserAction,
      message: '[RSVPYesModal] modal click'
    });
    e.stopPropagation();
  };

  const onMessageChange = (message: string) => {
    setMessage(message);
    invitee.rsvpMessage = message;
    setInvitee(new IUInvitee(invitee));
    setHasChanged(true);
  };

  const onPhoneChange = (phoneNumber: string, fullPhone: string) => {
    setPiiError('');
    setPhoneNum(phoneNumber);
    invitee.phone = fullPhone;
    setInvitee(new IUInvitee(invitee));
    setHasChanged(true);
    setInvalidPhone(false);
  };

  const onEmailChange = (emailAddress: string) => {
    setPiiError('');
    invitee.email = emailAddress;
    setInvitee(new IUInvitee(invitee));
    setHasChanged(true);
    setInvalidEmail(false);
  };

  const onNotificationPreferenceChange = (preference: TAppElkNotificationPreference) => {
    invitee.notificationPreference = preference;
    setInvitee(new IUInvitee(invitee));
    setHasChanged(true);
  };

  const onAnswerChange = (invitee: IUInvitee, answer: TAppElkEventAnswer) => {
    setQuestionResponse(eventId ?? '', answer);
    const existingAnswer = invitee.answers?.find((ans) => ans.questionUuid === answer.questionUuid);
    if (existingAnswer) {
      invitee.answers = invitee.answers?.map((ans) =>
        ans.questionUuid == answer.questionUuid ? ans = answer : ans
      );
    } else {
      let concatenatedAnswers;
      if (invitee.answers) {
        // Use the spread operator to concatenate arrays
        concatenatedAnswers = [...invitee.answers, answer];
      } else {
        concatenatedAnswers = [answer];
      }
      invitee.answers = concatenatedAnswers;
    }
    setInvitee(new IUInvitee(invitee));
    setHasChanged(true);
  };

  const validateForm = () => {
    const invalidPhone = !isPossiblePhoneNumber(invitee.phone ?? '');
    const invalidEmail = !validateEmail(invitee.email);

    const isNameInvalid = invitee.name === undefined || invitee.name.trim() === '';
    setInvalidName(isNameInvalid);

    if (isNameInvalid) {
      // TODO: remove logs
      void logSumoEvent({
        app: ULogApplication.ELK,
        severity: ULogSeverity.INFO,
        userId: userContext.id,
        tag: ULogTag.UserAction,
        message: `[RSVPYesModal] Invalid name: ${invitee.name}`
      });
      return false;
    }

    switch (invitee.notificationPreference) {
      case TAppElkNotificationPreference.EMAIL:
        setInvalidPhone(false);
        setInvalidEmail(invalidEmail);
        if (invalidEmail) {
          // TODO: remove logs
          void logSumoEvent({
            app: ULogApplication.ELK,
            severity: ULogSeverity.INFO,
            userId: userContext.id,
            tag: ULogTag.UserAction,
            message: `[RSVPYesModal] Invalid email: ${invitee.email}`
          });
        }
        return !invalidEmail;
      case TAppElkNotificationPreference.PHONE:
        setInvalidPhone(invalidPhone);
        setInvalidEmail(false);
        if (invalidPhone) {
          // TODO: remove logs
          void logSumoEvent({
            app: ULogApplication.ELK,
            severity: ULogSeverity.INFO,
            userId: userContext.id,
            tag: ULogTag.UserAction,
            message: `[RSVPYesModal] Invalid phone: ${invitee.phone}`
          });
        }
        return !invalidPhone;
      case TAppElkNotificationPreference.BOTH:
        setInvalidPhone(invalidPhone);
        setInvalidEmail(invalidEmail);
        if (invalidEmail || invalidPhone) {
          // TODO: remove logs
          void logSumoEvent({
            app: ULogApplication.ELK,
            severity: ULogSeverity.INFO,
            userId: userContext.id,
            tag: ULogTag.UserAction,
            message: `[RSVPYesModal] Invalid PII: email ${invitee.email} : phone ${invitee.phone}`
          });
        }
        return !invalidEmail && !invalidPhone;
      default:
        return true;
    }
  };

  const validateUnauthForm = () => {
    const isNameInvalid = invitee.name === undefined || invitee.name.trim() === '';
    setInvalidName(isNameInvalid);

    if (unauthRsvpMode === UnauthRsvpModes.PHONE) {
      const invalidPhone = !isPossiblePhoneNumber(invitee.phone ?? '');
      setInvalidPhone(invalidPhone);
      if (invalidName) {
        // TODO: remove logs
        void logSumoEvent({
          app: ULogApplication.ELK,
          severity: ULogSeverity.INFO,
          userId: userContext.id,
          tag: ULogTag.UserAction,
          message: `[RSVPYesModal] Invalid name: ${invitee.name}`
        });
      }
      if (invalidPhone) {
        setPiiError('Please provide a valid phone number.');
        //TODO: remove logs
        void logSumoEvent({
          app: ULogApplication.ELK,
          severity: ULogSeverity.INFO,
          userId: userContext.id,
          tag: ULogTag.UserAction,
          message: `[RSVPYesModal] Invalid phone: ${invitee.phone}`
        });
      }
      return !invalidPhone && !isNameInvalid;
    } else {
      const invalidEmail = !validateEmail(invitee.email);
      setInvalidEmail(invalidEmail);
      if (invalidName) {
        // TODO: remove logs
        void logSumoEvent({
          app: ULogApplication.ELK,
          severity: ULogSeverity.INFO,
          userId: userContext.id,
          tag: ULogTag.UserAction,
          message: `[RSVPYesModal] Invalid name: ${invitee.name}`
        });
      }
      if (invalidEmail) {
        setPiiError('Please provide a valid email.');
        //TODO: remove logs
        void logSumoEvent({
          app: ULogApplication.ELK,
          severity: ULogSeverity.INFO,
          userId: userContext.id,
          tag: ULogTag.UserAction,
          message: `[RSVPYesModal] Invalid email: ${invitee.email}`
        });
      }
      return !invalidEmail && !isNameInvalid;
    }
  };

  const onConfirmClick = () => {
    void logSumoEvent({
      app: ULogApplication.ELK,
      severity: ULogSeverity.INFO,
      userId: userContext.id,
      tag: ULogTag.UserAction,
      message: '[RSVPYesModal] On accept click'
    });

    const validForm = validateForm();
    const validHtmlForm = formRef.current?.reportValidity();
    if (!validForm || !validHtmlForm) {
      void logSumoEvent({
        app: ULogApplication.ELK,
        severity: ULogSeverity.INFO,
        userId: userContext.id,
        tag: ULogTag.UserAction,
        message: '[RSVPYesModal] Invalid form'
      });
      return;
    }

    void handleImIn();
    // updateTextPermission();
  };

  const unauthOnConfirmClick = (finishingRSVP: boolean) => {
    void logSumoEvent({
      app: ULogApplication.ELK,
      severity: ULogSeverity.INFO,
      userId: userContext.id,
      tag: ULogTag.UserAction,
      message: '[RSVPYesModal] On accept click'
    });

    const validForm = modalState === ModalState.UNAUTH_INITIAL ? validateUnauthForm() : validateForm();
    const validHtmlForm = formRef.current?.reportValidity();
    if (!validForm || !validHtmlForm) {
      void logSumoEvent({
        app: ULogApplication.ELK,
        severity: ULogSeverity.INFO,
        userId: userContext.id,
        tag: ULogTag.UserAction,
        message: '[RSVPYesModal] Invalid form'
      });
      return;
    }

    void handleUnauthRSVP(finishingRSVP);
  };

  const setGuestCount = (count: number) => {
    invitee.additionalGuestCount = count;
    setInvitee(new IUInvitee(invitee));
    setHasChanged(true);

  };

  const onNameChange = (name: string) => {
    invitee.name = name;
    setInvitee(new IUInvitee(invitee));
    setHasChanged(true);
    setInvalidName(false);
  };

  const onClose = () => {
    void logSumoEvent({
      app: ULogApplication.ELK,
      severity: ULogSeverity.INFO,
      userId: userContext.id,
      tag: ULogTag.UserAction,
      message: '[RSVPYesModal] Closed modal'
    });

    close();
  };

  const nameInput = <LoginInput $includeMargin={true}>
    <ExternalLabel>You&apos;re RSVPing for</ExternalLabel>
    <TallerWhiteInput
      autoFocus={false}
      placeholder="Taylor Swift"
      $isInvalid={invalidName}
      disabled={false}
      value={invitee.name ?? ''}
      onChange={(e) => onNameChange(e.currentTarget.value)}
    />
    {invalidName && <ErrorContainer><ErrorIcon/><span>Please provide a valid name.</span></ErrorContainer>}
  </LoginInput>;

  const rsvpDetails = <>
    <AdditionalGuestsPicker additionalGuestCount={additionalGuestCount}
                            setGuestCount={setGuestCount}/>

    <NotificationPreferenceSelector
      value={invitee.notificationPreference ?? TAppElkNotificationPreference.PHONE}
      onChange={onNotificationPreferenceChange}
    />

    {
      (invitee.notificationPreference === undefined || invitee.notificationPreference === TAppElkNotificationPreference.BOTH || invitee.notificationPreference === TAppElkNotificationPreference.PHONE) ?
        <LoginInput $includeMargin={true}>
          <ExternalLabel>What&apos;s your phone number?</ExternalLabel>
          <PhoneInput
            autoFocus={false}
            placeholder="(123) 456-7890"
            isInvalid={invalidPhone}
            autoComplete="tel"
            value={phoneNum}
            required={true}
            onChange={onPhoneChange}
            disabled={false}
            taller={true}
          />
          {invalidPhone &&
            <ErrorContainer><ErrorIcon/><span>Please provide a valid phone number.</span></ErrorContainer>}
        </LoginInput>
        : <></>
    }
    {
      (invitee.notificationPreference === TAppElkNotificationPreference.EMAIL || invitee.notificationPreference === TAppElkNotificationPreference.BOTH) ?
        <LoginInput $includeMargin={true}>
          <ExternalLabel>What&apos;s your email?</ExternalLabel>
          <TallerWhiteInput
            autoComplete="email"
            autoFocus={false}
            placeholder="stella@sunshine.com"
            $isInvalid={invalidEmail}
            disabled={false}
            value={invitee.email}
            onChange={(e) => onEmailChange(e.currentTarget.value)}
          />
          {invalidEmail && <ErrorContainer><ErrorIcon/><span>Please provide a valid email.</span></ErrorContainer>}
        </LoginInput>
        : <></>
    }

    <EventFormInput
      placeholder="Type message here"
      event={event}
      label="Message to the host"
      value={message}
      maxLength={1024}
      onChange={(value) => onMessageChange(value)}
      inputType={InputType.Description}
      optionNumber={0}
      isLabelOutside={true}
      excludeBackground={true}
      inModal={true}
    />

    {event?.questions.map(
      (question) => <EditableAnswer
        question={question}
        invitee={invitee}
        key={question.questionUuid}
        onAnswerChange={onAnswerChange}
      />)}
  </>;

  let modalContent: ReactNode;
  if (modalState === ModalState.AUTH) {
    modalContent = <RSVPForm ref={formRef} onSubmit={(e) => e.preventDefault()}>
      <ModalContentContainer>
        <ModalHeader>
          <BackArrowContainer onClick={onClose}>
            <BackArrow/>
          </BackArrowContainer>
          <ModalTitle>Accept invitation</ModalTitle>
        </ModalHeader>

        {nameInput}
        {rsvpDetails}
      </ModalContentContainer>
      <ButtonFooter>
        <ColorButton type="button" onClick={onConfirmClick}
                     disabled={submitting || !hasChanged}>Accept</ColorButton>
      </ButtonFooter>
    </RSVPForm>;
  } else if (modalState === ModalState.UNAUTH_INITIAL) {
    const handleSwitchToEmail = () => {
      setUnauthRsvpMode(UnauthRsvpModes.EMAIL);
      onNotificationPreferenceChange(TAppElkNotificationPreference.EMAIL);
      onPhoneChange('', '');
    };

    const handleSwitchToPhone = () => {
      setUnauthRsvpMode(UnauthRsvpModes.PHONE);
      onNotificationPreferenceChange(TAppElkNotificationPreference.PHONE);
      onEmailChange('');
    };

    modalContent = <RSVPForm ref={formRef} onSubmit={(e) => e.preventDefault()}>
      <ModalContentContainer>
        <ModalHeader>
          <BackArrowContainer onClick={onClose}>
            <BackArrow/>
          </BackArrowContainer>
          <ModalTitle>Accept invitation</ModalTitle>
        </ModalHeader>
        {nameInput}

        <SinglePhoneEmailInput phone={phoneNum} email={invitee.email ?? ''}
                               showPhoneInput={unauthRsvpMode === UnauthRsvpModes.PHONE}
                               switchToEmail={handleSwitchToEmail}
                               switchToPhone={handleSwitchToPhone}
                               handleEmailChange={onEmailChange}
                               handlePhoneChange={onPhoneChange}
                               useMoreVisibleLabels={true}
                               includeMargin={true}
                               autoFocus={false}
                               error={piiError}
                               setError={setPiiError}/>
      </ModalContentContainer>

      <ButtonFooter>
        <ColorButton type="button" onClick={() => unauthOnConfirmClick(false)}
                     disabled={submitting || !hasChanged}>Next</ColorButton>
        {unauthRsvpMode === UnauthRsvpModes.PHONE && <TextPermissionsDisclaimer>
          By signing up with your phone number you agree to receive texts when the host makes an updates or sends
          a reminder.
        </TextPermissionsDisclaimer>}
      </ButtonFooter>
    </RSVPForm>;
  } else {
    modalContent = <RSVPForm ref={formRef} onSubmit={(e) => e.preventDefault()}>
      <ModalContentContainer>
        <ModalHeader>
          <BackArrowContainer onClick={() => setModalState(ModalState.UNAUTH_INITIAL)}>
            <BackArrow/>
          </BackArrowContainer>
          <ModalTitle>Accept invitation</ModalTitle>
        </ModalHeader>
        {rsvpDetails}
      </ModalContentContainer>
      <ButtonFooter>
        <ColorButton type="button" onClick={() => unauthOnConfirmClick(true)}
                     disabled={submitting || !hasChanged}>Accept</ColorButton>
      </ButtonFooter>
    </RSVPForm>;
  }

  return (
    <>
      <DesktopShow>
        <Overlay onClick={onClose}>
          <ModalContainer onClick={onModalClick}>
            {modalContent}
          </ModalContainer>
        </Overlay>
      </DesktopShow>
      <MobileShow>
        <TransparentOverlayBottomModal onClick={onClose}>
          <BottomModalContainer onClick={onModalClick}>
            {modalContent}
          </BottomModalContainer>
        </TransparentOverlayBottomModal>
      </MobileShow>
    </>
  );
};

const RSVPModalContainer = styled.div`
  //min-height: 525px;
  width: 100%;
  max-height: 80vh;

  @media (${DeviceQueries.mobile}) {
    max-height: 100vh;
    min-height: 90vh;
  }
  overflow-y: scroll;
`;

const RSVPForm = styled.form`
  margin: 5px;
  width: calc(100% - 10px);
  display: flex;
  flex-direction: column;
  //gap: 30px;
`;

const ModalContentContainer = styled.div`
`;

const GuestCountDetails = styled.div`
  position: relative;
`;

const ButtonFooter = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
`;

const TextPermissionsDisclaimer = styled.div`
  color: rgba(0, 0, 0, 0.60);
  font-size: 12px;
  font-weight: 400;
`;

const NotificationPreferenceInputsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export default RSVPYesModal;
