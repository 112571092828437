import React from 'react';
import styled from 'styled-components';

import EditableSongAnswer from './EditableSongAnswer';
import useSpotify, { SpotifyTrack } from '../../lib/spotify';
import SpotifyIcon from '../icons/SpotifyIcon';
import { ContentTitle } from '../EventForm/ActionMenuCommon';
import EventFormInput from '../EventForm/EventFormInput';
import { IUEventQuestion, IUInvitee } from '../../lib/event';

import {
  TAppElkEventAnswer,
  TAppElkEventAnswerValue,
  TAppElkEventQuestionType,
  TAppElkSpotifySong
} from 'TProtocol/prototypes/events/messages';

interface Props {
  question: IUEventQuestion;
  invitee: IUInvitee;
  onAnswerChange: (invitee: IUInvitee, answer: TAppElkEventAnswer) => void;
}

const EditableAnswer = ({ question, invitee, onAnswerChange }: Props) => {
  const spotify = useSpotify();

  let blankAnswer;
  switch (question.questionType) {
    case TAppElkEventQuestionType.SONG:
      blankAnswer = new TAppElkEventAnswer({
        questionUuid: question.questionUuid,
        answerValue: new TAppElkEventAnswerValue({ song: new TAppElkSpotifySong({ songUri: '', songName: '' }) })
      });
      break;
    case TAppElkEventQuestionType.YES_NO:
      blankAnswer = new TAppElkEventAnswer({
        questionUuid: question.questionUuid,
        answerValue: new TAppElkEventAnswerValue({ isYes: false })
      });
      break;
    default:
      blankAnswer = new TAppElkEventAnswer({
        questionUuid: question.questionUuid,
        answerValue: new TAppElkEventAnswerValue({ text: '' })
      });
      break;
  }
  const answer = invitee.answers?.find(
    (answer) => answer.questionUuid === question.questionUuid
  ) ?? blankAnswer;

  const handleAnswerChange = (value: string) => {
    answer.answerValue.text = value;
    onAnswerChange(invitee, answer);
  };

  const handleSongAnswerChange = (song: SpotifyTrack) => {
    answer.answerValue.song = spotify.convertToTAppElkSpotifySong(song);
    onAnswerChange(invitee, answer);
  };

  const clearSongSelection = () => {
    answer.answerValue.song = new TAppElkSpotifySong({ songUri: '', songName: '' });
    onAnswerChange(invitee, answer);
  };

  const answerInput = answer.answerValue.song
    ? <EditableSongAnswer answer={answer} onSongSelect={handleSongAnswerChange} onRemoveSong={clearSongSelection}/>
    : <EventFormInput value={answer.answerValue.text ?? ''}
                      onChange={(value) => handleAnswerChange(value)}
                      maxLength={1000}
                      isLabelOutside={true}
                      excludeBackground={true}
                      noVertMargin={true}
                      inModal={true}
                      placeholder={'Type response here'}/>;

  return <Container>
    <QuestionContainer>
      <QuestionText>Host question: {question.question}</QuestionText>
      {question.questionType === TAppElkEventQuestionType.SONG &&
        <SpotifySectionTitle>
          <SpotifyIcon/>
          <SpotifyContentTitle>Spotify</SpotifyContentTitle>
        </SpotifySectionTitle>}
    </QuestionContainer>
    {answerInput}
  </Container>;
};

const Container = styled.div`
  font-size: 24px;
  display: flex;
  flex-direction: column;
  margin: 20px 0;
`;

const QuestionContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const QuestionText = styled.div`
  font-size: 16px;
  font-weight: 400;
`;

const SpotifySectionTitle = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
`;

const SpotifyContentTitle = styled(ContentTitle)`
  height: auto;
  font-size: 16px;
  font-weight: 300;
  line-height: normal;
`;

export default EditableAnswer;
