import React, { MouseEvent, ReactNode, useEffect, useState } from 'react';
import styled from 'styled-components';

import { useUserContext } from '../../contexts/UserContext';
import { useEditEventContext } from '../../contexts/EditEventContext';
import { ModalClose, ModalContainer, Overlay } from './CommonModal';
import { IUEvent } from '../../lib/event';
import Plus from '../icons/Plus';
import ImageUploader from '../ImageUploader';
import { getNormalizedPhotoUrl } from '../../lib/images';

import { logSumoEvent, ULogApplication, ULogSeverity, ULogTag } from 'Common/src/api/SumoLogicApi';
import CloseIcon from 'Common/src/components/icons/CloseIcon';

import { TAppElkImage } from 'TProtocol/prototypes/events/messages';
import { getEventTintColor } from '../../lib/colorPicker';
import { useBackgroundContext } from '../../contexts/BackgroundContext';

enum ImagesTab {
  Uploaded = 1,
  Generated
}

interface Props {
  close: () => void;
  event: IUEvent;
}

const EventImageHistoryModal = ({ close }: Props) => {
  const userContext = useUserContext();
  const editEventContext = useEditEventContext();
  const backgroundContext = useBackgroundContext();

  const [uploadedImages, setUploadedImages] = useState<TAppElkImage[] | undefined>();
  const [generatedImages, setGeneratedImages] = useState<TAppElkImage[]>([]);
  const [loading, setLoading] = useState(true);
  const [tab, setTab] = useState(ImagesTab.Generated);

  useEffect(() => {
    void fetchEventImages();
  }, []);

  const onModalClick = (e: MouseEvent) => {
    e.stopPropagation();
  };

  const fetchEventImages = async () => {
    if (userContext.isLoggedIn()) {
      const imageHistory = await userContext.fetchEventImageHistory();
      setUploadedImages(imageHistory.uploadedImages);
      setGeneratedImages(imageHistory.images);
    }
    setLoading(false);
  };


  const onImageClick = (imageUrl: string) => {
    close();
    editEventContext.setPhotoUrl(imageUrl);
  };

  const onImageCropDone = (imgSrc: string) => {
    editEventContext.setPhotoUrl(imgSrc);
  };

  const onClose = () => {
    void logSumoEvent({
      app: ULogApplication.ELK,
      severity: ULogSeverity.INFO,
      userId: userContext.id,
      tag: ULogTag.UserAction,
      message: '[EventImageHistoryModal] Closed modal'
    });

    close();
  };

  let uploader: ReactNode;
  if (tab === ImagesTab.Uploaded) {
    uploader = <ImageUploader circular={false} onImageCropAvailable={onImageCropDone} onPopupOpen={() => null}
                              onPopupClose={() => null}>
      <Uploader>
        <Plus size={75}/>
        <span>Upload photo</span>
      </Uploader>
    </ImageUploader>;
  }

  const images = tab === ImagesTab.Uploaded ? uploadedImages : generatedImages;

  let content: ReactNode;
  if (loading) {
    content = null;
  } else if (images !== undefined && images.length > 0) {
    content = images.map((eventImage) => <EventImageContainer
      key={eventImage.imageUrl} onClick={() => onImageClick(eventImage.imageUrl)}
    ><EventImage src={getNormalizedPhotoUrl(eventImage.imageUrl)} width="150" height="150"/></EventImageContainer>);
  } else if (tab === ImagesTab.Generated) {
    content = 'You have no previously generated images.';
  }

  return <Overlay onClick={onClose}>
    <ModalContainer onClick={onModalClick} $wide={true}>
      <ModalClose onClick={onClose}><CloseIcon/></ModalClose>

      <HeaderRow>
        <HeaderItem $active={tab === ImagesTab.Uploaded}
                    $backgroundColor={getEventTintColor(backgroundContext.colors.primary)}
                    onClick={() => setTab(ImagesTab.Uploaded)}>
          Uploaded Images
        </HeaderItem>
        <HeaderItem $active={tab === ImagesTab.Generated}
                    $backgroundColor={getEventTintColor(backgroundContext.colors.primary)}
                    onClick={() => setTab(ImagesTab.Generated)}>
          Previously Generated AI Images
        </HeaderItem>
      </HeaderRow>

      <EventImagesContainer>
        {uploader}
        {content}
      </EventImagesContainer>
    </ModalContainer>
  </Overlay>;
};

const HeaderRow = styled.div`
  display: flex;
  margin-bottom: 10px;
`;

const HeaderItem = styled.div<{
  $active: boolean,
  $backgroundColor: string
}>`
  padding: 8px 12px;
  cursor: pointer;
  color: ${props => (props.$active ? '#FFFFFF' : '#000000')};
  font-size: 15px;
  margin-right: 10px;
  border-radius: 20px;
  background-color: ${props => (props.$active ? props.$backgroundColor : 'none')};
`;

const EventImagesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
`;

const EventImageContainer = styled.div`
  border-radius: 7px;
  border: 1px solid #000;
  height: 150px;
`;

const EventImage = styled.img`
  border-radius: 6px;
`;

const Uploader = styled.div`
  border-radius: 6px;
  border: 1px solid #000;
  width: 150px;
  height: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--shine-highlight-color);
  color: var(--shine-system-button-text-color);
}
`;

export default EventImageHistoryModal;
