import React, { useEffect, useState } from 'react';
import { Location, useBlocker, useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { BasePageContent } from '../BasePage';
import { useEditEventContext } from '../../contexts/EditEventContext';
import { endUserEvent, startUserEvent, UserEventType } from '../../lib/performance';
import { ColorScheme, useBackgroundContext } from '../../contexts/BackgroundContext';
import { useEventCacheContext } from '../../contexts/EventCacheContext';
import { useUserContext } from '../../contexts/UserContext';
import BasePageHeader, { UPageType } from '../BasePageHeader';
import { parseName } from '../../util/attendee';
import EventRow from './EventRow';
import { EventsFilterType, IUEvent, separateEvents } from '../../lib/event';
import FooterLinks from '../../components/FooterLinks';
import { CarouselWrapper, FilterContainer, FilterPill, InactiveFilterPill } from '../../components/PillToggle';
import { useAutoSaveContext } from '../../contexts/AutoSaveContext';
import { useModalContext } from '../../contexts/ModalContext';

import { DeviceQueries } from 'Common/src/components/styled';

interface EventsPageState {
  activeTab: EventsFilterType;
}

const FilterLabels: {
  [key in EventsFilterType]: string;
} = {
  [EventsFilterType.All]: 'All',
  [EventsFilterType.HostedByYou]: 'Hosted by you',
  [EventsFilterType.Upcoming]: 'Upcoming',
  [EventsFilterType.Past]: 'Past',
  [EventsFilterType.Drafts]: 'Drafts'
};

const EventsPage = () => {
  const navigate = useNavigate();
  const location = useLocation() as Location<EventsPageState>;
  const eventContext = useEditEventContext();
  const userContext = useUserContext();
  useBlocker(() => {
    modalContext.hide();
    return false;
  });

  const eventCacheContext = useEventCacheContext();
  const autoSaveContext = useAutoSaveContext();
  const backgroundContext = useBackgroundContext();
  const modalContext = useModalContext();
  const [eventsByFilter, setEventsByFilter] = useState(separateEvents([], userContext.id ?? ''));
  const [filteredEvents, setFilteredEvents] = useState<IUEvent[]>([]);

  const activeTab = (location.state?.activeTab as EventsFilterType) || EventsFilterType.All;

  const drafts = autoSaveContext.drafts;

  useEffect(() => {
    document.title = 'Shine - My Events';

    backgroundContext.setScheme({ scheme: ColorScheme.Welcome });
    void loadEvents();
    eventContext.clearEvent();
  }, []);

  useEffect(() => {
    if (eventCacheContext.events !== undefined) {
      endUserEvent(userContext);
    }
  }, [eventCacheContext.events]);

  useEffect(() => {
    let eventsToShow: IUEvent[];

    if (eventCacheContext.events === undefined) {
      eventsToShow = [];
    } else {
      const separatedEvents = separateEvents(eventCacheContext.events, userContext.id ?? '');
      setEventsByFilter(separatedEvents);
      eventsToShow = separatedEvents[activeTab];
    }
    eventsToShow = eventsToShow.sort(sortEvents);

    if (activeTab === EventsFilterType.Drafts) {
      setFilteredEvents(autoSaveContext.drafts);
    } else {
      setFilteredEvents(eventsToShow);
    }
  }, [activeTab, eventCacheContext.events, userContext.id, autoSaveContext.drafts]);

  const [firstName] = parseName(userContext.name);

  const setActiveTab = (filter: EventsFilterType) => {
    navigate('/events', { state: { activeTab: filter }, replace: true });
  };

  const loadEvents = async () => {
    await eventCacheContext.fetchEvents();
    await autoSaveContext.fetchDrafts();
  };

  const onTap = (event: IUEvent) => {
    if (event.isDraft) {
      navigate('/event/draft/edit/' + event.id);
    } else {
      startUserEvent(UserEventType.LoadEventFromEvents);
      navigate('/event/' + event.id);
    }
  };

  const sortEvents = (a: IUEvent, b: IUEvent) => b.startTime - a.startTime;

  const renderEvents = () => {
    if (filteredEvents.length > 0) {
      return filteredEvents.map(event => (
        <EventRow key={event.id} event={event} showAttendees={true} onClick={() => onTap(event)}/>
      ));
    } else if (eventCacheContext.events !== undefined) {
      return null;
    } else {
      return [2, 3, 4, 5, 6, 7, 8].map(
        (i) => <EventRow showAttendees={true} key={i} onClick={() => null}/>
      );
    }
  };

  return (
    <>
      <BasePageHeader page={UPageType.Events}/>
      <BasePageContent $wide={true} $full={true}>
        <TopContent>
          {firstName && <GreetingText>
            Hey there, {firstName}!
          </GreetingText>}
          <FilterWrapper>
            <UpcomingEventsText>
              You have {eventsByFilter.UPCOMING.length} upcoming event{eventsByFilter.UPCOMING.length !== 1 && 's'}.
            </UpcomingEventsText>
            <CarouselWrapper>
              <FilterContainer>
                {
                  Object.keys(FilterLabels).map((filterType: EventsFilterType) => {
                    if (filterType === EventsFilterType.Drafts && drafts.length === 0) {
                      return null;
                    }

                    const PillTag = filterType === activeTab ? FilterPill : InactiveFilterPill;
                    return <PillTag
                      key={filterType}
                      onClick={() => setActiveTab(filterType)}
                    >
                      {FilterLabels[filterType]} ({
                      filterType === EventsFilterType.Drafts ? drafts.length : eventsByFilter[filterType].length
                    })
                    </PillTag>;
                  })
                }
              </FilterContainer>
            </CarouselWrapper>
          </FilterWrapper>
        </TopContent>
        <div>
          <CarouselWrapper>
            <EventsContainer>
              {renderEvents()}
              {eventCacheContext.events !== undefined ?
                <EventRow key="create" event={undefined} showAttendees={false} onClick={() => null} showCreate={true}
                          showPlus={filteredEvents.length > 0}/> : null}
            </EventsContainer>
          </CarouselWrapper>
          <FooterLinks/>
        </div>
      </BasePageContent>
    </>
  );
};

const GreetingText = styled.div`
  font-size: 40px;
  font-weight: 500;
  word-wrap: break-word;

  @media (${DeviceQueries.mobile}) {
    font-size: 30px;
  }
`;

const EventsContainer = styled.div`
  display: flex;
  gap: 46px 41px;

  @media not (${DeviceQueries.mobile}) {
    flex-wrap: wrap;
  }

  @media (${DeviceQueries.mobile}) {
    overflow-x: scroll;
    overflow-y: hidden;
    margin-bottom: -17px; // Hide bottom scroll bar
    padding-bottom: 17px; // Prevent bottom content cut off
    gap: 0 20px;
  }
`;

const TopContent = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 46px;
  width: 100%;
  color: #000000;

  @media (${DeviceQueries.mobile}) {
    margin-bottom: 20px;
    gap: 0;
  }
`;

const FilterWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  @media (${DeviceQueries.mobile}) {
    flex-direction: column;
    gap: 8px;
  }
`;

const UpcomingEventsText = styled.div`
  font-size: 28px;
  font-weight: 400;

  @media (${DeviceQueries.mobile}) {
    font-size: 20px;
  }
`;

export default EventsPage;

