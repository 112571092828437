import React, { MouseEvent, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ColorScheme, useBackgroundContext } from '../../contexts/BackgroundContext';
import styled from 'styled-components';
import Photo9 from '../../assets/promos/photo9.png';
import BackArrow from '../../components/icons/BackArrow';
import {
  BottomModalContainer,
  ModalContainer,
  ModalHeader,
  ModalTitle,
  Overlay,
  TransparentOverlayBottomModal
} from '../../components/modals/CommonModal';
import { BackArrowContainer } from '../InvitePage';
import { DesktopShow, MobileShow } from '../../components/styled/Common';
import { IUEvent } from '../../lib/event';
import ColorButton from '../../components/buttons/ColorButton';

const PostRsvpModal = ({ close, event, afterLogin }: {
  close: () => void,
  event: IUEvent,
  afterLogin?: boolean
}) => {
  const navigate = useNavigate();
  const backgroundContext = useBackgroundContext();

  useEffect(() => {
    backgroundContext.setScheme({ scheme: ColorScheme.Orange });
  }, []);

  const handleDownloadShineiOSClick = () => {
    window.open('https://apps.apple.com/us/app/sunshine-photos/id6469663904?itsct=apps_box_badge&amp;itscg=30200',
      '_blank');
  };

  const handleDownloadShineAndroidClick = () => {
    window.open('https://play.google.com/store/apps/details?id=com.lumilabs.android.shine.ga', '_blank');
  };

  const handleDownloadShineMobileClick = () => {
    window.open('https://photos.sunshine.com', '_blank');
  };

  const onModalClick = (e: MouseEvent) => {
    e.stopPropagation();
  };

  const titleText = afterLogin ? 'You\'re signed in' : 'RSVP received';
  const subtitleText = afterLogin
    ? <>Now download the Shine app to effortlessly share photos of <strong>{event?.title ?? 'your event'}.</strong></>
    : <>Now that you’ve responded, download the Shine app to effortlessly share photos
      of <strong>{event?.title ?? 'your event'}.</strong></>;

  const modalContent = <>
    <CardBody>
      <ModalHeader>
        <BackArrowContainer onClick={close}>
          <BackArrow/>
        </BackArrowContainer>
        <ModalTitle>{titleText}</ModalTitle>
      </ModalHeader>
      <TextWrapper>
        {subtitleText}
      </TextWrapper>
      <Image src={Photo9}/>
    </CardBody>
    <ButtonWrapper>
      <DesktopShow>
        <ColorButton onClick={handleDownloadShineiOSClick} disabled={false}>
          Shine for iOS
        </ColorButton>
        <ColorButton onClick={handleDownloadShineAndroidClick} disabled={false} outline={true}>
          Shine for Android
        </ColorButton>
      </DesktopShow>
      <MobileShow>
        <ColorButton onClick={handleDownloadShineMobileClick} disabled={false}>
          Download Shine
        </ColorButton>
      </MobileShow>
    </ButtonWrapper>
  </>;

  return <>
    <DesktopShow>
      <Overlay onClick={close}>
        <ModalContainer onClick={onModalClick}>
          {modalContent}
        </ModalContainer>
      </Overlay>
    </DesktopShow>
    <MobileShow>
      <TransparentOverlayBottomModal onClick={close}>
        <BottomModalContainer onClick={onModalClick}>
          {modalContent}
        </BottomModalContainer>
      </TransparentOverlayBottomModal>
    </MobileShow>
  </>;
};

const CardBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
`;

const TextWrapper = styled.div``;

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 20px;
`;

const Image = styled.img`
  width: 100%;
`;

export default PostRsvpModal;

