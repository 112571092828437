import React, { ReactNode } from 'react';

const URL_REGEX = /https?:\/\/[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&/=]*)/;

export const parseHyperlinks = (str: string): ReactNode => {
  return str.split(' ')
    .map((part, index) =>
      URL_REGEX.test(part) ? <a key={index} href={part} target="_blank" rel="noreferrer">{part} </a> : part + ' '
    );
};
