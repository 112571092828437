import React, { MouseEvent, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import find from 'lodash/find';

import { useUserContext } from '../contexts/UserContext';
import ExpandingTextInput from '../components/ExpandingTextInput';
import { ErrorContainer } from '../components/forms/Input';
import { validateEmail } from '../util/email';
import { sendEventComment } from '../api/ElkEventService';
import EventFormInput from '../components/EventForm/EventFormInput';
import {
  BottomModalContainer,
  ModalContainer,
  ModalHeader,
  ModalTitle,
  Overlay,
  TransparentOverlayBottomModal
} from '../components/modals/CommonModal';
import { BackArrowContainer } from './InvitePage';
import BackArrow from '../components/icons/BackArrow';
import ColorButton from '../components/buttons/ColorButton';
import { IUEvent, IUInvitee } from '../lib/event';
import { FilterType, separateInvitees, sortInvitees } from '../util/attendee';
import { CarouselWrapper, FilterContainer, FilterPill, InactiveFilterPill } from '../components/PillToggle';
import MessageBlastHistoryGroup from '../components/messages/MessageBlastHistoryGroup';

import { DeviceQueries } from 'Common/src/components/styled';
import { DesktopShow, MobileShow } from '../components/styled/Common';
import { logSumoEvent, ULogApplication, ULogSeverity, ULogTag } from 'Common/src/api/SumoLogicApi';

import { TAppElkAttendeeRole, TAppElkAttendeeStatus, TAppElkInviteStatus } from 'TProtocol/prototypes/events/messages';
import { reject } from 'lodash';


const CHAR_LIMIT = 900;

const FilterLabels: {
  [key in FilterType]: string;
} = {
  [FilterType.All]: 'All',
  [FilterType.Going]: 'Going',
  [FilterType.NotGoing]: 'Declined',
  [FilterType.Awaiting]: 'Awaiting',
  [FilterType.Unsent]: 'Unsent'
};

const SendMessageModal = ({ close, event }: {
  close: () => void,
  event: IUEvent | undefined,
  eventId: string | undefined,
}) => {
  const userContext = useUserContext();
  const formRef = useRef<HTMLFormElement>(null);
  const [email, setEmail] = useState<string>('');
  const [invalidEmail, setInvalidEmail] = useState(false);
  const [message, setMessage] = useState<string>('');
  const [messageId] = useState<string>(uuidv4);
  const [invalid, setInvalid] = useState(false);
  const [noRecipientError, setNoRecipientError] = useState(false);
  const [host, setHost] = useState<IUInvitee>();
  const [selectedStatusFilters, setSelectedStatusFilters] = useState<Set<FilterType>>(new Set([FilterType.All]));
  const [invitees, setInvitees] = useState<IUInvitee[]>([]);
  const [inviteesByType, setInviteesByType] = useState(separateInvitees([]));
  const [showMobileMessageHistory, setShowMobileMessageHistory] = useState<boolean>(false);

  const navigate = useNavigate();

  const hasMessages = event?.messages !== null && event?.messages !== undefined && event.messages.length > 0;

  useEffect(() => {
    if (event) {
      const host = find(event.attendees, { role: TAppElkAttendeeRole.ORGANIZER });
      if (host) {
        setHost(host);
        if (host.email) {
          setEmail(host.email);
        }
      }
      if (email === '') {
        setEmail(userContext?.emails?.[0] ?? '');
      }
      const newInvitees = sortInvitees(event.attendees);
      const newInviteesExcludingUnsent = reject(newInvitees, isInviteeUnsent);
      setInvitees(newInviteesExcludingUnsent);
    }
  }, [event]);

  useEffect(() => {
    setInviteesByType(separateInvitees(invitees));
  }, [invitees]);

  const isInviteeUnsent = (invitee: IUInvitee) => {
    return invitee.rsvpStatus === TAppElkAttendeeStatus.INVITED
      && (invitee.inviteStatus === TAppElkInviteStatus.PENDING
        || invitee.inviteStatus === TAppElkInviteStatus.TRIGGERED
        || invitee.inviteStatus === TAppElkInviteStatus.FAILED);
  }

  const generateSendToStatuses = () => {
    if (selectedStatusFilters.has(FilterType.All)) {
      return [TAppElkAttendeeStatus.NO, TAppElkAttendeeStatus.YES, TAppElkAttendeeStatus.INVITED];
    } else {
      const sendToStatuses = [];
      if (selectedStatusFilters.has(FilterType.NotGoing)) {
        sendToStatuses.push(TAppElkAttendeeStatus.NO);
      }
      if (selectedStatusFilters.has(FilterType.Going)) {
        sendToStatuses.push(TAppElkAttendeeStatus.YES);
      }
      if (selectedStatusFilters.has(FilterType.Awaiting)) {
        sendToStatuses.push(TAppElkAttendeeStatus.INVITED);
      }
      return sendToStatuses;
    }
  };

  const sendMessage = async () => {
    if (event?.id && host && host.email !== email) {
      host.email = email;
      //await updateAttendance(userContext, event.id, host, undefined, undefined, true);
    }

    void sendEventComment({
      userContext,
      eventId: event?.id ?? '',
      messageId,
      message,
      sendToStatuses: generateSendToStatuses(),
      email: email !== '' ? email : undefined
    });

    navigate('/event/' + event?.id);
  };

  const onEmailChange = (email: string) => {
    if (email) {
      setInvalid(false);
    }
    setEmail(email);
  };

  const onChange = (message: string) => {
    setMessage(message);
  };

  const validateForm = () => {
    let valid = true;

    if (validateEmail(email) || email === '') {
      setInvalidEmail(false);
    } else {
      setInvalidEmail(true);
      valid = false;
    }

    if (generateSendToStatuses().length === 0) {
      setNoRecipientError(true);
      valid = false;
    }

    if (message.length > CHAR_LIMIT) {
      valid = false;
    }
    return valid;
  };

  const onFilterChange = (filterType: FilterType, status: boolean) => {
    if (filterType === FilterType.All) {
      setSelectedStatusFilters(new Set([filterType]));
      // setSendToStatuses([TAppElkAttendeeStatus.YES, TAppElkAttendeeStatus.NO, TAppElkAttendeeStatus.INVITED])
      return;
    } else if (status) {
      selectedStatusFilters.delete(FilterType.All);
      selectedStatusFilters.add(filterType);
    } else {
      selectedStatusFilters.delete(filterType);
      if (selectedStatusFilters.size === 0) {
        selectedStatusFilters.add(FilterType.All);
      }
    }
    setSelectedStatusFilters(new Set(selectedStatusFilters));
  };

  const onSendClick = () => {
    const validForm = validateForm();
    const validHtmlForm = formRef.current?.reportValidity();
    if (!validForm || !validHtmlForm) {
      return;
    }
    void sendMessage();
  };

  const onClose = () => {
    void logSumoEvent({
      app: ULogApplication.ELK,
      severity: ULogSeverity.INFO,
      userId: userContext.id,
      tag: ULogTag.UserAction,
      message: '[RSVPModal] Closed modal'
    });

    close();
  };

  const onModalClick = (e: MouseEvent) => {
    void logSumoEvent({
      app: ULogApplication.ELK,
      severity: ULogSeverity.INFO,
      userId: userContext.id,
      tag: ULogTag.UserAction,
      message: '[RSVPModal] modal click'
    });
    e.stopPropagation();
  };

  const createMessageBlast = <FormContainer>
    <form ref={formRef} onSubmit={sendMessage}>
      <ModalHeader>
        <BackArrowContainer onClick={onClose}>
          <BackArrow/>
        </BackArrowContainer>
        <ModalTitle>Message blast</ModalTitle>
      </ModalHeader>

      <EventFormInput
        required
        label="What's your email?"
        value={email}
        onChange={onEmailChange}
        color={'var(--shine-text-color)'}
        isLabelOutside={true}
        excludeBackground={true}
        inModal={true}
        placeholder={'Email'}
      />
      {invalid ? <ErrorContainer>This is a required field</ErrorContainer> : null}
      {invalidEmail ? <ErrorContainer>Please enter a valid email</ErrorContainer> : null}

      <MessageDetails>
        <SendLabel>Send your message blast to:</SendLabel>
        <div>
          <CarouselWrapper>
            <CustomFilterContainer>
              {
                Object.keys(FilterLabels).map((filterType: FilterType) => {
                  if (filterType === FilterType.Unsent) {
                    return <></>;
                  } else {
                    const PillTag = selectedStatusFilters.has(filterType) ? FilterPill : InactiveFilterPill;
                    return <PillTag
                      key={filterType}
                      onClick={() => onFilterChange(filterType, !selectedStatusFilters.has(filterType))}
                    >
                      {FilterLabels[filterType]} ({inviteesByType[filterType].length})
                    </PillTag>;
                  }
                })
              }
            </CustomFilterContainer>
          </CarouselWrapper>
          {noRecipientError ? <ErrorContainer>Please select at least one recipient group</ErrorContainer> : null}
        </div>
        <ExpandingTextInput
          value={message}
          textColor="var(--shine-text-color)"
          placeholder="Write here..."
          onChange={onChange}
          required={true}
          minHeight={140}
          isForSideBar={true}
          noVertMargin={true}
        />
      </MessageDetails>

      <ReminderText>
        Guests will receive either an email or a text message based on their preferences.
      </ReminderText>

      <ColorButton type="submit" onClick={onSendClick}>Send</ColorButton>
      {hasMessages && <MobileShow>
        <MobileViewMessageHistory onClick={() => setShowMobileMessageHistory(true)}>View message
          history</MobileViewMessageHistory>
      </MobileShow>}
    </form>
  </FormContainer>;

  const messageHistory = event?.messages && hasMessages &&
    <MessageHistoryContainer>
      <MobileShow>
        <ModalHeader>
          <BackArrowContainer onClick={() => setShowMobileMessageHistory(false)}>
            <BackArrow/>
          </BackArrowContainer>
          <ModalTitle>Message history</ModalTitle>
        </ModalHeader>
      </MobileShow>
      <DesktopShow>
        <MessageHistoryTitle>Message history</MessageHistoryTitle>
      </DesktopShow>
      <MessageBlastHistoryContainer>
        <MessageBlastHistoryGroup messages={event.messages}/>
      </MessageBlastHistoryContainer>
    </MessageHistoryContainer>;

  return (
    <>
      <DesktopShow>
        <Overlay onClick={onClose}>
          <CustomModalContainer onClick={onModalClick} $showMessageHistory={hasMessages}>
            <ModalContent>
              {createMessageBlast}
              {messageHistory}
            </ModalContent>
          </CustomModalContainer>
        </Overlay>
      </DesktopShow>
      <MobileShow>
        <TransparentOverlayBottomModal onClick={onClose}>
          <CustomBottomModalContainer onClick={onModalClick} $noOverflowScroll={true}>
            {showMobileMessageHistory ? messageHistory : createMessageBlast}
          </CustomBottomModalContainer>
        </TransparentOverlayBottomModal>
      </MobileShow>
    </>
  );
};

export default SendMessageModal;

const ReminderText = styled.div`
  margin: 20px 0 30px 0;
  font-size: 16px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.40);
`;

const FormContainer = styled.div`
  padding: 20px;
  box-sizing: border-box;
`;

const MessageDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const SendLabel = styled.div`
  font-size: 16px;
  font-weight: 400;
`;

const MessageHistoryContainer = styled.div<{ $height?: number }>`
  padding: 20px 20px 0 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;

  @media (${DeviceQueries.mobile}) {
    height: 100%;
    box-sizing: border-box;
  }

  @media not (${DeviceQueries.mobile}) {
    border-left: 1px solid rgba(0, 0, 0, 0.20);
    width: 40%;
    gap: 16px;
  }
`;

const MessageHistoryTitle = styled.div`
  font-size: 16px;
  font-weight: 500;
`;

const CustomModalContainer = styled(ModalContainer)<{ $showMessageHistory: boolean }>`
  padding: 0;
  max-width: calc(min(${({ $showMessageHistory }) => $showMessageHistory ? '800px' : '429px'}, 100% - 60px));
  box-sizing: border-box;
`;

const CustomBottomModalContainer = styled(BottomModalContainer)`
  padding: 0;
  max-width: 100%;
`;

const ModalContent = styled.div`
  display: flex;
  max-height: 100%;
`;

const MobileViewMessageHistory = styled.div`
  text-align: center;
  width: 100%;
  padding: 10px 20px;
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.40);
  font-size: 16px;
  font-weight: 500;
`;

const CustomFilterContainer = styled(FilterContainer)`
  @media (${DeviceQueries.mobile}) {
    padding: 6px 0 23px 0;
  }
`;

const MessageBlastHistoryContainer = styled.div`
  padding-bottom: 20px;
  overflow-y: scroll;
`;
