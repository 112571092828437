import React from 'react';

const SpotifyIcon = ({ color = 'currentColor' }: { color?: string }) => {
  return <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
    <g clipPath="url(#clip0_1728_17882)">
      <path
        d="M11.9993 0.046875C5.37202 0.046875 0 5.4189 0 12.0462C0 18.6734 5.37202 24.0454 11.9993 24.0454C18.6265 24.0454 23.9986 18.6734 23.9986 12.0462C23.9986 5.4189 18.6265 0.046875 11.9993 0.046875ZM17.5031 17.3551C17.2882 17.7091 16.8268 17.8194 16.4743 17.6045C13.6557 15.8821 10.1093 15.4938 5.93229 16.4481C5.52964 16.5398 5.12843 16.2876 5.03672 15.8864C4.94501 15.4837 5.19577 15.0825 5.59986 14.9908C10.1709 13.9462 14.0913 14.3961 17.2552 16.3277C17.6077 16.5427 17.7195 17.0041 17.5031 17.3566V17.3551ZM18.9719 14.0866C18.7011 14.5265 18.125 14.6655 17.6851 14.3947C14.461 12.413 9.54326 11.8384 5.72739 12.9962C5.23303 13.1452 4.71001 12.8672 4.55956 12.3729C4.41053 11.8785 4.68995 11.3569 5.18288 11.2065C9.54182 9.88388 14.9597 10.5244 18.6624 12.8013C19.1023 13.0721 19.2413 13.6482 18.9704 14.0881L18.9719 14.0866ZM19.098 10.6849C15.2305 8.38791 8.84972 8.17727 5.15708 9.29781C4.56385 9.47836 3.93767 9.14306 3.75712 8.54983C3.578 7.9566 3.91188 7.33041 4.5051 7.14987C8.74369 5.8631 15.7908 6.11243 20.2429 8.75474C20.7759 9.07141 20.9507 9.76065 20.6355 10.2923C20.3188 10.8253 19.6296 11.0016 19.098 10.6849Z"
        fill={color}/>
    </g>
    <defs>
      <clipPath id="clip0_1728_17882">
        <rect width="24" height="24" fill={color} transform="translate(0 0.046875)"/>
      </clipPath>
    </defs>
  </svg>;
};

export default SpotifyIcon;
