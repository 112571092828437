import React, { FormEvent, ReactNode, RefObject, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { fromZonedTime } from 'date-fns-tz';

import { UEventFormField, useEditEventContext } from '../../contexts/EditEventContext';
import TimeFormInput from '../EventForm/TimeFormInput';
import { convertTimestampToDate, convertTimestampToTimeString } from '../../lib/time';
import GeneratedImageSelection from '../../pages/Create/GeneratedImageSelection';
import { extractSameRGBWithAlpha, getPalette } from '../../lib/colorPicker';
import { getPhotoDataUrl } from '../../lib/images';
import ExpandingTextInput from '../ExpandingTextInput';
import { EventsFilterType, IUEvent, IULocation } from '../../lib/event';
import { validEventLocation } from '../../lib/location';
import { useBackgroundContext } from '../../contexts/BackgroundContext';
import WhiteButton from '../buttons/WhiteButton';
import {
  ActionRow,
  EventDetails,
  EventDetailsCell,
  EventDetailsCellInner,
  EventDetailsCellInnerForAutocomplete,
  EventDetailsIconWrapper,
  EventModule,
  EventModuleHeading,
  EventModules,
  PillDiv,
  PillInputActionButton
} from '../EventForm/Common';
import LocationMarker from '../icons/LocationMarker';
import AddToCal from '../icons/AddToCal';
import PillInput from '../EventForm/PillInput';
import PillInputWithIcon from '../EventForm/PillInputWithIcon';
import { useToastContext } from '../../contexts/ToastContext';
import { useUserContext } from '../../contexts/UserContext';

import AddressAutocomplete, {
  PacContainerWidthStyle
} from 'Common/src/components/AddressAutocomplete/AddressAutocomplete';
import { calculateTimeZone } from 'Common/src/api/GoogleTimezoneApi';
import { DeviceQueries } from 'Common/src/components/styled';

import { TAppAddress } from 'TProtocol/common/models';

const EventForm = ({ event, formRef, onAddImage, onSave, onCancel }: {
  event: IUEvent,
  formRef: RefObject<HTMLFormElement>,
  handleEditClick?: () => void;
  inviteeUuid?: string | undefined;
  onAddImage?: () => void;
  onSave: () => void;
  onCancel: () => void;
}) => {
  const navigate = useNavigate();
  const userContext = useUserContext();
  const editEventContext = useEditEventContext();
  const backgroundContext = useBackgroundContext();
  const toastContext = useToastContext();
  const addressInputRef = useRef<HTMLInputElement>(null);

  const [showPlaceName, setShowPlaceName] = useState(false);
  const [createDisabled, setCreateDisabled] = useState(true);

  useEffect(() => {
    if (event.location.placeName) {
      setShowPlaceName(true);
    }
  }, [event.location.placeName]);

  const [startTime, setStartTime] = useState<string>(
    (event.startTime ? convertTimestampToTimeString(event.startTime, event.timeZone) : '' || '')
  );

  const [endTime, setEndTime] = useState<string>(
    (event.endTime ? convertTimestampToTimeString(event.endTime, event.timeZone) : '' || '')
  );

  const firstUpdate = useRef(!event.isFromInspiration && !event.IsFromQuickCreate);
  const [day] = useState<Date | null>(
    event.date ? event.date : (event.startTime ? convertTimestampToDate(event.startTime, event.timeZone) : null)
  );

  useEffect(() => {
    if (day !== null) {
      const startDatetime = fromZonedTime(startTime, event.timeZone).getTime();
      const endDatetime = fromZonedTime(endTime, event.timeZone).getTime();

      editEventContext.setTimes(startDatetime, endDatetime ?? 0);
      return;
    }
  }, [day, startTime, endTime]);

  useEffect(() => {
    if (!firstUpdate.current) {
      void calculatePalette();
    }
    firstUpdate.current = false;
  }, [event.photoUrl]);

  useEffect(() => {
    if (!event.isCreate) {
      return;
    }

    const missingFields: string[] = [];
    if (!event.title) {
      missingFields.push('title');
    }
    if (!event.startTime) {
      missingFields.push('start time');
    }
    if (!event.photoUrl) {
      missingFields.push('image');
    }
    if (!event.location.address?.address1) {
      missingFields.push('location');
    }
    setCreateDisabled(missingFields.length > 0);

    if (missingFields.length > 0) {
      const article = missingFields[0] === 'image' ? 'an' : 'a';

      let items: string;
      if (missingFields.length === 1) {
        items = missingFields[0];
      } else if (missingFields.length === 2) {
        items = missingFields.join(' and ');
      } else {
        const lastField = missingFields.pop();
        items = `${missingFields.join(', ')}, and ${lastField}`;
      }

      toastContext.show({
        contents: `You still need ${article} ${items} to create your event.`,
        actionText: userContext.isLoggedIn() ? 'Save draft.' : 'Log in to save draft.',
        onActionClick: onSaveDraft
      });
    } else {
      toastContext.hide();
    }

    return () => {
      toastContext.hide();
    };
  }, [event.photoUrl, event.title, event.description, event.startTime, event.location.address?.address1]);

  const calculatePalette = async () => {
    if (event.photoUrl && event.photoUrl !== editEventContext.previousEvent?.photoUrl) {
      const dataUrl = await getPhotoDataUrl(event.photoUrl);
      const img = new Image();
      img.crossOrigin = 'Anonymous';
      img.src = dataUrl;

      img.onload = () => {
        const colors = getPalette(img);
        if (colors) {
          editEventContext.setColors(colors);
        }
      };
    }
  };

  const handleInputChange = (name: string, value: string) => {
    if (name === 'description') {
      editEventContext.setDescription(value);
    } else if (name === 'hosted') {
      editEventContext.setHostedBy(value);
    } else if (name === 'hostEmail') {
      editEventContext.setHostEmail(value);
    } else if (name === 'title') {
      editEventContext.setTitle(value);
    } else if (name === 'subtitle') {
      editEventContext.setSubtitle(value);
    }
  };

  const handleAddressChange = (addressStr: string, address?: TAppAddress, placeName?: string) => {
    editEventContext.setLocation(new IULocation({
      displayName: addressStr,
      address: address,
      placeName: placeName
    }));
    editEventContext.updateFormErrors({
      ...event.errors,
      invalidLocation: false
    });
  };

  const handlePlaceNameChange = (placeName?: string) => {
    editEventContext.setLocation(new IULocation({
      ...event.location,
      placeName
    }));
  };

  const handleCancelPlaceName = () => {
    handlePlaceNameChange(undefined);
    setShowPlaceName(false);
  };

  const onStartTimeChange = (start: string) => {
    setStartTime(start);
    updateDate(start, endTime);
  };

  const onEndTimeChange = (end: string) => {
    setEndTime(end);
    updateDate(startTime, end);
  };

  const handleLatLonChange = async (lat: number, lng: number) => {
    const timeZone = await calculateTimeZone(lat, lng, event?.startTime);
    editEventContext.setTimeZone(timeZone);
    updateDate(startTime, endTime);
  };

  const updateDate = (startTimeStr: string, endTimeStr: string) => {
    if (startTimeStr !== '') {
      const startTime = fromZonedTime(startTimeStr, event.timeZone).getTime();
      const endTime: number | undefined = endTimeStr !== '' ? fromZonedTime(endTimeStr, event.timeZone).getTime() : undefined;

      const isValid = startTime !== undefined && (!endTimeStr || (endTime !== undefined && endTime > startTime));
      if (isValid) {
        editEventContext.setTimes(startTime, endTime ?? undefined);
        return;
      }
    }
  };

  const onInvalidLocation = () => {
    editEventContext.updateFormErrors({
      ...event.errors,
      invalidLocation: true
    });
  };

  const onInvalidLocationInput = (e: FormEvent) => {
    e.preventDefault();
    editEventContext.setFieldInvalid(UEventFormField.Location);
  };

  const onSaveDraft = () => {
    if (userContext.isLoggedIn()) {
      navigate('/events', { state: { activeTab: EventsFilterType.Drafts } });
    } else {
      navigate('/event/draft/create/action');
    }
  };

  let placeNameContent: ReactNode = null;
  if (event.location.address) {
    if (showPlaceName) {
      placeNameContent = <PillInputWithIcon
        icon={<TinyText>AKA</TinyText>}
        value={event.location.placeName}
        onChange={handlePlaceNameChange}
        inputRef={addressInputRef}
        placeholder=""
        onCancel={handleCancelPlaceName}
        expanding={true}
      />;
    } else {
      placeNameContent = <PillInputActionButton onClick={() => setShowPlaceName(true)} $width="250px" type="button">
        <TinyText>AKA</TinyText>
        Add display name
      </PillInputActionButton>;
    }
  }

  return <Form ref={formRef} onSubmit={(e) => e.preventDefault()}>
    <NewContent>
      <GeneratedImageSelection event={event} excludeImage={false} onAddImage={onAddImage}/>
      <NewContentText>
        <NewContentTitle>
          <ExpandingTextInput
            placeholder={'Event Title*'}
            value={event.title}
            textColor="#FFF"
            onChange={(value) => handleInputChange('title', value)}
            onInvalid={() => editEventContext.setFieldInvalid(UEventFormField.Title)}
            error={event.errors.invalidTitle ? 'Enter a valid title' : undefined}
            errorRef={(div: HTMLDivElement) => editEventContext.setFormErrorDiv(UEventFormField.Title, div)}
            required={true}
            noMargin={true}
            noVertMargin={true}
            fontSize={'40px'}
            excludeBackground={true}
          />
        </NewContentTitle>

        <ActionRow>
          <WhiteButton type="button" onClick={() => {
            onSave();
          }} disabled={event.isCreate && createDisabled}>
            {event.isCreate ? 'Create' : 'Save'}
          </WhiteButton>
          <WhiteButton type="button" onClick={onCancel}>
            Cancel
          </WhiteButton>
        </ActionRow>

        <CustomEventModules>
          <EventModule>
            <EventModuleHeading>
              Place & time
            </EventModuleHeading>
            <EventDetails>
              <EventDetailsCell>
                <EventDetailsIconWrapper>
                  <LocationMarker/>
                </EventDetailsIconWrapper>
              </EventDetailsCell>
              <EventDetailsCell>
                <EventDetailsCellInnerForAutocomplete>
                  <PacContainerWidthStyle $width={500}/>
                  <AddressAutocomplete
                    inputRef={addressInputRef}
                    onSelect={handleAddressChange}
                    onSelectLatLon={handleLatLonChange}
                    validator={validEventLocation}
                    onInvalid={onInvalidLocation}>
                    <PillInput
                      value={event.location.displayName}
                      onChange={handleAddressChange}
                      required={true}
                      inputRef={addressInputRef}
                      onInvalid={onInvalidLocationInput}
                      placeholder="Event address*"
                      expanding={true}
                    />
                  </AddressAutocomplete>
                  {placeNameContent}
                </EventDetailsCellInnerForAutocomplete>

                {
                  (event.errors.invalidLocation || editEventContext.isFieldInvalid(UEventFormField.Location)) &&
                  <ErrorContainer>
                    Select a valid location from Google Maps
                  </ErrorContainer>
                }
              </EventDetailsCell>

              <EventDetailsCell>
                <EventDetailsIconWrapper>
                  <AddToCal/>
                </EventDetailsIconWrapper>
              </EventDetailsCell>
              <EventDetailsCell>
                <EventDetailsCellInner>
                  <TimeFormInput
                    startTime={startTime}
                    endTime={endTime}
                    timeZone={event.timeZone}
                    onStartChange={onStartTimeChange}
                    onEndChange={onEndTimeChange}
                    onInvalid={() => editEventContext.setFieldInvalid(UEventFormField.Time)}
                    error={event.errors.invalidStartDate ? 'Start date cannot be in the past' :
                      event.errors.invalidDatePair ? 'End time must be after start time' :
                        event.errors.invalidDuration ? 'Event cannot be over 2 weeks long' : undefined}
                    required={true}
                    errorRef={(div: HTMLDivElement) => editEventContext.setFormErrorDiv(UEventFormField.Time, div)}
                  />
                </EventDetailsCellInner>
              </EventDetailsCell>
            </EventDetails>
          </EventModule>

          <EventModule>
            <EventModuleHeading>
              Description
            </EventModuleHeading>
            <ExpandingTextInput
              value={event.description}
              charLimit={1000}
              onChange={(value) => handleInputChange('description', value)}
              textColor="var(--shine-system-button-text-color)"
              fontSize={'20px'}
              backgroundColor={extractSameRGBWithAlpha(backgroundContext.colors.highlightText, 0.50)}
              placeholder="Write description here..."
              as={PillDiv}
            />
          </EventModule>
        </CustomEventModules>
      </NewContentText>
    </NewContent>
  </Form>;
};

const Form = styled.form`
  width: 100%;
`;

export const ErrorContainer = styled.div`
  font-size: 16px;
  color: var(--shine-system-error-color);
  font-weight: bold;
  margin: 1px 0 5px 16px;
  text-align: left;
`;

export const NewContent = styled.div`
  display: flex;
  max-width: 684px;
  flex-direction: column;
  align-items: center;
  text-align: center;
  color: rgba(255, 255, 255, 0.9);
  margin: auto;
  padding: 25px;
  box-sizing: border-box;

  --shine-system-text-color: rgba(255, 255, 255, 0.9);
  --shine-system-button-text-color: rgba(255, 255, 255, 0.9);
`;

const NewContentTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 25px;

  @media (${DeviceQueries.mobile}) {
    margin-top: 10px;
  }
`;

const NewContentText = styled.div`
  width: 100%;
`;

const TinyText = styled.span`
  font-size: 10px;
  font-weight: 700;
`;

const CustomEventModules = styled(EventModules)`
  width: auto;
`

export default EventForm;

