
export class TimeoutError extends Error {}

export const promiseTimeout = async <T,>(promise: Promise<T>, timeoutMs: number): Promise<T> => {
  const result: T | TimeoutError = await Promise.race([
    promise,
    new Promise<TimeoutError>((res) => setTimeout(() => res(new TimeoutError()), timeoutMs))
  ]);

  if (result instanceof TimeoutError) {
    throw result;
  } else {
    return result;
  }
}

export const promiseTimeoutWithDefault = async <T,>(promise: Promise<T>, timeoutMs: number, defaultValue: T): Promise<T> => {
  return Promise.race([
    promise,
    new Promise<T>((res) => setTimeout(() => res(defaultValue), timeoutMs))
  ]);
}
