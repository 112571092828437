import React from 'react';
import styled from 'styled-components';

import { ActionMenuLabel } from '../../components/EventForm/ActionMenuCommon';
import ExpandingTextInput from '../../components/ExpandingTextInput';

interface Props {
  searchQuery: string,
  onSearchQueryChange: (value: string) => void;
  onSubmit: (value: string) => void;
}

const BackgroundAnimationPicker = ({ searchQuery, onSearchQueryChange, onSubmit }: Props) => {
  return <AnimationPickerContainer>
    <ActionMenuLabel>Video search</ActionMenuLabel>
    <ExpandingTextInput
      placeholder={'Fireworks'}
      value={searchQuery}
      textColor="currentColor"
      onChange={onSearchQueryChange}
      onSubmit={onSubmit}
      noVertMargin={true}
      isForSideBar={true}
    />
  </AnimationPickerContainer>;
};

const AnimationPickerContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export default BackgroundAnimationPicker;
