import React, { FormEvent, Ref, useState } from 'react';
import styled from 'styled-components';

import { ErrorContainer } from '../forms/Input';
import { getDefaultEndTimeString } from '../../lib/time';
import Clock from '../icons/Clock';
import PillInput from './PillInput';
import { PillInputActionButton } from './Common';

import CloseIcon from 'Common/src/components/icons/CloseIcon';

interface Props {
  disabled?: boolean;
  startTime: string; // Ensure startTime is in "YYYY-MM-DDTHH:MM" format
  endTime: string; // Ensure endTime is in "YYYY-MM-DDTHH:MM" format
  onStartChange: (startTime: string) => void;
  onEndChange: (endTime: string) => void;
  onInvalid?: (e: FormEvent) => void;
  error?: string;
  errorRef?: Ref<HTMLDivElement>;
  required?: boolean;
  timeZone: string;
}

const TimeFormInput = ({
                         disabled,
                         startTime,
                         endTime,
                         onStartChange,
                         onEndChange,
                         onInvalid,
                         error,
                         required,
                         timeZone
                       }: Props) => {
  const [invalid, setInvalid] = useState(false);
  const [showEndTimeInput, setShowEndTimeInput] = useState<boolean>(endTime.trim() !== '');

  const myOnStartChange = (value: string) => {
    setInvalid(false);
    onStartChange(value);
  };

  const onInputInvalid = (e: FormEvent) => {
    e.preventDefault();
    setInvalid(true);
    if (onInvalid) {
      onInvalid(e);
    }
  };

  const addEndTimeInput = () => {
    setShowEndTimeInput(true);
    const defaultEndTime = getDefaultEndTimeString(startTime, timeZone);
    onEndChange(defaultEndTime);
  };

  const removeEndTimeInput = () => {
    setShowEndTimeInput(false);
    onEndChange('');
  };

  const endTimeComponent = showEndTimeInput
    ? <>
      <div>-</div>
      <TimeInput
        value={endTime}
        onChange={onEndChange}
        disabled={disabled}
        type="datetime-local" // Changed from 'time' to 'datetime-local'
      />
      <CloseIconContainer><CloseIcon onClick={removeEndTimeInput}/></CloseIconContainer>
    </>
    : <PillInputActionButton onClick={addEndTimeInput}>
      <Clock size={20}/>
      Add end time
    </PillInputActionButton>;

  return <Container>
    <Row>
      <TimeInput
        value={startTime}
        onChange={myOnStartChange}
        onInvalid={onInputInvalid}
        disabled={disabled}
        type="datetime-local" // Changed from 'time' to 'datetime-local'
        required={required}
      />
      {endTimeComponent}
    </Row>
    <Row>
      {invalid ? <ErrorContainer>This is a required field</ErrorContainer> : null}
      {error ? <ErrorContainer>{error}</ErrorContainer> : null}
    </Row>
  </Container>;
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Row = styled.div`
  display: flex;
  gap: 4px;
  flex-wrap: wrap;
  align-items: center;
`;

const TimeInput = styled(PillInput)`
  &::-webkit-calendar-picker-indicator {
    background: none;
    display: none;
  }
`;

const CloseIconContainer = styled.div`
  cursor: pointer;
`;

export default TimeFormInput;
