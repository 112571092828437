import React from 'react';
import styled from 'styled-components';

import MessageText from './MessageText';

import { TAppElkMessage } from 'TProtocol/prototypes/events/messages';

const dateFormat = new Intl.DateTimeFormat('en-US', { month: 'short', day: 'numeric' });
const timeFormat = new Intl.DateTimeFormat('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });

const MessageBlastHistoryRow = ({ message }: { message?: TAppElkMessage }) => {

  if (message === undefined) {
    return null;
  }

  const sentTime = message?.sentTime.toNumber();
  return <Container>
    <SentTime>
      {dateFormat.format(sentTime)}, {timeFormat.format(sentTime)}
    </SentTime>
    <MessageText message={message?.message}/>
  </Container>;
};

const Container = styled.div`
`;

const SentTime = styled.div`
  font-size: 14px;
  opacity: .5;
`;

export default MessageBlastHistoryRow;
