import React, { ReactElement, useEffect } from 'react';

import { useUserContext } from '../contexts/UserContext';

import { logSumoEvent, ULogApplication, ULogSeverity, ULogTag } from 'Common/src/api/SumoLogicApi';

const Performance = (): ReactElement => {
  const userContext = useUserContext();

  useEffect(() => {
    const perfObserver = (list: PerformanceObserverEntryList, observer: PerformanceObserver) => {
      list.getEntries().forEach((entry: PerformanceEntry) => {
        if (entry.entryType === 'navigation' || (isResourceTiming(entry) && entry.initiatorType === 'xmlhttprequest')) {
          void logSumoEvent({
            app: ULogApplication.ELK,
            severity: ULogSeverity.INFO,
            userId: userContext.id,
            tag: ULogTag.Network,
            message: JSON.stringify(entry)
          });
        }
      });
    };

    const isResourceTiming = (entry: PerformanceEntry): entry is PerformanceResourceTiming => {
      return entry.entryType === 'resource';
    };

    const observer = new PerformanceObserver(perfObserver);
    observer.observe({ entryTypes: ['navigation', 'resource'] });

    return () => {
      observer.disconnect();
    };
  }, []);

  return <></>;
};

export default Performance;
